import { useRef } from 'react';

import styled from '@mui/material/styles/styled';
import Tabs, { type TabsProps } from '@mui/material/Tabs';

import type { DefaultTheme } from '@mui/styles';

const StyledTabs = styled((props: TabsProps) => {
  const tabSx = useRef((theme: DefaultTheme) => ({
    width: 'fit-content',
    minHeight: 'fit-content',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  })).current;

  return (
    <Tabs
      {...props}
      sx={tabSx}
      variant="scrollable"
      scrollButtons={false}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  );
})(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(0.5),
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    maxWidth: theme.spacing(5),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

export default StyledTabs;
