import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { darken } from '@mui/system';
import { useTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

import { toMoment } from 'src/utils/datetime';
import { colors } from 'src/utils/customColors';

import messages from '../../messages';

import type { DefaultTheme } from '@mui/styles';

const iconButtonStyleSx = (bgColor?: string) => (theme: DefaultTheme) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: bgColor || theme.palette.error.main,
  '&:hover': {
    backgroundColor: darken(bgColor || theme.palette.error.main, 0.2),
  },
});

const iconStyleSx = (theme: DefaultTheme) => ({
  color: theme.palette.common.white,
  fontSize: theme.spacing(1.25),
});

interface Props {
  monthIndex: number;
  months: string[];
  data?: Record<string, UCM.CalendarJobType>;
  onReject: (data: UCM.CalendarJobType) => void;
  onApprove: (data: UCM.CalendarJobType) => void;
  onDayPress: (day: string | null) => void;
}

const iconButtonSx = (theme: DefaultTheme) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.25, 1),
  background: colors.orangeRed,
});

export default function JobCalendarDays({
  monthIndex,
  months,
  data,
  onReject,
  onApprove,
  onDayPress,
}: Props) {
  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState<string | null>(null);

  const handlePressReject = useCallback(
    (event: React.MouseEvent<HTMLElement>, data: UCM.CalendarJobType) => {
      event.stopPropagation();
      onReject(data);
    },
    [onReject],
  );

  const handlePressApprove = useCallback(
    (event: React.MouseEvent<HTMLElement>, data: UCM.CalendarJobType) => {
      event.stopPropagation();
      onApprove(data);
    },
    [onApprove],
  );

  const handlePressDay = useCallback(
    (date: string) => {
      const day = selected !== date ? date : null;

      onDayPress(day);
      setSelected(day);
    },
    [onDayPress, selected],
  );

  const days = useMemo(() => {
    const month = months[monthIndex];
    if (!month) return null;

    const currMonth = toMoment(month)?.month();
    const lastDay = toMoment(month)?.endOf('month').endOf('week');
    const currDay = toMoment(month)?.startOf('month').startOf('week');

    let currWeek = null;
    let dates: Array<JSX.Element> = [];

    const weeks: Array<JSX.Element> = [];

    while (currDay && lastDay && currDay < lastDay) {
      if (currWeek !== currDay.week()) {
        dates = [];
        currWeek = currDay.week();

        weeks.push(
          <Grid key={currWeek} container size={12}>
            {dates}
          </Grid>,
        );
      }

      const disabledDay = currDay.month() !== currMonth;
      const dayColor = disabledDay ? 'textDisabled' : 'primary';
      const currDayString = currDay?.format(moment.HTML5_FMT.DATE);
      const currDayData = data?.[currDayString];

      const bgColor = (theme: DefaultTheme) => {
        switch (currDayData?.status) {
          case 'accepted':
          case 'booked':
            return colors.green;
          case 'rejected':
          case 'unbooked':
            return theme.palette.error.light;
          default:
            return theme.palette.common.white;
        }
      };

      dates.push(
        <Grid
          key={currDayString}
          flex={1}
          px={0.5}
          height={90}
          onClick={() => handlePressDay(currDayString)}
          sx={(theme) => ({
            pointerEvents: disabledDay ? 'none' : 'pointer',
            borderBottom: `thin solid ${theme.palette.divider}`,
            borderRight: `thin solid ${theme.palette.divider}`,
            backgroundColor:
              currDayString === selected ? colors.lighterBlue : bgColor(theme),
          })}
        >
          <Typography variant="body2" color={dayColor}>
            {currDay.format('DD')}
          </Typography>

          {currDayData?.isWaitlisted && (
            <Typography variant="caption">
              {formatMessage(messages.waitlistedLabel)}
            </Typography>
          )}

          {currDayData && (
            <Box mt={0.5}>
              <JobBadge
                label="M"
                count={currDayData.slotsNumber}
                filled={currDayData.bookedCount}
              />
            </Box>
          )}
          <Grid container spacing={0.5}>
            {currDayData &&
              (currDayData?.status === 'unbooked' ||
                currDayData?.status === 'cancelled' ||
                currDayData?.status === 'applied') && (
                <Grid>
                  <IconButton
                    size="small"
                    sx={iconButtonStyleSx(colors.lightGreen)}
                    onClick={(event) => handlePressApprove(event, currDayData)}
                  >
                    <AddOutlinedIcon sx={iconStyleSx} />
                  </IconButton>
                </Grid>
              )}
            {currDayData &&
              (currDayData?.status === 'booked' ||
                currDayData?.status === 'applied') && (
                <Grid>
                  <IconButton
                    size="small"
                    sx={iconButtonStyleSx()}
                    onClick={(event) => handlePressReject(event, currDayData)}
                  >
                    <RemoveOutlinedIcon sx={iconStyleSx} />
                  </IconButton>
                </Grid>
              )}
          </Grid>
          {currDayData?.status === 'rejected' && (
            <Grid>
              <IconButton
                size="small"
                sx={iconButtonSx}
                onClick={(event) => handlePressApprove(event, currDayData)}
              >
                <Typography
                  fontSize={8}
                  color="common.white"
                  lineHeight="normal"
                >
                  {formatMessage(messages.reapplyLabel)}
                </Typography>
              </IconButton>
            </Grid>
          )}
          {currDayData?.status === 'accepted' && (
            <Grid>
              <IconButton
                size="small"
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  padding: theme.spacing(0.25, 1),
                  border: `thin solid ${theme.palette.primary.main}`,
                })}
                onClick={(event) => handlePressReject(event, currDayData)}
              >
                <Typography
                  fontSize={8}
                  color="common.black"
                  lineHeight="normal"
                >
                  {formatMessage(messages.cancelLabel)}
                </Typography>
              </IconButton>
            </Grid>
          )}
        </Grid>,
      );
      currDay.add(1, 'day');
    }
    return weeks;
  }, [data, months, monthIndex, selected]);

  return <>{days}</>;
}

interface JobBadgeProps {
  label: string;
  filled: number;
  count: number;
}

const stackSx = (theme: DefaultTheme) => ({
  marginBottom: theme.spacing(0.25),
  padding: theme.spacing(0.25, 0.5),
  width: 'fit-content',
});

const JobBadge = ({ label, count, filled }: JobBadgeProps) => {
  const remainingSlots = count - filled;
  const theme = useTheme();

  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      bgcolor={colors.lighterBrown}
      borderRadius={theme.spacing(1)}
      sx={stackSx}
    >
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body2" color={colors.lightBrown}>
        {filled}/{count}
      </Typography>

      {remainingSlots > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml={theme.spacing(0.5)}
          bgcolor={colors.orangeRed}
          width={theme.spacing(1.5)}
          height={theme.spacing(1.5)}
          borderRadius={theme.spacing(1)}
        >
          <Typography fontSize={theme.spacing(1)} color="common.white">
            {remainingSlots}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
