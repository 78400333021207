import { useIntl } from 'react-intl';
import moment, { type Moment } from 'moment';
import { useMutation } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { queryClient } from 'src/config';
import { putRequest } from 'src/utils/request';
import { forceString } from 'src/utils/datetime';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

interface ActivateContractParamsType {
  contractUuid: string;
  endDate: Moment | null;
  startDate: Moment | null;
}

const activateContract = async ({
  endDate,
  startDate,
  contractUuid,
}: ActivateContractParamsType) => {
  const params = {
    user_contract: {
      end_date: forceString(endDate, moment.HTML5_FMT.DATE),
      start_date: forceString(startDate, moment.HTML5_FMT.DATE),
    },
  };

  return await putRequest<RequestSimplifiedData>(
    API.PUT.activateContract(contractUuid),
    params,
    { headers: { Accept: 'application/vnd.falcon.v2+json' } },
  );
};

export const useActivateContract = () => {
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: activateContract,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.contractActivated));
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CONTRACTS],
      });
    },
    onError: handleError,
  });
};
