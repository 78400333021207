import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import download from 'src/utils/download';
import {
  type ErrorType,
  useMessageHandler,
} from 'src/utils/hooks/useMessageHandler';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import messages from '../../messages';

type DownloadContractFetchParamsType = {
  contractId: string;
  contractUuid: string;
  onSettled?: () => void;
};

const downloadContract = async ({
  contractId,
  contractUuid,
}: DownloadContractFetchParamsType) => {
  const data = await getRequest(API.GET.getDownloadContract(contractUuid), {
    responseType: 'blob',
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
  download(data.data, `contract_${contractId}.pdf`, 'application/pdf');
  return data.data;
};

export const useDownloadContract = (
  { contractId, contractUuid, onSettled }: DownloadContractFetchParamsType,
  config: { enabled?: boolean } = { enabled: false },
) => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  const { isSuccess, isError, error } = useQuery<unknown, ErrorType>({
    queryKey: [QUERY_KEYS.CONTRACT_DOWNLOAD],
    queryFn: () => downloadContract({ contractId, contractUuid }),
    ...config,
  });

  useEffect(() => {
    if (isSuccess) {
      handleSuccess(formatMessage(messages.contractDownloaded), 1000);
      client.removeQueries({ queryKey: [QUERY_KEYS.CONTRACT_DOWNLOAD] });
    }

    if ((isSuccess || isError) && onSettled) {
      onSettled();
    }

    if (isError && error) {
      handleError(error);
    }
  }, [isSuccess, isError]);
};
