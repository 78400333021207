import React from 'react';

import TextField, { type TextFieldProps } from '@mui/material/TextField';

type TextInputProps = TextFieldProps & {
  EndIcon?: React.ElementType;
  readOnly?: boolean;
};

const getSlotProps = (
  readOnly?: TextInputProps['readOnly'],
  EndIcon?: TextInputProps['EndIcon'],
) =>
  readOnly || EndIcon
    ? {
        input: {
          endAdornment: EndIcon && <EndIcon />,
          readOnly: readOnly,
        },
      }
    : undefined;

export default function TextInput({
  value,
  EndIcon,
  variant,
  readOnly,
  ...props
}: TextInputProps) {
  return (
    <TextField
      value={value ?? ''}
      variant={variant ?? 'standard'}
      slotProps={getSlotProps(readOnly, EndIcon)}
      // sx={{
      //   input: { fontSize: 16 },
      //   textarea: { fontSize: 16 },
      // }}
      {...props}
    />
  );
}
