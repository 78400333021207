/*
 * StudentDetails Messages
 *
 * This contains all the text for the StudentDetails component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.StudentDetails';

export default defineMessages({
  studentDetailsLabel: {
    id: `${scope}.studentDetailsLabel`,
    defaultMessage: 'Student details',
  },
  yesLabel: { id: `${scope}.yesLabel`, defaultMessage: 'Yes' },
  noLabel: { id: `${scope}.noLabel`, defaultMessage: 'No' },
  ageLabel: { id: `${scope}.ageLabel`, defaultMessage: 'Age: {age}' },
  ratingLabel: {
    id: `${scope}.ratingLabel`,
    defaultMessage: 'Rating: {rating}',
  },
  preScreenScoreLabel: {
    id: `${scope}.preScreenScoreLabel`,
    defaultMessage: 'Pre-screen Score: {score}',
  },
});
