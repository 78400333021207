import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, serialize } from 'src/utils/request';

const getAssetsPictures = async (user_id?: string) => {
  const params = serialize({ user_id }, 'filters').toString();

  const { data } = await getRequest(API.GET.getAssetsPictures(params), {
    headers: { Accept: 'application/vnd.falcon.v1+json' },
  });

  return mapData(data);
};

export const useGetAssetsPictures = ({
  uuid,
  enabled = false,
}: {
  uuid?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ASSETS_PICTURES, uuid],
    queryFn: () => getAssetsPictures(uuid),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

interface UserImage {
  contentType: string;
  fileName: string;
  id: string;
  mediaType: string;
  original: string;
  type: string;
  uploadedAt: string;
  uuid: string;
}
type IncludedData = Record<string, any>; // eslint-disable-line

// eslint-disable-next-line
function mapData(res: any): UserImage[] {
  const mediaMap = new Map(
    res.included
      .filter((item: IncludedData) => item.type === 'media')
      .map((media: IncludedData) => [media.id, media]),
  );

  // eslint-disable-next-line
  return res.data.map((item: any) => {
    const media = mediaMap.get(item.id) as any; // eslint-disable-line
    if (!media) return;

    return {
      contentType: media.attributes.asset_content_type,
      fileName: media.attributes.asset_file_name,
      id: item.id,
      mediaType: media.attributes.media_type_code,
      original: media.attributes.medium_asset_url,
      type: item.type,
      uploadedAt: item.meta.timestamps.created,
      uuid: media.attributes.uuid,
    };
  });
}
