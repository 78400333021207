import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

export const Styled = {
  Container: styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    border: `thin solid ${theme.palette.grey[300]}`,
  })),
  Cell: styled(TableCell)(({ theme }) => ({
    '&:not(:last-of-type)': {
      borderRight: `thin solid ${theme.palette.grey[300]}`,
    },
    borderBottom: 'none',
  })),
  Row: styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.white,
    },
  })),
  Pagination: styled(TablePagination)(({ theme }) => ({
    border: 0,
    borderTop: `thin solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
  })),
};
