import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { isEmpty, map } from 'lodash';
import { useIntl } from 'react-intl';
import ImageGallery from 'react-image-gallery';

import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import { forceString } from 'src/utils/datetime';
import { formatNumber } from 'src/utils/standards';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import { useDraggable } from 'src/utils/hooks/useDraggable';
import {
  useGetAssetsPictures,
  useGetCandidateDetails,
  useGetFieldsCategory,
  useGetInternalAttributes,
} from 'src/pages/ProjectDetailsPage/api';

import messages from './messages';

const SliderIconStyles = { color: '#fff', fontSize: '12px' };
const SliderButtonStyles = { position: 'absolute', bottom: 0, zIndex: 1 };
const FullScreenButton = { position: 'absolute', top: 0, right: 0, zIndex: 1 };

interface Props {
  candidate?: string;
  handleClose: () => void;
}

export default function StudentDetails({ candidate, handleClose }: Props) {
  const { formatMessage } = useIntl();

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside({ ref, callback: handleClose });

  const { position, handleMouseDown } = useDraggable({ ref });

  const { data: user, isPending } = useGetCandidateDetails({
    uuid: candidate,
    enabled: Boolean(candidate),
  });

  const { data: pictures } = useGetAssetsPictures({
    uuid: user?.data?.id,
    enabled: Boolean(user?.data?.id),
  });

  const { data: fieldsCategory } = useGetFieldsCategory({
    enabled: Boolean(candidate),
  });

  const { data: internalAttribures, isPending: pendingFields } =
    useGetInternalAttributes({
      userUuid: candidate,
      fields: fieldsCategory!,
      enabled: Boolean(fieldsCategory) && Boolean(candidate) && Boolean(user),
    });

  const status = useMemo(() => {
    switch (user?.data?.status?.code) {
      case 'completed':
        return { Icon: CheckOutlinedIcon, color: '#72A98F' };
      default:
        return { Icon: null, color: '#002E40' };
    }
  }, [user?.data?.status?.code]);

  // eslint-disable-next-line
  const resolveUserDynamicField = useCallback((dynamicField: any) => {
    if (!dynamicField) return null;
    const { label, description, values, type } = dynamicField;
    const value = () => {
      if (!Array.isArray(values) || values.length === 0) return '-';

      switch (type.code) {
        case 'date':
          return forceString(values[0].answer) || '-';
        case 'single-selectable':
          return values[0]?.option?.inputValue || '-';
        case 'number':
          return (
            map(values, ({ answer }) => formatNumber(answer, 'de')).join(
              ', ',
            ) || '-'
          );
        case 'multi-selectable':
          return (
            map(values, ({ option }) => option?.inputValue).join(', ') || '-'
          );
        case 'boolean':
          return values[0].answer
            ? formatMessage(messages.yesLabel)
            : formatMessage(messages.noLabel);
        default:
          return map(values, ({ answer }) => answer).join(', ') || '-';
      }
    };

    return (
      <Stack flex={1} display={'flex'}>
        <Typography fontSize={12} fontWeight={600}>
          {label}:
        </Typography>
        <Typography fontSize={12} fontWeight={600}>
          {value()}
        </Typography>
        {description && (
          <Typography fontSize={8} fontWeight={600}>
            {description}
          </Typography>
        )}
      </Stack>
    );
  }, []);

  const renderLoader = () => {
    return (
      <Box
        display={'flex'}
        flex={1}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CircularProgress size={20} />
      </Box>
    );
  };

  const { Icon, color } = status;

  return (
    <Paper
      ref={ref}
      sx={{
        position: 'fixed',
        top: position.y,
        left: position.x,
        zIndex: 10,
        width: '450px',
        height: '440px',
      }}
    >
      {isPending || pendingFields ? (
        renderLoader()
      ) : (
        <Stack height="100%">
          <Grid
            container
            px={1.5}
            py={1}
            sx={{ borderBottom: '0.497px solid #E2E2E2', cursor: 'move' }}
            alignItems="center"
            justifyContent="space-between"
            onMouseDown={handleMouseDown}
          >
            <Typography fontSize={14} fontWeight={600}>
              {formatMessage(messages.studentDetailsLabel)}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid flex={1} container p={1.5}>
            {pictures && (
              <Box
                width={150}
                height={190}
                borderRadius={1}
                overflow="hidden"
                mr={1}
                position="relative"
                bgcolor="#333"
              >
                {
                  <ImageGallery
                    items={pictures}
                    showThumbnails={false}
                    showPlayButton={false}
                    renderLeftNav={(onClick, disabled) => (
                      <IconButton
                        onClick={onClick}
                        disabled={disabled}
                        sx={{ ...SliderButtonStyles, left: 0 }}
                      >
                        <ArrowBackOutlinedIcon sx={SliderIconStyles} />
                      </IconButton>
                    )}
                    renderRightNav={(onClick, disabled) => (
                      <IconButton
                        onClick={onClick}
                        disabled={disabled}
                        sx={{ ...SliderButtonStyles, right: 0 }}
                      >
                        <ArrowForwardOutlinedIcon sx={SliderIconStyles} />
                      </IconButton>
                    )}
                    renderFullscreenButton={(onClick, disabled) => (
                      <IconButton
                        onClick={onClick}
                        disabled={disabled}
                        sx={FullScreenButton}
                      >
                        <FullscreenOutlinedIcon sx={SliderIconStyles} />
                      </IconButton>
                    )}
                  />
                }
              </Box>
            )}
            <Stack gap={0.5}>
              <Typography fontSize={12} fontWeight={600}>
                {user?.data?.id} - {user?.data?.firstName}
                {user?.data?.lastName}
              </Typography>
              <Grid container alignItems="center" gap={0.5}>
                {Icon && (
                  <Icon sx={{ color: color, width: '12px', height: '12px' }} />
                )}
                <Typography fontSize={8} fontWeight={600} color={color}>
                  {user?.data?.status.name}
                </Typography>
              </Grid>
              <Stack gap={0.5} mt={1}>
                <Typography fontSize={8} fontWeight={600}>
                  {formatMessage(messages.ageLabel, {
                    age: user?.data?.age ?? '-',
                  })}
                </Typography>
                <Typography fontSize={8} fontWeight={600}>
                  {formatMessage(messages.ratingLabel, {
                    rating: user?.data?.rating ?? '-',
                  })}
                </Typography>
                <Typography fontSize={8} fontWeight={600}>
                  {/* TODO - validate with backend */}
                  {formatMessage(messages.preScreenScoreLabel, {
                    score: user?.data?.rating ?? '-',
                  })}
                </Typography>
              </Stack>
              <Stack gap={0.5} mt={1}>
                <Typography fontSize={8} fontWeight={600}>
                  {user?.data?.email}
                </Typography>
              </Stack>
              <Grid container gap={0.5} mt={1} alignItems="center">
                {user?.data?.userLanguages.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <Typography
                      fontSize={8}
                      fontWeight={600}
                      color="#1E6A4F"
                      sx={{ textDecoration: 'underline' }}
                    >
                      {item.language.name}
                    </Typography>
                    {index !== user?.data?.userLanguages.length - 1 && (
                      <Box
                        bgcolor="#E2E2E2"
                        width="4px"
                        height="4px"
                        borderRadius="100%"
                      />
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Stack>
          </Grid>

          {!isEmpty(internalAttribures) && (
            <Stack sx={{ overflowX: 'auto' }} px={1.5} pb={1.5}>
              <Grid
                container
                spacing={2}
                width="100%"
                p={1.5}
                border="1px solid #A1A1A1"
                borderRadius={0.5}
              >
                {map(internalAttribures, (dynamicField) => (
                  <Grid key={dynamicField?.id} size={{ xs: 12, md: 6 }}>
                    {resolveUserDynamicField(dynamicField)}
                  </Grid>
                ))}
              </Grid>
            </Stack>
          )}
        </Stack>
      )}
    </Paper>
  );
}
