import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapTaskOptions = (
  option: UCM.ContractTaskOptionRawType,
): UCM.ContractTaskOptionType => ({
  value: option.code,
  label: option.name,
});

const getTaskOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.ContractTaskOptionRawType>
  >(API.GET.contractTaskOptions, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
  return data.data.map(({ attributes }) => mapTaskOptions(attributes));
};

export const useGetTaskOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACT_TASK_OPTIONS],
    queryFn: getTaskOptions,
    staleTime: 1000 * 60 * 10,
    ...config,
  });
};
