import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapOccupationOptions = (
  option: UCM.OccupationOptionRawType,
): UCM.OccupationOptionType => ({
  value: option.code,
  label: option.name,
});

const getOccupationOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.OccupationOptionRawType>
  >(API.GET.occupationOptions, {
    headers: {
      Accept: 'application/vnd.falcon.v4+json',
    },
  });
  return data.data.map(({ attributes }) => mapOccupationOptions(attributes));
};

export const useGetOccupationOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.OCCUPATION_OPTIONS],
    queryFn: getOccupationOptions,
    staleTime: 1000 * 60 * 10,
    ...config,
  });
};
