import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { queryClient } from 'src/config';
import { deleteRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

const deleteContract = async (contractUuid: string) => {
  return await deleteRequest<RequestSimplifiedData>(
    API.DELETE.deleteContract(contractUuid),
    { headers: { Accept: 'application/vnd.falcon.v2+json' } },
  );
};

export const useDeleteContract = () => {
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: deleteContract,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.contractDeleted));
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CONTRACTS],
      });
    },
    onError: handleError,
  });
};
