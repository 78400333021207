import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { postRequest, putRequest } from 'src/utils/request';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/ProjectDetailsPage/messages';

const updateUserRating = async ({
  jobUuid,
  applicationUuid,
  comment,
  score,
  userRatingUuid,
}: {
  jobUuid: string;
  applicationUuid: string;
  comment: string | null;
  score: number | null;
  userRatingUuid?: string;
}) => {
  const payload = {
    user_job_performance_rating: {
      comment,
      score,
    },
  };

  if (userRatingUuid) {
    return await putRequest(
      API.PUT.updateUserRating(jobUuid, applicationUuid, userRatingUuid),
      payload,
    );
  }

  return await postRequest(
    API.POST.createUserRating(jobUuid, applicationUuid),
    payload,
  );
};

export const useUpdateUserRating = () => {
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateUserRating,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.ratingSuccess));
    },
    onError: handleError,
  });
};
