import React, { useCallback, useRef, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';

import { colors } from 'src/utils/customColors';

const ITEM_HEIGHT = 48;

const menuSlotProps = {
  paper: { style: { maxHeight: ITEM_HEIGHT * 5 } },
};

interface MoreActionProps {
  loading?: boolean;
  onClose?: () => void;
  selectedOption?: string;
  iconColor?: keyof typeof colors;
  options: Array<{ label: string; onSelect?: () => void }>;
}

export default function MoreActions({
  options,
  onClose,
  loading,
  selectedOption,
  iconColor = 'black',
}: MoreActionProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const iconSx = useRef({ color: colors[iconColor] }).current;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    if (onClose) onClose();
  }, [onClose]);

  const handleOptionPress = useCallback(
    (event: React.MouseEvent<HTMLElement>, onSelect?: () => void) => {
      event.stopPropagation();
      if (onSelect) onSelect();
      handleClose();
    },
    [],
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ pointerEvents: loading ? 'none' : 'auto' }}
      >
        {loading ? (
          <CircularProgress size={22} sx={iconSx} />
        ) : (
          <MoreVertIcon sx={iconSx} />
        )}
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={menuSlotProps}
      >
        {options.map(({ label, onSelect }) => (
          <MenuItem
            key={label}
            selected={label === selectedOption}
            onClick={(event) => handleOptionPress(event, onSelect)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
