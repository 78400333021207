import { ReactNode, useCallback, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ExpansionPanelProps {
  header: string;
  children: ReactNode;
}

export default function ExpansionPanel({
  header,
  children,
}: ExpansionPanelProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary" />}>
        <Typography>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
