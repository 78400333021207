/*
 * JobCalendar Messages
 *
 * This contains all the text for the JobCalendar component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.JobCalendar';

export default defineMessages({
  waitlistedLabel: {
    id: `${scope}.waitlistedLabel`,
    defaultMessage: 'Waitlisted',
  },
  cancelLabel: {
    id: `${scope}.cancelLabel`,
    defaultMessage: 'Cancel',
  },
  reapplyLabel: {
    id: `${scope}.reapplyLabel`,
    defaultMessage: 'Re-apply',
  },
  confirmAcceptButton: {
    id: `${scope}.confirmAcceptButton`,
    defaultMessage: 'Accept Selected',
  },
  acceptModalLabel: {
    id: `${scope}.acceptModalLabel`,
    defaultMessage: 'Please specify a reason for accepting',
  },
  rebookApplicationConfirmMessage: {
    id: `${scope}.rebookApplicationConfirmMessage`,
    defaultMessage: 'Please specify a reason for uncancelling',
  },
  confirmRejectButton: {
    id: `${scope}.confirmRejectButton`,
    defaultMessage: 'Reject Selected',
  },
  rejectModalLabel: {
    id: `${scope}.rejectModalLabel`,
    defaultMessage: 'Please specify a reason for rejecting',
  },
  resetApplicationConfirmMessage: {
    id: `${scope}.resetApplicationConfirmMessage`,
    defaultMessage: 'Do you really want to apply back to this Job?',
  },
  cancelApplicantConfirmTitle: {
    id: `${scope}.cancelApplicantConfirmTitle`,
    defaultMessage: 'Please specify a reason for cancelling',
  },
});
