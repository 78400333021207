/*
 * PriorityChip Messages
 *
 * This contains all the text for the PriorityChip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.PriorityChip';

export default defineMessages({
  priority: {
    id: `${scope}.priority`,
    defaultMessage: 'Priority',
  },
});
