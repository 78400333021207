import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';
import head from 'lodash/head';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, resolveQueryString } from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';

interface CandidateType {
  uuid: string;
  email: string;
  last_name: string;
  first_name: string;
}

const mapCandidate = (candidate: CandidateType) => {
  return {
    userUuid: candidate.uuid,
    userEmail: candidate.email,
    userName: `${candidate.first_name} ${candidate.last_name}`,
  };
};

const getCandidates = async (filters: Record<string, string>) => {
  const query = resolveQueryString([filters]);

  const {
    data: { data },
  } = await getRequest<RequestDataArray<CandidateType>>(
    `${API.GET.getCandidates}${query}`,
    { headers: { Accept: 'application/vnd.falcon.v1+json' } },
  );

  if (data.length == 0) return null;

  return head(map(data, (candidate) => mapCandidate(candidate.attributes)));
};

export const useGetCandidates = (
  filters: Record<string, string>,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CANDIDATES, filters],
    queryFn: () => getCandidates(filters),
    ...config,
  });
};
