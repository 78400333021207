import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapCustomerOptions = (
  option: UCM.ContractCustomerOptionRawType,
): UCM.ContractCustomerOptionType => ({
  value: option.uuid,
  label: option.name,
  address: option.address,
  createdAt: option.created_at,
  createdBy: option.created_by,
  name: option.name,
  phoneNumber: option.phone_number,
  updatedAt: option.updated_at,
  updatedBy: option.updated_by,
  uuid: option.uuid,
});

const getCustomerOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.ContractCustomerOptionRawType>
  >(API.GET.customerOptions, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
  return data.data.map(({ attributes }) => mapCustomerOptions(attributes));
};

export const useGetCustomerOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACT_CUSTOMER_OPTIONS],
    queryFn: getCustomerOptions,
    staleTime: 1000 * 60 * 10,
    ...config,
  });
};
