import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { Moment } from 'moment';

export interface backupJobParamsType {
  uuid: string;
  status: string;
  quantity: string;
  wageBonus: string;
  shifts: Array<{
    startsAt: string | Moment;
    endsAt: string | Moment;
  }>;
}

const createBackupJob = async ({ uuid, ...backupJob }: backupJobParamsType) => {
  const params = {
    job: {
      total_vacancies: backupJob.quantity,
      wage_bonus: backupJob.wageBonus,
      status_code: backupJob.status,
      job_dates: map(backupJob.shifts, (shift) => ({
        start_date_time: shift.startsAt,
        end_date_time: shift.endsAt,
      })),
    },
  };

  const { data } = await postRequest<RequestSimplifiedData>(
    API.POST.createBackupJob(uuid),
    params,
  );

  return data;
};

export const useCreateBackupJob = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createBackupJob,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.backupJobSaved), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.BACKUP_JOB_SHFT_OPTIONS],
      });
    },
    onError: handleError,
  });
};
