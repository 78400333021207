import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl';
import { FormikErrors, useFormik } from 'formik';
import { date, number, object, string } from 'yup';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { locale } from 'src/config';
import {
  useActivateContract,
  useCreateContract,
  type ContractParamsType,
} from 'src/pages/ContractsPage/api';
import globalMessages from 'src/messages';
import { forceString } from 'src/utils/datetime';
import ConfirmModal from 'src/components/ConfirmModal';
import { useGetCandidates } from 'src/pages/ProjectDetailsPage/api';
import { formatNumber, toParsableNumber } from 'src/utils/standards';

import messages from './messages';
import LongTermContractEditPanel from './components/LongTermContractEditPanel';

interface LongTermContractFormProps {
  create?: boolean;
  contractUuid?: string;
  contract?: ContractParamsType | null;
  triggerButton?: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
}

export default function LongTermContractForm({
  contract,
  contractUuid,
  triggerButton,
  create = true,
}: LongTermContractFormProps) {
  const { formatMessage } = useIntl();

  const [debouncedUserId, setDebouncedUserId] = useState('');
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { mutate: activateContract, isPending: isActivatingContract } =
    useActivateContract();
  const { data: candidate, isFetching: isFetchingUser } = useGetCandidates(
    { id: debouncedUserId },
    { enabled: !!debouncedUserId },
  );
  const { mutate: createContract, isPending: isCreating } = useCreateContract();

  const validateMinValue = useRef(
    (
      errors: FormikErrors<ContractParamsType>,
      field: keyof ContractParamsType,
      value: string,
      min: number,
      formattedMin?: string,
    ) => {
      if (value.trim()) {
        const parsedValue = parseFloat(toParsableNumber(value, locale));
        if (Number.isNaN(parsedValue)) {
          errors[field] = formatMessage(globalMessages.notTypeField, {
            type: 'number',
          });
        } else if (parsedValue < min) {
          errors[field] = formatMessage(globalMessages.minValueField, {
            min: formattedMin || min,
          });
        }
      }
      return errors;
    },
  ).current;

  const notDraft = useMemo(
    () => contract?.status !== 'draft',
    [contract?.status],
  );
  const noStatus = useMemo(() => contract?.status == null, [contract?.status]);

  const loading = useMemo(() => {
    return isCreating || isFetchingUser || isActivatingContract;
  }, [isCreating, isFetchingUser, isActivatingContract]);

  const initialValues = useMemo(() => {
    return {
      userId: contract?.userId ?? '',
      userUuid: contract?.userUuid ?? '',
      userName: contract?.userName ?? '',
      endDate: contract?.endDate ?? null,
      status: contract?.status ?? 'all',
      userEmail: contract?.userEmail ?? '',
      startDate: contract?.startDate ?? null,
      maxDuration: contract?.maxDuration ?? '',
      weeklyHours: contract?.weeklyHours ?? '',
      jobTypeCode: contract?.jobTypeCode ?? '',
      unpaidLeave: contract?.unpaidLeave ?? '',
      taskTypeCode: contract?.taskTypeCode ?? '',
      monthlyHours: contract?.monthlyHours ?? '',
      customerCode: contract?.customerCode ?? '',
      certificateCode: contract?.certificateCode ?? '',
      jobLocationCode: contract?.jobLocationCode ?? '',
      tariffGroupCode: contract?.tariffGroupCode ?? '',
      plannedEndDate: contract?.plannedEndDate ?? null,
      latestStartDate: contract?.latestStartDate ?? null,
      plannedStartDate: contract?.plannedStartDate ?? null,
      employmentTypeCode: contract?.employmentTypeCode ?? '',
      jobRequirementCode: contract?.jobRequirementCode ?? '',
      wagePerHour: contract?.wagePerHour ?? formatNumber(0, locale),
    };
  }, [contract]);

  const formik = useFormik<ContractParamsType>({
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    initialValues: initialValues,
    validationSchema: object().shape({
      customerCode: string().required(),
      jobTypeCode: string().required(),
      wagePerHour: string().required(),
      monthlyHours: string().required(),
      taskTypeCode: string().required(),
      latestStartDate: date().required(),
      tariffGroupCode: string().required(),
      jobLocationCode: string().required(),
      employmentTypeCode: string().required(),
      jobRequirementCode: string().required(),
      userId: number().required().integer().min(0),
      maxDuration: number().required().integer().min(1),
      unpaidLeave: number().required().integer().min(0).max(12),
    }),
    validate: ({
      userUuid,
      wagePerHour,
      monthlyHours,
      plannedEndDate,
      latestStartDate,
      plannedStartDate,
    }) => {
      const errors: FormikErrors<ContractParamsType> = {};

      if (
        plannedStartDate &&
        latestStartDate &&
        latestStartDate.isBefore(plannedStartDate)
      ) {
        errors.latestStartDate = formatMessage(globalMessages.minDateField, {
          min: forceString(plannedStartDate),
        });
      }
      if (
        plannedEndDate &&
        latestStartDate &&
        latestStartDate.isAfter(plannedEndDate)
      ) {
        errors.latestStartDate = formatMessage(globalMessages.maxDateField, {
          max: forceString(plannedEndDate),
        });
      }

      validateMinValue(errors, 'wagePerHour', wagePerHour, 0);
      validateMinValue(errors, 'monthlyHours', monthlyHours, 0);

      if (userUuid === null) {
        errors.userId = formatMessage(globalMessages.notFound);
      }
      return errors;
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (isFetchingUser) return;

    const userNotFound = formatMessage(messages.userNotFound);

    const fields = {
      userName: debouncedUserId ? (candidate?.userName ?? userNotFound) : '',
      userUuid: debouncedUserId ? (candidate?.userUuid ?? userNotFound) : '',
      userEmail: debouncedUserId ? (candidate?.userEmail ?? userNotFound) : '',
    };

    formik.setFieldValue('userName', fields.userName);
    formik.setFieldValue('userUuid', fields.userUuid);
    formik.setFieldValue('userEmail', fields.userEmail);
  }, [candidate, debouncedUserId, isFetchingUser]);

  const { errors, values } = formik;

  const debouncedSetUserId = useCallback(
    debounce((value: string) => {
      setDebouncedUserId(value);
    }, 1000),
    [],
  );

  const handleUserIdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target as { value: string };

      formik.setFieldValue('userId', value);
      debouncedSetUserId(value);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setValidateOnChange(false);
    formik.resetForm();
  }, []);

  const handleConfirm = useCallback(async () => {
    setValidateOnChange(true);

    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) return false;

    return new Promise<boolean>((resolve) => {
      if (create && noStatus) {
        createContract(values, {
          onSuccess: () => {
            resolve(true);
            handleClose();
          },
          onError: () => {
            resolve(false);
          },
        });
      }

      if (!create && !notDraft) {
        const { startDate, endDate } = values;

        activateContract(
          { contractUuid: contractUuid ?? '', startDate, endDate },
          {
            onSuccess: () => {
              resolve(true);
              handleClose();
            },
            onError: () => {
              resolve(false);
            },
          },
        );
      }
    });
  }, [values, handleClose, create, noStatus, notDraft, contract, contractUuid]);

  return (
    <ConfirmModal
      width="700px"
      loading={loading}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmationRequired={false}
      title={formatMessage(messages.title)}
      disabled={loading || (!create && notDraft)}
      submitTextButton={
        create
          ? formatMessage(messages.createButton)
          : formatMessage(messages.saveButton)
      }
      triggerButton={
        triggerButton ||
        ((handleOpen) => (
          <Button
            variant="outlined"
            onClick={handleOpen}
            startIcon={<AddOutlinedIcon color="primary" />}
          >
            {formatMessage(messages.openButton)}
          </Button>
        ))
      }
    >
      <LongTermContractEditPanel
        errors={errors}
        values={values}
        create={create}
        notDraft={notDraft}
        onChange={formik.handleChange}
        onUserIdChange={handleUserIdChange}
        onValueChange={formik.setFieldValue}
      />
    </ConfirmModal>
  );
}
