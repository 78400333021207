import { useCallback, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { date, object, string } from 'yup';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';

// Inactive SVGs
import { ReactComponent as ListAddIcon } from 'src/images/ListAdd.svg';
import { timeFormat, toTimezone } from 'src/utils/datetime';

import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import { TimeField } from '@mui/x-date-pickers/TimeField';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import { useCreateBriefing } from 'src/pages/ProjectDetailsPage/api/mutations/useCreateBriefing';
import { useUpdateBriefing } from 'src/pages/ProjectDetailsPage/api/mutations/useUpdateBriefing';
import { useGetBriefingDetails } from 'src/pages/ProjectDetailsPage/api/queries/useGetBriefingDetails';
import { PencilAdornment } from 'src/utils/components';

import messages from '../../messages';

interface BriefingModalProps {
  jobUuid: string;
}

const multilineOptions = {
  multiline: true,
  minRows: 1,
  maxRows: 3,
};

export default function BriefingModal({ jobUuid }: BriefingModalProps) {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { data } = useGetBriefingDetails(jobUuid, { enabled: open });
  const { mutate: createBriefing, isPending: isPendingCreate } =
    useCreateBriefing();
  const { mutate: updateBriefing, isPending: isPendingUpdate } =
    useUpdateBriefing();

  const initialValues: UCM.BriefingPayloadState = useMemo(
    () => ({
      contactPerson: data?.contactPerson?.name ?? '',
      contactPhoneNumber: data?.contactPerson?.phoneNumber ?? '',
      meetingTime: data?.meetingTime
        ? toTimezone(data.meetingTime, timeFormat)
        : undefined,
      meetingPoint: data?.meetingPoint ?? '',
      dressCodeInfo: data?.dressCodeInfo ?? '',
      cateringInfo: data?.cateringInfo ?? '',
      preparationInfo: data?.preparationInfo ?? '',
      behavioralInfo: data?.behavioralInfo ?? '',
      taskDescription: data?.taskDescription ?? '',
      additionalInfo: data?.additionalInfo ?? '',
    }),
    [data],
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validationSchema: object().shape({
      contactPerson: string(),
      contactPhoneNumber: string(),
      meetingTime: date(),
      meetingPoint: string(),
      dressCodeInfo: string(),
      cateringInfo: string(),
      preparationInfo: string(),
      behavioralInfo: string(),
      taskDescription: string(),
      additionalInfo: string(),
    }),
    onSubmit: () => {},
  });

  const { values, errors } = formik;

  const handleClose = useCallback(() => {
    setValidateOnChange(false);
    formik.resetForm();
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const changeDateValue = useCallback(
    (date: Moment | null, name: keyof UCM.BriefingPayloadState) => {
      // if (!date?.isValid()) return;

      // const value = date.format(timeFormat);
      formik.setFieldValue(name, date);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    setValidateOnChange(true);

    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) throw errors;

    if (data?.uuid) {
      return updateBriefing(
        { jobUuid, uuid: data.uuid, briefing: { ...values } },
        { onSettled: handleClose },
      );
    }
    return createBriefing(
      { jobUuid, briefing: { ...values } },
      { onSettled: handleClose },
    );
  }, [values, data, jobUuid]);

  return (
    <ConfirmModal
      onOpen={handleOpen}
      onClose={handleClose}
      onConfirm={handleSubmit}
      confirmationDialogMaxWidth="xs"
      loading={isPendingCreate || isPendingUpdate}
      title={formatMessage(messages.briefingModalLabel)}
      submitTextButton={
        data?.uuid
          ? formatMessage(messages.briefingUpdateButton)
          : formatMessage(messages.briefingCreateButton)
      }
      triggerButton={(handleOpen) => {
        return (
          <IconButton onClick={handleOpen}>
            <ListAddIcon />
          </IconButton>
        );
      }}
    >
      <Grid container spacing={2}>
        <TextInput
          fullWidth
          name="contactPerson"
          value={values.contactPerson}
          label={formatMessage(messages.contactPersonField)}
          onChange={formik.handleChange}
          error={!!errors.contactPerson}
          helperText={errors.contactPerson}
          EndIcon={PencilAdornment}
        />
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="contactPhoneNumber"
            value={values.contactPhoneNumber}
            label={formatMessage(messages.phoneNumberField)}
            onChange={formik.handleChange}
            error={!!errors.contactPhoneNumber}
            helperText={errors.contactPhoneNumber}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          {/* <DemoContainer components={['TimeField']}>
            </DemoContainer> */}
          <TimeField
            name="meetingTime"
            variant="standard"
            fullWidth
            value={values.meetingTime}
            // value={
            //   values.meetingTime
            //     ? moment(values.meetingTime, timeFormat)
            //     : undefined
            // }
            label={formatMessage(messages.meetingTimeField)}
            format={timeFormat}
            onChange={(value) => changeDateValue(value, 'meetingTime')}
          />
        </Grid>
        <TextInput
          fullWidth
          name="meetingPoint"
          value={values.meetingPoint}
          label={formatMessage(messages.meetingPointLabel)}
          onChange={formik.handleChange}
          error={!!errors.meetingPoint}
          helperText={errors.meetingPoint}
          EndIcon={PencilAdornment}
          {...multilineOptions}
          maxRows={2}
        />
        <TextInput
          fullWidth
          name="taskDescription"
          value={values.taskDescription}
          label={formatMessage(messages.taskField)}
          onChange={formik.handleChange}
          error={!!errors.taskDescription}
          helperText={errors.taskDescription}
          EndIcon={PencilAdornment}
          {...multilineOptions}
        />
        <TextInput
          fullWidth
          name="dressCodeInfo"
          value={values.dressCodeInfo}
          label={formatMessage(messages.dressCodeField)}
          onChange={formik.handleChange}
          error={!!errors.dressCodeInfo}
          helperText={errors.dressCodeInfo}
          EndIcon={PencilAdornment}
          {...multilineOptions}
        />
        <TextInput
          fullWidth
          name="preparationInfo"
          value={values.preparationInfo}
          label={formatMessage(messages.preparationField)}
          onChange={formik.handleChange}
          error={!!errors.preparationInfo}
          helperText={errors.preparationInfo}
          EndIcon={PencilAdornment}
          {...multilineOptions}
        />
        <TextInput
          fullWidth
          name="behavioralInfo"
          value={values.behavioralInfo}
          label={formatMessage(messages.behavioralField)}
          onChange={formik.handleChange}
          error={!!errors.behavioralInfo}
          helperText={errors.behavioralInfo}
          EndIcon={PencilAdornment}
          {...multilineOptions}
        />
        <TextInput
          fullWidth
          name="cateringInfo"
          value={values.cateringInfo}
          label={formatMessage(messages.cateringField)}
          onChange={formik.handleChange}
          error={!!errors.cateringInfo}
          helperText={errors.cateringInfo}
          EndIcon={PencilAdornment}
          {...multilineOptions}
        />
        <TextInput
          fullWidth
          name="additionalInfo"
          value={values.additionalInfo}
          label={formatMessage(messages.additionalInfoField)}
          onChange={formik.handleChange}
          error={!!errors.additionalInfo}
          helperText={errors.additionalInfo}
          EndIcon={PencilAdornment}
          {...multilineOptions}
        />
      </Grid>
    </ConfirmModal>
  );
}
