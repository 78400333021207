import { useCallback, useMemo, useRef } from 'react';

import { useIntl } from 'react-intl';

import range from 'lodash/range';
import isArray from 'lodash/isArray';

import Grid from '@mui/material/Grid2';
import type { SelectChangeEvent } from '@mui/material';

import { locale } from 'src/config';
import {
  useGetTaskOptions,
  useGetJobTypeOptions,
  useGetCustomerOptions,
  useGetLocationOptions,
  useGetTariffGroupOptions,
  useGetRequirementOptions,
  useGetCertificateOptions,
  useGetEmploymentTypeOptions,
  type ContractParamsType,
} from 'src/pages/ContractsPage/api';
import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import { PencilAdornment } from 'src/utils/components';
import { checkCurrencyTyping } from 'src/utils/standards';
import { DatePickerField } from 'src/components/DatePickerField';

import messages from './messages';

import type { Moment } from 'moment';
import type { FormikErrors } from 'formik';

const checkParam = { locale };

interface LongTermContractEditPanelProps {
  create: boolean;
  notDraft: boolean;
  values: ContractParamsType;
  errors: FormikErrors<ContractParamsType>;
  onValueChange: (
    name: string,
    value: string | number | Moment | null | Array<string>,
  ) => void;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
  ) => void;
  onUserIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MONTH_OPTIONS = range(13).map((num) => ({
  label: num.toString(),
  value: num.toString(),
}));

export default function LongTermContractEditPanel({
  create,
  values,
  errors,
  notDraft,
  onChange,
  onValueChange,
  onUserIdChange,
}: LongTermContractEditPanelProps) {
  const { formatMessage } = useIntl();

  const { data: taskTypeOptions } = useGetTaskOptions();
  const { data: jobTypeOptions } = useGetJobTypeOptions();
  const { data: locationOptions } = useGetLocationOptions();
  const { data: customerOptions } = useGetCustomerOptions();
  const { data: tariffGroupOptions } = useGetTariffGroupOptions();
  const { data: requirementOptions } = useGetRequirementOptions();
  const { data: certificateOptions } = useGetCertificateOptions();
  const { data: employmentTypeOptions } = useGetEmploymentTypeOptions();

  const hasEmploymentType = useMemo(
    () =>
      values.employmentTypeCode != null &&
      values.employmentTypeCode.length !== 0,
    [values.employmentTypeCode],
  );

  const handleDateChange = useCallback(
    (name: string, value: Moment | Array<Moment> | null) => {
      if (!isArray(value)) onValueChange(name, value);
    },
    [],
  );
  const handleCurrencyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (checkCurrencyTyping(value, checkParam)) {
        onValueChange(name, value);
      }
    },
    [onValueChange],
  );

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 4 }}>
        <TextInput
          fullWidth
          name="userId"
          disabled={!create}
          value={values.userId}
          error={!!errors.userId}
          helperText={errors.userId}
          onChange={onUserIdChange}
          label={formatMessage(messages.userIdLabel)}
          EndIcon={PencilAdornment}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 8 }}>
        <TextInput
          disabled
          fullWidth
          name="userEmail"
          value={values.userEmail}
          label={formatMessage(messages.userEmailLabel)}
          EndIcon={PencilAdornment}
        />
      </Grid>

      <Grid size={12}>
        <TextInput
          disabled
          fullWidth
          multiline
          name="userName"
          value={values.userName}
          label={formatMessage(messages.userNameLabel)}
          EndIcon={PencilAdornment}
        />
      </Grid>

      {(create || hasEmploymentType) && (
        <>
          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              name="employmentTypeCode"
              options={employmentTypeOptions}
              onChange={onChange}
              disabled={!create}
              value={values.employmentTypeCode}
              error={!!errors.employmentTypeCode}
              helperText={errors.employmentTypeCode}
              label={formatMessage(messages.employmentTypeLabel)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              name="jobTypeCode"
              options={jobTypeOptions}
              onChange={onChange}
              disabled={!create}
              value={values.jobTypeCode}
              error={!!errors.jobTypeCode}
              helperText={errors.jobTypeCode}
              label={formatMessage(messages.jobTypeLabel)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              name="taskTypeCode"
              options={taskTypeOptions}
              onChange={onChange}
              disabled={!create}
              value={values.taskTypeCode}
              error={!!errors.taskTypeCode}
              helperText={errors.taskTypeCode}
              label={formatMessage(messages.taskTypeLabel)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              name="jobLocationCode"
              options={locationOptions}
              onChange={onChange}
              disabled={!create}
              value={values.jobLocationCode}
              error={!!errors.jobLocationCode}
              helperText={errors.jobLocationCode}
              label={formatMessage(messages.jobLocationLabel)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              name="tariffGroupCode"
              options={tariffGroupOptions}
              onChange={onChange}
              disabled={!create}
              value={values.tariffGroupCode}
              error={!!errors.tariffGroupCode}
              helperText={errors.tariffGroupCode}
              label={formatMessage(messages.tariffGroupLabel)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              name="jobRequirementCode"
              options={requirementOptions}
              onChange={onChange}
              disabled={!create}
              value={values.jobRequirementCode}
              error={!!errors.jobRequirementCode}
              helperText={errors.jobRequirementCode}
              label={formatMessage(messages.jobRequirementLabel)}
            />
          </Grid>
        </>
      )}

      <Grid size={{ xs: 12, md: 8 }}>
        <Dropdown
          name="customerCode"
          options={customerOptions}
          onChange={onChange}
          disabled={!create}
          value={values.customerCode}
          error={!!errors.customerCode}
          helperText={errors.customerCode}
          label={formatMessage(messages.customerLabel)}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 4 }}>
        <Dropdown
          name="unpaidLeave"
          options={MONTH_OPTIONS}
          onChange={onChange}
          disabled={!create}
          value={values.unpaidLeave}
          error={!!errors.unpaidLeave}
          helperText={errors.unpaidLeave}
          label={formatMessage(messages.unpaidLeaveLabel)}
        />
      </Grid>

      {!create && !hasEmploymentType && (
        <Grid size={12}>
          <Dropdown
            name="certificateCode"
            options={certificateOptions}
            onChange={onChange}
            disabled={!create}
            value={values.certificateCode}
            error={!!errors.certificateCode}
            helperText={errors.certificateCode}
            label={formatMessage(messages.certificateLabel)}
          />
        </Grid>
      )}

      {(create || hasEmploymentType) && (
        <>
          <Grid size={{ xs: 12, md: 6 }}>
            <DatePickerField
              disabled={!create}
              name="plannedStartDate"
              value={values.plannedStartDate}
              error={!!errors.plannedStartDate}
              helperText={errors.plannedStartDate}
              label={formatMessage(messages.plannedStartDateLabel)}
              onChange={(date) => handleDateChange('plannedStartDate', date)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <DatePickerField
              disabled={!create}
              name="latestStartDate"
              value={values.latestStartDate}
              error={!!errors.latestStartDate}
              helperText={errors.latestStartDate}
              label={formatMessage(messages.latestStartDateLabel)}
              onChange={(date) => handleDateChange('latestStartDate', date)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <DatePickerField
              disabled={!create}
              name="plannedEndDate"
              value={values.plannedEndDate}
              error={!!errors.plannedEndDate}
              helperText={errors.plannedEndDate}
              label={formatMessage(messages.plannedEndDateLabel)}
              onChange={(date) => handleDateChange('plannedEndDate', date)}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Dropdown
              disabled={!create}
              name="maxDuration"
              options={MONTH_OPTIONS}
              onChange={onChange}
              value={values.maxDuration}
              error={!!errors.maxDuration}
              helperText={errors.maxDuration}
              label={formatMessage(messages.maxDurationLabel)}
            />
          </Grid>
        </>
      )}

      {!create && (
        <>
          <Grid size={{ xs: 12, md: 6 }}>
            <DatePickerField
              name="startDate"
              disabled={notDraft}
              label={formatMessage(messages.startDateLabel)}
              value={values.startDate}
              onChange={(date) => handleDateChange('startDate', date)}
              helperText={errors.startDate}
              error={!!errors.startDate}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <DatePickerField
              name="endDate"
              disabled={notDraft}
              label={formatMessage(messages.endDateLabel)}
              value={values.endDate}
              onChange={(date) => handleDateChange('endDate', date)}
              helperText={errors.endDate}
              error={!!errors.endDate}
            />
          </Grid>
        </>
      )}

      <Grid size={{ xs: 12, md: 6 }}>
        <TextInput
          fullWidth
          disabled={!create}
          name="wagePerHour"
          value={values.wagePerHour}
          error={!!errors.wagePerHour}
          helperText={errors.wagePerHour}
          onChange={onChange}
          label={formatMessage(messages.wagePerHourLabel)}
          EndIcon={PencilAdornment}
        />
      </Grid>

      {!create && !hasEmploymentType && (
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="weeklyHours"
            disabled={!create}
            value={values.weeklyHours}
            error={!!errors.weeklyHours}
            helperText={errors.weeklyHours}
            onChange={handleCurrencyChange}
            label={formatMessage(messages.weeklyHoursLabel)}
            EndIcon={PencilAdornment}
          />
        </Grid>
      )}

      {(create || hasEmploymentType) && (
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="monthlyHours"
            disabled={!create}
            value={values.monthlyHours}
            error={!!errors.monthlyHours}
            helperText={errors.monthlyHours}
            onChange={onChange}
            label={formatMessage(messages.monthlyHoursLabel)}
            EndIcon={PencilAdornment}
          />
        </Grid>
      )}
    </Grid>
  );
}
