/*
 * Standby Messages
 *
 * This contains all the text for the MainJob component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.Standby';

export default defineMessages({
  jobSerchLabel: {
    id: `${scope}.jobSerchLabel`,
    defaultMessage: 'In Job Search',
  },
  availableTab: {
    id: `${scope}.availableTab`,
    defaultMessage: 'Available',
  },
  transferredTab: {
    id: `${scope}.transferredTab`,
    defaultMessage: 'Transferred to this job',
  },
});
