export {};
/*
 * QuickApplyUser Form Messages
 *
 * This contains all the text for the QuickApplyUser Form.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobList.components.QuickApplyUserForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Quick Apply',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'Specify the User to be applied/accepted.',
  },
  userIdLabel: {
    id: `${scope}.userIdLabel`,
    defaultMessage: 'User ID',
  },
  shiftDateLabel: {
    id: `${scope}.shiftDateLabel`,
    defaultMessage: 'Date',
  },
  userEmailLabel: {
    id: `${scope}.userEmailLabel`,
    defaultMessage: 'Email',
  },
  userNameLabel: {
    id: `${scope}.userNameLabel`,
    defaultMessage: 'Name',
  },
  userNotFound: {
    id: `${scope}.userNotFound`,
    defaultMessage: 'User not found',
  },
  applyButtonLabel: {
    id: `${scope}.applyButtonLabel`,
    defaultMessage: 'Apply',
  },
  acceptModalLabel: {
    id: `${scope}.acceptModalLabel`,
    defaultMessage: 'Please specify a reason for accepting',
  },
  applyAndAcceptButtonLabel: {
    id: `${scope}.applyAndAcceptButtonLabel`,
    defaultMessage: 'Apply and Accept',
  },
});
