import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';

const mapLanguage = (
  lang: UCM.LanguageOptionRawType,
): UCM.LanguageOptionType => ({
  value: lang.code,
  label: lang.name,
});

const getLanguageOptions = async () => {
  const {
    data: { data },
  } = await getRequest<RequestDataArray<UCM.LanguageLevelOptionRawType>>(
    API.GET.getLanguageOptions,
    { headers: { Accept: 'application/vnd.falcon.v1+json' } },
  );

  return map(data, ({ attributes }) => mapLanguage(attributes));
};

export const useGetLangueOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.LANGUAGE_OPTIONS],
    queryFn: getLanguageOptions,
    ...config,
  });
};
