import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestDataArray } from 'src/types/requestData';
import { forceString } from 'src/utils/datetime';
import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';

const mapPreviousFineReports = (
  attributes: UCM.PreviousFineReportRawType,
  id: string,
): UCM.PreviousFineReportType => ({
  id,
  uuid: attributes.uuid,
  createdAt: forceString(attributes.created_at),
  finesCount: attributes.fines_count,
});

const getPreviousFineReports = async (
  params: Record<string, string | number>,
) => {
  const query = resolveQueryString([], params);

  const {
    data: { data, meta },
  } = await getRequest<
    RequestDataArray<UCM.PreviousFineReportRawType, UCM.MetaRawType>
  >(API.GET.getPreviousFineReports(query));

  return {
    meta: paginationMapper(meta),
    data: data.map(({ attributes, id }) =>
      mapPreviousFineReports(attributes, id),
    ),
  };
};

export const useGetPreviousFineReports = ({
  params,
  enabled = true,
}: {
  filters?: Record<string, string | number>;
  params: Record<string, string | number>;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PREVIOUS_FINE_REPORTS, params],
    queryFn: () => getPreviousFineReports(params),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};
