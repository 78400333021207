import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestData } from 'src/types/requestData';

const mapBriefing = (
  briefing: UCM.GeneralBriefingRawType,
  briefingId: string,
): UCM.GeneralBriefingType => {
  return {
    id: briefingId,
    additionalInfo: briefing.additional_info,
    cateringInfo: briefing.catering_info,
    dressCodeInfo: briefing.dress_code_info,
    preparationInfo: briefing?.preparation_info,
    behavioralInfo: briefing?.behavioral_info,
    taskDescription: briefing?.task_description,
    contactPerson: {
      name: briefing?.contact_person.name,
      phoneNumber: briefing?.contact_person.phone_number,
    },
    createdAt: briefing.created_at,
    for: briefing.for,
    meetingPoint: briefing.meeting_point,
    meetingTime: briefing.meeting_time,
    summary: {
      bookedCount: briefing.summary.booked_count,
      confirmedCount: briefing.summary.confirmed_count,
    },
    uuid: briefing.uuid,
  };
};

const getBriefingDetails = async (jobUuid: string) => {
  const {
    data: {
      data: { attributes, id },
    },
  } = await getRequest<RequestData<UCM.GeneralBriefingRawType>>(
    API.GET.getBriefingDetails(jobUuid),
  );

  return mapBriefing(attributes, id);
};

export const useGetBriefingDetails = (
  jobUuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.BRIEFING_DETAILS, jobUuid],
    queryFn: () => getBriefingDetails(jobUuid),
    ...config,
  });
};
