import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';

const mapJobType = (job: UCM.JobTypeOptionRawType): UCM.JobTypeOptionType => ({
  value: job.code,
  description: job.description,
  label: job.male_title ?? job.female_title ?? '',
});

const getJobTypeOptions = async () => {
  const {
    data: { data },
  } = await getRequest<RequestDataArray<UCM.JobTypeOptionRawType>>(
    API.GET.getJobTypeOptions,
    { headers: { Accept: 'application/vnd.falcon.v1+json' } },
  );

  return map(data, ({ attributes }) => mapJobType(attributes));
};

export const useGetJobTypeOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.JOB_TYPE_OPTIONS],
    queryFn: getJobTypeOptions,
    ...config,
  });
};
