import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestDataArray } from 'src/types/requestData';
import { forceString } from 'src/utils/datetime';
import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';

export type FinesStatusType =
  | 'pending'
  | 'approved'
  | 'exported'
  | 'paid'
  | 'exempted';

const mapFines = (attributes: UCM.FinesRawType, id: string): UCM.FinesType => ({
  id,
  uuid: attributes.uuid,
  amount: attributes.amount,
  affectedDate: attributes.affected_date,
  finesReportId: attributes.fines_report_id,
  cancellationNoticeId: attributes.cancellation_notice_id,
  defaultAmount: attributes.default_amount,
  userId: attributes.user_id,
  jobId: attributes.job_id,
  reasonId: attributes.reason_id,
  explanation: attributes.explanation,
  externalExplanation: attributes.external_explanation,
  timeDeviation: attributes.time_deviation,
  adminComment: attributes.admin_comment,
  verifiedAt: forceString(attributes.verified_at),
  verifiedBy: attributes.verified_by,
  createdAt: forceString(attributes.created_at),
  createdBy: attributes.created_by,
  status: attributes.status,
  user: {
    id: attributes.user.id,
    uuid: attributes.user.uuid,
    firstName: attributes.user.first_name,
    lastName: attributes.user.last_name,
  },
  job: {
    id: attributes.job.id,
    projectUuid: attributes.job.project_uuid,
  },
  reason: {
    code: attributes.reason.code,
    title: attributes.reason.title,
  },
  finesReport: {
    id: attributes.fines_report.id,
    createdAt: attributes.fines_report.created_at,
  },
});

const getFines = async (
  filters: Record<string, string | number>,
  params: Record<string, string | number>,
) => {
  const query = resolveQueryString([filters], params);

  const {
    data: { data, meta },
  } = await getRequest<RequestDataArray<UCM.FinesRawType, UCM.MetaRawType>>(
    API.GET.getFines(query),
  );

  return {
    meta: paginationMapper(meta),
    data: data.map(({ attributes, id }) => mapFines(attributes, id)),
  };
};

export const useGetFines = ({
  filters = {},
  params,
  enabled = true,
}: {
  filters?: Record<string, string | number>;
  params: Record<string, string | number>;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FINES, filters, params],
    queryFn: () => getFines(filters, params),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};
