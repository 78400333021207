import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { putRequest, serialize } from 'src/utils/request';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import { DailyBriefingsFilters } from '../queries/useGetDailyBriefing';

const assignUsers = async (filters: DailyBriefingsFilters) => {
  const queryFilters = serialize(filters, 'filters');

  const data = await putRequest(API.PUT.briefingAssignUsers(queryFilters));

  return data;
};

export const useUpdateBriefingAssignUsers = () => {
  const client = useQueryClient();
  const { handleError } = useMessageHandler();

  return useMutation({
    mutationFn: assignUsers,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.DAILY_BRIEFINGS],
      });
    },
    onError: handleError,
  });
};
