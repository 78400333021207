/*
 * BackupSettingsForm Messages
 *
 * This contains all the text for the BackupSettingsForm component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.BackupSettingsForm';

export default defineMessages({
  settingsLabel: {
    id: `${scope}.settingsLabel`,
    defaultMessage: 'Settings',
  },
  jobTypeLabel: {
    id: `${scope}.jobTypeLabel`,
    defaultMessage: 'Job Type',
  },
  tasksLabel: {
    id: `${scope}.tasksLabel`,
    defaultMessage: 'Tasks',
  },
  languagesLabel: {
    id: `${scope}.languagesLabel`,
    defaultMessage: 'Languages',
  },
  ignoreCitiesLabel: {
    id: `${scope}.ignoreCitiesLabel`,
    defaultMessage: 'Ignore Cities',
  },
  jobStatusLabel: {
    id: `${scope}.jobStatusLabel`,
    defaultMessage: 'Job Status',
  },
  locationLabel: {
    id: `${scope}.locationLabel`,
    defaultMessage: 'Location',
  },
  venueLabel: {
    id: `${scope}.venueLabel`,
    defaultMessage: 'Venue',
  },
  addressLabel: {
    id: `${scope}.addressLabel`,
    defaultMessage: 'Address',
  },
  numberLabel: {
    id: `${scope}.numberLabel`,
    defaultMessage: 'Number',
  },
  cityLabel: {
    id: `${scope}.cityLabel`,
    defaultMessage: 'City',
  },
  zipCodeLabel: {
    id: `${scope}.zipCodeLabel`,
    defaultMessage: 'Zip Code',
  },
  requirementAndFinanceLabel: {
    id: `${scope}.requirementAndFinanceLabel`,
    defaultMessage: 'Requirement & Finance',
  },
  quantityLabel: {
    id: `${scope}.quantityLabel`,
    defaultMessage: 'Quantity',
  },
  bookingDealineLabel: {
    id: `${scope}.bookingDealineLabel`,
    defaultMessage: 'Booking Deadline',
  },
  salaryLabel: {
    id: `${scope}.salaryLabel`,
    defaultMessage: 'Salary',
  },
  salaryBonusLabel: {
    id: `${scope}.salaryBonusLabel`,
    defaultMessage: 'Backup Bonus',
  },
  priceLabel: {
    id: `${scope}.priceLabel`,
    defaultMessage: 'Price',
  },
  priceBonusLabel: {
    id: `${scope}.priceBonusLabel`,
    defaultMessage: 'Price Bonus',
  },
  mainWageBonusLabel: {
    id: `${scope}.mainWageBonusLabel`,
    defaultMessage: 'Salary Bonus',
  },
  descriptionLabel: {
    id: `${scope}.descriptionLabel`,
    defaultMessage: 'Description',
  },
  dateColumnLabel: {
    id: `${scope}.dateColumnLabel`,
    defaultMessage: 'Date',
  },
  dayColumnLabel: {
    id: `${scope}.dayColumnLabel`,
    defaultMessage: 'Day',
  },
  timeframeColumnLabel: {
    id: `${scope}.timeframeColumnLabel`,
    defaultMessage: 'Timeframe',
  },
});
