import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { postRequest, putRequest } from 'src/utils/request';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

const saveShift = async (params: {
  jobUuid: string;
  shift: UCM.ShiftPayload;
}) => {
  const { jobUuid, shift } = params;

  const payload = {
    shift: {
      slots_number: shift.quantity ?? '',
      start_date_time: shift.startTime,
      end_date_time: shift.endTime,
    },
  };
  if (shift.uuid) {
    return await putRequest(API.PUT.updateShift(jobUuid, shift.uuid), payload);
  }
  return await postRequest(API.POST.createShift(jobUuid), payload);
};

export const useUpdateSaveShifts = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: saveShift,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.shiftSaved), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.SHIFTS_LIST] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.CALENDAR_JOBS] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
    },
    onError: handleError,
  });
};
