export enum QUERY_KEYS {
  JOB = 'JOB',
  JOBS = 'JOBS',
  PROJECT = 'PROJECT',
  PROJECTS = 'PROJECTS',
  STAFF_TYPE = 'STAFF_TYPE',
  CANDIDATES = 'CANDIDATES',
  CHECK_EMAIL = 'CHECK_EMAIL',
  TASK_OPTIONS = 'TASK_OPTIONS',
  USER_ACCOUNT = 'USER_ACCOUNT',
  CITY_OPTIONS = 'CITY_OPTIONS',
  PRE_SCREENING = 'PRE_SCREENING',
  USER_ACCOUNTS = 'USER_ACCOUNTS',
  COUNTRY_OPTIONS = 'COUNTRY_OPTIONS',
  LANGUAGE_OPTIONS = 'LANGUAGE_OPTIONS',
  JOB_TYPE_OPTIONS = 'JOB_TYPE_OPTIONS',
  PROJECT_SETTINGS = 'PROJECT_SETTINGS',
  STAFF_TYPE_OPTIONS = 'STAFF_TYPE_OPTIONS',
  PROJECT_STAFF_PLAN = 'PROJECT_STAFF_PLAN',
  OCCUPATION_OPTIONS = 'OCCUPATION_OPTIONS',
  DYNAMIC_FIELD_OPTIONS = 'DYNAMIC_FIELD_OPTIONS',
  LANGUAGE_LEVEL_OPTIONS = 'LANGUAGE_LEVEL_OPTIONS',
  INTERVIEW_METHOD_OPTIONS = 'INTERVIEW_METHOD_OPTIONS',
  CANDIDATE = 'CANDIDATE',
  POSITION_JOB_DETAILS = 'POSITION_JOB_DETAILS',
  POSITION_JOB_DETAILS_DOWNLOAD = 'POSITION_JOB_DETAILS_DOWNLOAD',
  JOB_STAFF_PLAN = 'JOB_STAFF_PLAN',
  DAILY_BRIEFINGS = 'DAILY_BRIEFINGS',
  BRIEFING_DETAILS = 'BRIEFING_DETAILS',
  JOB_APPLICATIONS = 'JOB_APPLICATIONS',
  JOB_APPLICATIONS_COUNT = 'JOB_APPLICATIONS_COUNT',
  BOOKED_TO_TRANSFER = 'BOOKED_TO_TRANSFER',
  BOOKED_FROM_TRANSFER = 'BOOKED_FROM_TRANSFER',
  RATING_SCORE = 'RATING_SCORE',
  USER_RATING_SCORE = 'USER_RATING_SCORE',
  ACCEPT_REASON = 'ACCEPT_REASON',
  REJECT_REASON = 'REJECT_REASON',
  CANDIDATE_DETAILS = 'CANDIDATE_DETAILS',
  FIELDS_CATEGORY = 'FIELDS_CATEGORY',
  INTERNAL_ATTRIBUTES = 'INTERNAL_ATTRIBUTES',
  CALENDAR_JOBS = 'CALENDAR_JOBS',
  CANCELLATION_SHIFTS = 'CANCELLATION_SHIFTS',
  CANCELLATION_NOTICES = 'CANCELLATION_NOTICES',
  JOB_COVERAGES = 'JOB_COVERAGES',
  SHIFTS_LIST = 'SHIFTS_LIST',
  BACKUP_JOB = 'BACKUP_JOB',
  BACKUP_JOB_SHFT_OPTIONS = 'BACKUP_JOB_SHFT_OPTIONS',
  VERIFICATION_METHODS = 'VERIFICATION_METHODS',
  ASSETS_PICTURES = 'ASSETS_PICTURES',
  ACCEPTED_COUNT = 'ACCEPTED_COUNT',
  JOB_STATUSES = 'JOB_STATUSES',
  CONTRACTS = 'CONTRACTS',
  CONTRACT_DOWNLOAD = 'CONTRACT_DOWNLOAD',
  CONTRACT_EMPLOYMENT_TYPE_OPTIONS = 'CONTRACT_EMPLOYMENT_TYPE_OPTIONS',
  CONTRACT_REQUIREMENT_OPTIONS = 'CONTRACT_REQUIREMENT_OPTIONS',
  CONTRACT_TASK_OPTIONS = 'CONTRACT_TASK_OPTIONS',
  CONTRACT_JOB_TYPE_OPTIONS = 'CONTRACT_JOB_TYPE_OPTIONS',
  CONTRACT_TARIFF_GROUP_OPTIONS = 'CONTRACT_TARIFF_GROUP_OPTIONS',
  CONTRACT_LOCATION_OPTIONS = 'CONTRACT_LOCATION_OPTIONS',
  CONTRACT_CUSTOMER_OPTIONS = 'CONTRACT_CUSTOMER_OPTIONS',
  CONTRACT_CERTIFICATE_OPTIONS = 'CONTRACT_CERTIFICATE_OPTIONS',
  CANCELLATIONS = 'CANCELLATIONS',
  FINES = 'FINES',
  PREVIOUS_FINE_REPORTS = 'PREVIOUS_FINE_REPORTS',
  FINE_REPORT_DOWNLOAD = 'FINE_REPORT_DOWNLOAD',
}
