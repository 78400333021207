import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import download from 'src/utils/download';
import { getRequest } from 'src/utils/request';
import {
  ErrorType,
  useMessageHandler,
} from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

const downloadPositionJobDates = async (fine?: UCM.PreviousFineReportType) => {
  if (!fine) return;

  const { data } = await getRequest(
    `${API.GET.downloadFineReport(fine.uuid)}`,
    {
      responseType: 'blob',
    },
  );
  download(data, `Fines_${fine.createdAt}_${fine.id}.csv`, 'text/csv');
  return data;
};

export const useDownloadFineReport = ({
  fine,
  enabled = false,
}: {
  fine?: UCM.PreviousFineReportType;
  enabled?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();

  const { isFetching, isSuccess, isError, error } = useQuery<
    unknown,
    ErrorType
  >({
    queryKey: [QUERY_KEYS.FINE_REPORT_DOWNLOAD],
    queryFn: () => downloadPositionJobDates(fine),
    enabled,
  });

  useEffect(() => {
    if (isSuccess) {
      handleSuccess(formatMessage(messages.reportDownloaded));
      client.removeQueries({
        queryKey: [QUERY_KEYS.FINE_REPORT_DOWNLOAD],
      });
    }
    if (isError && error) {
      handleError(error);
    }
  }, [isSuccess, isError]);

  return { isSuccess, isFetching };
};
