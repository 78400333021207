/*
 * Job List Messages
 *
 * This contains all the text for the Job List.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.JobList';

export default defineMessages({
  searchInputPlaceholder: {
    id: `${scope}.searchInputPlaceholder`,
    defaultMessage: 'Job Search',
  },
  multiple: {
    id: `${scope}.multiple`,
    defaultMessage: 'Multiple',
  },
  downloadStaffPlanMenuLabel: {
    id: `${scope}.downloadStaffPlanMenuLabel`,
    defaultMessage: 'Download Staff Plan',
  },
  quickApplyUserMenuLabel: {
    id: `${scope}.quickApplyUserMenuLabel`,
    defaultMessage: 'Quick Apply a User',
  },
  copyJobMenuLabel: {
    id: `${scope}.copyJobMenuLabel`,
    defaultMessage: 'Copy Job without shifts',
  },
  jobColumnLabel: {
    id: `${scope}.jobColumnLabel`,
    defaultMessage: 'Job',
  },
  periodColumnLabel: {
    id: `${scope}.periodColumnLabel`,
    defaultMessage: 'Start/End Date',
  },
  jobTypeColumnLabel: {
    id: `${scope}.jobTypeColumnLabel`,
    defaultMessage: 'Job Type',
  },
  bookingColumnLabel: {
    id: `${scope}.bookingColumnLabel`,
    defaultMessage: 'Booking',
  },
  locationsColumnLabel: {
    id: `${scope}.locationsColumnLabel`,
    defaultMessage: 'Location',
  },
  multipleCitiesLabel: {
    id: `${scope}.multipleCitiesLabel`,
    defaultMessage: 'Multiple',
  },
  StatusColumnLabel: {
    id: `${scope}.StatusColumnLabel`,
    defaultMessage: 'Status',
  },
  toggleVisibilityConfirmMessage: {
    id: `${scope}.toggleVisibilityConfirmMessage`,
    defaultMessage:
      'Are you sure you want to change the visibility of this job?',
  },
});
