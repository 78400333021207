import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { deleteRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

const deleteJob = async (jobUuid: string) => {
  const { data } = await deleteRequest<RequestSimplifiedData>(
    API.DELETE.deleteJob(jobUuid),
  );
  return data;
};

export const useDeleteJob = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: deleteJob,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.jobDeleted), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
    },
    onError: handleError,
  });
};
