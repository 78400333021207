/*
 * Previous Export Fines Form Modal
 *
 * This contains all the text for the Previous Export Fines Form Modal.
 */

import { defineMessages } from 'react-intl';
export const scope =
  'src.pages.FinesPage.components.PreviousExportFinesFormModal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Previous Export',
  },
  buttonOpenLabel: {
    id: `${scope}.buttonOpenLabel`,
    defaultMessage: 'Previous Export',
  },
  dateColumnLabel: {
    id: `${scope}.dateColumnLabel`,
    defaultMessage: 'Date Created',
  },
  idColumnLabel: {
    id: `${scope}.idColumnLabel`,
    defaultMessage: 'ID',
  },
  countColumnLabel: {
    id: `${scope}.countColumnLabel`,
    defaultMessage: 'Fine Amount',
  },
});
