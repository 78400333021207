import { useIntl } from 'react-intl';
import { useCallback, useMemo, useRef, useState } from 'react';
import { map } from 'lodash';

import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import ConfirmModal from 'src/components/ConfirmModal';

import { useGetBackupJobDetails } from 'src/pages/ProjectDetailsPage/api/queries/useGetBackupJobDetails';
import {
  toString,
  toTimezone,
  weekFormat,
  forceString,
  forceTimeString,
} from 'src/utils/datetime';
import { formatCurrency } from 'src/utils/standards';
import { currency, locale } from 'src/config';
import DataTable from 'src/components/DataTable';

import { useGetBackupShiftOptions } from 'src/pages/ProjectDetailsPage/api';

import BackupJobForm from '../BackupJobForm';
import messages from './messages';

import type { DefaultTheme } from '@mui/styles';

interface Props {
  uuid: string;
  jobUuid?: string;
}

export default function BackupSettingsForm({ uuid, jobUuid }: Props) {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  const { data } = useGetBackupJobDetails(uuid, {
    enabled: Boolean(uuid) && open,
  });

  const { data: shiftOptions } = useGetBackupShiftOptions(uuid ?? '', {
    enabled: Boolean(uuid) && open,
  });

  const settingsIconSx = useRef((theme: DefaultTheme) => ({
    fontSize: theme.spacing(2),
  })).current;

  const columns: Array<{
    id: keyof UCM.BackupJobShiftOptionType | string;
    label: string | React.ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'date',
        label: formatMessage(messages.dateColumnLabel),
        render: (_: Object = {}, shift: UCM.BackupJobShiftOptionType) => (
          <Typography variant="inherit">
            {forceString(shift.startsAt)}
          </Typography>
        ),
      },
      {
        id: 'day',
        label: formatMessage(messages.dayColumnLabel),
        align: 'center',
        render: (_: Object = {}, shift: UCM.BackupJobShiftOptionType) => (
          <Typography variant="inherit">
            {toString(toTimezone(shift.startsAt)?.locale('en'), weekFormat)}
          </Typography>
        ),
      },
      {
        id: 'timeframe',
        label: formatMessage(messages.timeframeColumnLabel),
        size: 'small',
        align: 'center',
        render: (_: Object = {}, shift: UCM.BackupJobShiftOptionType) => (
          <Typography variant="inherit">
            {[
              forceTimeString(shift.startsAt),
              forceTimeString(shift.endsAt),
            ].join(' - ')}
          </Typography>
        ),
      },
    ],
    [shiftOptions],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ConfirmModal
      onClose={handleClose}
      onOpen={handleOpen}
      width="991px"
      title={`${data?.title} - Backup JID ${data?.id}`}
      triggerButton={(handleOpen) => (
        <div onClick={handleOpen}>
          <SettingsOutlinedIcon sx={settingsIconSx} />
        </div>
      )}
    >
      <Grid container spacing={2} p={0.5}>
        <Grid size={{ md: 4, xs: 12 }}>
          <Grid container spacing={2}>
            <Grid width="100%" size={12}>
              <Paper>
                <Grid container spacing={1} p={1}>
                  <Grid size={12}>
                    <Typography variant="h6" color="textSecondary">
                      {formatMessage(messages.settingsLabel)}
                    </Typography>
                  </Grid>
                  <Setting
                    label={formatMessage(messages.jobTypeLabel)}
                    value={data?.jobTypeCode?.label}
                  />
                  <Setting
                    label={formatMessage(messages.tasksLabel)}
                    value={
                      map(data?.taskCodes, (task) => task.label).join(', ') ||
                      '-'
                    }
                  />
                  <Setting
                    label={formatMessage(messages.languagesLabel)}
                    value={
                      map(data?.languageCodes, (lang) => lang.label).join(
                        ', ',
                      ) || '-'
                    }
                  />
                  <Setting
                    label={formatMessage(messages.ignoreCitiesLabel)}
                    value={data?.ignoreDesiredCities ? 'Yes' : 'No'}
                  />
                  <Setting
                    label={formatMessage(messages.jobStatusLabel)}
                    value={data?.statusName}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid width="100%" size={12}>
              <Paper>
                <Grid container spacing={1} p={1}>
                  <Grid size={12}>
                    <Typography variant="h6" color="textSecondary">
                      {formatMessage(messages.locationLabel)}
                    </Typography>
                  </Grid>
                  <Location
                    label={formatMessage(messages.venueLabel)}
                    value={data?.location?.name}
                  />
                  <Grid size={{ xs: 12, md: 'grow' }}>
                    <Location
                      label={formatMessage(messages.addressLabel)}
                      value={data?.location?.streetName}
                    />
                    <Location
                      label={formatMessage(messages.cityLabel)}
                      value={data?.location?.city?.label}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 'auto' }}>
                    <Location
                      label={formatMessage(messages.numberLabel)}
                      value={data?.location?.houseNumber}
                    />
                    <Location
                      label={formatMessage(messages.zipCodeLabel)}
                      value={data?.location?.zipCode}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Paper>
            <Grid container spacing={1} p={1}>
              <Grid size={12}>
                <Typography variant="h6" color="textSecondary">
                  {formatMessage(messages.requirementAndFinanceLabel)}
                </Typography>
              </Grid>
              <Setting
                label={formatMessage(messages.quantityLabel)}
                value={data?.quantity}
              />
              <Setting
                label={formatMessage(messages.bookingDealineLabel)}
                value={
                  data?.bookingDeadlineDate
                    ? forceString(data?.bookingDeadlineDate)
                    : '-'
                }
              />
              <Setting
                label={formatMessage(messages.salaryLabel)}
                value={formatCurrency(data?.parentWageBonus, currency, locale)}
              />
              <Setting
                label={formatMessage(messages.mainWageBonusLabel)}
                value={formatCurrency(data?.salary, currency, locale)}
              />
              <Setting
                label={formatMessage(messages.salaryBonusLabel)}
                value={formatCurrency(data?.salaryBonus, currency, locale)}
              />
              <Setting
                label={formatMessage(messages.priceLabel)}
                value={formatCurrency(data?.price, currency, locale)}
              />
              <Setting
                label={formatMessage(messages.priceBonusLabel)}
                value={formatCurrency(data?.priceBonus, currency, locale)}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, md: 5 }}>
          <Grid container spacing={2}>
            <Grid size={12} width="100%">
              <DataTable
                columns={columns}
                rows={shiftOptions}
                enabledFooter={false}
              />
            </Grid>
            <Grid size={12}>
              <Paper>
                <Grid container spacing={1} p={1}>
                  <Grid size={12}>
                    <Typography variant="h6" color="textSecondary">
                      {formatMessage(messages.descriptionLabel)}
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Typography>{data?.description ?? '-'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container justifyContent="flex-end" pb={1}>
        <BackupJobForm isFlex={false} jobUuid={jobUuid} backupUuid={uuid} />
      </Grid>
    </ConfirmModal>
  );
}

const Setting = ({ label, value }: { label: string; value?: string }) => (
  <Grid container size={12}>
    <Grid size={6}>
      <Typography color="textSecondary">{label}</Typography>
    </Grid>
    <Grid size={6}>
      <Typography color="secondary" style={{ textAlign: 'right' }}>
        {value ?? '-'}
      </Typography>
    </Grid>
  </Grid>
);

const Location = ({
  label,
  value,
}: {
  label: string;
  value?: string | null;
}) => (
  <Grid container size={12}>
    <Grid size={12}>
      <Typography color="textSecondary">{label}</Typography>
      <Typography color="secondary">{value ?? '-'}</Typography>
    </Grid>
  </Grid>
);
