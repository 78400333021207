import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import ConfirmModal from 'src/components/ConfirmModal';

import { formatCurrency } from 'src/utils/standards';
import { currency, locale } from 'src/config';

import messages from './messages';

export default function ExemptFineFormModal({
  onConfirm,
  selected,
  disabled,
}: {
  onConfirm: () => void;
  selected: UCM.FinesType[];
  disabled?: boolean;
}) {
  const { formatMessage } = useIntl();

  const exemptFineInfoMessage = useMemo(() => {
    return selected.length > 1
      ? formatMessage(messages.exemptFinesInfoMessage)
      : formatMessage(messages.exemptFineInfoMessage);
  }, [selected]);

  return (
    <ConfirmModal
      width="700px"
      onConfirm={onConfirm}
      title={formatMessage(messages.title)}
      triggerButton={(handleOpen) => (
        <Button color="secondary" onClick={handleOpen} disabled={disabled}>
          {formatMessage(messages.buttonOpenLabel)}
        </Button>
      )}
    >
      <Grid container spacing={2}>
        <Typography variant="inherit">{exemptFineInfoMessage}:</Typography>
        <Grid size={12}>
          {selected.map((fine) => {
            const user = `${fine.userId} - ${fine.user.firstName} ${fine.user.lastName}`;
            const fineAmount = formatCurrency(fine.amount, currency, locale);
            return (
              <ul key={fine.uuid}>
                <li>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="inherit" color="secondary">
                      {user} -
                    </Typography>
                    <Typography variant="inherit" color="error">
                      {fineAmount}
                    </Typography>
                  </Stack>
                </li>
              </ul>
            );
          })}
        </Grid>
        <Grid size={12}>
          <Typography variant="inherit">
            {formatMessage(messages.exemptFineConfirmLabel)}
          </Typography>
        </Grid>
      </Grid>
    </ConfirmModal>
  );
}
