import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Property } from 'csstype';

import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import ConfirmationDialog from 'src/components/ConfirmationDialog';

import messages from './messages';
import { Styled } from './index.styled';

import type { QueryObserverResult } from '@tanstack/react-query';

const sxModalAlignment = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

interface ConfirmModalProps {
  title: string;
  disabled?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  submitTextButton?: string;
  confirmationMessage?: string;
  confirmationTitle?: string;
  maxWidth?: string;
  confirmationRequired?: boolean;
  width?: Property.Width<number>;
  open?: boolean;
  loading?: boolean;
  confirmationDialogMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onOpen?: () => Promise<QueryObserverResult<unknown, unknown>> | void;
  onConfirm?: (
    event: React.MouseEvent<HTMLElement>,
  ) => void | Promise<void> | Promise<boolean>;
  triggerButton?: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
  renderExtraButton?: React.ReactNode;
  disableBackdropPress?: boolean;
}

export default function ConfirmModal({
  width,
  title,
  onOpen,
  onClose,
  children,
  onConfirm,
  triggerButton,
  open = false,
  loading = false,
  disabled = false,
  confirmationTitle,
  submitTextButton,
  renderExtraButton,
  confirmationMessage,
  disableBackdropPress = false,
  confirmationRequired = false,
  confirmationDialogMaxWidth = 'sm',
}: ConfirmModalProps) {
  const { formatMessage } = useIntl();

  const [isOpen, setOpen] = useState(false);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (mountedRef.current) setOpen(true);
    if (onOpen) onOpen();
  }, []);

  const handleClose = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      reason: 'backdropClick' | 'escapeKeyDown' | '' = '',
    ) => {
      event.stopPropagation();
      if (disableBackdropPress && reason.match(/backdropClick|escapeKeyDown/))
        return;
      if (mountedRef.current) setOpen(false);
      if (onClose) onClose();
    },
    [onClose, disableBackdropPress],
  );

  const handleConfirm = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      let close: boolean | void = true;

      try {
        if (onConfirm) close = await onConfirm(event);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        close = false;
      }

      if (close !== false) handleClose(event);
    },
    [onConfirm, handleClose],
  );

  const renderButton = useCallback(
    (handleClick: (event: React.MouseEvent<HTMLElement>) => void) => (
      <LoadingButton
        loading={loading}
        variant="contained"
        disabled={disabled}
        onClick={handleClick}
      >
        {submitTextButton || formatMessage(messages.submitButtonLabel)}
      </LoadingButton>
    ),
    [disabled, submitTextButton, loading],
  );

  const preventEventBubbling = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      {triggerButton && triggerButton(handleOpen)}

      <Modal
        open={isOpen || open}
        onClose={handleClose}
        sx={sxModalAlignment}
        onClick={preventEventBubbling}
      >
        <Styled.ModalContainer style={{ width }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontWeight={'600'} fontSize={26}>
              {title}
            </Typography>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Divider />

          <Stack spacing={2} mt={2} pr={0.25} maxHeight="60vh" overflow="auto">
            {children}
          </Stack>

          {onConfirm && (
            <Stack mt={3} gap={2} flexDirection="row" justifyContent="flex-end">
              {renderExtraButton}
              {confirmationRequired && confirmationMessage ? (
                <ConfirmationDialog
                  title={confirmationTitle}
                  onConfirm={handleConfirm}
                  message={confirmationMessage}
                  maxWidth={confirmationDialogMaxWidth}
                >
                  {renderButton}
                </ConfirmationDialog>
              ) : (
                renderButton(handleConfirm)
              )}
            </Stack>
          )}
        </Styled.ModalContainer>
      </Modal>
    </>
  );
}
