import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

const getRatingScores = async () => {
  const { data } = await getRequest(API.GET.getRatingScores);

  return mapRatingScore(data?.data);
};

export const useGetRatingScore = ({
  enabled = true,
}: {
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RATING_SCORE],
    queryFn: getRatingScores,
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

const mapRatingScore = (
  data: { attributes: UCM.JobRatingRawType; id: string }[],
): UCM.JobRatingType[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    value: attributes.score.toString(),
    label: attributes.name,
  }));
};
