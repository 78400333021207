import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest, serialize } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

interface Params {
  user_id?: string | number;
  job_id?: string;
  timespan: string;
}

const getCancellationShifts = async (params: Params) => {
  const query = serialize(params);

  const { data } = await getRequest(
    API.GET.getCancellationShifts(query.toString()),
  );

  return mapShift(data.data);
};

export const useGetCancellationShifts = ({
  params,
  enabled = false,
}: {
  params: Params;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CANCELLATION_SHIFTS, params],
    queryFn: () => getCancellationShifts(params),
    enabled,
  });
};

const mapShift = (
  data: {
    attributes: UCM.ShiftRawType;
    id: string;
  }[],
): UCM.ShiftType[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    uuid: attributes.uuid,
    shift: {
      endsAt: attributes.shift.ends_at,
      id: attributes.shift.id,
      jobId: attributes.shift.job_id,
      projectUuid: attributes.shift.project_uuid,
      startsAt: attributes.shift.starts_at,
      uuid: attributes.shift.uuid,
    },
  }));
};
