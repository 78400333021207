import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import {
  buildSaveStaffTypeParams,
  type saveStaffParamsType,
} from './useCreateStaffType';

const updateStaffType = async (params: saveStaffParamsType) => {
  const { data } = await putRequest<RequestSimplifiedData>(
    API.PUT.updateStaffType(params.uuid),
    buildSaveStaffTypeParams(params),
  );

  return data;
};

export const useUpdateStaffType = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateStaffType,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.staffTypeSaved), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.STAFF_TYPE_OPTIONS],
      });
    },
    onError: handleError,
  });
};
