import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import ConfirmationDialog from 'src/components/ConfirmationDialog';

import { useUpdateCancellationBackup } from 'src/pages/CancellationsPage/api';

import messages from './messages';

export default function CancellationBackupButton({
  uuid,
  isBackupRequired,
}: {
  uuid: string;
  isBackupRequired: boolean;
}) {
  const { formatMessage } = useIntl();

  const {
    mutate: updateCancellationBackup,
    isPending: isUpdatingCancellationBackup,
  } = useUpdateCancellationBackup();

  const handleRequiredBackup = useCallback(
    (uuid: string, isBackupRequired: boolean = false) => {
      updateCancellationBackup(
        { uuid, isBackupRequired },
        {
          onSuccess: () => {
            formatMessage(
              isBackupRequired
                ? messages.cancellationBackupSuccess
                : messages.cancellationNoBackupSuccess,
            );
          },
        },
      );
    },
    [],
  );

  const borderRadiusSx = useRef(() => ({
    borderRadius: 100,
  })).current;

  const buttonLabel = isBackupRequired
    ? formatMessage(messages.requireBackupConfirmMessage)
    : formatMessage(messages.noBackupConfirmMessage);

  return (
    <ConfirmationDialog
      onConfirm={() => handleRequiredBackup(uuid, isBackupRequired)}
      message={buttonLabel}
    >
      {(handleDialogOpen) => (
        <Button
          variant="contained"
          color={isBackupRequired ? 'info' : 'error'}
          sx={borderRadiusSx}
          onClick={handleDialogOpen}
          disabled={isUpdatingCancellationBackup}
        >
          {isBackupRequired ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
        </Button>
      )}
    </ConfirmationDialog>
  );
}
