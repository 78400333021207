/*
 * Error Modal
 *
 * This contains all the text for the Error Modal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.components.ErrorModal';

export default defineMessages({
  defaultTitle: { id: `${scope}.defaultTitle`, defaultMessage: 'Attention' },
  defaultConfirm: { id: `${scope}.defaultConfirm`, defaultMessage: 'Confirm' },
  defaultCancel: { id: `${scope}.defaultCancel`, defaultMessage: 'Cancel' },
  additionalCancelLabel: {
    id: `${scope}.additionalCancelLabel`,
    defaultMessage: "Okay, I've got it",
  },
  warningsLabel: {
    id: `${scope}.warningsLabel`,
    defaultMessage: '{length}. Warnings',
  },
  errorsLabel: {
    id: `${scope}.errorsLabel`,
    defaultMessage: '{length}. Errors',
  },
  pjd_not_found: {
    id: `${scope}.pjd_not_found`,
    defaultMessage: 'Unknown PJD ID in Imported File',
  },
  uid_removed: {
    id: `${scope}.uid_removed`,
    defaultMessage: 'Unknown PJD ID in Imported File',
  },
  extra_headers: {
    id: `${scope}.extra_headers`,
    defaultMessage: 'Extra header in Imported File',
  },
  other: { id: `${scope}.other`, defaultMessage: 'Other' },
  user_not_found: {
    id: `${scope}.user_not_found`,
    defaultMessage: 'Missing UID',
  },
  uid_duplicated: {
    id: `${scope}.uid_duplicated`,
    defaultMessage: 'Duplicate UID on the Same Workday',
  },
  pjd_id_duplicated: {
    id: `${scope}.pjd_id_duplicated`,
    defaultMessage: 'Duplicate PJD ID on the Same Workday',
  },
  missing_headers: {
    id: `${scope}.missing_headers`,
    defaultMessage: 'Header Mismatch',
  },
});
