import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
  type SelectProps,
} from '@mui/material';

type DropdownProps = SelectProps & {
  label?: string;
  name?: string;
  value?: boolean | string;
  error?: boolean;
  sx?: Record<string, unknown>;
  helperText?: string;
  placeholder?: string;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  renderSelectedValue?: (value: string | number | boolean) => string;
  onChange?: (event: SelectChangeEvent<string | number | boolean>) => void;
  options:
    | Array<{
        value?: string | number | boolean;
        label?: string;
        code?: string;
        title?: string;
      }>
    | undefined;
};

const selectMenuProps = { PaperProps: { style: { maxHeight: 500 } } };

const sxLabel = { ml: -1.5 };

const sxHelperText = { marginLeft: 0 };

export default function Dropdown({
  sx,
  name,
  label,
  value,
  error,
  options,
  onChange,
  disabled,
  readOnly,
  helperText,
  fullWidth = true,
  renderSelectedValue,
  variant = 'standard',
  placeholder,
}: DropdownProps) {
  if (options == null) return null;

  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {placeholder && <InputLabel sx={sxLabel}>{placeholder}</InputLabel>}
      {label && <InputLabel variant="standard">{label}</InputLabel>}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        MenuProps={selectMenuProps}
        variant={variant}
        displayEmpty
        sx={sx}
        readOnly={readOnly}
        disabled={disabled}
        renderValue={(selectedValue) => {
          // If no selected value, show the placeholder
          if (!selectedValue && label) {
            return '';
          }

          // If renderSelectedValue is passed as a function, use it
          if (renderSelectedValue) {
            return renderSelectedValue(selectedValue);
          }

          // Default behavior: Find the selected option and return the label
          const selectedOption = options.find(
            (option) =>
              option.value === selectedValue || option.code === selectedValue,
          );

          return selectedOption
            ? selectedOption.label || selectedOption.title
            : '';
        }}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}

        {options.map((option) => {
          const { value, label, title, code } = option;
          const optionValue = value || code;
          return (
            <MenuItem
              key={optionValue?.toString()}
              value={
                optionValue as string | number | readonly string[] | undefined
              }
            >
              {label || title}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && (
        <FormHelperText sx={sxHelperText}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
