import { useIntl } from 'react-intl';

import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import styled from '@mui/material/styles/styled';

import { colors } from 'src/utils/customColors';

import messages from './messages';

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: colors.yellow,
  color: theme.palette.common.black,
  borderRadius: theme.spacing(0.5),
  '& .MuiChip-icon': {
    color: theme.palette.common.black,
  },
}));

export default function PriorityChip() {
  const { formatMessage } = useIntl();

  return (
    <StyledChip
      icon={<StarIcon />}
      label={formatMessage(messages.priority)}
      size="small"
    />
  );
}
