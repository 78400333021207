import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';

const mapStaffType = (
  staff: UCM.StaffTypeRawOptionType,
): UCM.StaffTypeOptionType => ({
  value: staff.uuid,
  label: staff.name,
});

const getStaffTypeOptions = async () => {
  const {
    data: { data },
  } = await getRequest<RequestDataArray<UCM.StaffTypeRawOptionType>>(
    API.GET.getStaffTypeOptions,
    {
      headers: { Accept: 'application/vnd.falcon.v1+json' },
    },
  );

  return map(data, ({ attributes }) => mapStaffType(attributes));
};

export const useGetStaffTypeOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.STAFF_TYPE_OPTIONS],
    queryFn: getStaffTypeOptions,
    ...config,
  });
};
