import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapBackupJobShiftOption = (
  shift: UCM.BackupJobShiftOptionRawType,
  shiftId: string,
): UCM.BackupJobShiftOptionType => ({
  id: shiftId,
  selected: shift.selected
    ? { startsAt: shift.selected.starts_at, endsAt: shift.selected.ends_at }
    : null,
  remaining: shift.remaining,
  endsAt: shift.ends_at,
  startsAt: shift.starts_at,
});

const getBackupShiftOptions = async (jobUuid: string, backupUuid?: string) => {
  const params = new URLSearchParams();
  if (backupUuid) {
    params.set('backup_job_uuid', backupUuid);
  }

  const {
    data: { data },
  } = await getRequest<RequestDataArray<UCM.BackupJobShiftOptionRawType>>(
    API.GET.backupShiftOptions(jobUuid, params.toString()),
  );
  return map(data, ({ attributes, id }) =>
    mapBackupJobShiftOption(attributes, id),
  );
};

export const useGetBackupShiftOptions = (
  jobUuid: string,
  config: { enabled?: boolean } = { enabled: true },
  backupUuid?: string,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.BACKUP_JOB_SHFT_OPTIONS, jobUuid, backupUuid],
    queryFn: () => getBackupShiftOptions(jobUuid, backupUuid),
    staleTime: 1000 * 60,
    ...config,
  });
};
