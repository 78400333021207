import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import download from 'src/utils/download';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import {
  ErrorType,
  useMessageHandler,
} from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/ProjectDetailsPage/messages';

export type DownloadStaffPlanFetchParamsType = {
  jobId: string;
  jobUuid: string;
};

const downloadJobStaffPlan = async ({
  jobId,
  jobUuid,
}: DownloadStaffPlanFetchParamsType) => {
  const data = await getRequest(API.GET.getJobStaffPlan(jobUuid), {
    responseType: 'blob',
  });
  download(data.data, `job_staff_plan_${jobId}.pdf`, 'application/pdf');
  return data.data;
};

export const useDownloadJobStaffPlan = ({
  filters,
  onSettled,
  enabled = false,
}: {
  filters: DownloadStaffPlanFetchParamsType;
  onSettled?: () => void;
  enabled?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();

  const { isSuccess, isError, error } = useQuery<unknown, ErrorType>({
    queryKey: [QUERY_KEYS.JOB_STAFF_PLAN],
    queryFn: () => downloadJobStaffPlan(filters),
    enabled,
  });

  useEffect(() => {
    if (isSuccess) {
      handleSuccess(formatMessage(messages.staffPlanDownloaded), 1000);
      client.removeQueries({ queryKey: [QUERY_KEYS.JOB_STAFF_PLAN] });
    }
    if ((isSuccess || isError) && onSettled) {
      onSettled();
    }
    if (isError && error) {
      handleError(error);
    }
  }, [isSuccess, isError]);
};
