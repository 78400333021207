import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapCountryOptions = (
  option: UCM.CountryOptionRawType,
): UCM.CountryOptionType => ({
  value: option.code,
  label: option.name,
});

const getCountryOptions = async () => {
  const { data } = await getRequest<RequestDataArray<UCM.CountryOptionRawType>>(
    API.GET.countryOptions,
    {
      headers: {
        Accept: 'application/vnd.falcon.v4+json',
      },
    },
  );
  return data.data.map(({ attributes }) => mapCountryOptions(attributes));
};

export const useGetCountryOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.COUNTRY_OPTIONS],
    queryFn: getCountryOptions,
    ...config,
  });
};
