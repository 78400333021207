/*
 * StandbyChip Messages
 *
 * This contains all the text for the StandbyChip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.StandbyChip';

export default defineMessages({
  standby: {
    id: `${scope}.standby`,
    defaultMessage: 'Standby',
  },
});
