/*
 * JobDetails Messages
 *
 * This contains all the text for the JobDetails component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.JobDetails';

export default defineMessages({
  applicantLabel: {
    id: `${scope}.applicantLabel`,
    defaultMessage: 'Applicant',
  },
  appliedToLabel: {
    id: `${scope}.appliedToLabel`,
    defaultMessage: 'Applied to',
  },
  workdayLabel: { id: `${scope}.workdayLabel`, defaultMessage: 'Workday' },
  daysSinceAppliedLabel: {
    id: `${scope}.daysSinceAppliedLabel`,
    defaultMessage: 'Day since applied',
  },
  daysSinceAcceptedLabel: {
    id: `${scope}.daysSinceAcceptedLabel`,
    defaultMessage: 'Day since accepted',
  },
  cancelRateLabel: {
    id: `${scope}.cancelRateLabel`,
    defaultMessage: 'Cancel Rate',
  },
  timeToConfirmLabel: {
    id: `${scope}.timeToConfirmLabel`,
    defaultMessage: 'Time to confirm',
  },
  logInLabel: { id: `${scope}.logInLabel`, defaultMessage: 'Log in' },
  ratingLabel: { id: `${scope}.ratingLabel`, defaultMessage: 'Rating' },
  preScreenLabel: {
    id: `${scope}.preScreenLabel`,
    defaultMessage: 'Pre-Screen',
  },
  profileLabel: { id: `${scope}.profileLabel`, defaultMessage: 'Profile' },
  lastJobLabel: { id: `${scope}.lastJobLabel`, defaultMessage: 'Last job' },
  statusLabel: { id: `${scope}.statusLabel`, defaultMessage: 'Status' },
  briefingLabel: { id: `${scope}.briefingLabel`, defaultMessage: 'Briefing' },
  preJobQLabel: { id: `${scope}.preJobQLabel`, defaultMessage: 'Pre-Job Q' },
  contractSummaryLabel: {
    id: `${scope}.contractSummaryLabel`,
    defaultMessage: 'Contract status',
  },
  daySinceAppliedLabel: {
    id: `${scope}.daySinceAppliedLabel`,
    defaultMessage: 'Day since applied',
  },
  checkInLabel: { id: `${scope}.checkInLabel`, defaultMessage: 'Check-in' },
  checkOutLabel: { id: `${scope}.checkOutLabel`, defaultMessage: 'Check-out' },
  commentLabel: { id: `${scope}.commentLabel`, defaultMessage: 'Comment' },
  scoreLabel: { id: `${scope}.scoreLabel`, defaultMessage: 'Score' },
  commentField: { id: `${scope}.commentField`, defaultMessage: 'Comment' },
  toTransferLabel: {
    id: `${scope}.toTransferLabel`,
    defaultMessage: 'To transfer',
  },
  transferredByLabel: {
    id: `${scope}.transferredByLabel`,
    defaultMessage: 'Transferred by',
  },
  saveRatingButton: {
    id: `${scope}.saveRatingButton`,
    defaultMessage: 'Save rating',
  },
  confirmAcceptButton: {
    id: `${scope}.confirmAcceptButton`,
    defaultMessage: 'Accept Selected',
  },
  acceptModalLabel: {
    id: `${scope}.acceptModalLabel`,
    defaultMessage: 'Please specify a reason for accepting',
  },
  confirmRejectButton: {
    id: `${scope}.confirmRejectButton`,
    defaultMessage: 'Reject Selected',
  },
  rejectModalLabel: {
    id: `${scope}.rejectModalLabel`,
    defaultMessage: 'Please specify a reason for rejecting',
  },
  mainJobCard: { id: `${scope}.mainJobCard`, defaultMessage: 'Main Job' },
  standbyCard: { id: `${scope}.standbyCard`, defaultMessage: 'Standby' },
  incompleteLabel: {
    id: `${scope}.incompleteLabel`,
    defaultMessage: 'Incomplete',
  },
  completeLabel: { id: `${scope}.completeLabel`, defaultMessage: 'Complete' },
  confirmedBriefingLabel: {
    id: `${scope}.confirmedBriefingLabel`,
    defaultMessage: 'Confirmed',
  },
  daysSinceBookedLabel: {
    id: `${scope}.daysSinceBookedLabel`,
    defaultMessage: 'Days since booked',
  },
  languagesColumnLabel: {
    id: `${scope}.languagesColumnLabel`,
    defaultMessage: 'Languages',
  },
  initialScoreColumnLabel: {
    id: `${scope}.initialScoreColumnLabel`,
    defaultMessage: 'Gut feeling score',
  },
  reapplyTooltip: {
    id: `${scope}.reapplyTooltip`,
    defaultMessage: 'Re-apply',
  },
  reapplyApplicationConfirmMessage: {
    id: `${scope}.reapplyApplicationConfirmMessage`,
    defaultMessage: 'Do you really want to apply back to this Job?',
  },
  cancelRejectButton: {
    id: `${scope}.cancelRejectButton`,
    defaultMessage: 'Cancel',
  },
  rejectButton: {
    id: `${scope}.rejectButton`,
    defaultMessage: 'Reject',
  },
  hiddenLabel: {
    id: `${scope}.hiddenLabel`,
    defaultMessage: 'Hidden',
  },
  visibleLabel: {
    id: `${scope}.visibleLabel`,
    defaultMessage: 'Visible',
  },
});
