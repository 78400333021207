/*
 * Contract Tooltip
 *
 * This contains all the text for the Contract Tooltip.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.ContractTooltip';

export default defineMessages({
  physicalLabel: {
    id: `${scope}.physicalLabel`,
    defaultMessage: 'Physical',
  },
  digitalLabel: {
    id: `${scope}.digitalLabel`,
    defaultMessage: 'Digital',
  },
  mixedLabel: {
    id: `${scope}.mixedLabel`,
    defaultMessage: 'Physical / Digital',
  },
  completedLabel: {
    id: `${scope}.completedLabel`,
    defaultMessage: 'Completed: {amount}',
  },
  initializedLabel: {
    id: `${scope}.initializedLabel`,
    defaultMessage: 'Initialized: {amount}',
  },
  userSignedLabel: {
    id: `${scope}.userSignedLabel`,
    defaultMessage: 'User signed: {amount}',
  },
  ucmSignedLabel: {
    id: `${scope}.ucmSignedLabel`,
    defaultMessage: 'UCM Signed: {amount}',
  },
});
