import { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

interface Props {
  text?: string;
  active?: boolean;
  handleChange?: (active: boolean) => void;
}

export default function FavoriteButton({ text, active, handleChange }: Props) {
  const handlePress = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      if (handleChange) handleChange(!active);
    },
    [active, handleChange],
  );

  return (
    <Stack alignItems="center" flexDirection="row" gap={1}>
      <Box
        display={'flex'}
        onClick={handlePress}
        sx={{
          cursor: typeof handleChange === 'function' ? 'pointer' : 'default',
        }}
      >
        {active ? (
          <FavoriteOutlinedIcon sx={{ width: 16, height: 16 }} />
        ) : (
          <FavoriteBorderOutlinedIcon sx={{ width: 16, height: 16 }} />
        )}
      </Box>
      {text && (
        <Typography
          variant="body1"
          fontWeight={(theme) => theme.typography.fontWeightMedium}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
}
