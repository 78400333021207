/*
 * Evaluation Form Modal Messages
 *
 * This contains all the text for the Evaluation Modal component.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.GatekeeperPage.components.EvaluationFormModal';

export default defineMessages({
  createTitle: {
    id: `${scope}.createTitle`,
    defaultMessage: 'New Evaluation',
  },
  editTitle: {
    id: `${scope}.editTitle`,
    defaultMessage: 'Edit Evaluation',
  },
  evaluationButton: {
    id: `${scope}.evaluationButton`,
    defaultMessage: 'New evaluation',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'Student Email*',
  },
  methodLabel: {
    id: `${scope}.methodLabel`,
    defaultMessage: 'Pre-screening method*',
  },
  germanSpeakerLabel: {
    id: `${scope}.germanSpeakerLabel`,
    defaultMessage: 'German Language*',
  },
  mindsetLabel: {
    id: `${scope}.mindsetLabel`,
    defaultMessage: 'Mindset*',
  },
  impressionLabel: {
    id: `${scope}.impressionLabel`,
    defaultMessage: 'Impression*',
  },
  comprehensionLabel: {
    id: `${scope}.comprehensionLabel`,
    defaultMessage: 'Comprehension*',
  },
  expressionLabel: {
    id: `${scope}.expressionLabel`,
    defaultMessage: 'Expression*',
  },
  vetoLabel: {
    id: `${scope}.vetoLabel`,
    defaultMessage: 'Allow Access?*',
  },
  vetoCommentLabel: {
    id: `${scope}.vetoCommentLabel`,
    defaultMessage: 'Block Reason*',
  },
  preScreeningCreated: {
    id: `${scope}.preScreeningCreated`,
    defaultMessage: 'Pre-screening created successfully',
  },
  preScreeningUpdated: {
    id: `${scope}.preScreeningUpdated`,
    defaultMessage: 'Pre-screening updated successfully',
  },
  videoLinkLabel: {
    id: `${scope}.videoLinkLabel`,
    defaultMessage: 'Go to the video',
  },
  confirmationMessage: {
    id: `${scope}.confirmationMessage`,
    defaultMessage: 'Do you want to submit this evaluation form?',
  },
});
