/*
 * BackupJobForm Messages
 *
 * This contains all the text for the BackupJobForm component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.JobDetails.JobDetails';

export default defineMessages({
  backupJobButtonLabel: {
    id: `${scope}.backupJobButtonLabel`,
    defaultMessage: 'Add Backup Job',
  },
  backupEditLabel: { id: `${scope}.backupEditLabel`, defaultMessage: 'Edit' },
  title: { id: `${scope}.title`, defaultMessage: 'Backup Job' },
  shiftTableHeading: {
    id: `${scope}.shiftTableHeading`,
    defaultMessage: 'Select Shifts',
  },
  dateColumnLabel: { id: `${scope}.dateColumnLabel`, defaultMessage: 'Date' },
  originalTimeColumnLabel: {
    id: `${scope}.originalTimeColumnLabel`,
    defaultMessage: 'Original Timeframe',
  },
  adjustedTimeColumnLabel: {
    id: `${scope}.adjustedTimeColumnLabel`,
    defaultMessage: 'Adjusted Timeframe',
  },
  remainingColumnLabel: {
    id: `${scope}.remainingColumnLabel`,
    defaultMessage: 'Remaining',
  },
  shiftIndexRequired: {
    id: `${scope}.shiftIndexRequired`,
    defaultMessage: 'At least one shift must be selected',
  },
});
