/*
 * Exempt Fine Form Modal
 *
 * This contains all the text for the Exempt Fine Form Modal.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.FinesPage.components.ExemptFineFormModal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Exempt Fines',
  },
  buttonOpenLabel: {
    id: `${scope}.buttonOpenLabel`,
    defaultMessage: 'Exempt Selected',
  },
  exemptFineInfoMessage: {
    id: `${scope}.exemptFineInfoMessage`,
    defaultMessage: 'You are going to exempt fine for this user',
  },
  exemptFinesInfoMessage: {
    id: `${scope}.exemptFinesInfoMessage`,
    defaultMessage: 'You are going to exempt fines for these users',
  },
  exemptFineConfirmLabel: {
    id: `${scope}.exemptFineConfirmLabel`,
    defaultMessage: 'Please confirm if this is correct and intentional',
  },
});
