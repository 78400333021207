import { useCallback, useMemo } from 'react';

import map from 'lodash/map';
import isArray from 'lodash/isArray';

import { buildHelperText } from 'src/utils/components';
import AutoCompleteField from 'src/components/AutoCompleteField';

import type { DynamicOptionType } from 'src/types/DynamicField/DynamicField';

interface MultiSelectFieldProps {
  helperText?: string;
  field: UCM.DynamicFieldType;
  value: { options?: Array<string> };
  onChange: (
    field: UCM.DynamicFieldType,
    { options }: { options: Array<string> },
  ) => void;
}

export default function MultiSelectField({
  field,
  value,
  onChange,
  helperText = '',
}: MultiSelectFieldProps) {
  const selectedOption = useMemo(() => {
    if (!value?.options) return [];

    return field.options?.filter((option) =>
      value.options?.includes(option.value),
    );
  }, [field.options, value.options]);

  const handleChange = useCallback(
    (values: Array<DynamicOptionType> | DynamicOptionType | null) => {
      if (!values || !isArray(values)) {
        return;
      }

      const options = map(values, (option) => option.value);
      onChange(field, { options });
    },
    [onChange, field],
  );

  return (
    <AutoCompleteField
      multiple
      label={field.label}
      value={selectedOption}
      options={field.options}
      onChange={handleChange}
      helperText={buildHelperText(field.description, helperText)}
    />
  );
}
