import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useGetBookedToTransfer } from 'src/pages/ProjectDetailsPage/api/queries/useGetBookedToTransfer';
import DataTable from 'src/components/DataTable';

import { resolveQuickSearch } from 'src/components/SearchFilterBar';

import { useStyles } from '../../JobDetails.styled';
import messages from '../../messages';
import ContractTooltip from '../../../ContractTooltip';

export default function JobAvailable({
  jobId,
  standbyUuid = '',
  filters,
  candidate,
  handleSelectCandidate,
}: UCM.JobStandbyTabProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { data, isLoading } = useGetBookedToTransfer({
    standbyUuid,
    jobId,
    enabled: Boolean(standbyUuid) && Boolean(jobId),
    filters: resolveQuickSearch(filters),
  });

  const columns: Array<{
    id: keyof UCM.JobApplicationType | string;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 'applicant',
        label: formatMessage(messages.applicantLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant) return '-';
          const { id, fullName } = item.applicant;
          return (
            <Typography
              variant="body1"
              fontWeight={(theme) => theme.typography.fontWeightMedium}
            >
              {`${id} ${fullName}`}
            </Typography>
          );
        },
      },
      {
        id: 'appliedCount',
        label: formatMessage(messages.appliedToLabel),
        render: (appliedCount: number) => (
          <Typography variant="inherit">{appliedCount ?? '-'}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'workday',
        label: formatMessage(messages.workdayLabel),
        // TODO - waiting for backend adjustements
        render: () => <Typography variant="inherit">DD/MM/YYYY</Typography>,
        align: 'center',
      },
      {
        id: 'ratingScore',
        label: formatMessage(messages.ratingLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.ratingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.ratingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      {
        id: 'preScreen',
        label: formatMessage(messages.preScreenLabel),
        // TODO - waiting for backend adjustements
        render: () => <Typography variant="inherit">-</Typography>,
        align: 'center',
      },
      {
        id: 'profile',
        label: formatMessage(messages.profileLabel),
        // TODO - waiting for backend adjustements
        render: () => (
          // TODO - if profile complete <CheckOutlinedIcon sx={{ color: '#1E6A4F', width: 24, height: 24 }}/>
          <CloseOutlinedIcon sx={{ color: '#904A44', width: 24, height: 24 }} />
        ),
        align: 'center',
      },
      {
        id: 'toTransfer',
        label: formatMessage(messages.toTransferLabel),
        // TODO - waiting for backend adjustements
        render: () => (
          // TODO - if can transfter <CheckOutlinedIcon sx={{ color: '#1E6A4F', width: 24, height: 24 }}/>
          <CloseOutlinedIcon sx={{ color: '#904A44', width: 24, height: 24 }} />
        ),
        align: 'center',
      },
      {
        id: 'contractSummary',
        label: formatMessage(messages.contractSummaryLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.contractSummary.status) return '-';
          return <ContractTooltip contract={item?.contractSummary} />;
        },
        align: 'center',
      },
    ],
    [],
  );

  return (
    <Grid size={{ xs: 12 }}>
      <DataTable
        rows={data}
        columns={columns}
        className={classes.tableContainer}
        loading={isLoading}
        enabledFooter={false}
        pressRow={handleSelectCandidate}
        activeRow={candidate}
        checkActiveKey="applicant.id"
      />
      {/* TODO - add action button */}
    </Grid>
  );
}
