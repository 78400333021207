import { useQueries } from '@tanstack/react-query';
import { filter, map } from 'lodash';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, serialize } from 'src/utils/request';

type CountType =
  | 'all_count'
  | 'applied_count'
  | 'accepted_count'
  | 'booked_count'
  | 'denied_count'
  | 'done_count';

type Result = { [key in CountType]: number };

const getCount = async (
  jobUuid: string,
  type: string,
  filters: Record<string, string>[],
) => {
  const queryString = [
    ...map(filters, (filterParams) => serialize(filterParams, 'filters')),
  ];
  let query = filter(queryString, (query) => query?.length).join('&');
  query = query.length ? `?${query}` : '';

  const { data } = await getRequest(
    API.GET.getJobApplications(jobUuid, type, query),
  );

  return { data, type };
};

export const useGetJobApplicationsCount = ({
  jobUuid,
  types = [],
  filters,
  enabled = false,
}: {
  jobUuid: string;
  types: string[];
  filters: Record<string, string>;
  enabled?: boolean;
}) => {
  return useQueries({
    queries: types?.map((type) => {
      return {
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS_COUNT, jobUuid, type, filters],
        queryFn: () => getCount(jobUuid, type, [filters]),
        staleTime: 1000 * 60 * 30,
        enabled,
      };
    }),
    combine: (results) => {
      if (!results) return { data: [], isPending: false };

      const filtered = results.map((result) => result?.data);
      const counts = {} as Result;

      filtered?.forEach((item) => {
        if (item?.data) {
          const { data, type } = item;
          counts[type as CountType] = data?.count ?? 0;
        }
      });

      const isPending = results.some((result) => result.isPending);
      return { counts, isPending };
    },
  });
};
