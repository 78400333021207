import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import PageLayout from 'src/components/PageLayout';
import DataTable from 'src/components/DataTable';
import { PencilAdornment } from 'src/utils/components';
import SearchFilterBar, {
  QuickFiltersType,
} from 'src/components/SearchFilterBar';

import { useGetCancellations } from 'src/pages/CancellationsPage/api';
import {
  useUpdateCancellationNotices,
  useUpdateWithdrawCancellationNotices,
} from 'src/pages/ProjectDetailsPage/api';
import { resolveClearFilters } from 'src/utils/request';

import CancellationModal from '../ProjectDetailsPage/components/JobDetails/components/CancellationModal/CancellationModal';
import { REJECT_REASON } from '../ProjectDetailsPage/api/queries/useGetRejectReason';
import { CancellationData } from '../ProjectDetailsPage/components/JobCalendar';
import CreateCancellationFormModal from './components/CreateCancellationFormModal/CreateCancellationFormModal';

import messages from './messages';
import CancellationBackupButton from './components/CancellationBackupButton/CancellationBackupButton';

type CancellationType = 'all' | 'open' | 'transfer';

enum StateType {
  PROCESSED = 'processed',
}

const navTabs: Array<{ value: CancellationType; label: string }> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'transfer',
    label: 'Transfer',
  },
];

export default function CancellationsPage() {
  const { formatMessage } = useIntl();

  const [state, setState] = useState<Record<string, string | boolean>>({
    key: navTabs[0].value,
    state: StateType.PROCESSED,
  });
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [openCancellation, setOpenCancellation] = useState(false);
  const [editCancellation, setEditCancellation] =
    useState<UCM.CancellationType | null>(null);

  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: cancellations, isFetching: isFetchingCancellations } =
    useGetCancellations({
      filters: resolveClearFilters({
        ...state,
        ...filters,
      }),
      params,
    });

  const { mutate: updateCancellationNotices } = useUpdateCancellationNotices({
    inCancellationsPage: true,
  });

  const { mutate: withdrawCancellationNotices } =
    useUpdateWithdrawCancellationNotices({
      inCancellationsPage: true,
    });

  const handleEditCancellation = useCallback(
    (cancellation: UCM.CancellationType) => {
      setEditCancellation(cancellation);
      setOpenCancellation(true);
    },
    [],
  );

  const fixedColumns = useMemo(() => {
    return [
      {
        id: 'student',
        label: formatMessage(messages.studentColumnLabel),
        render: (_: unknown, item: UCM.CancellationType) => {
          if (!item?.user) return '-';
          const { id, firstName, lastName } = item.user;
          return (
            <Typography variant="inherit">{`${id} - ${firstName} ${lastName}`}</Typography>
          );
        },
      },
      {
        id: 'id',
        label: formatMessage(messages.cancellationIdColumnLabel),
        render: (id: string) => <Typography variant="inherit">{id}</Typography>,
        align: 'center',
      },
      {
        id: 'created',
        label: formatMessage(messages.createdByColumnLabel),
        render: (_: unknown, item: UCM.CancellationType) => {
          return (
            <>
              <Typography variant="inherit">{item.source}</Typography>
              <Typography variant="inherit">{item.createdAt}</Typography>
            </>
          );
        },
        align: 'center',
      },
      {
        id: 'jobId',
        label: formatMessage(messages.jobIdColumnLabel),
        render: (jobId: string) => (
          <Typography variant="inherit">{jobId}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'project',
        label: formatMessage(messages.projectDetailsColumnLabel),
        render: (_: unknown, item: UCM.CancellationType) => {
          if (!item?.project) return '-';
          const { name, id, projectManager } = item.project;
          return (
            <>
              <Typography variant="inherit">{id}</Typography>
              <Typography variant="inherit">{name}</Typography>
              <Typography variant="inherit">({projectManager})</Typography>
            </>
          );
        },
        align: 'center',
      },
      {
        id: 'shifts',
        label: formatMessage(messages.shiftsColumnLabel),
        render: (_: unknown, item: UCM.CancellationType) => {
          const { shiftDates, shiftCount } = item;
          return (
            <Typography variant="inherit">
              {`${shiftDates?.length}/${shiftCount}`}
            </Typography>
          );
        },
        align: 'center',
      },
      {
        id: 'reason',
        label: formatMessage(messages.reasonColumnLabel),
        render: (_: unknown, item: UCM.CancellationType) => (
          <Typography variant="inherit">{item.reason.title}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'explanation',
        label: formatMessage(messages.explanationColumnLabel),
        render: (explanation: string) => (
          <Typography variant="inherit">{explanation}</Typography>
        ),
      },
      {
        id: 'adminComment',
        label: formatMessage(messages.adminCommentColumnLabel),
        render: (adminComment: string) => (
          <Typography variant="inherit">{adminComment}</Typography>
        ),
        align: 'center',
      },
    ];
  }, []);

  const allColumns = useMemo(() => {
    return [
      ...fixedColumns,
      {
        id: 'backup',
        label: '',
        align: 'center',
        render: (_: unknown, item: UCM.CancellationType) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => handleEditCancellation(item)}
          >
            <PencilAdornment />
          </IconButton>
        ),
      },
    ];
  }, [fixedColumns]);

  const openColumns = useMemo(() => {
    return [
      ...fixedColumns,
      {
        id: 'actions',
        label: formatMessage(messages.backupButtonLabel),
        align: 'center',
        width: 0,
        render: (_: unknown, item: UCM.CancellationType) => (
          <Grid container spacing={1} justifyContent="center">
            <CancellationBackupButton uuid={item.uuid} isBackupRequired />
            <CancellationBackupButton
              uuid={item.uuid}
              isBackupRequired={false}
            />
          </Grid>
        ),
      },
      {
        id: 'edit',
        label: '',
        align: 'center',
        width: 0,
        render: (_: unknown) => (
          <IconButton color="inherit" size="small">
            <PencilAdornment />
          </IconButton>
        ),
      },
    ];
  }, [fixedColumns]);

  const columns: Record<
    CancellationType,
    Array<{
      id: string;
      label: string | React.ReactNode;
    }>
  > = useMemo(
    () => ({
      all: allColumns,
      open: openColumns,
      transfer: allColumns,
    }),
    [allColumns, openColumns],
  );

  const searchFilters = useMemo(() => {
    return [
      {
        id: 'user_id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchUidPlaceholder),
      },
      {
        id: 'job_id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchJobIdPlaceholder),
      },
      {
        id: 'id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchCancelIdPlaceholder),
      },
    ];
  }, []);

  const handleActiveTabChange = useCallback((tab: CancellationType) => {
    let newState = { key: tab } as Record<string, string | boolean>;
    if (tab === 'open') {
      newState = { ...newState, backupable: true };
    } else if (tab === 'transfer') {
      newState = {
        ...newState,
        state: StateType.PROCESSED,
        confirmation_status: 'with_transfer',
      };
    } else {
      newState = { ...newState, state: StateType.PROCESSED };
    }

    setState(newState);
    setParams({ page: 1 });
  }, []);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  // eslint-disable-next-line
  const handleSearch = useCallback((search: Record<string, any>) => {
    setFilters(search);
  }, []);

  const handleCancellation = useCallback(async (data: CancellationData) => {
    const { type, cancellationUuid, ...payload } = data;
    const filteredData = Object.fromEntries(
      Object.entries(payload).filter(
        ([_, value]) => value != null && value !== '',
      ),
    );
    return new Promise<boolean>((resolve) => {
      const mutationOptions = {
        onSuccess: () => {
          closeCancellation;
          resolve(true);
        },
        onError: () => resolve(false),
      };

      if (type === 'update') {
        void updateCancellationNotices(
          {
            cancellationUuid,
            data: filteredData,
          },
          mutationOptions,
        );
      } else if (type === 'withdraw') {
        void withdrawCancellationNotices(
          {
            cancellationUuid,
            reason_code: payload.reason_code,
          },
          mutationOptions,
        );
      }
    });
  }, []);

  const closeCancellation = useCallback(() => {
    setOpenCancellation(false);
    setEditCancellation(null);
  }, []);

  return (
    <PageLayout
      iconName="fines"
      title={formatMessage(messages.title)}
      tabs={navTabs}
      activeTab={state.key as CancellationType}
      onActiveTabChange={handleActiveTabChange}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid size={6}>
          <SearchFilterBar
            fields={searchFilters}
            onSearch={handleSearch}
            variant="noBorder"
          />
        </Grid>
        <Grid size={6} container alignItems="center" justifyContent="flex-end">
          <CreateCancellationFormModal />
        </Grid>
        <Grid size={12}>
          <DataTable
            rows={cancellations?.data}
            pagination={cancellations?.meta}
            columns={columns[state.key as CancellationType]}
            onPaginate={handlePagination}
            loading={isFetchingCancellations}
          />
        </Grid>
      </Grid>
      <CancellationModal
        type={REJECT_REASON.RESET_TIME_TRACKING}
        enabledFetch={openCancellation}
        onConfirm={handleCancellation}
        open={openCancellation}
        onClose={closeCancellation}
        candidate={editCancellation?.user}
        cancellation={editCancellation}
      />
    </PageLayout>
  );
}
