import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

const getCandidate = async (uuid?: string) => {
  const { data } = await getRequest(API.GET.getCandidate(uuid));

  return mapCandidate(data?.data);
};

export const useGetCandidate = ({
  uuid,
  enabled = false,
}: {
  uuid?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CANDIDATE, uuid],
    queryFn: () => getCandidate(uuid),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

const mapCandidate = (data: {
  attributes: UCM.CandidateRawType;
  id: string;
}): UCM.CandidateType | null => {
  if (!data) return null;

  const { id, attributes } = data;

  return {
    id,
    averageJobPerformanceRating: attributes.average_job_performance_rating,
    birthDate: attributes.birth_date,
    birthName: attributes.birth_name,
    birthPlace: attributes.birth_place,
    birthPostCode: attributes.birth_post_code,
    deletedAt: attributes.deleted_at,
    email: attributes.email,
    firstName: attributes.first_name,
    hasDrivingLicense: attributes.has_driving_license,
    lastName: attributes.last_name,
    ratedJobs: attributes.rated_jobs.map((job) => ({
      jobId: job.job_id,
      projectUuid: job.project_uuid,
    })),
    uuid: attributes.uuid,
    zupAccess: attributes.zup_access,
  };
};
