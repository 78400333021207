/*
 * Summary Panel Messages
 *
 * This contains all the text for the Summary Panel component.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.ProjectDetailsPage.components.SummaryPanel';

export default defineMessages({
  downloadStaffPlanMenuLabel: {
    id: `${scope}.downloadStaffPlanMenuLabel`,
    defaultMessage: 'Download Staff Plan',
  },
  copyProjectLinkMenuLabel: {
    id: `${scope}.copyProjectLinkMenuLabel`,
    defaultMessage: 'Copy Project Link',
  },
  unmarkPriorityMenuLabel: {
    id: `${scope}.unmarkPriorityMenuLabel`,
    defaultMessage: 'Unmark as priority',
  },
  markPriorityMenuLabel: {
    id: `${scope}.markPriorityMenuLabel`,
    defaultMessage: 'Mark as priority',
  },
  staffPlanDownloaded: {
    id: `${scope}.staffPlanDownloaded`,
    defaultMessage: 'Staff Plan downloaded successfully',
  },
  projectLinkCopied: {
    id: `${scope}.projectLinkCopied`,
    defaultMessage: 'Project link copied successfully',
  },
  multipleCitiesLabel: {
    id: `${scope}.multipleCitiesLabel`,
    defaultMessage: 'Cities',
  },
});
