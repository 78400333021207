import { useQuery } from '@tanstack/react-query';

import keyBy from 'lodash/keyBy';

import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { buildAttributes, buildChild } from 'src/utils/tools';

type IncludedDataResponse<
  TDataAttributes = unknown,
  TIncluded = unknown,
  TMeta = unknown,
> = {
  data: Array<{
    id: string;
    type: string;
    attributes: TDataAttributes;
    relationships: {
      user: { data: { id: string; type: string } | null };
      company: { data: { id: string; type: string } | null };
      certificate: { data: { id: string; type: string } | null };
    };
  }>;
  included: Array<{
    id: string;
    type: string;
    attributes: TIncluded;
  }>;
  meta: TMeta;
};

export interface ContractFiltersType {
  job_id: string;
  user_id: string;
  project_id: string;
  status: UCM.ContractStatusType;
  project_type: UCM.ProjectContractType;
}

export const mapContract = (
  contracts: IncludedDataResponse<UCM.ContractRawType>['data'],
  included: Record<
    string,
    { id: string; type: string; attributes: UCM.ContractIncludedType }
  >,
): Array<UCM.ContractType> => {
  return contracts.map((contract) => {
    const company = buildChild(contract, included, 'company');
    const user = buildChild(contract, included, 'user', null, {
      email: 'email',
      uuid: 'uuid',
      firstName: 'first_name',
      lastName: 'last_name',
      id: 'id',
    });
    const certificate = buildChild(contract, included, 'certificate');

    const contractAttributes =
      buildAttributes(contract, {
        wage: 'wage',
        uuid: 'uuid',
        jobIds: 'job_ids',
        visible: 'visible',
        endDate: 'end_date',
        jobType: 'job_type',
        location: 'location',
        daysLeft: 'days_left',
        taskType: 'task_type',
        startDate: 'start_date',
        statusCode: 'status_code',
        noticeDate: 'notice_date',
        tariffCode: 'tariff_code',
        requirement: 'requirement',
        maxDuration: 'max_duration',
        unpaidLeave: 'unpaid_leave',
        projectUuids: 'project_uuids',
        monthlyHours: 'monthly_hours',
        archiveNumber: 'archive_number',
        documentOrigin: 'document_origin',
        employmentType: 'employment_type',
        contractNumber: 'contract_number',
        avgWeeklyHours: 'avg_weekly_hours',
        plannedEndDate: 'planned_end_date',
        signatureStatus: 'signature_status',
        projectTypeCode: 'project_type_code',
        latestStartDate: 'latest_start_date',
        userSigningDate: 'user_signing_date',
        plannedStartDate: 'planned_start_date',
        companySigningDate: 'company_signing_date',
      }) ?? {};

    return {
      user,
      company,
      certificate,
      id: contract.id,
      ...contractAttributes,
    } as UCM.ContractType;
  });
};

const getContracts = async (
  filters: ContractFiltersType,
  params: Record<string, string | number>,
) => {
  const query = resolveQueryString([filters], params);

  const {
    data: { data, meta, included },
  } = await getRequest<
    IncludedDataResponse<
      UCM.ContractRawType,
      UCM.ContractIncludedType,
      UCM.MetaRawType
    >
  >(`${API.GET.getContracts}${query}`, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });

  const includedRecords = keyBy(included, (item) => `${item.id}-${item.type}`);

  return {
    meta: paginationMapper(meta),
    data: mapContract(data, includedRecords),
  };
};

export const useGetContracts = (
  params: Record<string, string | number>,
  filters: ContractFiltersType,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACTS, filters, params],
    queryFn: () => getContracts(filters, params),
    staleTime: 1000 * 60 * 5, // Dashboards refreshed after every 5 minutes automatically
    ...config,
  });
};
