import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestDataArray } from 'src/types/requestData';
import { forceString } from 'src/utils/datetime';
import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';

const mapCancellation = (
  attributes: UCM.CancellationRawType,
  id: string,
): UCM.CancellationType => ({
  id,
  uuid: attributes.uuid,
  userId: attributes.user_id,
  jobId: attributes.job_id,
  source: attributes.source,
  explanation: attributes.explanation,
  adminComment: attributes.admin_comment,
  shiftDates: attributes.shift_dates,
  createdAt: forceString(attributes.created_at),
  user: {
    id: attributes.user.id,
    uuid: attributes.user.uuid,
    firstName: attributes.user.first_name,
    lastName: attributes.user.last_name,
    email: attributes.user.email,
  },
  project: {
    id: attributes.project.id,
    uuid: attributes.project.uuid,
    name: attributes.project.name,
    projectManager: attributes.project.project_manager,
  },
  reason: {
    code: attributes.reason.code,
    title: attributes.reason.title,
  },
  jobShifts: attributes.job_shifts
    ? attributes.job_shifts?.map((shift: UCM.JobShiftRawType) => ({
        id: shift.id,
        uuid: shift.attributes.uuid,
        shift: {
          id: shift.attributes.shift.id,
          uuid: shift.attributes.shift.uuid,
          jobId: shift.attributes.shift.job_id,
          projectUuid: shift.attributes.shift.project_uuid,
          startsAt: shift.attributes.shift.starts_at,
          endsAt: shift.attributes.shift.ends_at,
        },
      }))
    : null,
  shiftCount: attributes.shift_count,
});

const getCancellations = async (
  filters: Record<string, string | boolean | number>,
  params: Record<string, string | number>,
) => {
  const query = resolveQueryString([filters], params);

  const {
    data: { data, meta },
  } = await getRequest<
    RequestDataArray<UCM.CancellationRawType, UCM.MetaRawType>
  >(API.GET.getCancellations(query));

  return {
    meta: paginationMapper(meta),
    data: data.map(({ attributes, id }) => mapCancellation(attributes, id)),
  };
};

export const useGetCancellations = ({
  filters = {},
  params,
  enabled = true,
}: {
  filters?: Record<string, string | boolean | number>;
  params: Record<string, string | number>;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CANCELLATIONS, filters, params],
    queryFn: () => getCancellations(filters, params),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};
