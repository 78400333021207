import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';
import { toTimeString } from 'src/utils/datetime';

const mapGeneralBriefing = (
  state: UCM.BriefingPayloadState,
): UCM.GeneralBriefingPayload => {
  return {
    briefing: {
      instruction: state?.instruction ?? undefined,
      catering_info: state?.cateringInfo ?? null,
      preparation_info: state?.preparationInfo ?? null,
      behavioral_info: state?.behavioralInfo ?? null,
      dress_code_info: state?.dressCodeInfo ?? null,
      task_description: state?.taskDescription ?? null,
      additional_info: state?.additionalInfo ?? null,
      contact_person: {
        name: state?.contactPerson ?? null,
        phone_number: state?.contactPhoneNumber ?? null,
      },
      meeting: {
        point: state?.meetingPoint ?? null,
        time: toTimeString(state?.meetingTime) ?? null,
      },
    },
  };
};
const mapDailyBriefing = (
  state: UCM.DailyBriefingPayloadState,
): UCM.DailyBriefingPayload => {
  return {
    briefing: {
      instruction: state?.instruction ?? null,
      position_job_date_id: state?.position_job_date_id,
      contact_person: {
        name: state?.contactPerson ?? null,
        phone_number: state?.contactPhoneNumber ?? null,
      },
      meeting: {
        point: state?.meetingPoint ?? null,
        time: toTimeString(state?.meetingTime) ?? null,
      },
    },
  };
};

const createBriefing = async ({
  jobUuid,
  briefing,
}: {
  jobUuid: string;
  briefing: UCM.DailyBriefingPayloadState | UCM.BriefingPayloadState;
}) => {
  const payload =
    'position_job_date_id' in briefing
      ? mapDailyBriefing(briefing as UCM.DailyBriefingPayloadState)
      : mapGeneralBriefing(briefing as UCM.BriefingPayloadState);

  const data = await postRequest(API.POST.createBriefing(jobUuid), payload);

  return data;
};

export const useCreateBriefing = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createBriefing,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.BriefingCreated), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.BRIEFING_DETAILS] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.DAILY_BRIEFINGS] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
    },
    onError: handleError,
  });
};
