import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';

import ConfirmModal from 'src/components/ConfirmModal';
import { useGetRejectReason } from 'src/pages/ProjectDetailsPage/api';
import { REJECT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetRejectReason';

import messages from './messages';

export default function RebookReasonModal({
  modalLabel,
  buttonLabel,
  disabledButton,
  enabledFetch,
  isLoading,
  onConfirm,
  open,
  onClose,
}: UCM.RebookReasonModalType) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [reason, setReason] = useState('');

  const { data: rejectReason } = useGetRejectReason(
    { reason_for: REJECT_REASON.RESET_TIME_TRACKING },
    { enabled: enabledFetch },
  );

  const handleSubmit = useCallback(async () => {
    if (typeof onConfirm == 'function') {
      onConfirm(reason);
    }
  }, [onConfirm, reason]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReason((event.target as HTMLInputElement).value);
    },
    [],
  );

  return (
    <ConfirmModal
      disabled={!reason}
      onConfirm={handleSubmit}
      open={open}
      onClose={onClose}
      width={theme.spacing(75)}
      submitTextButton={formatMessage(messages.confirmButtonLabel)}
      title={modalLabel ?? ''}
      triggerButton={(handleOpen) => {
        return (
          buttonLabel && (
            <LoadingButton
              onClick={handleOpen}
              loading={isLoading}
              variant="contained"
              disabled={disabledButton}
            >
              {buttonLabel}
            </LoadingButton>
          )
        );
      }}
    >
      <Grid p={1.5}>
        <RadioGroup
          defaultValue=""
          name="radio-buttons-group"
          onChange={handleChange}
        >
          {rejectReason?.map(({ id, value, label }) => {
            return (
              <FormControlLabel
                key={id}
                value={value}
                control={<Radio />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </Grid>
    </ConfirmModal>
  );
}
