import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

const importPositionJobDates = async ({
  file,
  forced,
}: {
  file: File;
  forced?: boolean;
}) => {
  const params = new URLSearchParams();
  if (forced) {
    params.set('forced', 'true');
  }

  const formData = new FormData();
  formData.append('file', file);
  const res = await putRequest(
    API.PUT.importPositionJobDates(params?.toString()),
    formData,
    { headers: { 'content-type': 'multipart/form-data' } },
  );

  return res.data;
};

export const useImportPositionJobDates = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: importPositionJobDates,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.importSuccess), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.DAILY_BRIEFINGS] });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    },
  });
};
