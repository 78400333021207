import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import { ReactComponent as CloseIcon } from 'src/images/Close_gray.svg';

import Dropdown from 'src/components/Dropdown';
import JobDetailsForm from 'src/pages/ProjectDetailsPage/components/JobDetailsForm';
import StandbyCoverageForm from 'src/pages/ProjectDetailsPage/components/JobList/components/StandbyCoverageForm';
import { QuickApplyUserForm } from 'src/pages/ProjectDetailsPage/components/JobList/components/QuickApplyUserForm';

import {
  useGetJobStatuses,
  useUpdateJobVisible,
  useUpdateJobStatus,
} from '../../api';

import messages from './messages';
import JobCalendar from '../JobCalendar';
import { Styled } from './JobDetails.styled';
import MainJob from './components/MainJob/MainJob';
import Standby from './components/Standby/Standby';
import BackupJobForm from './components/BackupJobForm';
import ShiftsForm from '../JobList/components/ShiftsForm';
import StyledCard from './components/StyledCard/StyledCard';
import StyledCards from './components/StyledCards/StyledCards';
import BackupSettingsForm from './components/BackupSettingsForm';

import StudentDetails from './components/StudentDetails/StudentDetails';
import BriefingModal from '../DailyBriefings/components/BriefingModal/BriefingModal';

import { useTheme, type SelectChangeEvent } from '@mui/material';
import { common } from '@mui/material/colors';

type CardComponentsNameType = 'mainjob' | 'standby' | 'backup';

interface CardComponentsType {
  label: string | React.ReactNode;
  name?: CardComponentsNameType;
  component?: React.FC<UCM.JobTypeProps>;
  uuid?: string;
  customTab?: JSX.Element;
  isVisible?: boolean;
}

export default function JobDetails({
  job,
  projectUuid,
  handleClose,
  updateJobDetails,
}: UCM.JobDetailsProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const jobVisibilityOptions = useRef([
    { value: 'true', label: formatMessage(messages.visibleLabel) },
    { value: 'false', label: formatMessage(messages.hiddenLabel) },
  ]).current;

  const [card, setCard] = useState(0);
  const [candidate, setCandidate] = useState<
    UCM.JobApplicationType | string | null
  >(null);

  const [calendarfilters, setCalendarfilters] = useState<
    Record<string, string>
  >({});

  const { data: statuses } = useGetJobStatuses({
    params: { for_job_management: true },
  });

  const { mutate: changeVisibility } = useUpdateJobVisible({
    onSuccess: updateJobDetails,
  });

  const { mutate: changeStatus } = useUpdateJobStatus({
    onSuccess: updateJobDetails,
  });

  const pressClose = useCallback(() => {
    handleClose(null);
    setCandidate(null);
  }, [handleClose]);

  const handleStatus = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target;
      if (job?.uuid) {
        changeStatus({ jobUuid: job?.uuid, statusName: value as string });
      }
    },
    [job?.uuid],
  );

  const jobCount = useMemo(() => {
    if (job?.summary && job?.quantity) {
      const {
        jobApplications: { booked, done },
      } = job.summary;
      return `${booked + done}/${job.quantity}`;
    }
    return null;
  }, [job]);

  const briefingCount = useMemo(() => {
    if (job?.summary) {
      const {
        briefing: { confirmedCount, bookedCount },
      } = job.summary;
      return `${confirmedCount}/${bookedCount}`;
    }
    return null;
  }, [job?.summary]);

  const CardComponents = useMemo(() => {
    const components: CardComponentsType[] = [
      {
        name: 'mainjob',
        label: formatMessage(messages.mainJobCard),
        component: MainJob,
        uuid: job?.uuid,
      },
    ];

    if (job?.standbyJob?.uuid) {
      components.push({
        name: 'standby',
        label: (
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              my={1}
              size="auto"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Typography variant="inherit">
                {formatMessage(messages.standbyCard)}
              </Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Badge
                showZero
                color="primary"
                badgeContent={job.standbyJob.booked}
              />
            </Grid>
            <Grid size="auto">
              <Typography component="span" variant="body2">
                {job.standbyJob.id} - {job.standbyJob.title}
              </Typography>
            </Grid>
          </Grid>
        ),
        component: Standby,
        uuid: job?.standbyJob?.uuid,
      });
    }

    if (job?.backupJobs?.length) {
      job?.backupJobs.forEach((item) =>
        components.push({
          name: 'backup',
          label: `Backup JID ${item.id}`,
          component: MainJob,
          uuid: item?.uuid,
          isVisible: item.isVisible,
        }),
      );
    }

    return components;
  }, [job?.uuid, job?.standbyJob?.uuid, job?.backupJobs?.length]);

  const changeCard = useCallback(
    (_: React.SyntheticEvent, newValue: number) => {
      if (
        typeof newValue === 'number' &&
        CardComponents?.[newValue].component
      ) {
        setCard(newValue);
        setCalendarfilters({});
        setCandidate(null);
      }
    },
    [CardComponents],
  );
  const handleCloseCandidate = useCallback(() => {
    setCandidate(null);
  }, []);

  const CurrentCard = useMemo(() => {
    if (job && CardComponents?.[card]) {
      const { uuid, name, isVisible } = CardComponents?.[card] || {};

      return {
        uuid,
        name,
        component: CardComponents?.[card].component,
        isVisible,
      };
    }
    return null;
  }, [job, card]);

  const handleSelectCandidate = useCallback(
    (item: UCM.JobApplicationType | string | null) => {
      if (job?.isFlex) {
        setCandidate(item ?? null);
      }
    },
    [job?.isFlex],
  );

  const handleDayPress = useCallback((day: string | null) => {
    return setCalendarfilters({ shift_date: day ?? '' });
  }, []);

  const handleVisibility = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target;
      const jobUuid =
        CurrentCard?.name === 'backup' ? CurrentCard?.uuid : job?.uuid;
      if (jobUuid) {
        const isVisible = value === 'true';
        changeVisibility({ jobUuid, visibility: isVisible });
      }
    },
    [job?.uuid, CurrentCard],
  );

  const visibleValue = useMemo(() => {
    if (CurrentCard?.name === 'backup') {
      return CurrentCard?.isVisible;
    }
    return job?.isVisible;
  }, [CurrentCard, job]);

  const candidateCalendar = useMemo(() => {
    if (typeof candidate === 'object') {
      return candidate?.applicant;
    }
    return undefined;
  }, [candidate]);

  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      gap={1}
      mb={3}
    >
      {job?.isFlex && CurrentCard?.uuid && (
        <JobCalendar
          job={job}
          uuid={CurrentCard?.uuid}
          candidate={candidateCalendar}
          onDayPress={handleDayPress}
        />
      )}
      <Styled.Container>
        <Stack
          display="flex"
          flexDirection="row"
          alignContent="center"
          bgcolor={theme.palette.grey[100]}
        >
          <Styled.CloseContainer onClick={pressClose}>
            <CloseIcon />
          </Styled.CloseContainer>
          <StyledCards value={card} onChange={changeCard}>
            {CardComponents.map((cardItem) => (
              <StyledCard key={cardItem.uuid} label={cardItem.label}>
                {cardItem?.name === 'backup' && cardItem.uuid && (
                  <BackupSettingsForm
                    uuid={cardItem?.uuid}
                    jobUuid={job?.uuid}
                  />
                )}
              </StyledCard>
            ))}
          </StyledCards>
        </Stack>
        <Grid
          container
          size={12}
          p={2}
          justifyContent="space-between"
          bgcolor={common.white}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            size={{ lg: 6, md: 12, sm: 12 }}
          >
            <Styled.Title>
              {job?.id} - {job?.title}
            </Styled.Title>

            {job && !job.isFlex && (
              <BackupJobForm isFlex={false} jobUuid={job.uuid} />
            )}
          </Grid>
          <Grid
            size={{ lg: 6, md: 12, sm: 12 }}
            justifyContent="flex-end"
            display="flex"
          >
            <Styled.Toolbar
              gap={3}
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
            >
              <Styled.Toolbar gap={1}>
                {job?.isStandby && <StandbyCoverageForm jobUuid={job.uuid} />}

                {job && <QuickApplyUserForm job={job} />}

                {/* TODO */}
                {/* Need to implement job statistics (BE Required) */}
                {/* <BarChartOutlinedIcon /> */}

                {job && <ShiftsForm jobUuid={job.uuid} isFlex={job.isFlex} />}

                {job && (
                  <JobDetailsForm
                    jobUuid={job.uuid}
                    projectUuid={projectUuid}
                  />
                )}
              </Styled.Toolbar>

              <Styled.Toolbar gap={0.5}>
                <GroupOutlinedIcon />
                <Typography variant="h4" color="error.dark">
                  {jobCount}
                </Typography>
              </Styled.Toolbar>

              <Styled.Toolbar gap={0.5}>
                {job && <BriefingModal jobUuid={job.uuid} />}
                <Typography variant="h4" color="error.dark">
                  {briefingCount}
                </Typography>
              </Styled.Toolbar>

              <Styled.Toolbar gap={1.5}>
                {statuses && CurrentCard?.name !== 'backup' && (
                  <Dropdown
                    name="statusName"
                    value={job?.statusName}
                    options={statuses}
                    onChange={handleStatus}
                    variant="outlined"
                    sx={dropdownStyles}
                  />
                )}
                <Dropdown
                  name="isVisible"
                  value={visibleValue?.toString()}
                  options={jobVisibilityOptions}
                  onChange={handleVisibility}
                  variant="outlined"
                  sx={dropdownStyles}
                />
              </Styled.Toolbar>
            </Styled.Toolbar>
          </Grid>
        </Grid>
        <Styled.Content>
          {typeof candidate === 'string' && (
            <StudentDetails
              candidate={candidate as string}
              handleClose={handleCloseCandidate}
            />
          )}
          {CurrentCard?.component && (
            <CurrentCard.component
              job={job}
              uuid={CurrentCard?.uuid}
              candidate={candidate as UCM.JobApplicationType}
              handleSelectCandidate={handleSelectCandidate}
              calendarfilters={calendarfilters}
            />
          )}
        </Styled.Content>
      </Styled.Container>
    </Stack>
  );
}

const dropdownStyles = {
  width: '130px',
  backgroundColor: 'transparent',
  borderRadius: '8px',
  color: '#1E6A4F',
  border: '1px solid #6F797B',
  height: '32px',
  fontWeight: '600',
  '& fieldset': { display: 'none' },
};
