import { useCallback } from 'react';

import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import type { OptionType } from 'src/components/AutoCompleteField';

interface RadioButtonGroupProps {
  row?: boolean;
  value: string;
  label?: string;
  helperText?: string;
  options: Array<OptionType>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const sxHelperTextProps = { marginLeft: '0px' };

export default function RadioButtonGroup({
  label,
  value,
  options,
  onChange,
  helperText,
  row = true,
}: RadioButtonGroupProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(event, value);
    },
    [],
  );

  return (
    <FormControl component="fieldset">
      {label && <FormLabel>{label}</FormLabel>}

      <RadioGroup value={value} onChange={handleChange} row={row}>
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={label}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>

      {helperText && (
        <FormHelperText sx={sxHelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
