/*
 * JobForm Messages
 *
 * This contains all the text for the JobForm.
 */
import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.JobForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Create Job',
  },
  copyTitle: {
    id: `${scope}.copyTitle`,
    defaultMessage: 'Copy of {title}',
  },
  createJobButton: {
    id: `${scope}.createJobButton`,
    defaultMessage: 'Add new job',
  },
  standbyJobMenuLabel: {
    id: `${scope}.standbyJobMenuLabel`,
    defaultMessage: 'Add standby job',
  },
  flexDateJobMenuLabel: {
    id: `${scope}.flexDateJobMenuLabel`,
    defaultMessage: 'Add flex-date job',
  },
  deleteDynamicFieldConfirmMessage: {
    id: `${scope}.deleteDynamicFieldConfirmMessage`,
    defaultMessage: 'Do you really want to delete this Field?',
  },
  notVisibleByUserLabel: {
    id: `${scope}.notVisibleByUserLabel`,
    defaultMessage: '(only visible by ucm)',
  },
  minWordsCount: {
    id: `${scope}.minWordsCount`,
    defaultMessage: 'Must have at least {min} words',
  },
});
