import React, { useRef, useEffect, useCallback, useMemo } from 'react';

import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid2';

import { locale } from 'src/config';
import globalMessages from 'src/messages';
import TextInput from 'src/components/TextInput';
import { buildHelperText } from 'src/utils/components';
import { toParsableNumber, checkNumberTyping } from 'src/utils/standards';

interface NumberRangeProps {
  field: UCM.DynamicFieldType;
  value: { minimum?: string; maximum?: string };
  onChange: (
    field: UCM.DynamicFieldType,
    value: { minimum: string; maximum: string },
  ) => void;
  helperText?: string;
}

const checkParam = { locale, decimals: 2 };

export default function NumberRangeField({
  field,
  value,
  onChange,
  helperText = '',
}: NumberRangeProps) {
  const { formatMessage } = useIntl();

  const initialValues = useMemo(
    () => ({
      minimum: value?.minimum || '',
      maximum: value?.maximum || '',
    }),
    [value],
  );

  const validateNumber = useRef(
    (errors: Record<string, string>, name: string, strValue: string) => {
      if (strValue?.trim()) {
        const parsedValue = parseFloat(toParsableNumber(strValue, locale));
        if (isNaN(parsedValue)) {
          errors[name] = formatMessage(globalMessages.notTypeField, {
            type: 'number',
          });
        }
        return parsedValue;
      }
      return null;
    },
  ).current;

  const validateLessThan = useRef(
    (
      errors: Record<string, string>,
      name: string,
      min: number | null,
      max: number | null,
    ) => {
      if (min !== null && max !== null && min > max) {
        errors[name] = formatMessage(globalMessages.maxValueField, { max });
      }
    },
  ).current;

  const validateGreaterThan = useRef(
    (
      errors: Record<string, string>,
      name: string,
      min: number | null,
      max: number | null,
    ) => {
      if (min !== null && max !== null && min > max) {
        errors[name] = formatMessage(globalMessages.minValueField, { min });
      }
    },
  ).current;

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validate: ({ minimum, maximum }) => {
      const errors: Record<string, string> = {};
      const min = validateNumber(errors, 'minimum', minimum);
      const max = validateNumber(errors, 'maximum', maximum);
      validateGreaterThan(errors, 'maximum', min, max);
      validateLessThan(errors, 'minimum', min, max);
      return errors;
    },
    validationSchema: object().shape({
      minimum: string().nullable(),
      maximum: string().nullable(),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (formik.dirty) {
      onChange(field, formik.values);
    }
  }, [formik.values.minimum, formik.values.maximum, onChange, field]);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      name: string,
    ) => {
      const stringValue = event.target.value;

      if (checkNumberTyping(stringValue, checkParam)) {
        formik.setFieldValue(name, stringValue);
      }
    },
    [],
  );

  return (
    <Grid container spacing={4}>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextInput
          fullWidth
          name="minimum"
          label={`${field.label} (min)`}
          value={formik.values.minimum}
          onChange={(e) => handleChange(e, 'minimum')}
          helperText={
            formik.errors.minimum ||
            buildHelperText(field.description, helperText)
          }
          error={!!formik.errors.minimum}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6 }}>
        <TextInput
          fullWidth
          name="maximum"
          label={`${field.label} (max)`}
          value={formik.values.maximum}
          onChange={(e) => handleChange(e, 'maximum')}
          helperText={
            formik.errors.maximum ||
            buildHelperText(field.description, helperText)
          }
          error={!!formik.errors.maximum}
        />
      </Grid>
    </Grid>
  );
}
