import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { postRequest } from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

const createFines = async (fine: Record<string, string | number | boolean>) => {
  const { data } = await postRequest(API.POST.createFines, { fine });
  return data;
};

export const useCreateFines = () => {
  const client = useQueryClient();
  const { handleError } = useMessageHandler();

  return useMutation({
    mutationFn: createFines,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.FINES] });
    },
    onError: handleError,
  });
};
