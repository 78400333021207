import React, { useCallback, useRef } from 'react';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import type { DefaultTheme } from '@mui/styles';

interface ToggleOptionsType {
  name: string;
  value: boolean;
}

interface CustomToggleButtonProps {
  label: string;
  value: boolean | undefined;
  name: string;
  options?: ToggleOptionsType[];
  onChange: (name: string, value: string) => void;
  error?: string;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
}

// TODO - temporary solution
const DEFAULT_OPTIONS = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export default function CustomToggleButton({
  label,
  value,
  name,
  options,
  onChange,
  error,
}: CustomToggleButtonProps) {
  const inputLabelSx = useRef((theme: DefaultTheme) => ({
    width: theme.spacing(18),
    fontWeight: theme.typography.fontWeightMedium,
  })).current;

  const togglebuttonSx = useRef((theme: DefaultTheme) => ({
    width: theme.spacing(7),
    height: theme.spacing(4),
    borderRadius: theme.spacing(2),
  })).current;

  const handleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: string) => {
      onChange && onChange(name, value);
    },
    [onChange],
  );

  return (
    <Stack direction="row" alignItems="center">
      <InputLabel sx={inputLabelSx} error={!!error}>
        {label}
      </InputLabel>
      <ToggleButtonGroup exclusive value={value} onChange={handleChange}>
        {(options || DEFAULT_OPTIONS).map((item) => (
          <ToggleButton
            key={item.name}
            value={item.value}
            color={error ? 'error' : undefined}
            sx={togglebuttonSx}
          >
            {item.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
