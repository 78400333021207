import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { common } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import { Theme, useMediaQuery } from '@mui/material';

import SearchFilterBar, {
  QuickFiltersType,
} from 'src/components/SearchFilterBar';

import StyledTabs from 'src/components/StyledTabs/StyledTabs';
import StyledTab from 'src/components/StyledTab/StyledTab';
import messages from './messages';
import JobAvailable from '../JobAvailable/JobAvailable';
import JobTransferred from '../JobTransferred/JobTransferred';

const Components = [JobAvailable, JobTransferred];

export default function Standby({
  job,
  handleSelectCandidate,
  candidate,
  calendarfilters,
}: UCM.JobTypeProps) {
  const { formatMessage } = useIntl();

  const [tab, setTab] = useState(0);
  const [filters, setFilters] = useState<Record<string, string>>({});

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: false,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.jobSerchLabel),
    },
  ]).current;

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, ...calendarfilters }));
  }, [calendarfilters]);

  const changeTab = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setFilters({});
    setTab(newValue);
  }, []);

  // eslint-disable-next-line
  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const jobCountSummary = useMemo(() => {
    if (job?.summary) {
      const {
        jobApplications: {
          booked,
          done,
          acceptedBooked,
          applied,
          denied,
          transferredIn,
        },
      } = job.summary;
      return {
        transferredIn,
        all: booked + done,
        booked,
        applied,
        accepted: acceptedBooked,
        denied,
        done,
      };
    }
  }, [job?.summary]);

  const TabData = useMemo(
    () => [
      {
        label: formatMessage(messages.availableTab),
        badge: job?.standbyJob?.booked ?? 0,
      },
      {
        label: formatMessage(messages.transferredTab),
        badge: jobCountSummary?.transferredIn ?? 0,
      },
    ],
    [],
  );

  const CurrentJobComponent = useMemo(() => {
    if (Components?.[tab]) {
      return Components?.[tab];
    }
    return null;
  }, [tab]);

  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <>
      <Stack
        display={'flex'}
        flexDirection={large ? 'row' : 'column'}
        justifyContent={large ? 'space-between' : undefined}
        alignItems={large ? 'center' : undefined}
        gap={2}
        px={2}
        pb={2}
        bgcolor={common.white}
      >
        <StyledTabs value={tab} onChange={changeTab}>
          {TabData.map((tabItem, index) => (
            <StyledTab
              key={index}
              label={tabItem.label}
              badge={tabItem.badge}
            />
          ))}
        </StyledTabs>
        <Grid size={{ xs: 12, sm: 7, md: 5, lg: 3 }} alignSelf="flex-end">
          <SearchFilterBar fields={quickFilters} onSearch={handleSearch} />
        </Grid>
      </Stack>
      {CurrentJobComponent && job?.standbyJob && (
        <CurrentJobComponent
          jobUuid={job!.uuid}
          jobId={job!.id}
          standbyId={job?.standbyJob.id}
          standbyUuid={job?.standbyJob!.uuid}
          filters={filters}
          handleSelectCandidate={handleSelectCandidate}
          candidate={candidate}
        />
      )}
    </>
  );
}
