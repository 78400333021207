import { styled, keyframes, css } from '@mui/material/styles';

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

const CirclePrimitive = styled('div', {
  shouldForwardProp: (prop) => prop !== '$delay', // Prevent $delay from being passed to the DOM
})<{ rotate?: number; $delay?: number }>`
  width: 40px;
  height: 40px;
  position: absolute;
  ${({ rotate }) =>
    rotate &&
    css`
      -webkit-transform: rotate(${rotate}deg);
      -ms-transform: rotate(${rotate}deg);
      transform: rotate(${rotate}deg);
    `}
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #999;
    border-radius: 100%;
    animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;
    ${({ $delay }) =>
      $delay &&
      css`
        -webkit-animation-delay: ${$delay}s;
        animation-delay: ${$delay}s;
      `};
  }
`;

type CirclePropType = { rotate?: number; delay?: number };

const Circle = ({ rotate, delay }: CirclePropType) => {
  return <CirclePrimitive rotate={rotate} $delay={delay} />;
};

export default Circle;
