import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { forceString } from 'src/utils/datetime';
import { postRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

type QuickApplyUserParamsType = {
  jobUuid: string;
  candidateUuid: string;
  applyAndAccept: boolean;
  shiftUuid: string | null;
  shiftDate: Moment | null;
  reasonCode: string | null;
};

const quickApplyUser = async ({
  jobUuid,
  shiftDate,
  shiftUuid,
  reasonCode,
  candidateUuid,
  applyAndAccept,
}: QuickApplyUserParamsType) => {
  const params = {
    quick_apply: {
      job_uuid: jobUuid,
      shift_uuid: shiftUuid,
      reason_code: reasonCode,
      candidate_uuid: candidateUuid,
      apply_and_accept: applyAndAccept,
      shift_date: shiftDate
        ? forceString(shiftDate, moment.HTML5_FMT.DATE)
        : null,
    },
  };

  const { data } = await postRequest<RequestSimplifiedData>(
    API.POST.createApplyUser,
    params,
    {
      headers: {
        Accept: 'application/vnd.falcon.v1+json',
      },
    },
  );

  return data;
};

export const useCreateQuickApplyUser = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: quickApplyUser,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.userApplied), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS_COUNT],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS],
      });
    },
    onError: handleError,
  });
};
