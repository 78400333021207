import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import download from 'src/utils/download';
import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import {
  ErrorType,
  useMessageHandler,
} from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/ProjectDetailsPage/messages';

type DownloadStaffPlanFetchParamsType = {
  projectId: string;
  projectUuid: string;
};

const downloadProjectStaffPlan = async ({
  projectId,
  projectUuid,
}: DownloadStaffPlanFetchParamsType) => {
  const data = await getRequest(API.GET.getProjectStaffPlan(projectUuid), {
    responseType: 'blob',
  });
  download(data.data, `project_staff_plan_${projectId}.pdf`, 'application/pdf');
  return data.data;
};

export const useDownloadProjectStaffPlan = ({
  projectId,
  projectUuid,
}: DownloadStaffPlanFetchParamsType) => {
  const { formatMessage } = useIntl();
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();

  const { refetch, isFetching, isSuccess, isError, error } = useQuery<
    unknown,
    ErrorType
  >({
    queryKey: [QUERY_KEYS.PROJECT_STAFF_PLAN],
    queryFn: () => downloadProjectStaffPlan({ projectId, projectUuid }),
    enabled: false,
  });

  useEffect(() => {
    if (isSuccess) {
      handleSuccess(formatMessage(messages.staffPlanDownloaded), 1000);
      client.removeQueries({ queryKey: [QUERY_KEYS.PROJECT_STAFF_PLAN] });
    }
    if (isError && error) {
      handleError(error);
    }
  }, [isSuccess, isError]);

  return { refetch, isFetching };
};
