import { useCallback } from 'react';

import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { locale } from 'src/config';
import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import { checkCurrencyTyping } from 'src/utils/standards';
import { useGetJobStatuses } from 'src/pages/ProjectDetailsPage/api';

import messages from './messages';

import type { FormikErrors } from 'formik';
import type { FormikState } from '../..';
import type { SelectChangeEvent } from '@mui/material';

const checkParam = { locale };

interface BackupJobEditPanelProps {
  values: FormikState;
  errors: FormikErrors<FormikState>;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
  ) => void;
}

export default function BackupJobEditPanel({
  values,
  errors,
  onChange,
}: BackupJobEditPanelProps) {
  const { data: statuses } = useGetJobStatuses({
    params: { for_backup_creation: true },
  });

  const { formatMessage } = useIntl();

  const handleCurrencyChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
    ) => {
      event.stopPropagation();
      const {
        target: { value },
      } = event;

      if (checkCurrencyTyping(value as string, checkParam)) {
        onChange(event);
      }
    },
    [onChange],
  );

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid size={12}>
        <Typography variant="h5">
          {formatMessage(messages.jobSettingsLabel)}
        </Typography>
      </Grid>

      <Grid container size={12}>
        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <TextInput
            fullWidth
            name="quantity"
            type="number"
            value={values.quantity}
            error={!!errors.quantity}
            helperText={errors.quantity}
            onChange={onChange}
            label={formatMessage(messages.userAmountLabel)}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <TextInput
            fullWidth
            name="wageBonus"
            value={values.wageBonus}
            error={!!errors.wageBonus}
            helperText={errors.wageBonus}
            onChange={handleCurrencyChange}
            label={formatMessage(messages.oneTimeBonusLabel)}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <Dropdown
            name="status"
            value={values.status}
            options={statuses}
            error={!!errors.status}
            helperText={errors.status}
            onChange={onChange}
            placeholder={formatMessage(messages.statusLabel)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
