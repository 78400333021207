import TextField from '../TextField';
import BooleanField from '../BooleanField';
import DateRangeField from '../DateRangeField';
import MultiSelectField from '../MultiSelectField';
import NumberRangeField from '../NumberRangeField';

export default function resolveDynamicFieldRenderer(
  field: UCM.DynamicFieldType,
) {
  switch (field.type.code) {
    case 'boolean':
      return BooleanField;
    case 'multi-selectable':
    case 'single-selectable':
      return MultiSelectField;
    case 'date':
      return DateRangeField;
    case 'number':
      return NumberRangeField;
    default:
      return TextField;
  }
}
