import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';

const updateJobVisibility = async ({
  jobUuid,
  visibility,
}: {
  jobUuid: string;
  visibility: boolean;
}) => {
  const res = await putRequest(
    API.PUT.updateJobVisibility(jobUuid, visibility),
  );
  return res.data;
};

export const useUpdateJobVisible = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const client = useQueryClient();
  const { handleError } = useMessageHandler();

  return useMutation({
    mutationFn: updateJobVisibility,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
      if (onSuccess) onSuccess();
    },
    onError: handleError,
  });
};
