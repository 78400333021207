import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { postRequest } from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { forceString } from 'src/utils/datetime';
import download from 'src/utils/download';

import messages from '../../messages';

const createFinesReports = async ({ uuids }: { uuids: Array<string> }) => {
  const payload = {
    fines_report: {
      fine_uuids: uuids,
    },
  };

  const { data } = await postRequest(API.POST.finesReports, payload, {
    responseType: 'blob',
  });
  const now = forceString(moment(), moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
  download(data, `Fines_${now}.csv`, 'text/csv');
  return data;
};

export const useCreateFinesReports = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createFinesReports,
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.FINES] });
      handleSuccess(
        variables.uuids?.length > 1
          ? formatMessage(messages.finesExported)
          : formatMessage(messages.fineExported),
      );
    },
    onError: handleError,
  });
};
