import { useCallback, useMemo, useState } from 'react';

import moment from 'moment';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import {
  forceString,
  weekDateFormat,
  forceTimeString,
  toTimezone,
} from 'src/utils/datetime';
import {
  useUpdateSaveShifts,
  useGetShiftList,
  useDeleteShifts,
} from 'src/pages/ProjectDetailsPage/api';
import DataTable from 'src/components/DataTable';
import ConfirmModal from 'src/components/ConfirmModal';
import ConfirmationDialog from 'src/components/ConfirmationDialog';

import messages from './messages';
import ShiftEditPanel, {
  type ShiftFormikType,
} from './components/ShiftEditPanel';

interface ShiftsFormProps {
  jobUuid: string;
  isFlex: boolean;
}

export default function ShiftsForm({ jobUuid, isFlex }: ShiftsFormProps) {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedShift, setSelectedShift] = useState<UCM.ShiftListType | null>(
    null,
  );

  const { data: shifts, isFetching: isFetchingShifts } = useGetShiftList(
    jobUuid,
    { enabled: open },
  );
  const { mutateAsync: onSaveShift, isPending: isPendingSave } =
    useUpdateSaveShifts();

  const { mutate: onDeleteShift, isPending: isPendingDelete } =
    useDeleteShifts();

  const columns: Array<{
    id: keyof UCM.ShiftListType | string;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 'date',
        label: formatMessage(messages.dateLabel),
        render: (_: Object, shift: UCM.ShiftListType) => (
          <Typography variant="inherit">
            {forceString(shift?.startsAt, weekDateFormat)}
          </Typography>
        ),
      },
      {
        id: 'startsAt',
        align: 'center',
        label: formatMessage(messages.startTimeLabel),
        render: (startsAt: string) => (
          <Typography variant="inherit">{forceTimeString(startsAt)}</Typography>
        ),
      },
      {
        id: 'endsAt',
        align: 'center',
        label: formatMessage(messages.endTimeLabel),
        render: (endsAt: string) => (
          <Typography variant="inherit">{forceTimeString(endsAt)}</Typography>
        ),
      },
      {
        id: 'slotsNumber',
        align: 'center',
        label: formatMessage(messages.quantityLabel),
        render: (_: Object, shift: UCM.ShiftListType) => (
          <Typography variant="inherit">{shift.slotsNumber ?? 0}</Typography>
        ),
      },
      {
        id: 'actions',
        label: '',
        width: 0,
        whiteSpace: 'nowrap',
        render: (_: Object, shift: UCM.ShiftListType) => {
          return (
            <>
              <IconButton onClick={() => handleEditClick(shift)}>
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <ConfirmationDialog
                onConfirm={() => handleDelete(shift)}
                message={formatMessage(messages.deleteShiftConfirmMessage)}
              >
                {(handleDialogOpen) => (
                  <IconButton onClick={handleDialogOpen}>
                    <DeleteForeverOutlinedIcon fontSize="small" />
                  </IconButton>
                )}
              </ConfirmationDialog>
            </>
          );
        },
      },
    ],
    [],
  );

  const handleEditClick = useCallback((shift: UCM.ShiftListType) => {
    setSelectedShift(shift);
    setEditMode(true);
  }, []);

  const handleClose = useCallback(() => {
    setSelectedShift(null);
    setEditMode(false);
  }, []);

  const handleDelete = useCallback(
    (shift: UCM.ShiftListType) => {
      onDeleteShift({ jobUuid, shiftUuid: shift.uuid });
    },
    [jobUuid],
  );

  const buildShiftParam = useCallback((shift: ShiftFormikType) => {
    const endTime = toTimezone(shift.endTime);
    const startTime = toTimezone(shift.startTime);
    if (!shift.date || !shift.date.isValid()) {
      return { startTime, endTime, quantity: shift.quantity };
    }
    if (startTime) {
      startTime
        .year(shift.date.year())
        .month(shift.date.month())
        .date(shift.date.date());
    }
    if (endTime) {
      endTime
        .year(shift.date.year())
        .month(shift.date.month())
        .date(shift.date.date());
    }
    if (startTime && endTime) {
      const diffInHours = moment.duration(endTime.diff(startTime)).as('hours');
      if (diffInHours < 0) {
        endTime.add(1, 'days');
      }
    }
    return { startTime, endTime, quantity: shift.quantity };
  }, []);

  const handleShiftSave = useCallback(
    async (shift: ShiftFormikType) => {
      const shiftParam = { ...shift, ...buildShiftParam(shift) };
      await onSaveShift(
        { jobUuid, shift: shiftParam },
        { onSettled: handleClose },
      );
    },
    [jobUuid],
  );

  const loading = useMemo(() => {
    return isPendingDelete || isPendingSave || isFetchingShifts;
  }, [isPendingDelete, isPendingSave, isFetchingShifts]);

  return (
    <ConfirmModal
      width="900px"
      disableBackdropPress
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      confirmationDialogMaxWidth="xs"
      title={formatMessage(messages.shiftListLabel)}
      triggerButton={(handleOpen) => (
        <IconButton onClick={handleOpen}>
          <CalendarMonthOutlinedIcon />
        </IconButton>
      )}
    >
      <Grid container spacing={2}>
        <Grid size={12}>
          <ShiftEditPanel
            multiple={true}
            isFlex={isFlex}
            open={editMode}
            isCreate={!editMode}
            shift={selectedShift}
            onSave={handleShiftSave}
            onClose={handleClose}
          />
        </Grid>

        <Grid size={12}>
          <DataTable
            rows={shifts}
            columns={columns}
            loading={loading}
            enabledFooter={false}
          />
        </Grid>
      </Grid>
    </ConfirmModal>
  );
}
