/*
 * TextField Messages
 *
 * This contains all the text for the TextField.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobForm.components.TextField';

export default defineMessages({
  textFieldPlaceholder: {
    id: `${scope}.textFieldPlaceholder`,
    defaultMessage: '(not needed to enter any text here)',
  },
});
