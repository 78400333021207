import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { deleteRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

const deleteShift = async (params: { jobUuid: string; shiftUuid: string }) => {
  const { jobUuid, shiftUuid } = params;
  return await deleteRequest(API.DELETE.deleteShift(jobUuid, shiftUuid));
};

export const useDeleteShifts = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: deleteShift,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.shiftSaved), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.SHIFTS_LIST] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
    },
    onError: handleError,
  });
};
