import { useRef } from 'react';

import { useIntl } from 'react-intl';
import clsx from 'clsx';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import messages from './messages';
import { Styled } from './MiceField.styled';

import type { DefaultTheme } from '@mui/styles';

interface MiceFieldProps {
  label: string;
  name: string;
  value: number | null | undefined;
  onChange: (name: string, value: number) => void;
  error?: string;
}

// TODO - temporary solution
const OPTIONS = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
];

export default function MiceField({
  label,
  name,
  value,
  error,
  onChange,
}: MiceFieldProps) {
  const { formatMessage } = useIntl();

  const inputLabelSx = useRef((theme: DefaultTheme) => ({
    width: theme.spacing(18),
    fontWeight: theme.typography.fontWeightMedium,
  })).current;

  const handleChange = (newValue: number) => {
    if (newValue !== value) {
      onChange(name, newValue);
    }
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ paddingBottom: 1.5 }}>
      <InputLabel sx={inputLabelSx} error={!!error}>
        {label}
      </InputLabel>
      <Stack direction="row" alignItems="center" gap={1}>
        {OPTIONS.map((item, index) => {
          return (
            <Stack key={item.value} position="relative">
              <Styled.MiceButton
                variant="outlined"
                className={clsx({
                  active: value === item.value,
                })}
                onClick={() => handleChange(item.value)}
                color={error ? 'error' : undefined}
              >
                <Typography variant="h6">{item.name}</Typography>
              </Styled.MiceButton>
              {index === 0 && (
                <Styled.Info variant="caption" color="textSecondary">
                  {formatMessage(messages.bad)}
                </Styled.Info>
              )}
              {index === OPTIONS.length - 1 && (
                <Styled.Info variant="caption" color="textSecondary">
                  {formatMessage(messages.perfect)}
                </Styled.Info>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
