import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import download from 'src/utils/download';
import { getRequest, serialize } from 'src/utils/request';
import {
  ErrorType,
  useMessageHandler,
} from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/ProjectDetailsPage/messages';

import { DailyBriefingsFilters } from './useGetDailyBriefing';

const downloadPositionJobDates = async (filters: DailyBriefingsFilters) => {
  const queryFilters = serialize(filters, 'filters');

  const { data } = await getRequest(
    `${API.GET.getDownloadPositionJobDates(queryFilters.toString())}`,
    { responseType: 'blob' },
  );

  download(data, `position_job_dates_${filters.project_uuid}.csv`, 'text/csv');
  return data;
};

export const useDownloadPositionJobDates = (filters: DailyBriefingsFilters) => {
  const { formatMessage } = useIntl();
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();

  const { refetch, isFetching, isSuccess, isError, error } = useQuery<
    unknown,
    ErrorType
  >({
    queryKey: [QUERY_KEYS.POSITION_JOB_DETAILS_DOWNLOAD],
    queryFn: () => downloadPositionJobDates(filters),
    enabled: false,
  });

  useEffect(() => {
    if (isSuccess) {
      handleSuccess(formatMessage(messages.positionJobDetailsDownloaded), 1000);
      client.removeQueries({
        queryKey: [QUERY_KEYS.POSITION_JOB_DETAILS_DOWNLOAD],
      });
    }
    if (isError && error) {
      handleError(error);
    }
  }, [isSuccess, isError]);

  return { refetch, isFetching };
};
