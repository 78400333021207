import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

const getAcceptedCount = async (jobUuid: string) => {
  const { data } = await getRequest<{ count: number }>(
    API.GET.getAcceptedCount(jobUuid),
  );
  return data.count;
};

export const useGetAcceptedCount = ({
  jobUuid,
  enabled = true,
}: {
  jobUuid: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ACCEPTED_COUNT],
    queryFn: () => getAcceptedCount(jobUuid),
    staleTime: 1000 * 60 * 2,
    enabled,
  });
};
