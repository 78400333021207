import map from 'lodash/map';
import { useQuery } from '@tanstack/react-query';

import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { forceString } from 'src/utils/datetime';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';
import type { FilterStateType } from 'src/components/SearchFilterBar';

const mapJob = (job: UCM.JobRawType, jobId: string): UCM.JobType => ({
  autoTransfer: job.auto_transfer,
  backupJobs: map(job.backup_jobs, (backupJob) => ({
    booked: backupJob.booked,
    firstDayOfWork: backupJob.first_day_of_work,
    id: backupJob.id,
    isVisible: backupJob.is_visible,
    lastDayOfWork: backupJob.last_day_of_work,
    slotsNumber: backupJob.slots_number,
    statusName: backupJob.status_name,
    title: backupJob.title,
    uuid: backupJob.uuid,
  })),
  description: job.description,
  firstShiftAt: forceString(job.first_shift_at),
  isFlex: job.is_flex,
  isStandby: job.is_standby,
  isVisible: job.is_visible,
  jobType: {
    label: job.job_type.name,
    value: job.job_type.code,
  },
  lastShiftAt: forceString(job.last_shift_at),
  position: job.position,
  standbyJob: {
    id: job.standby_job?.id,
    uuid: job.standby_job?.uuid,
    title: job.standby_job?.title,
    booked: job.standby_job?.booked,
    projectUuid: job.standby_job?.project_uuid,
  },
  statusName: job.status_name,
  summary: {
    favorites: job.summary.favorites,
    briefing: {
      createdAt: job.summary.briefing.created_at,
      confirmedCount: job.summary.briefing.confirmed_count,
      bookedCount: job.summary.briefing.booked_count,
    },
    jobApplications: {
      applied: job.summary.job_applications.applied,
      acceptedBooked: job.summary.job_applications.accepted_booked,
      transferred: job.summary.job_applications.transferred,
      transferredIn: job.summary.job_applications.transferred_in,
      booked: job.summary.job_applications.booked,
      denied: job.summary.job_applications.denied,
      done: job.summary.job_applications.done,
    },
    usersAvailability: job.summary.users_availability ?? 0,
  },
  id: jobId,
  title: job.title,
  uuid: job.uuid,
  quantity: job.slots_number,
  location: {
    name: job.location.name,
    streetName: job.location.street_name,
    houseNumber: job.location.house_number,
    additionalAddress: job.location.additional_address,
    city: job.location.city,
    zipCode: job.location.zip_code,
    country: job.location.country,
  },
});

const getJobs = async (
  projectUuid: string,
  filters: FilterStateType = {},
  params: Record<string, string | number>,
) => {
  const query = resolveQueryString([filters], params);

  const {
    data: { data, meta },
  } = await getRequest<RequestDataArray<UCM.JobRawType, UCM.MetaRawType>>(
    `${API.GET.getJobs(projectUuid)}${query}`,
  );

  return {
    meta: paginationMapper(meta),
    data: data.map(({ attributes, id }) => mapJob(attributes, id)),
  };
};

export const useGetJobs = (
  projectUuid: string,
  params: Record<string, string | number>,
  filters: FilterStateType = {},
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.JOBS, projectUuid, filters, params],
    queryFn: () => getJobs(projectUuid, filters, params),
    staleTime: 1000 * 60 * 5, // Dashboards refreshed after every 5 minutes automatically
    ...config,
  });
};
