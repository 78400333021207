/*
 * Daily Briefings
 *
 * This contains all the text for the Daily Briefings.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.DailyBriefings';

export default defineMessages({
  pjdidLabel: {
    id: `${scope}.pjdidLabel`,
    defaultMessage: 'PJD ID',
  },
  shiftStartLabel: {
    id: `${scope}.shiftStartLabel`,
    defaultMessage: 'Shift Start',
  },
  shiftEndLabel: {
    id: `${scope}.shiftEndLabel`,
    defaultMessage: 'Shift End',
  },
  meetingTimeLabel: {
    id: `${scope}.meetingTimeLabel`,
    defaultMessage: 'Meeting Time',
  },
  jobLabel: {
    id: `${scope}.jobLabel`,
    defaultMessage: 'Job',
  },
  dateLabel: {
    id: `${scope}.dateLabel`,
    defaultMessage: 'Date',
  },
  statusLabel: {
    id: `${scope}.statusLabel`,
    defaultMessage: 'Status',
  },
  uidLabel: {
    id: `${scope}.uidLabel`,
    defaultMessage: 'UID',
  },
  shiftContactPersonLabel: {
    id: `${scope}.shiftContactPersonLabel`,
    defaultMessage: 'Shift Contact Person',
  },
  shiftContactNumberLabel: {
    id: `${scope}.shiftContactNumberLabel`,
    defaultMessage: 'Shift Contact Number',
  },
  meetingPointLabel: {
    id: `${scope}.meetingPointLabel`,
    defaultMessage: 'Meeting Point',
  },
  specialInstructionLabel: {
    id: `${scope}.specialInstructionLabel`,
    defaultMessage: 'Special Instruction',
  },
  downloadButton: {
    id: `${scope}.downloadButton`,
    defaultMessage: 'Download',
  },
  uploadButton: {
    id: `${scope}.uploadButton`,
    defaultMessage: 'Upload',
  },
  briefingModalLabel: {
    id: `${scope}.briefingModalLabel`,
    defaultMessage: 'Briefing',
  },
  dailyBriefingModalLabel: {
    id: `${scope}.dailyBriefingModalLabel`,
    defaultMessage: 'Daily Briefing',
  },
  briefingModalJobLabel: {
    id: `${scope}.briefingModalJobLabel`,
    defaultMessage: 'Job',
  },
  briefingModalShiftTimeLabel: {
    id: `${scope}.briefingModalShiftTimeLabel`,
    defaultMessage: 'Shift time',
  },
  briefingUpdateButton: {
    id: `${scope}.briefingUpdateButton`,
    defaultMessage: 'Update Briefing',
  },
  briefingCreateButton: {
    id: `${scope}.briefingCreateButton`,
    defaultMessage: 'Create Briefing',
  },
  contactPersonField: {
    id: `${scope}.contactPersonField`,
    defaultMessage: 'Contact Person',
  },
  phoneNumberField: {
    id: `${scope}.phoneNumberField`,
    defaultMessage: 'Phone Number',
  },
  meetingTimeField: {
    id: `${scope}.meetingTimeField`,
    defaultMessage: 'Meeting Time',
  },
  meetingPointField: {
    id: `${scope}.meetingPointField`,
    defaultMessage: 'Meeting Point',
  },
  taskField: {
    id: `${scope}.taskField`,
    defaultMessage: 'Task',
  },
  dressCodeField: {
    id: `${scope}.dressCodeField`,
    defaultMessage: 'Dress code',
  },
  preparationField: {
    id: `${scope}.preparationField`,
    defaultMessage: 'Training/Preparation',
  },
  behavioralField: {
    id: `${scope}.behavioralField`,
    defaultMessage: 'Behavioral Expectations',
  },
  cateringField: {
    id: `${scope}.cateringField`,
    defaultMessage: 'Catering',
  },
  additionalInfoField: {
    id: `${scope}.additionalInfoField`,
    defaultMessage: 'Additional Info',
  },
  specialInstructionField: {
    id: `${scope}.specialInstructionField`,
    defaultMessage: 'Special Instruction',
  },
  assignStudentButton: {
    id: `${scope}.assignStudentButton`,
    defaultMessage: 'Assign {amount} Student',
  },
});
