/*
 * FlexDateChip Messages
 *
 * This contains all the text for the FlexDateChip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.FlexDateChip';

export default defineMessages({
  flexDate: {
    id: `${scope}.flexDate`,
    defaultMessage: 'Flex-date',
  },
});
