import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';
import { RequestDataArray } from 'src/types/requestData';
import { daysUntilToday } from 'src/utils/datetime';
import moment from 'moment';

export type JobApplicationType =
  | 'all'
  | 'accepted'
  | 'applied'
  | 'booked'
  | 'denied'
  | 'done';

const getJobApplications = async (
  jobUuid: string,
  type: JobApplicationType,
  filters: Record<string, string>,
  params: Record<string, string | number>,
) => {
  const query = resolveQueryString([filters], params);

  const { data } = await getRequest<
    RequestDataArray<UCM.JobApplicationRawType, UCM.MetaRawType>
  >(API.GET.getJobApplications(jobUuid, type, query));

  return {
    meta: paginationMapper(data?.meta),
    data: mapJobApplications(data?.data),
  };
};

export const useGetJobApplications = ({
  jobUuid,
  type,
  params,
  filters,
  enabled = true,
}: {
  jobUuid: string;
  type: JobApplicationType;
  params: Record<string, string | number>;
  filters: Record<string, string>;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.JOB_APPLICATIONS, jobUuid, type, filters, params],
    queryFn: () => getJobApplications(jobUuid, type, filters, params),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

const mapJobApplications = (
  data: { attributes: UCM.JobApplicationRawType; id: string }[],
): UCM.JobApplicationType[] => {
  if (!data) return [];

  // TODO - adjust when backend changes will be ready
  return data.map(({ attributes, id }) => ({
    id,
    uuid: attributes.uuid,
    statuses: map(attributes.statuses, (data) => ({
      reason: {
        projectUuid: data.reason.project_uuid,
        jobId: data.reason.job_id,
      },
      isWaitlisted: data.is_waitlisted,
      status: data.status,
    })),
    statusCode: attributes.status_code,
    favoriteCompany: attributes.favorite.company,
    favoriteManager: attributes.favorite.project_manager,
    appliedCount: attributes.applied_count,
    daysSinceApplied: daysUntilToday(attributes.created_at),
    daysSinceBooked: daysUntilToday(attributes.booking_time_at),
    contractSummary: {
      status: attributes.contract_summary?.status,
      completed: attributes.contract_summary?.completed,
      documentType: attributes.contract_summary?.document_type,
      contractType: attributes.contract_summary?.contract_type,
      initialized: attributes.contract_summary?.initialized,
      userSigned: attributes.contract_summary?.user_signed,
      ucmSigned: attributes.contract_summary?.ucm_signed,
    },
    hasLongTermContract: attributes.has_long_term_contract,
    briefingReadAt: attributes.briefing_read_at,
    bookingTimeAt: attributes.booking_time_at,
    applicant: {
      id: attributes.applicant.id,
      uuid: attributes.applicant.uuid,
      fullName: attributes.applicant.full_name,
      languages: attributes.applicant.languages,
      ratingScore: attributes.applicant.rating_score,
      initialRatingScore: attributes.applicant.initial_rating_score,
      status: {
        code: attributes.applicant.status.code,
        name: attributes.applicant.status.name,
      },
      lastLoginAt: moment(attributes.applicant.last_login_at)
        .locale('en')
        .fromNow(),
    },
    lastWorkInDays: attributes.last_work_in_days,
  })) as UCM.JobApplicationType[];
};
