import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { number, object, string, array } from 'yup';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PencilAdornment } from 'src/utils/components';
import ConfirmModal from 'src/components/ConfirmModal';
import TextInput from 'src/components/TextInput';
import Dropdown from 'src/components/Dropdown';
import {
  REJECT_REASON,
  useGetRejectReason,
} from 'src/pages/ProjectDetailsPage/api/queries/useGetRejectReason';
import { forceString, forceTimeString } from 'src/utils/datetime';
import {
  useCreateCancellationNotices,
  useGetCancellationShifts,
  useGetCandidates,
} from 'src/pages/ProjectDetailsPage/api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import DataTable from 'src/components/DataTable';
import CheckButton from 'src/pages/ProjectDetailsPage/components/JobDetails/components/CheckButton/CheckButton';

import { locale } from 'src/config';
import { checkNumberTyping } from 'src/utils/standards';

import messages from './messages';

interface CreateCancellationFormState {
  userId: string;
  userUuid: string;
  userName: string;
  userEmail: string;
  reasonCode: string;
  explanation: string;
  adminComment: string;
  jobShiftUuids: Array<string>;
}

export default function CreateCancellationFormModal() {
  const client = useQueryClient();
  const { formatMessage } = useIntl();

  const classes = customStyles();

  const [isOpen, setOpen] = useState(false);
  const [debouncedUserId, setDebouncedUserId] = useState('');
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { data: candidate, isFetching: isFetchingUser } = useGetCandidates(
    { id: debouncedUserId },
    { enabled: !!debouncedUserId },
  );

  const { data: cancellationReason } = useGetRejectReason(
    { reason_for: REJECT_REASON.CANCELLATION_NOTICE },
    { enabled: isOpen },
  );

  const params = useMemo(
    () => ({ user_id: debouncedUserId, timespan: 'future' }),
    [debouncedUserId],
  );

  const { data: shifts, isFetching: isFetchingShifts } =
    useGetCancellationShifts({
      params,
      enabled: Boolean(candidate?.userUuid),
    });

  const {
    mutate: createCancellationNotices,
    isPending: isCreatingCancellationNotices,
  } = useCreateCancellationNotices({
    inCancellationsPage: true,
  });

  useEffect(() => {
    if (isOpen) {
      setValidateOnChange(false);
    }
  }, [isOpen]);

  const initialValues: CreateCancellationFormState = useMemo(
    () => ({
      userId: '',
      userUuid: '',
      userName: '',
      userEmail: '',
      reasonCode: '',
      explanation: '',
      adminComment: '',
      jobShiftUuids: [],
    }),
    [],
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validationSchema: object().shape({
      userId: number().required().integer().min(0),
      reasonCode: string().required(),
      jobShiftUuids: array()
        .of(string())
        .min(1, formatMessage(messages.requiredField)),
    }),
    onSubmit: () => {},
  });

  const { values, errors, setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (!debouncedUserId) {
      resetForm();
      return;
    }

    if (isFetchingUser) return;

    const fields = {
      userName: candidate?.userName ?? formatMessage(messages.userNotFound),
      userUuid: candidate?.userUuid ?? '',
      userEmail: candidate?.userEmail ?? formatMessage(messages.userNotFound),
    };

    setFieldValue('userName', fields.userName);
    setFieldValue('userUuid', fields.userUuid);
    setFieldValue('userEmail', fields.userEmail);
  }, [candidate, debouncedUserId, isFetchingUser]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setDebouncedUserId('');
    resetForm();
    client.removeQueries({
      queryKey: [QUERY_KEYS.CANCELLATION_SHIFTS],
    });
    client.removeQueries({
      queryKey: [QUERY_KEYS.CANDIDATES],
    });
  }, [resetForm]);

  const debouncedSetUserId = useCallback(
    debounce((value: string) => {
      setDebouncedUserId(value);
    }, 1000),
    [],
  );

  const onChangeEventValue = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      name: keyof CreateCancellationFormState,
    ) => {
      const { value } = event.target as { value: string };

      if (name === 'userId' && checkNumberTyping(value, { locale })) {
        debouncedSetUserId(value);
        setFieldValue(name, value);
        return;
      }

      setFieldValue(name, value);
    },
    [],
  );

  const checkIfExist = useCallback(
    (uuid: string) => {
      return values.jobShiftUuids.findIndex((item) => item === uuid) !== -1;
    },
    [values.jobShiftUuids],
  );

  const isSelectAll = useMemo(() => {
    return (
      shifts?.length !== 0 && values.jobShiftUuids.length === shifts?.length
    );
  }, [shifts, values.jobShiftUuids]);

  useEffect(() => {
    if (isSelectAll && values?.reasonCode !== '') {
      setFieldValue('reasonCode', '');
    }
  }, [isSelectAll]);

  const handleSelectAll = useCallback(() => {
    let local: Array<string> = [];
    if (shifts) {
      local = shifts?.map((item) => item.uuid);
    }
    setFieldValue('jobShiftUuids', local);
  }, [shifts, isSelectAll]);

  const handleSelect = useCallback((uuid: string) => {
    const exist = checkIfExist(uuid);
    let local = [...values.jobShiftUuids];
    if (exist) {
      const filtered = local.filter((item) => item !== uuid);
      local = filtered;
    } else {
      local.push(uuid);
    }
    setFieldValue('jobShiftUuids', local);
  }, []);

  const handleSubmit = useCallback(async () => {
    setValidateOnChange(true);

    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid || !candidate?.userUuid) return false;

    return new Promise<boolean>((resolve) => {
      createCancellationNotices(
        {
          explanation: values.explanation,
          reason_code: values.reasonCode,
          user_uuid: candidate?.userUuid,
          admin_comment: values.adminComment,
          job_shift_uuids: values.jobShiftUuids,
        },
        {
          onSuccess: () => {
            handleClose();
            resolve(true);
          },
          onError: () => resolve(false),
        },
      );
    });
  }, [formik, values]);

  const columns: Array<{
    id: keyof UCM.ShiftType | string;
    label: string | React.ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'actions',
        label: (
          <CheckButton active={isSelectAll} handleChange={handleSelectAll} />
        ),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '-';
          const { uuid } = item;
          const isSelected = checkIfExist(uuid);
          return (
            <CheckButton
              active={isSelected}
              handleChange={() => handleSelect(item.uuid)}
            />
          );
        },
        align: 'center',
      },
      {
        id: 'startsAt',
        label: formatMessage(messages?.dateLabel),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '';
          return (
            <Typography variant="inherit">
              {forceString(item?.shift.startsAt)}
            </Typography>
          );
        },
        align: 'center',
      },
      {
        id: 'jobId',
        label: formatMessage(messages?.jobIdLabel),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '';
          return <Typography variant="inherit">{item?.shift.jobId}</Typography>;
        },
        align: 'center',
      },
      {
        id: 'timeframe',
        label: formatMessage(messages?.shiftTimeframeLabel),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '';
          return (
            <Typography variant="inherit">
              {`${forceTimeString(item?.shift.startsAt)} - ${forceTimeString(item?.shift.endsAt)}`}
            </Typography>
          );
        },
        align: 'center',
      },
    ],
    [values.jobShiftUuids, isSelectAll, handleSelect, handleSelectAll],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const inputSx = useRef(() => ({
    '& input::placeholder': {
      fontSize: '14px',
    },
  })).current;

  return (
    <ConfirmModal
      onConfirm={() => handleSubmit()}
      maxWidth="550px"
      open={isOpen}
      loading={isCreatingCancellationNotices}
      onClose={handleClose}
      submitTextButton={formatMessage(messages.confirmButtonLabel)}
      title={formatMessage(messages.header)}
      triggerButton={() => (
        <Button
          color="secondary"
          onClick={handleOpen}
          variant="outlined"
          disabled={isCreatingCancellationNotices}
        >
          {formatMessage(messages.buttonOpenLabel)}
        </Button>
      )}
    >
      <Stack mb={2} spacing={1}>
        <Grid container spacing={2}>
          <Grid size={4}>
            <TextInput
              fullWidth
              name="userId"
              value={values.userId}
              label={formatMessage(messages.userIdLabel)}
              onChange={(event) => onChangeEventValue(event, 'userId')}
              error={!!errors.userId}
              helperText={errors.userId}
              EndIcon={PencilAdornment}
              sx={inputSx}
            />
          </Grid>
          <Grid size={8} flex={1}>
            <TextInput
              fullWidth
              name="userEmail"
              value={values.userEmail}
              label={formatMessage(messages.userEmailLabel)}
              disabled
            />
          </Grid>
        </Grid>
        <TextInput
          fullWidth
          name="userName"
          value={values.userName}
          label={formatMessage(messages.userNameLabel)}
          disabled
        />
      </Stack>

      <DataTable
        columns={columns}
        rows={shifts}
        loading={isFetchingShifts || isFetchingUser}
        className={classes.tableContainer}
        enabledFooter={false}
        customEmptyHeight={25}
      />
      {!!errors.jobShiftUuids && (
        <Typography variant="inherit" color="error" fontSize="10px">
          {errors.jobShiftUuids}
        </Typography>
      )}
      <Dropdown
        label={formatMessage(messages.reasonDropDownLabel)}
        value={values.reasonCode}
        options={cancellationReason}
        onChange={(event) => onChangeEventValue(event, 'reasonCode')}
        fullWidth
        error={!!errors.reasonCode}
        helperText={errors.reasonCode}
      />
      <TextInput
        value={values.explanation}
        label={formatMessage(messages.userCommentInputLabel)}
        onChange={(event) => onChangeEventValue(event, 'explanation')}
        margin="none"
        size="small"
        sx={inputSx}
        EndIcon={PencilAdornment}
      />
      <TextInput
        value={values.adminComment}
        label={formatMessage(messages.adminCommentInputLabel)}
        onChange={(event) => onChangeEventValue(event, 'adminComment')}
        EndIcon={PencilAdornment}
        margin="none"
        size="small"
        sx={inputSx}
      />
    </ConfirmModal>
  );
}

// @ts-ignore
export const customStyles = makeStyles(() => ({
  tableContainer: {
    minHeight: 'auto!important',
    maxHeight: '200px',
    overflowY: 'scroll !important',
    overflowX: 'hidden',
    width: 'calc(100% - 2px)!important',

    '& td': { padding: '16px' },
  },
}));
