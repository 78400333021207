import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import { ErrorType } from 'src/utils/hooks/useMessageHandler';

import messages from './messages';

type Type = 'errors' | 'warnings';

interface Errors {
  errors?: Record<string, string[]>;
  warnings?: Record<string, string[]>;
}

interface Props {
  title?: string;
  errorResponse: ErrorType | null;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function ErrorModal({
  title,
  errorResponse,
  onConfirm,
  onCancel,
}: Props) {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  const pressClose = useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    setOpen(false);
  }, [onCancel]);

  const pressConfirm = useCallback(() => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    setOpen(false);
  }, [onConfirm]);

  const errors = useMemo(() => {
    if (errorResponse) {
      return errorResponse?.response?.data as Errors;
    }
  }, [errorResponse]);

  useEffect(() => {
    if (errors && !open) {
      setOpen(true);
    }
  }, [errors]);

  const isConfirmAvailable = useMemo(() => {
    if (!errors || (errors?.errors && Object.keys(errors?.errors).length))
      return false;

    return typeof onConfirm === 'function';
  }, [errors]);

  return (
    <Modal
      open={open}
      onClose={pressClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ width: '600px' }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          p={1}
          borderBottom={1}
          borderColor="#E2E2E2"
        >
          <IconButton onClick={pressClose}>
            <CloseOutlinedIcon />
          </IconButton>
          <Typography fontWeight="600" fontSize={22} color="#114358">
            {title ?? formatMessage(messages.defaultTitle)}
          </Typography>
        </Stack>
        <Stack p={2} gap={1.5}>
          <ResponseContainer data={errors?.errors} type="errors" />
          <ResponseContainer data={errors?.warnings} type="warnings" />
        </Stack>
        <Stack
          p={2}
          pt={0}
          flexDirection="row"
          justifyContent="flex-end"
          gap={1}
        >
          {isConfirmAvailable && (
            <Button
              onClick={pressConfirm}
              variant="outlined"
              color="primary"
              sx={{ borderRadius: '100px', fontWeight: 600 }}
            >
              {formatMessage(messages.defaultConfirm)}
            </Button>
          )}
          <Button
            onClick={pressClose}
            variant="contained"
            sx={{ borderRadius: '100px', fontWeight: 600 }}
          >
            {isConfirmAvailable
              ? formatMessage(messages.defaultCancel)
              : formatMessage(messages.additionalCancelLabel)}
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
}

function ResponseContainer({
  data,
  type,
}: {
  data?: Record<string, string[]>;
  type: Type;
}) {
  const { formatMessage } = useIntl();

  const length = useMemo(() => {
    if (!data) return 0;

    return Object.keys(data).length;
  }, [data]);

  const variants = useMemo(() => {
    const isError = type === 'errors';
    return {
      Icon: isError ? DangerousOutlinedIcon : ReportProblemOutlinedIcon,
      label: isError
        ? formatMessage(messages.errorsLabel, { length })
        : formatMessage(messages.warningsLabel, { length }),
      bgColor: isError ? '#FFDAD6' : '#FFDF9C',
      fontColor: isError ? '#904A44' : '#775A0B',
    };
  }, [data, length, type]);

  if (!data || !length) return null;

  const { Icon } = variants;

  return (
    <Stack borderRadius={1.5} p={2} bgcolor={variants.bgColor} gap={2}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Icon
          sx={{
            fontSize: 28,
            color: variants?.fontColor,
          }}
        />
        <Typography fontSize={24} fontWeight="600" color={variants.fontColor}>
          {variants.label}
        </Typography>
      </Stack>
      {Object.entries(data).map(([key, value], index) => {
        return (
          <Stack px={1} key={key}>
            <Typography fontSize={18} fontWeight="600">
              {/* @ts-ignore */}
              {index + 1}. {formatMessage(messages[key])}
            </Typography>
            <Stack>
              {value?.map((msg) => (
                <Stack
                  flexDirection="row"
                  alignItems="flex-start"
                  gap={1}
                  key={msg}
                >
                  <Box
                    width={4}
                    height={4}
                    borderRadius="100%"
                    bgcolor="#000"
                    ml={0.5}
                    mt={1}
                  />
                  <Typography
                    fontSize={14}
                    color="#000"
                    display="flex"
                    flex={1}
                  >
                    {msg}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
