import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';
import keyBy from 'lodash/keyBy';

import {
  buildChild,
  buildChildren,
  buildAttributes,
} from 'src/utils/selectors';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';
import { formatNumber } from 'src/utils/standards';
import { locale } from 'src/config';

type IncludedDataResponse<TDataAttributes = unknown, TIncluded = unknown> = {
  data: {
    id: string;
    type: string;
    attributes: TDataAttributes;
    relationships: {
      job_type: { data: { id: string; type: string } };
      staff_type_outfits: { data: Array<Record<string, any>> }; // eslint-disable-line
      staff_type_tasks: { data: { id: string; type: string } };
      staff_type_languages: { data: { id: string; type: string } };
    };
  };
  included: Array<{
    id: string;
    type: string;
    attributes: TIncluded;
  }>;
};

export const mapStaffType = (
  staffType: IncludedDataResponse<UCM.StaffTypeRawOptionType>['data'],
  included: Record<
    string,
    { id: string; type: string; attributes: UCM.StaffIncludedType }
  >,
): UCM.StaffType | null => {
  const staffTypeData = buildAttributes(staffType, {
    description: 'job_description',
    title: 'job_name',
    staffTypeUuid: 'uuid',
  }) as UCM.StaffType;

  if (staffTypeData) {
    staffTypeData.price = formatNumber(staffType.attributes.price, locale);
    staffTypeData.salary = formatNumber(staffType.attributes.wage, locale);

    staffTypeData.languageCodes = map(
      buildChildren(staffType, included, 'staff_type_languages', 'language', {
        code: 'code',
      }),
      (language) => language.code,
    );

    staffTypeData.taskCodes = map(
      buildChildren(staffType, included, 'staff_type_tasks', 'task', {
        code: 'code',
      }),
      (task) => task.code,
    );

    const jobType = buildChild(staffType, included, 'job_type', null, {
      code: 'code',
    });
    staffTypeData.jobTypeCode = jobType && jobType.code;
  }

  return staffTypeData;
};

const getStaffType = async (staffTypeUuid: string) => {
  const { data } = await getRequest<
    IncludedDataResponse<UCM.StaffTypeRawOptionType, UCM.StaffIncludedType>
  >(API.GET.getStaffType(staffTypeUuid), {
    headers: { Accept: 'application/vnd.falcon.v1+json' },
  });

  const included = keyBy(data.included, (item) => `${item.id}-${item.type}`);

  return mapStaffType(data.data, included);
};

export const useGetStaffType = (
  staffTypeUuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.STAFF_TYPE, staffTypeUuid],
    queryFn: () => getStaffType(staffTypeUuid),
    ...config,
  });
};
