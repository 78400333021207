/*
 * CreateCancellationFormModal Messages
 *
 * This contains all the text for the CreateCancellationFormModal component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.CancellationsPage.components.CreateCancellationFormModal';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Create Cancellation Notice',
  },
  buttonOpenLabel: {
    id: `${scope}.buttonOpenLabel`,
    defaultMessage: 'Add new cancellation',
  },
  userIdLabel: {
    id: `${scope}.userIdLabel`,
    defaultMessage: 'User ID',
  },
  userEmailLabel: {
    id: `${scope}.userEmailLabel`,
    defaultMessage: 'E-mail',
  },
  userNameLabel: {
    id: `${scope}.userNameLabel`,
    defaultMessage: 'Name',
  },
  userNotFound: {
    id: `${scope}.userNotFound`,
    defaultMessage: 'User not found',
  },
  dateLabel: {
    id: `${scope}.dateLabel`,
    defaultMessage: 'Date',
  },
  jobIdLabel: {
    id: `${scope}.jobIdLabel`,
    defaultMessage: 'Job ID',
  },
  shiftTimeframeLabel: {
    id: `${scope}.shiftTimeframeLabel`,
    defaultMessage: 'Shift Timeframe',
  },
  confirmButtonLabel: {
    id: `${scope}.confirmButtonLabel`,
    defaultMessage: 'Confirm',
  },
  userInputLabel: {
    id: `${scope}.userInputLabel`,
    defaultMessage: 'User ID',
  },
  emailInputLabel: {
    id: `${scope}.emailInputLabel`,
    defaultMessage: 'E-mail',
  },
  nameInputLabel: {
    id: `${scope}.nameInputLabel`,
    defaultMessage: 'Name',
  },
  reasonDropDownLabel: {
    id: `${scope}.reasonDropDownLabel`,
    defaultMessage: 'Reason',
  },
  userCommentInputLabel: {
    id: `${scope}.userCommentInputLabel`,
    defaultMessage: 'User comment',
  },
  adminCommentInputLabel: {
    id: `${scope}.adminCommentInputLabel`,
    defaultMessage: 'Admin comment',
  },
  requiredField: {
    id: `${scope}.requiredField`,
    defaultMessage: 'required',
  },
});
