import { createTheme } from '@mui/material/styles';
import { colors, disable, opacity } from './customColors';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme();

const defaultTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
          borderBottom: `thin solid ${theme.palette.grey[300]}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 600,
          fontFamily: 'Figtree',
          color: colors.darkerBlue,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: theme.spacing(6),
          borderRadius: theme.spacing(3),
          paddingRight: theme.spacing(1),
          backgroundColor: colors.lightGrey,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme }) => ({
          color: theme.palette.common.white,
          fontFamily: theme.typography.subtitle2.fontFamily,
        }),
      },
    },
    // Uncomment and customize the following as needed
    // MuiListSubheader: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.darkTurquoise,
    //     },
    //     sticky: {
    //       backgroundColor: colors.lighterBlue,
    //     },
    //   },
    // },
    // MuiTablePagination: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: colors.lighterBlue,
    //       borderRadius: 8,
    //     },
    //   },
    // },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.4,
          },
        },
      },
    },
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.darkTurquoise,
    //       backgroundColor: colors.yellow,
    //     },
    //     sizeSmall: {
    //       height: 18,
    //     },
    //     labelSmall: {
    //       fontSize: '0.6rem', // 10px
    //     },
    //   },
    // },
    MuiTabs: {
      styleOverrides: {
        root: {
          // display: 'flex',
          alignItems: 'center',
          // alignContent: 'center',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          margin: 0,
          minWidth: 0,
          minHeight: 0,
          padding: theme.spacing(1, 2),
          marginRight: theme.spacing(2),
          '&.Mui-selected': {
            // color: '#114358',
            fontWeight: 600,
            backgroundColor: colors.lightestBlue,
            border: `thin solid ${colors.lightBlue}`,
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
          },
          '&.Mui-selected': {
            backgroundColor: colors.lighterBlue,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lightestBlue,
          borderRadius: '8px !important',
          '&.Mui-expanded': {
            margin: '0px !important',
            borderBottomLeftRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          },
        },
      },
    },
    // MuiExpansionPanelSummary: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 8,
    //     },
    //   },
    // },
    // MuiListItem: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: opacity(colors.yellow, 0.12),
    //       },
    //     },
    //   },
    // },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: opacity(colors.yellow, 0.12),
    //       },
    //     },
    //   },
    // },
    // MuiPickersDay: {
    //   styleOverrides: {
    //     current: {
    //       backgroundColor: colors.lighterBlue,
    //     },
    //   },
    // },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: colors.darkerBlue,
      contrastText: theme.palette.getContrastText(colors.darkerBlue),
    },
    secondary: {
      main: colors.darkTurquoise,
      contrastText: theme.palette.getContrastText(colors.darkTurquoise),
    },
    text: {
      primary: colors.darkerBlue,
      secondary: opacity(colors.darkerBlue),
    },
    action: {
      disabledBackground: disable(colors.darkTurquoise),
      disabled: theme.palette.getContrastText(colors.darkTurquoise),
    },
    error: {
      main: colors.red,
      dark: colors.darkRed,
      light: colors.lightRed,
    },
    warning: {
      main: colors.darkYellow,
    },
  },
  typography: {
    fontWeightMedium: 600,
    h1: {
      fontFamily: 'Figtree',
      fontSize: '70pt',
      fontWeight: 900,
    },
    h2: {
      fontFamily: 'Figtree',
      fontWeight: 900,
    },
    h3: {
      fontFamily: 'Figtree',
      fontSize: '1.75rem', // 28px
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Figtree',
      fontSize: '1.5rem', // 24px
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Figtree',
      fontSize: '1rem',
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Figtree',
      fontSize: '0.875rem', // 14px
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Figtree',
      fontSize: '0.875rem', // 14px
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: 'Figtree',
      fontSize: '0.75rem', // 12px
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Figtree',
      fontSize: '0.875rem', // 14px
    },
    body2: {
      fontFamily: 'Figtree',
      fontSize: '0.75rem', // 12px
    },
    caption: {
      fontFamily: 'Figtree',
      fontSize: '0.6rem', // 10px
    },
    button: {
      fontFamily: 'Figtree',
      textTransform: 'none',
      fontWeight: 400,
    },
  },
});

export default defaultTheme;
