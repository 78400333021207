import mapValues from 'lodash/mapValues';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';
import trim from 'lodash/trim';
import map from 'lodash/map';

export function buildAttributes(parent, attributes) {
  if (!parent) return null;
  let mapped = null;
  if (isEmpty(attributes)) {
    mapped = mapKeys(parent.attributes, (value, key) => camelCase(key));
  } else {
    mapped = mapValues(attributes, (attribute) => {
      if (typeof attribute === 'function') return attribute(parent);
      return attribute && parent.attributes && parent.attributes[attribute];
    });
  }
  mapped.uuid = parent.attributes && parent.attributes.uuid;
  mapped.id = parent.id;
  return mapped;
}

function buildItem(parentRef, included, childName, attributes) {
  if (!parentRef) return null;
  const { id, type } = parentRef;
  const parent = (included && included[`${id}-${type}`]) || parentRef;
  if (childName) {
    return buildChild(parent, included, childName, null, attributes);
  }
  return buildAttributes(parent, attributes);
}

export function buildChild(
  parent,
  included,
  childName,
  grandChildName,
  attributes,
) {
  if (
    parent &&
    childName &&
    parent.relationships &&
    parent.relationships[childName] &&
    parent.relationships[childName].data
  ) {
    return buildItem(
      parent.relationships[childName].data,
      included,
      grandChildName,
      attributes,
    );
  }
  return null;
}

export function buildChildren(
  parent,
  included,
  childrenName,
  grandChildName,
  attributes,
) {
  if (
    parent &&
    childrenName &&
    parent.relationships &&
    parent.relationships[childrenName] &&
    parent.relationships[childrenName].data
  ) {
    const children = parent.relationships[childrenName].data;
    return map(children, (childRef) =>
      buildItem(childRef, included, grandChildName, attributes),
    );
  }
  return null;
}

export function resolveProjectName(name) {
  return trim(
    replace(replace(name, /\s+|\/+/g, '-'), /-+/g, '-').substring(0, 20),
    '-',
  );
}
