import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

import { colors } from 'src/utils/customColors';

import messages from './messages';

interface Props {
  contract: {
    status: string | null;
    completed: number | null;
    documentType: string;
    contractType: string;
    initialized: number | null;
    userSigned: number | null;
    ucmSigned: number | null;
  };
}

export default function ContractTooltip({ contract }: Props) {
  const { formatMessage } = useIntl();

  const statusColor = useMemo(() => {
    switch (contract.status) {
      case 'completed':
        return colors.greenSecondary;
      case 'initialized':
        return colors.darkerBlue;
      default:
        return colors.darkerYellow;
    }
  }, [contract.status]);

  const label = useMemo(() => {
    switch (contract.documentType) {
      case 'skribble_document':
        return formatMessage(messages.digitalLabel);
      case 'physical_document':
        return formatMessage(messages.physicalLabel);
      default:
        return formatMessage(messages.mixedLabel);
    }
  }, [contract.documentType]);

  const contractType = useMemo(() => {
    switch (contract.contractType) {
      case 'short_term':
        return 'STC';
      case 'long_term':
        return 'LTC';
      default:
        return '';
    }
  }, [contract.contractType]);

  const contractAmounts = useMemo(() => {
    if (
      !contract?.completed &&
      !contract?.initialized &&
      !contract?.userSigned &&
      !contract?.ucmSigned
    ) {
      return null;
    }

    return (
      <Stack>
        {contract.completed && (
          <Typography variant="inherit">
            {formatMessage(messages.completedLabel, {
              amount: contract.completed,
            })}
          </Typography>
        )}
        {contract.initialized && (
          <Typography variant="inherit">
            {formatMessage(messages.initializedLabel, {
              amount: contract.initialized,
            })}
          </Typography>
        )}
        {contract.userSigned && (
          <Typography variant="inherit">
            {formatMessage(messages.userSignedLabel, {
              amount: contract.userSigned,
            })}
          </Typography>
        )}
        {contract.ucmSigned && (
          <Typography variant="inherit">
            {formatMessage(messages.ucmSignedLabel, {
              amount: contract.ucmSigned,
            })}
          </Typography>
        )}
      </Stack>
    );
  }, [
    contract.completed,
    contract.initialized,
    contract.userSigned,
    contract.ucmSigned,
  ]);

  const iconColorSx = useMemo(() => ({ color: statusColor }), [statusColor]);

  return (
    <Tooltip title={contractAmounts} placement="top">
      <Stack direction="row" alignItems="center" gap={1}>
        {contract?.status === 'completed' ? (
          <CheckOutlinedIcon sx={iconColorSx} />
        ) : (
          <PendingOutlinedIcon sx={iconColorSx} />
        )}
        <Typography variant="inherit" color={statusColor}>
          {`${label} ${contractType}`}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
