import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';
import { formatNumber } from 'src/utils/standards';
import { locale } from 'src/config';

const mapJobDetail = (
  job: UCM.JobDetailRawType,
  jobId: string,
): UCM.JobDetailType => ({
  additionalAttributes: map(job.agile_attributes, (att) => ({
    dynamicFieldName: att.dynamic_field_name,
    dynamicFieldUuid: att.dynamic_field_uuid,
    targets: { ...att.targets },
  })),
  autoTransfer: job.auto_transfer,
  bookingDeadlineDate: job.booking_deadline_date,
  description: job.description,
  firstShiftAt: job.first_shift_at,
  ignoreDesiredCities: job.ignore_user_desired_cities,
  isFlex: job.is_flex,
  isStandby: job.is_standby,
  isVisible: job.is_visible,
  jobTypeCode: {
    label: job.job_type.name,
    value: job.job_type.code,
  },
  languageCodes: map(job.languages, (language) => ({
    value: language.code,
    label: language.name,
  })),
  lastShiftAt: job.last_shift_at,
  venue: job.location.name ?? '',
  houseNumber: job.location.house_number ?? '',
  address: job.location.street_name ?? '',
  zipCode: job.location.post_code ?? '',
  parentWageBonus: job.parent_wage_bonus,
  price: formatNumber(job.price, locale),
  priceBonus: formatNumber(job.price_bonus, locale),
  staffTypeUuid: job.staff_type_uuid ?? '',
  statusName: job.status_name,
  summary: {
    favorites: job.summary.favorites,
    jobApplications: {
      applied: job.summary.job_applications.applied,
      acceptedBooked: job.summary.job_applications.accepted_booked,
      transferred: job.summary.job_applications.transferred,
      transferredIn: job.summary.job_applications.transferred_in,
      booked: job.summary.job_applications.booked,
      denied: job.summary.job_applications.denied,
      done: job.summary.job_applications.done,
    },
  },
  quantity: job.slots_number?.toString(),
  taskCodes: job.tasks.map((task) => ({
    value: task.code,
    label: task.name,
  })),
  title: job.title,
  uuid: job.uuid,
  tariffCode: job.tariff_code,
  salary: formatNumber(job.wage, locale),
  salaryBonus: formatNumber(job.wage_bonus, locale),
  backupJobs: map(job.backup_jobs, (backupJob) => ({
    booked: backupJob.booked,
    firstDayOfWork: backupJob.first_day_of_work,
    id: backupJob.id,
    isVisible: backupJob.is_visible,
    lastDayOfWork: backupJob.last_day_of_work,
    slotsNumber: backupJob.slots_number,
    statusName: backupJob.status_name,
    title: backupJob.title,
    uuid: backupJob.uuid,
  })),
  position: job.position,
  standbyJob: job.standby_job
    ? {
        id: job.standby_job.id,
        uuid: job.standby_job.uuid,
        title: job.standby_job.title,
        booked: job.standby_job.booked,
        projectUuid: job.standby_job.project_uuid,
      }
    : null,
  id: jobId,
  location: {
    name: job.location.name,
    streetName: job.location.street_name,
    houseNumber: job.location.house_number,
    additionalAddress: job.location.additional_address,
    city: {
      value: job.location.city.code,
      label: job.location.city.name,
    },
    zipCode: job.location.post_code,
    country: job.location.country,
  },
});

const getJob = async (jobUuid?: string) => {
  const {
    data: { data },
  } = await getRequest<RequestData<UCM.JobDetailRawType>>(
    API.GET.getJob(jobUuid),
  );

  return mapJobDetail(data.attributes, data.id);
};

export const useGetJob = (
  jobUuid?: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.JOB, jobUuid],
    queryFn: () => getJob(jobUuid),
    staleTime: Infinity,
    ...config,
  });
};
