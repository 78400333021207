import Checkbox from '@mui/material/Checkbox';
import styled from '@mui/material/styles/styled';
import Autocomplete, {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  type AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField, { type TextFieldVariants } from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Ref, useCallback, useMemo } from 'react';
import find from 'lodash/find';
import { isArray, isObject } from 'lodash';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(-1),
  padding: theme.spacing(0.5, 1.25),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export interface OptionType {
  value: string;
  label: string;
}

interface NewAutoCompleteFieldProps<T extends OptionType> {
  label: string;
  error?: boolean;
  options: Array<T>;
  multiple?: boolean;
  freeSolo?: boolean;
  helperText?: string;
  inputRef?: Ref<string>;
  value: string | Array<T>;
  variant?: TextFieldVariants;
  groupBy?: (option: T) => string;
  onChange: (
    value: T | Array<T> | null,
    reason: AutocompleteChangeReason,
  ) => void;
  onTextChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

export default function AutoCompleteField<T extends OptionType>({
  value,
  label,
  error,
  options,
  groupBy,
  onChange,
  inputRef,
  helperText,
  onTextChange,
  multiple = false,
  freeSolo = false,
  variant = 'standard',
}: NewAutoCompleteFieldProps<T>) {
  // Ensure correct value selection based on multiple or freeSolo
  const selected = useMemo(() => {
    if (multiple) {
      // For multiple selection, cast value to array if it's not already an array
      return isArray(value) ? value : [];
    }
    return find(options, (option) => option.value === value) ?? '';
  }, [options, value, multiple]);

  // Handle changes for multiple selection or freeSolo
  const handleChange = useCallback(
    (
      _: React.SyntheticEvent<Element, Event>,
      option: T | string | Array<string | T> | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (typeof option === 'string') {
        return;
      }

      if (option == null) return onChange(null, reason);

      // If multiple, ensure option is an array and contains valid T objects
      if (multiple) {
        const validOptions = (
          Array.isArray(option)
            ? option.filter(
                (item) => isObject(item) && 'value' in item && 'label' in item,
              )
            : [option]
        ) as Array<T>; // If it's a single valid option, wrap it in an array

        return onChange(validOptions, reason);
      } else {
        // For single selection, ensure option is a valid T object
        if (isObject(option) && 'value' in option && 'label' in option) {
          return onChange(option, reason);
        }
      }
    },
    [onChange, multiple],
  );

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onTextChange?.(event);
    },
    [onTextChange],
  );

  const getOptionLabel = useCallback((option: string | T) => {
    if (typeof option === 'object') {
      return option.label;
    }
    return option;
  }, []);

  const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => {
    return (
      <li key={params.key}>
        <GroupHeader>{params.group}</GroupHeader>
        <GroupItems>{params.children}</GroupItems>
      </li>
    );
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          label={label}
          error={error}
          variant={variant}
          inputRef={inputRef}
          helperText={helperText}
          onChange={handleTextChange}
        />
      );
    },
    [label, error, helperText, inputRef, variant, handleTextChange],
  );

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement> & {
        key: string;
      },
      option: OptionType,
      { selected }: AutocompleteRenderOptionState,
    ) => {
      const { key, ...optionProps } = props;
      return (
        <li key={key} {...optionProps}>
          {multiple && (
            <Checkbox
              checked={selected}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            />
          )}
          {option.label}
        </li>
      );
    },
    [multiple],
  );

  return (
    <Autocomplete
      size="small"
      value={selected}
      options={options}
      groupBy={groupBy}
      multiple={multiple}
      freeSolo={freeSolo}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      popupIcon={<ArrowDropDownIcon color="primary" />}
      renderOption={renderOption}
      renderGroup={renderGroup}
      renderInput={renderInput}
    />
  );
}
