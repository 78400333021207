import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapCertificateOptions = (
  option: UCM.ContractCertificateOptionRawType,
): UCM.ContractCertificateOptionType => ({
  value: option.code,
  label: option.title,
});

const getCertificateOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.ContractCertificateOptionRawType>
  >(API.GET.contractCertificateOptions);
  return data.data.map(({ attributes }) => mapCertificateOptions(attributes));
};

export const useGetCertificateOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACT_CERTIFICATE_OPTIONS],
    queryFn: getCertificateOptions,
    staleTime: 1000 * 60,
    ...config,
  });
};
