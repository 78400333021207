import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest, serialize } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

interface Params {
  month: string;
  user_id?: number;
  with_transferred_count?: number;
}

const getCalendarJobs = async (jobUuid: string, params: Params) => {
  const query = serialize(params, 'filters');

  const { data } = await getRequest(
    API.GET.getCalendarJobs(jobUuid, query.toString()),
  );

  return mapCalendarJobs(data.data);
};

export const useGetCalendarJobs = ({
  jobUuid,
  params,
  enabled = false,
}: {
  jobUuid: string;
  params: Params;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CALENDAR_JOBS, jobUuid, params],
    queryFn: () => getCalendarJobs(jobUuid, params),
    enabled,
  });
};

const mapCalendarJobs = (
  data: Record<string, UCM.CalendarJobRawType>,
): Record<string, UCM.CalendarJobType> => {
  const mappedData: Record<string, UCM.CalendarJobType> = {};

  for (const [date, item] of Object.entries(data)) {
    const { job_application } = item;

    mappedData[date] = {
      appliedCount: item.applied_count,
      bookedCount: item.booked_count,
      slotsNumber: item.slots_number,
      transferredCount: item.transferred_count,
      shifts: item.shifts.map((shift) => ({
        endsAt: shift.ends_at,
        id: shift.id,
        startsAt: shift.starts_at,
      })),
      cancellationNotice: job_application?.cancellation_notice ?? null,
      id: job_application?.id,
      isWaitlisted: job_application?.is_waitlisted ?? null,
      jobShiftId: job_application?.job_shift_id,
      jobShiftUuid: job_application?.job_shift_uuid,
      shift: job_application?.shift
        ? {
            endsAt: job_application.shift.ends_at,
            id: job_application.shift.id,
            startsAt: job_application.shift.starts_at,
          }
        : null,
      status: job_application?.status_code,
      uuid: job_application?.uuid,
    };
  }

  return mappedData;
};
