import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { common } from '@mui/material/colors';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import DataTable from 'src/components/DataTable';

import { daysUntilToday, forceString } from 'src/utils/datetime';
import {
  useCreateCancellationNotices,
  useGetJobApplications,
} from 'src/pages/ProjectDetailsPage/api';
import { REJECT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetRejectReason';

import { useStyles } from '../../JobDetails.styled';
import messages from '../../messages';
import CancellationModal from '../CancellationModal/CancellationModal';
import { CancellationData } from '../../../JobCalendar';
import { JobApplicationTabProps } from '../JobApplicationsAll/JobApplicationsAll';
import ContractTooltip from '../../../ContractTooltip';
import { DefaultTheme } from 'node_modules/@mui/styles/defaultTheme';

export default function JobApplicationsBooked({
  job,
  jobUuid,
  filters,
  candidate,
  handleSelectCandidate,
}: JobApplicationTabProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState<UCM.JobApplicationType | null>(null);
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: jobApplications, isLoading } = useGetJobApplications({
    jobUuid,
    type: 'booked',
    filters,
    params,
    enabled: Boolean(jobUuid),
  });

  useEffect(() => {
    if (jobApplications?.data && candidate) {
      const userNotExist =
        jobApplications.data.findIndex(
          (item) => item.applicant.uuid === candidate?.applicant?.uuid,
        ) === -1;
      if (userNotExist) {
        handleSelectCandidate?.(null);
      }
    }
  }, [jobApplications?.data]);

  const { mutate: cancellationNotices, isPending: isCancellationPending } =
    useCreateCancellationNotices({});

  const handleCancellation = useCallback((data: CancellationData) => {
    const { ...payload } = data;

    const filteredData = Object.fromEntries(
      Object.entries(payload).filter(
        ([_, value]) => value != null && value !== '',
      ),
    );
    void cancellationNotices(filteredData);
  }, []);

  const pressCandidate = useCallback(
    (item: UCM.JobApplicationType | null) => {
      let candidate = (item as UCM.JobApplicationType | string) ?? null;

      if (!job?.isFlex) {
        return setSelected(item);
      }

      handleSelectCandidate?.(candidate);
    },
    [job?.isFlex, handleSelectCandidate],
  );

  const columns: Array<{
    id: keyof UCM.JobApplicationType | string;
    label: string | React.ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'applicant',
        label: formatMessage(messages.applicantLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant) return '-';
          const { id, fullName } = item.applicant;
          return (
            <Typography
              variant="body1"
              fontWeight={(theme) => theme.typography.fontWeightMedium}
            >
              {`${id} ${fullName}`}
            </Typography>
          );
        },
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'languages',
        label: formatMessage(messages.languagesColumnLabel),
        size: 'small',
        align: 'center',
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant?.languages) return '-';
          return (
            <Typography variant="inherit">
              {item.applicant.languages?.join(', ') || '-'}
            </Typography>
          );
        },
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'initialScore',
        label: formatMessage(messages.initialScoreColumnLabel),
        size: 'small',
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.initialRatingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.initialRatingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      // {
      //   id: 'profile',
      //   label: formatMessage(messages.profileLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">%</Typography>,
      //   align: 'center',
      // },
      {
        id: 'ratingScore',
        label: formatMessage(messages.ratingLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.ratingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.ratingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      // {
      //   id: 'preScreen',
      //   label: formatMessage(messages.preScreenLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'profile',
        label: formatMessage(messages.profileLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant?.status) return '-';
          let label = formatMessage(messages.completeLabel);
          if (item?.applicant?.status?.code === 'required')
            label = formatMessage(messages.incompleteLabel);
          return <Typography variant="inherit">{label}</Typography>;
        },
        align: 'center',
      },
      {
        id: 'statusCode',
        label: formatMessage(messages.statusLabel),
        render: (statusCode: string) => (
          <Typography variant="inherit">{statusCode ?? '-'}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'briefingReadAt',
        label: formatMessage(messages.briefingLabel),
        render: (briefingReadAt: string | null) => {
          if (!briefingReadAt)
            return (
              <CloseOutlinedIcon
                sx={{ color: '#904A44', width: 24, height: 24 }}
              />
            );
          return (
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="center"
              gap={1}
            >
              <CheckOutlinedIcon
                sx={{ color: '#1E6A4F', width: 24, height: 24 }}
              />
              {forceString(briefingReadAt)}
            </Stack>
          );
        },
        align: 'center',
      },
      // {
      //   id: 'preJobQ',
      //   label: formatMessage(messages.preJobQLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      {
        id: 'contractSummary',
        label: formatMessage(messages.contractSummaryLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.contractSummary.status) return '-';
          return <ContractTooltip contract={item?.contractSummary} />;
        },
        align: 'center',
      },
      {
        id: 'bookingTimeAt',
        label: formatMessage(messages.daysSinceBookedLabel),
        render: (bookingTimeAt: string | null) => {
          if (!bookingTimeAt) return '-';
          return (
            <Typography variant="inherit">
              {daysUntilToday(bookingTimeAt)}
            </Typography>
          );
        },
        align: 'center',
      },
    ],
    [job?.isFlex, selected?.applicant?.uuid],
  );

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const tableFooterSx = useCallback(
    (theme: DefaultTheme) => ({
      borderTop: `thin solid ${theme.palette.grey[200]}`,
    }),
    [],
  );

  return (
    <Grid size={{ xs: 12 }}>
      <DataTable
        rows={jobApplications?.data}
        columns={columns}
        className={classes.tableContainer}
        loading={isLoading}
        activeRow={job?.isFlex ? candidate : selected}
        pressRow={pressCandidate}
        checkActiveKey="applicant.id"
        onPaginate={handlePagination}
        pagination={jobApplications?.meta}
      />
      {!job?.isFlex && (
        <Stack
          p={2}
          alignItems="flex-end"
          sx={tableFooterSx}
          bgcolor={common.white}
        >
          <CancellationModal
            type={REJECT_REASON.CANCELLATION_NOTICE}
            enabledFetch={Boolean(jobUuid) && Boolean(selected)}
            buttonLabel={formatMessage(messages.cancelRejectButton)}
            onConfirm={handleCancellation}
            isLoading={isCancellationPending}
            disabledButton={!selected || isCancellationPending}
            candidate={selected?.applicant}
            job={job}
          />
        </Stack>
      )}
    </Grid>
  );
}
