/*
 * Cancellations Dashboard  Messages
 *
 * This contains all the text for the Cancellations Page.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.CancellationsPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Cancellations',
  },
  studentColumnLabel: {
    id: `${scope}.studentColumnLabel`,
    defaultMessage: 'Student',
  },
  cancellationIdColumnLabel: {
    id: `${scope}.cancellationIdColumnLabel`,
    defaultMessage: 'Cancel ID',
  },
  createdByColumnLabel: {
    id: `${scope}.createdByColumnLabel`,
    defaultMessage: 'Created by',
  },
  jobIdColumnLabel: {
    id: `${scope}.jobIdColumnLabel`,
    defaultMessage: 'Job ID',
  },
  projectDetailsColumnLabel: {
    id: `${scope}.projectDetailsColumnLabel`,
    defaultMessage: 'Project Details',
  },
  shiftsColumnLabel: {
    id: `${scope}.shiftsColumnLabel`,
    defaultMessage: 'Shifts',
  },
  reasonColumnLabel: {
    id: `${scope}.reasonColumnLabel`,
    defaultMessage: 'Reason',
  },
  explanationColumnLabel: {
    id: `${scope}.explanationColumnLabel`,
    defaultMessage: 'Explanation',
  },
  adminCommentColumnLabel: {
    id: `${scope}.adminCommentColumnLabel`,
    defaultMessage: 'Admin Comment',
  },
  searchUidPlaceholder: {
    id: `${scope}.searchUidPlaceholder`,
    defaultMessage: 'UID',
  },
  searchJobIdPlaceholder: {
    id: `${scope}.searchJobIdPlaceholder`,
    defaultMessage: 'JID',
  },
  searchCancelIdPlaceholder: {
    id: `${scope}.searchCancelIdPlaceholder`,
    defaultMessage: 'CID',
  },
  backupButtonLabel: {
    id: `${scope}.backupButtonLabel`,
    defaultMessage: 'Backup',
  },
});
