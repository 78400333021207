import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import type { RequestDataArray } from 'src/types/requestData';

const mapShiftsList = (
  data: {
    attributes: UCM.ShiftListRawType;
    id: string;
  }[],
): UCM.ShiftListType[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    uuid: attributes.uuid,
    endsAt: attributes.ends_at,
    startsAt: attributes.starts_at,
    slotsNumber: attributes.slots_number,
  }));
};

const getShiftList = async (jobUuid: string) => {
  const { data } = await getRequest<RequestDataArray<UCM.ShiftListRawType>>(
    API.GET.getShiftsList(jobUuid),
  );
  return mapShiftsList(data?.data);
};

export const useGetShiftList = (
  jobUuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SHIFTS_LIST, jobUuid],
    queryFn: () => getShiftList(jobUuid),
    staleTime: 1000 * 60,
    ...config,
  });
};
