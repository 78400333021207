export const colors = {
  // Shades of Turquoise
  turquoise: '#1B728C',
  darkTurquoise: '#114358',
  darkerTurquoise: '#18374a',

  // Shades of Yellow
  yellow: '#FCC224',
  darkYellow: '#E1C16E',
  darkerYellow: '#775A0B',

  // Shades of Blue
  lightBlue: '#88D4FF',
  lighterBlue: '#C2E8FF',
  lightestBlue: '#D8F1FF',
  darkerBlue: '#001E2B',

  // Shades of White
  white: '#FFFFFF',

  // Shades of Grey
  grey: '#6F797B61',
  darkGrey: '#535353',
  darkerGrey: '#787878',
  lightGrey: '#F6F6F6',
  steelGrey: '#BFC8CB',
  mediumGrey: '#E2E2E2',
  lighterGrey: '#FBFBFB',

  // Shades of Red
  red: '#DE2B2B',
  darkRed: '#904A44',
  orangeRed: '#F64740',
  lightRed: '#FFDAD6',

  // Shades of Green
  green: '#0FBF00',
  greenSecondary: '#1E6A4F',
  darkGreen: '#20392A',
  lightGreen: '#72A98F',
  lightestGreen: '#C7DDD2',

  // Shades of Black
  black: '#000000',

  // Shades of Brown
  brown: '#1D1B201F',
  lightBrown: '#836942',
  lighterBrown: '#FEF0C8',
};

export type RGB = {
  r: number;
  g: number;
  b: number;
};

export function hexToRGB(hex: string): RGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }
  return null;
}

export function hexToRGBA(hexColor: string, opacityLevel: number): string {
  const rgb = hexToRGB(hexColor);
  if (rgb) {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacityLevel})`;
  }
  return ''; // Return an empty string if the hex color is invalid
}

export function opacity(hexColor: string, level: number = 0.5): string {
  return hexToRGBA(hexColor, level);
}

export function disable(color: string, opacityLevel: number = 0.26): string {
  return hexToRGBA(color, opacityLevel);
}
