import { useQuery } from '@tanstack/react-query';
import { upperFirst } from 'lodash';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

const getJobStatuses = async (params: Record<string, boolean>) => {
  const query = new URLSearchParams();

  Object.entries(params).map(([key, value]) =>
    query.append(key, value.toString()),
  );

  const { data } = await getRequest(API.GET.getJobStatuses(query.toString()), {
    headers: { Accept: 'application/vnd.falcon.v1+json' },
  });

  return mapJobStatuses(data?.data);
};

export const useGetJobStatuses = ({
  params,
  enabled = true,
}: {
  params: Record<string, boolean>;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.JOB_STATUSES, params],
    queryFn: () => getJobStatuses(params),
    staleTime: 1000 * 60 * 60,
    enabled,
  });
};

const mapJobStatuses = (
  data: { attributes: UCM.JobStatusesRawType; id: string }[],
): UCM.JobStatuses[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    value: attributes.name,
    label: upperFirst(attributes.name),
  }));
};
