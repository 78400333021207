import { useCallback, useEffect, useState, RefObject } from 'react';

type DragInfo = {
  startX: number;
  startY: number;
  top: number;
  left: number;
  width: number;
  height: number;
};

type UseDragProps = {
  ref: RefObject<HTMLElement>;
  calculateFor?: 'topLeft' | 'bottomRight';
};

type UseDragReturn = {
  position: { x: number | string; y: number | string };
  handleMouseDown: (evt: React.MouseEvent) => void;
  recalculate: (width?: number, height?: number) => void;
};

export const useDraggable = ({
  ref,
  calculateFor = 'topLeft',
}: UseDragProps): UseDragReturn => {
  const [dragInfo, setDragInfo] = useState<DragInfo | null>(null);
  const [finalPosition, setFinalPosition] = useState<{
    x: number | string;
    y: number | string;
  }>({
    x: '50%',
    y: '25%',
  });
  const [isDragging, setIsDragging] = useState(false);

  const updateFinalPosition = useCallback(
    (width: number, height: number, x: number, y: number) => {
      setFinalPosition(() => {
        if (calculateFor === 'bottomRight') {
          return {
            x: Math.max(
              Math.min(
                window.innerWidth - width,
                window.innerWidth - (x + width),
              ),
              0,
            ),
            y: Math.max(
              Math.min(
                window.innerHeight - height,
                window.innerHeight - (y + height),
              ),
              0,
            ),
          };
        }
        return {
          x: Math.min(Math.max(0, x), window.innerWidth - width),
          y: Math.min(Math.max(0, y), window.innerHeight - height),
        };
      });
    },
    [calculateFor],
  );

  const handleMouseUp = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    setIsDragging(false);
  }, []);

  const handleMouseDown = useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    const { clientX, clientY } = evt;
    const draggableElement = ref.current;
    if (!draggableElement) return;

    const { top, left, width, height } =
      draggableElement.getBoundingClientRect();
    setIsDragging(true);
    setDragInfo({
      startX: clientX,
      startY: clientY,
      top,
      left,
      width,
      height,
    });
  }, []);

  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      if (!isDragging || !dragInfo || !ref.current) return;
      evt.preventDefault();

      const { clientX, clientY } = evt;
      const position = {
        x: dragInfo.startX - clientX,
        y: dragInfo.startY - clientY,
      };
      const { top, left, width, height } = dragInfo;

      updateFinalPosition(width, height, left - position.x, top - position.y);
    },
    [isDragging, dragInfo, updateFinalPosition],
  );

  const recalculate = useCallback(
    (width?: number, height?: number) => {
      const draggableElement = ref.current;
      if (!draggableElement) return;
      const {
        top,
        left,
        width: boundingWidth,
        height: boundingHeight,
      } = draggableElement.getBoundingClientRect();

      updateFinalPosition(
        width ?? boundingWidth,
        height ?? boundingHeight,
        left,
        top,
      );
    },
    [updateFinalPosition],
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return { position: finalPosition, handleMouseDown, recalculate };
};
