import { useQueries } from '@tanstack/react-query';
import { keyBy, map, mapValues, startsWith } from 'lodash';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, serialize } from 'src/utils/request';
import { buildAttributes, buildChild, buildChildren } from 'src/utils/tools';

const getInternalAttributes = async (
  categoryUuid: string,
  userUuid?: string,
) => {
  const params = serialize(
    { field_category_uuid: categoryUuid },
    'filters',
  ).toString();

  const { data } = await getRequest(
    API.GET.getInternalAttributes(userUuid, params),
    { headers: { Accept: 'application/vnd.falcon.v2+json' } },
  );

  return { data: data.data, included: data.included, categoryUuid };
};

export const useGetInternalAttributes = ({
  userUuid,
  fields = [],
  enabled = false,
}: {
  userUuid?: string;
  fields: { value: string; label: string }[];

  enabled?: boolean;
}) => {
  return useQueries({
    queries: fields?.map((field) => {
      return {
        queryKey: [QUERY_KEYS.INTERNAL_ATTRIBUTES, userUuid, field.value],
        queryFn: () => getInternalAttributes(field.value, userUuid),
        staleTime: 1000 * 60 * 30,
        enabled,
      };
    }),
    combine: (results) => {
      if (!results) return { data: [], isPending: false };

      let included: Record<string, any> = {}; // eslint-disable-line
      const data: Record<string, any[]> = {}; // eslint-disable-line

      const filtered = results
        .map((result) => result?.data)
        .filter((item) => item?.data?.length);

      // @ts-ignore
      filtered?.forEach(({ included: inc, categoryUuid, data: resultData }) => {
        if (!categoryUuid || !resultData) return;

        Object.assign(
          included,
          // @ts-ignore
          keyBy(inc, (item) => `${item.id}-${item.type}`),
        );
        data[categoryUuid] = resultData;
      });

      const isPending = results.some((result) => result.isPending);

      return {
        data: Object.values(buildUserAttributes(data, included)).flat(),
        isPending,
      };
    },
  });
};

// eslint-disable-next-line
function buildUserAttributes(attributes: any, included: any) {
  return mapValues(attributes, (fields) =>
    map(fields, (field) =>
      buildAttributes(field, {
        label: 'name',
        isVisibleByUser: 'is_visible_by_user',
        description: () =>
          startsWith(field.attributes.description, '*')
            ? field.attributes.description.substring(1)
            : null,
        type: () => buildChild(field, included, 'type'),
        category: () => buildChild(field, included, 'category'),
        values: () =>
          buildChildren(field, included, 'answers', null, {
            option: (answer) => buildChild(answer, included, 'dynamic_option'),
            answer: 'answer',
          }),
        options: () =>
          buildChildren(field, included, 'options', null, {
            label: 'input_value',
            value: 'uuid',
          }),
      }),
    ),
  );
}
