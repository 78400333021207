import {
  useRef,
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import map from 'lodash/map';
import uniq from 'lodash/uniq';
import some from 'lodash/some';
import every from 'lodash/every';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {
  formatNumber,
  formatCurrency,
  toParsableNumber,
  checkCurrencyTyping,
} from 'src/utils/standards';
import SearchFilterBar, {
  QuickFiltersType,
  resolveQuickSearch,
} from 'src/components/SearchFilterBar';
import {
  useGetJobCoverages,
  useUpdateJobCoverage,
} from 'src/pages/ProjectDetailsPage/api';
import { locale, currency } from 'src/config';
import { forceString } from 'src/utils/datetime';
import DataTable from 'src/components/DataTable';
import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import CoverageIcon from 'src/components/Icons/CoverageIcon';

import messages from './messages';

const checkParam = { locale };

interface StandbyCoverageFormProps {
  jobUuid: string;
}

export default function StandbyCoverageForm({
  jobUuid,
}: StandbyCoverageFormProps) {
  const { formatMessage } = useIntl();

  const [bonus, setBonus] = useState('');
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({ page: 1 });
  const [checked, setChecked] = useState<Array<string>>([]);
  const [filters, setFilters] = useState<Record<string, string>>({});

  const { data: jobs, isFetching } = useGetJobCoverages(
    jobUuid,
    params,
    resolveQuickSearch(filters),
    { enabled: open },
  );
  const { mutate: updateJobCoverage, isPending: isUpdating } =
    useUpdateJobCoverage();

  const quickFilters = useRef<QuickFiltersType[]>([
    {
      autoFocus: true,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchPlaceholder),
    },
  ]).current;

  useEffect(() => {
    if (jobs?.meta) {
      setChecked((prevChecked) =>
        uniq([...prevChecked, ...jobs.meta.selectedJobUuids]),
      );
      setBonus(formatNumber(jobs.meta.transferBonus, locale));
    }
  }, [open, jobs?.meta.selectedJobUuids.length, jobs?.meta.transferBonus]);

  const areAllChecked = useMemo(
    () =>
      checked.length > 0 &&
      every(jobs?.data, (coverage) =>
        some(checked, (checkedJobUuid) => checkedJobUuid === coverage.uuid),
      ),
    [jobs?.data.length, checked.length],
  );

  const handleCheckAll = useCallback(() => {
    if (!jobs?.data) return;

    if (areAllChecked) {
      setChecked([]);
      return;
    }
    const allCheckedItems = uniq([...checked, ...map(jobs.data, 'uuid')]);
    setChecked(allCheckedItems);
  }, [jobs?.data.length, checked.length]);

  const handleCheck = useCallback((uuid: string) => {
    setChecked((prevChecked) => {
      if (includes(prevChecked, uuid)) {
        return prevChecked.filter((id) => id !== uuid);
      } else {
        return [...prevChecked, uuid];
      }
    });
  }, []);

  const columns: Array<{
    id: keyof UCM.UserAccountType | string;
    label: string | ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'multi-select',
        label: (
          <Checkbox
            checked={areAllChecked}
            onClick={handleCheckAll}
            disabled={jobs?.data.length === 0}
          />
        ),
        render: (_: Object, job: UCM.JobCoverageType) => {
          return (
            <Checkbox
              onClick={() => handleCheck(job.uuid)}
              checked={
                findIndex(
                  checked,
                  (checkedJobUuid) => checkedJobUuid === job.uuid,
                ) !== -1
              }
            />
          );
        },
      },
      {
        id: 'job',
        label: formatMessage(messages.jobColumnLabel),
        render: (_: Object, job: UCM.JobCoverageType) => (
          <Typography variant="inherit">
            {job.id} - {job.title}
          </Typography>
        ),
      },
      {
        id: 'project',
        label: formatMessage(messages.projectColumnLabel),
        render: (project: UCM.JobCoverageType['project']) => (
          <Typography variant="inherit">
            {/* TODO */}
            {/* I need to move to a separate function like BO */}
            {/* Will do it later as it good to have  */}
            <Link to={`/project_details/${project.uuid}`}>
              {project.id} - {project.name}
            </Link>
          </Typography>
        ),
      },
      {
        id: 'date',
        align: 'center',
        label: formatMessage(messages.dateColumnLabel),
        render: (_: Object, job: UCM.JobCoverageType) => (
          <Typography variant="inherit">
            {uniq([
              forceString(job.firstShiftAt),
              forceString(job.lastShiftAt),
            ]).join(' - ')}
          </Typography>
        ),
      },
      {
        id: 'booked',
        align: 'center',
        label: formatMessage(messages.bookingColumnLabel),
        render: (_: Object, job: UCM.JobCoverageType) => (
          <Typography variant="inherit">
            {job.bookedCount} / {job.slotsNumber}
          </Typography>
        ),
      },
      {
        id: 'compensation',
        align: 'center',
        label: formatMessage(messages.compensationColumnLabel),
        render: (compensation: UCM.JobCoverageType['compensation']) => {
          const { wage, nightWageBonus, sundayWageBonus, holidayWageBonus } =
            compensation;
          return (
            <Typography variant="inherit">
              {formatCurrency(wage, currency, locale).replace(/\s/g, '')} /{' '}
              {formatNumber(nightWageBonus, locale)}% /{' '}
              {formatNumber(sundayWageBonus, locale)}% /{' '}
              {formatNumber(holidayWageBonus, locale)}%
            </Typography>
          );
        },
      },
    ],
    [jobs?.data.length, checked.length, areAllChecked],
  );

  const handleClose = useCallback(() => {
    setFilters({});
    setChecked([]);
    setParams({ page: 1 });

    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClear = useCallback(() => {
    setChecked([]);
    setBonus('');
  }, []);

  const handleCurrencyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (checkCurrencyTyping(value, checkParam)) {
        setBonus(value);
      }
    },
    [],
  );

  // eslint-disable-next-line
  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const handleConfirm = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      updateJobCoverage(
        {
          jobUuid,
          jobCoverageUuids: checked,
          transferBonus: toParsableNumber(bonus, locale),
        },
        {
          onSuccess: () => {
            handleClose();
            resolve(true);
          },
          onError: () => resolve(false),
        },
      );
    });
  }, [jobUuid, checked, bonus, handleClose]);

  const disabled = useMemo(
    () => isUpdating || checked.length === 0,
    [checked.length, isUpdating],
  );

  return (
    <ConfirmModal
      width="90%"
      open={open}
      disabled={disabled}
      loading={isUpdating}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmationRequired={false}
      title={formatMessage(messages.title)}
      submitTextButton={formatMessage(messages.confirmButton)}
      triggerButton={() => (
        <IconButton onClick={handleOpen}>
          <CoverageIcon />
        </IconButton>
      )}
      renderExtraButton={
        <Button color="error" disabled={disabled} onClick={handleClear}>
          {formatMessage(messages.clearButton)}
        </Button>
      }
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid size={12}>
          <Typography color="grey" fontWeight="600">
            {formatMessage(messages.searchFieldLabel)}
          </Typography>
        </Grid>

        <Grid size={{ xs: 12, md: 5 }}>
          <SearchFilterBar fields={quickFilters} onSearch={handleSearch} />
        </Grid>

        <Grid size={{ xs: 12, md: 7 }} display="flex" justifyContent="flex-end">
          <TextInput
            value={bonus}
            onChange={handleCurrencyChange}
            label={formatMessage(messages.transferBonusLabel)}
          />
        </Grid>

        <Grid size={12} p={0.25}>
          <DataTable
            columns={columns}
            rows={jobs?.data}
            loading={isFetching}
            pagination={jobs?.meta}
            onPaginate={handlePagination}
          />
        </Grid>
      </Grid>
    </ConfirmModal>
  );
}
