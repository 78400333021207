import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import map from 'lodash/map';

import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Import SVGs as React components
import { ReactComponent as InfoIcon } from 'src/images/Info.svg';

import {
  useGetProject,
  useGetProjectSettings,
  useGetVerificationMethodOptions,
} from 'src/pages/ProjectDetailsPage/api';
import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';

import messages from './messages';

export default function ProjectSettingsForm() {
  const { formatMessage } = useIntl();

  const { uuid } = useParams<{ uuid: string }>();

  const { data: project } = useGetProject(uuid ?? '', {
    enabled: !!uuid,
  });
  const { data: projectSettings } = useGetProjectSettings(uuid ?? '', {
    enabled: !!uuid,
  });
  const { data: verificationMethodOptions } = useGetVerificationMethodOptions();

  const booleanFormatter = useRef((value: boolean) => {
    return value
      ? formatMessage(messages.yesLabel)
      : formatMessage(messages.noLabel);
  }).current;

  const methodFormatter = useCallback(
    (value: string) =>
      verificationMethodOptions?.find((option) => option.value === value)
        ?.label,
    [verificationMethodOptions],
  );

  return (
    <ConfirmModal
      width="700px"
      title={formatMessage(messages.title)}
      triggerButton={(handleOpen) => {
        return (
          <IconButton onClick={handleOpen}>
            <InfoIcon />
          </IconButton>
        );
      }}
    >
      {project && (
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="h4" fontWeight={'600'}>
              {formatMessage(messages.detailSectionTitle)}
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.projectTypeFieldLabel)}
                value={project.type.label}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.verificationCodeFieldLabel)}
                value={project.verificationCode}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.contactPersonFieldLabel)}
                value={project.customer.contact.person}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.projectManagerFieldLabel)}
                value={project.manager.name}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.statusFieldLabel)}
                value={project.statusName}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.visibilityFieldLabel)}
                value={booleanFormatter(project.isVisible)}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={12}>
              <TextInput
                label={formatMessage(messages.certificateFieldLabel)}
                value={map(project.certificates, ({ label }) => label).join(
                  ', ',
                )}
                fullWidth
                disabled
                multiline={true}
              />
            </Grid>
            <Grid size={12}>
              <TextInput
                label={formatMessage(messages.descriptionFieldLabel)}
                value={project.description}
                fullWidth
                disabled
                multiline={true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {projectSettings && (
        <Grid container spacing={3}>
          <Grid size={12} mt={2}>
            <Typography variant="h4" fontWeight={'600'}>
              {formatMessage(messages.settingSectionTitle)}
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.minWorkHoursFieldLabel)}
                value={projectSettings.minWorkingHours}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.wageBonusNightFieldLabel)}
                value={projectSettings.wageBonusNight}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.isBreakFieldLabel)}
                value={booleanFormatter(projectSettings.isBreak)}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.wageBonusSundayFieldLabel)}
                value={projectSettings.wageBonusSunday}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.verificationMethodFieldLabel)}
                value={methodFormatter(projectSettings.verificationMethod)}
                fullWidth
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextInput
                label={formatMessage(messages.wageBonusHolidayFieldLabel)}
                value={projectSettings.wageBonusHoliday}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </ConfirmModal>
  );
}
