/* eslint-disable */

import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

type IncludedData = Record<string, any>;
type AttributeMap = Record<string, string | ((parent: any) => any)>;

export function buildAttributes(parent: any | null, attributes?: AttributeMap) {
  if (!parent) return null;

  const mapped = isEmpty(attributes)
    ? mapKeys(parent.attributes, (value, key) => camelCase(key))
    : mapValues(attributes, (attribute) =>
        typeof attribute === 'function'
          ? attribute(parent)
          : parent.attributes?.[attribute],
      );

  return {
    ...mapped,
    uuid: parent.attributes?.uuid,
    id: parent.id,
  };
}

function buildItem(
  parentRef: { id: string; type: string } | null,
  included: IncludedData,
  childName?: string | null,
  attributes?: AttributeMap,
) {
  if (!parentRef) return null;

  const { id, type } = parentRef;
  const parent = included?.[`${id}-${type}`] || parentRef;

  return childName
    ? buildChild(parent, included, childName, null, attributes)
    : buildAttributes(parent, attributes);
}

export function buildChild(
  parent: any | null,
  included: IncludedData,
  childName: string,
  grandChildName?: string | null,
  attributes?: AttributeMap,
): any {
  if (!parent?.relationships?.[childName]?.data) return null;
  return buildItem(
    parent.relationships[childName].data,
    included,
    grandChildName,
    attributes,
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function buildChildren(
  parent: any | null,
  included: IncludedData,
  childrenName: string,
  grandChildName?: string | null,
  attributes?: AttributeMap,
): any[] | null {
  if (!parent?.relationships?.[childrenName]?.data) return null;

  return map(parent.relationships[childrenName].data, (childRef) =>
    buildItem(childRef, included, grandChildName, attributes),
  );
}

export const buildJobProps = (jobDetails?: UCM.JobDetailType) => {
  if (!jobDetails) return null;

  return {
    uuid: jobDetails.uuid,
    title: jobDetails.title,
    price: jobDetails.price,
    venue: jobDetails.venue,
    salary: jobDetails.salary,
    isFlex: jobDetails.isFlex,
    address: jobDetails.address,
    zipCode: jobDetails.zipCode,
    quantity: jobDetails.quantity,
    isStandby: jobDetails.isStandby,
    tariffCode: jobDetails.tariffCode,
    priceBonus: jobDetails.priceBonus,
    salaryBonus: jobDetails.salaryBonus,
    description: jobDetails.description,
    cityCode: jobDetails.location.city.value,
    houseNumber: jobDetails.houseNumber,
    staffTypeUuid: jobDetails.staffTypeUuid,
    jobTypeCode: jobDetails.jobTypeCode.value,
    ignoreDesiredCities: jobDetails.ignoreDesiredCities,
    additionalAttributes: jobDetails.additionalAttributes,
    taskCodes: map(jobDetails.taskCodes, (task) => task.value),
    bookingDeadlineDate: moment(jobDetails.bookingDeadlineDate),
    languageCodes: map(jobDetails.languageCodes, (lang) => lang.value),
  };
};
