import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, serialize } from 'src/utils/request';

export enum ACCEPT_REASON {
  FINES = 'fines',
  ACCEPTANCE_APPLICATION = 'job_application_acceptance',
}

const getAcceptReason = async (filters: Record<string, string>) => {
  const queryFilters = serialize(filters, 'filters');

  const { data } = await getRequest(
    API.GET.getAcceptReason(queryFilters.toString()),
  );

  return mapRejectReason(data?.data);
};

export const useGetAcceptReason = (
  filters: Record<string, string>,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ACCEPT_REASON, filters],
    queryFn: () => getAcceptReason(filters),
    staleTime: 1000 * 60 * 5,
    ...config,
  });
};

const mapRejectReason = (
  data: {
    attributes: UCM.AcceptReasonRawType;
    id: string;
  }[],
): UCM.AcceptReasonType[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    code: attributes.code,
    reasonFor: attributes.reason_for,
    title: attributes.title,
  }));
};
