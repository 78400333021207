import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { API } from 'src/reactQuery/Api';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

const updateFavorite = async ({
  jobUuid,
  applicationUuid,
  favorite,
}: {
  jobUuid: string;
  applicationUuid: string;
  favorite: boolean;
}) => {
  const res = await putRequest(
    API.PUT.updateJobFavorite(jobUuid, applicationUuid, favorite),
  );
  return { ...res.data, favorite };
};

export const useUpdateJobFavourite = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateFavorite,
    onSuccess: (res) => {
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS],
      });
      handleSuccess(
        formatMessage(
          res.favorite
            ? messages.applicationFavorited
            : messages.applicationUnfavorited,
        ),
      );
    },
    onError: handleError,
  });
};
