import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';

const reapplyShift = async ({
  jobUuid,
  applicationUuid,
}: {
  jobUuid: string;
  applicationUuid: string;
}) => {
  const payload = { type: 'job_applications', uuid: applicationUuid };
  const res = await putRequest(
    API.PUT.reapplyShift(jobUuid, applicationUuid),
    payload,
  );
  return res.data;
};

export const useUpdateReapplyShift = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: reapplyShift,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS_COUNT],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.CALENDAR_JOBS],
      });
      handleSuccess(formatMessage(messages.applicationReapplied));
    },
    onError: handleError,
  });
};
