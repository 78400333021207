import head from 'lodash/head';
import compact from 'lodash/compact';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

export const PencilAdornment: React.FC = () => (
  <ModeEditOutlineOutlinedIcon fontSize="small" />
);

// Build helper text from multiple sources
export const buildHelperText = (...helpText: Array<string | null>) => {
  const texts = compact(helpText);
  if (texts.length === 0) return '';
  if (texts.length === 1) return head(texts) ?? '';
  return texts.join(' ');
};
