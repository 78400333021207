/*
 * Create Fine Form Modal
 *
 * This contains all the text for the Create Fine Form Modal.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.FinesPage.components.CreateFineFormModal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Fine',
  },
  buttonOpenLabel: {
    id: `${scope}.buttonOpenLabel`,
    defaultMessage: 'Create Fine',
  },
  saveAndApproveButton: {
    id: `${scope}.saveAndApproveButton`,
    defaultMessage: 'Create and Approve',
  },
  saveButton: {
    id: `${scope}.saveButton`,
    defaultMessage: 'Save',
  },
  userIdLabel: {
    id: `${scope}.userIdLabel`,
    defaultMessage: 'User ID',
  },
  userEmailLabel: {
    id: `${scope}.userEmailLabel`,
    defaultMessage: 'E-mail',
  },
  userNameLabel: {
    id: `${scope}.userNameLabel`,
    defaultMessage: 'Name',
  },
  reasonLabel: {
    id: `${scope}.reasonLabel`,
    defaultMessage: 'Reason',
  },
  cancelIdLabel: {
    id: `${scope}.cancelIdLabel`,
    defaultMessage: 'Cancel ID',
  },
  jobIdLabel: {
    id: `${scope}.jobIdLabel`,
    defaultMessage: 'Job ID',
  },
  affectedDateLabel: {
    id: `${scope}.affectedDateLabel`,
    defaultMessage: 'Affected Date',
  },
  amountLabel: {
    id: `${scope}.amountLabel`,
    defaultMessage: 'Fine ( Euros )',
  },
  timeDeviationLabel: {
    id: `${scope}.timeDeviationLabel`,
    defaultMessage: 'Time Deviation',
  },
  explanationLabel: {
    id: `${scope}.explanationLabel`,
    defaultMessage: 'Explanation',
  },
  externalExplanationLabel: {
    id: `${scope}.externalExplanationLabel`,
    defaultMessage: 'External Explanation',
  },
  adminCommentLabel: {
    id: `${scope}.adminCommentLabel`,
    defaultMessage: 'Admin Comment',
  },
  userNotFound: {
    id: `${scope}.userNotFound`,
    defaultMessage: 'User not found',
  },
  fineSaved: {
    id: `${scope}.fineSaved`,
    defaultMessage: 'Fine saved successfully',
  },
  fineSavedAndApproved: {
    id: `${scope}.fineSavedAndApproved`,
    defaultMessage: 'Fine saved and approved successfully',
  },
});
