import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import ConfirmModal from 'src/components/ConfirmModal';
import DataTable from 'src/components/DataTable';

import {
  useDownloadFineReport,
  useGetPreviousFineReports,
} from 'src/pages/FinesPage/api';

import messages from './messages';

export default function PreviousExportFinesFormModal() {
  const { formatMessage } = useIntl();

  const [selectedFine, setSelectedFine] = useState<
    UCM.PreviousFineReportType | undefined
  >();

  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: reports, isLoading } = useGetPreviousFineReports({
    params,
    enabled: open,
  });

  const { isSuccess, isFetching: isDownloading } = useDownloadFineReport({
    fine: selectedFine,
    enabled: !!selectedFine,
  });

  useEffect(() => {
    if (isSuccess) {
      setSelectedFine(undefined);
    }
  }, [isSuccess]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedFine(undefined);
  }, []);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const handleSelectReport = useCallback((fine: UCM.PreviousFineReportType) => {
    setSelectedFine(fine);
  }, []);

  const columns: Array<{
    id: keyof UCM.PreviousFineReportType | string;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 'createdAt',
        label: formatMessage(messages.dateColumnLabel),
        size: 'small',
        render: (createdAt: string) => (
          <Typography variant="inherit">{createdAt}</Typography>
        ),
      },
      {
        id: 'id',
        label: formatMessage(messages.idColumnLabel),
        size: 'small',
        align: 'center',
        render: (id: string) => <Typography variant="inherit">{id}</Typography>,
      },
      {
        id: 'finesCount',
        label: formatMessage(messages.countColumnLabel),
        size: 'small',
        align: 'center',
        render: (finesCount: number) => (
          <Typography variant="inherit">{finesCount}</Typography>
        ),
      },
      {
        id: 'action',
        label: '',
        size: 'small',
        align: 'center',
        render: (_: object, item: UCM.PreviousFineReportType) => {
          const isSelected = selectedFine?.uuid === item.uuid;
          return (
            <IconButton
              onClick={() => handleSelectReport(item)}
              disabled={isDownloading && isSelected}
            >
              <FileDownloadOutlinedIcon fontSize="small" />
            </IconButton>
          );
        },
      },
    ],
    [isDownloading, handleSelectReport],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <ConfirmModal
      width="700px"
      onOpen={handleOpen}
      onClose={handleClose}
      title={formatMessage(messages.title)}
      triggerButton={(handleOpen) => (
        <Button color="secondary" onClick={handleOpen}>
          {formatMessage(messages.buttonOpenLabel)}
        </Button>
      )}
    >
      <Grid container spacing={2}>
        <DataTable
          onPaginate={handlePagination}
          columns={columns}
          rows={reports?.data}
          loading={isLoading}
          pagination={reports?.meta}
        />
      </Grid>
    </ConfirmModal>
  );
}
