/*
 * Fines Page Messages
 *
 * This contains all the text for the Fines Page.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.FinesPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Fines',
  },
  fineIdColumnLabel: {
    id: `${scope}.fineIdColumnLabel`,
    defaultMessage: 'Fine ID',
  },
  createdByColumnLabel: {
    id: `${scope}.createdByColumnLabel`,
    defaultMessage: 'Created by',
  },
  studentNameColumnLabel: {
    id: `${scope}.studentNameColumnLabel`,
    defaultMessage: 'Student Name',
  },
  cancellationIdColumnLabel: {
    id: `${scope}.cancellationIdColumnLabel`,
    defaultMessage: 'Cancellation ID',
  },
  jobIdColumnLabel: {
    id: `${scope}.jobIdColumnLabel`,
    defaultMessage: 'Job ID',
  },
  reasonColumnLabel: {
    id: `${scope}.reasonColumnLabel`,
    defaultMessage: 'Reason',
  },
  timeDeviationColumnLabel: {
    id: `${scope}.timeDeviationColumnLabel`,
    defaultMessage: 'Time Deviation',
  },
  explanationColumnLabel: {
    id: `${scope}.explanationColumnLabel`,
    defaultMessage: 'Explanation',
  },
  fineAmountColumnLabel: {
    id: `${scope}.fineAmountColumnLabel`,
    defaultMessage: 'Fine (€)',
  },
  externalExplanationColumnLabel: {
    id: `${scope}.externalExplanationColumnLabel`,
    defaultMessage: 'External Explanation',
  },
  adminCommentColumnLabel: {
    id: `${scope}.adminCommentColumnLabel`,
    defaultMessage: 'Admin Comment',
  },
  approvedByColumnLabel: {
    id: `${scope}.approvedByColumnLabel`,
    defaultMessage: 'Approved by',
  },
  fineSaved: {
    id: `${scope}.fineSaved`,
    defaultMessage: 'Fine saved successfully',
  },
  paidButtonLabel: {
    id: `${scope}.paidButtonLabel`,
    defaultMessage: 'Paid',
  },
  paidConfirmMessage: {
    id: `${scope}.paidConfirmMessage`,
    defaultMessage: 'Do you really want to mark this Fine as paid?',
  },
  finePaid: {
    id: `${scope}.finePaid`,
    defaultMessage: 'Fine paid successfully',
  },
  searchUidPlaceholder: {
    id: `${scope}.searchUidPlaceholder`,
    defaultMessage: 'UID',
  },
  searchFineIdPlaceholder: {
    id: `${scope}.searchFineIdPlaceholder`,
    defaultMessage: 'FID',
  },
  searchJobIdPlaceholder: {
    id: `${scope}.searchJobIdPlaceholder`,
    defaultMessage: 'JID',
  },
  searchCancellationIdPlaceholder: {
    id: `${scope}.searchCancellationIdPlaceholder`,
    defaultMessage: 'CID',
  },
  approveSelectedButtonLabel: {
    id: `${scope}.approveSelectedButtonLabel`,
    defaultMessage: 'Approve Selected',
  },
  deleteSelectedButtonLabel: {
    id: `${scope}.deleteSelectedButtonLabel`,
    defaultMessage: 'Delete Selected',
  },
  exemptSelectedButtonLabel: {
    id: `${scope}.exemptSelectedButtonLabel`,
    defaultMessage: 'Exempt Selected',
  },
  exportSelectedButtonLabel: {
    id: `${scope}.exportSelectedButtonLabel`,
    defaultMessage: 'Export Selected',
  },
  revertSelectedButtonLabel: {
    id: `${scope}.revertSelectedButtonLabel`,
    defaultMessage: 'Revert Selected',
  },
  saveFineConfirmMessage: {
    id: `${scope}.saveFineConfirmMessage`,
    defaultMessage: 'Do you really want to save this Fine?',
  },
  approveFineConfirmMessage: {
    id: `${scope}.approveFineConfirmMessage`,
    defaultMessage: 'Do you really want to approve this Fine?',
  },
  approveFinesConfirmMessage: {
    id: `${scope}.approveFinesConfirmMessage`,
    defaultMessage: 'Do you really want to approve these Fines?',
  },
  deleteFineConfirmMessage: {
    id: `${scope}.deleteFineConfirmMessage`,
    defaultMessage: 'Do you really want to delete this Fine?',
  },
  deleteFinesConfirmMessage: {
    id: `${scope}.deleteFinesConfirmMessage`,
    defaultMessage: 'Do you really want to delete these Fines?',
  },
  revertFineConfirmMessage: {
    id: `${scope}.revertFineConfirmMessage`,
    defaultMessage: 'Do you really want to revert this Fine?',
  },
  revertFinesConfirmMessage: {
    id: `${scope}.revertFinesConfirmMessage`,
    defaultMessage: 'Do you really want to revert these Fines?',
  },
  paidFineConfirmMessage: {
    id: `${scope}.paidFineConfirmMessage`,
    defaultMessage: 'Do you really want to mark this Fine as paid?',
  },
  exportFinesConfirmMessage: {
    id: `${scope}.exportFinesConfirmMessage`,
    defaultMessage: 'Do you really want to export these fines as a CSV file?',
  },
  exportFineConfirmMessage: {
    id: `${scope}.exportFineConfirmMessage`,
    defaultMessage: 'Do you really want to export this fine as a CSV file?',
  },
  fineApproved: {
    id: `${scope}.fineApproved`,
    defaultMessage: 'Fine approved successfully',
  },
  finesApproved: {
    id: `${scope}.finesApproved`,
    defaultMessage: 'Fines approved successfully',
  },
  fineDeleted: {
    id: `${scope}.fineDeleted`,
    defaultMessage: 'Fine deleted successfully',
  },
  finesDeleted: {
    id: `${scope}.finesDeleted`,
    defaultMessage: 'Fines deleted successfully',
  },
  fineExempted: {
    id: `${scope}.fineExempted`,
    defaultMessage: 'Fine exempted successfully',
  },
  finesExempted: {
    id: `${scope}.finesExempted`,
    defaultMessage: 'Fines exempted successfully',
  },
  fineExported: {
    id: `${scope}.fineExported`,
    defaultMessage: 'Fine exported successfully',
  },
  finesExported: {
    id: `${scope}.finesExported`,
    defaultMessage: 'Fines exported successfully',
  },
  fineReverted: {
    id: `${scope}.fineReverted`,
    defaultMessage: 'Fine reverted successfully',
  },
  finesReverted: {
    id: `${scope}.finesReverted`,
    defaultMessage: 'Fines reverted successfully',
  },
  reportDownloaded: {
    id: `${scope}.reportDownloaded`,
    defaultMessage: 'Report downloaded successfully',
  },
});
