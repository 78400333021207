/*
 * JobDetails Messages
 *
 * This contains all the text for the JobDetails component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.RebookReasonModal';

export default defineMessages({
  confirmButtonLabel: {
    id: `${scope}.confirmButtonLabel`,
    defaultMessage: 'Confirm',
  },
});
