import { useCallback, useRef } from 'react';

import { useIntl } from 'react-intl';

import { buildHelperText } from 'src/utils/components';
import RadioButtonGroup from 'src/components/RadioButtonGroup';
import messages from 'src/pages/ProjectDetailsPage/components/JobForm/components/JobEditPanel/messages';

interface BooleanFieldProps {
  helperText?: string;
  field: UCM.DynamicFieldType;
  value: { value?: boolean | null };
  onChange: (field: UCM.DynamicFieldType, { value }: { value: string }) => void;
}

export default function BooleanField({
  value,
  field,
  onChange,
  helperText = '',
  ...others
}: BooleanFieldProps) {
  const { formatMessage } = useIntl();

  const booleanOptions = useRef([
    { value: 'true', label: formatMessage(messages.yesLabel) },
    { value: 'false', label: formatMessage(messages.noLabel) },
  ]).current;

  const handleChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(field, { value });
    },
    [field, onChange],
  );

  return (
    <RadioButtonGroup
      {...others}
      label={field.label}
      onChange={handleChange}
      options={booleanOptions}
      value={value?.value != null ? value.value.toString() : ''}
      helperText={buildHelperText(field.description, helperText)}
    />
  );
}
