import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

const getCancellation = async (uuid?: string) => {
  const { data } = await getRequest(API.GET.getCancellationNotices(uuid));

  return mapCancellation(data.data);
};

export const useGetCancellation = ({
  uuid,
  enabled = false,
}: {
  uuid?: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CANCELLATION_NOTICES, uuid],
    queryFn: () => getCancellation(uuid),
    enabled,
  });
};

const mapCancellation = (data: {
  attributes: UCM.CancellationRawType;
  id: string;
}): UCM.CancellationType | null => {
  if (!data) return null;

  const { attributes } = data;

  return {
    uuid: attributes.uuid,
    userId: attributes.user_id,
    jobId: attributes.job_id,
    source: attributes.source,
    explanation: attributes.explanation,
    adminComment: attributes.admin_comment,
    shiftDates: attributes.shift_dates,
    createdAt: attributes.created_at,
    user: {
      id: attributes.user.id,
      uuid: attributes.user.uuid,
      firstName: attributes.user.first_name,
      lastName: attributes.user.last_name,
      email: attributes.user.email,
    },
    project: {
      id: attributes.project.id,
      uuid: attributes.project.uuid,
      name: attributes.project.name,
      projectManager: attributes.project.project_manager,
    },
    reason: {
      code: attributes.reason.code,
      title: attributes.reason.title,
    },
    jobShifts: attributes.job_shifts.map((shift: UCM.JobShiftRawType) => ({
      id: shift.id,
      uuid: shift.attributes.uuid,
      shift: {
        id: shift.attributes.shift.id,
        uuid: shift.attributes.shift.uuid,
        jobId: shift.attributes.shift.job_id,
        projectUuid: shift.attributes.shift.project_uuid,
        startsAt: shift.attributes.shift.starts_at,
        endsAt: shift.attributes.shift.ends_at,
      },
    })),
    shiftCount: attributes.shift_count,
  };
};
