import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';
import { resolveDataFilter } from 'src/components/SearchFilterBar';

const mapProjects = (
  project: UCM.ProjectRawType,
  projectId: string,
): UCM.ProjectType => {
  return {
    id: projectId,
    uuid: project.uuid,
    name: project.name,
    endDate: project.end_date,
    startDate: project.start_date,
    cities: project.unique_cities,
    statusName: project.status_name,
    isPriority: project.is_priority,
    hasFlexJobs: project.has_flex_jobs,
    customerName: project.customer_name,
    hasStandbyJobs: project.has_standby_jobs,
    slotsCount: project.total_published.slots_count,
    bookedStaffCount: project.total_published.booked_staff_count,
  };
};

const getProjects = async <T extends UCM.ProjectStatusType>(
  projectStatus: T,
  filters: Array<Array<{ value: string; name: string }>> = [],
  params: Record<string, string | number>,
) => {
  // TODO
  // Just a quick thingy BE needs to refactor a little this should be consistent like we do for gatekeeper
  // Updated filter for open tab only showing status active and draft
  const updatedFilter = [
    ...filters,
    resolveDataFilter([{ name: 'Status', operator: 'in', value: ['1', '3'] }]),
  ];

  const query = resolveQueryString(
    projectStatus === 'open' ? updatedFilter : filters,
    params,
  );

  const {
    data: { data, meta },
  } = await getRequest<RequestDataArray<UCM.ProjectRawType, UCM.MetaRawType>>(
    `${API.GET.getAllProjects}${query}`,
  );

  return {
    meta: paginationMapper(meta),
    data: data.map(({ attributes, id }) => mapProjects(attributes, id)),
  };
};

export const useGetProjects = (
  projectStatus: UCM.ProjectStatusType,
  params: Record<string, string | number>,
  filters: Array<{ value: string; name: string }> = [],
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROJECTS, projectStatus, filters, params],
    queryFn: () => getProjects(projectStatus, [filters], params),
    staleTime: 1000 * 60 * 5, // Dashboards refreshed after every 5 minutes automatically
    ...config,
  });
};
