import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapLocationOptions = (
  option: UCM.ContractLocationOptionRawType,
): UCM.ContractLocationOptionType => ({
  value: option.code,
  label: option.name,
});

const getLocationOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.ContractLocationOptionRawType>
  >(API.GET.contractLocationOptions, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
  return data.data.map(({ attributes }) => mapLocationOptions(attributes));
};

export const useGetLocationOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACT_LOCATION_OPTIONS],
    queryFn: getLocationOptions,
    ...config,
  });
};
