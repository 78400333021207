import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import map from 'lodash/map';

import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

// Import SVGs as React components
import { ReactComponent as SalesforceIcon } from 'src/images/Salesforce.svg';

import { useDownloadProjectStaffPlan } from 'src/pages/ProjectDetailsPage/api';
import { colors } from 'src/utils/customColors';
import MoreActions from 'src/components/MoreActions';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import StatusChip from 'src/pages/ProjectDetailsPage/components/StatusChip';
import PriorityChip from 'src/pages/ProjectDetailsPage/components/PriorityChip';
import ProjectSettingsForm from 'src/pages/ProjectDetailsPage/components/ProjectSettingsForm';

import messages from './messages';
import { Styled } from './index.styled';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(2),
    backgroundColor: colors.lightestBlue,
  },
}));

interface SummaryPanelProps {
  title: string;
  endDate: string;
  company: string;
  projectId: string;
  startDate: string;
  slotsCount: number;
  projectUuid: string;
  isPriority: boolean;
  cities: Array<string>;
  bookedStaffCount: number;
  status: UCM.ProjectDetailType['statusName'];
}

export default function SummaryPanel({
  title,
  cities,
  status,
  company,
  endDate,
  startDate,
  projectId,
  slotsCount,
  isPriority,
  projectUuid,
  bookedStaffCount,
}: SummaryPanelProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { handleSuccess } = useMessageHandler();

  const { refetch: downloadProjectStaffPlan, isFetching: isDownloading } =
    useDownloadProjectStaffPlan({ projectId, projectUuid });

  const handleCopyLink = useCallback(() => {
    navigator.clipboard
      .writeText(
        `https://ucm.onelink.me/8k7Z/custom?af_dp=ucm://ucm.agency/projects/${projectUuid}`,
      )
      .then(() => handleSuccess(formatMessage(messages.projectLinkCopied)));
  }, [projectUuid]);

  const MenuOptions = useMemo(
    () => [
      {
        label: formatMessage(messages.downloadStaffPlanMenuLabel),
        onSelect: downloadProjectStaffPlan,
      },
      {
        label: formatMessage(messages.copyProjectLinkMenuLabel),
        onSelect: handleCopyLink,
      },
    ],
    [handleCopyLink],
  );

  const renderCityInfo = useMemo(() => {
    if (cities.length > 1) {
      return (
        <Tooltip
          arrow
          title={map(cities, (city) => (
            <div key={city}>{city}</div>
          ))}
        >
          <Grid
            container
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
          >
            <Typography fontWeight={600}>
              {formatMessage(messages.multipleCitiesLabel)}
            </Typography>
            <Styled.SmallInfoOutlinedIcon />
          </Grid>
        </Tooltip>
      );
    }

    return <Typography fontWeight={600}>{cities[0]}</Typography>;
  }, [cities]);

  return (
    <Grid container className={classes.container} p={2}>
      <Grid
        container
        size="auto"
        direction="column"
        justifyContent="space-between"
      >
        <ProjectSettingsForm />
        <MoreActions options={MenuOptions} loading={isDownloading} />
      </Grid>

      <Grid container size="grow" direction="column" spacing={2} px={2}>
        <Grid container direction="row" alignItems="center">
          <Typography fontSize={24} fontWeight={600}>
            {projectId} - {title}
          </Typography>
          {isPriority && <PriorityChip />}
          {status !== 'done' && <StatusChip status={status} />}
        </Grid>

        <Grid container alignItems="center" spacing={2}>
          <Grid>
            <SalesforceIcon />
          </Grid>
          <Grid>
            <Typography fontWeight={600}>{company}</Typography>
          </Grid>

          <Grid container>{renderCityInfo}</Grid>

          <Grid>
            <Typography fontWeight={600}>
              {[startDate, endDate].join(' - ')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid size="auto" container alignItems="center" justifyContent="flex-end">
        <Typography fontSize={32} fontWeight={600} color="success">
          {bookedStaffCount}/{slotsCount}
        </Typography>
      </Grid>
    </Grid>
  );
}
