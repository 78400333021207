import React, { useCallback, useRef, useState } from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper, { type PopperPlacementType } from '@mui/material/Popper';

const sxPopperContainer = { zIndex: 3 };
const popperContentStyle = (placement: PopperPlacementType) => ({
  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
});

export type MenuOptionType = {
  label: string;
  onSelect: (e: React.MouseEvent<HTMLLIElement>) => void;
};

interface DropdownSplitButtonProps {
  options: Array<MenuOptionType>;
  button?: () => React.ReactNode;
}

export default function DropdownSplitButton({
  button,
  options,
}: DropdownSplitButtonProps) {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  }, []);

  return (
    <>
      <ButtonGroup ref={anchorRef}>
        <Button
          size="small"
          aria-haspopup="menu"
          onClick={handleToggle}
          aria-label="select option"
          aria-expanded={open ? 'true' : undefined}
          aria-controls={open ? 'split-button-menu' : undefined}
        >
          <ArrowDropDownIcon />
        </Button>

        {button && button()}
      </ButtonGroup>

      <Popper
        transition
        open={open}
        disablePortal
        sx={sxPopperContainer}
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={popperContentStyle(placement)}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map(({ label, onSelect }) => (
                    <MenuItem
                      key={label}
                      onClick={(e) => {
                        onSelect(e);
                        setOpen(false);
                      }}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
