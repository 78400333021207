import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/styles';

import ConfirmModal from 'src/components/ConfirmModal';
import { useGetRejectReason } from 'src/pages/ProjectDetailsPage/api';

import messages from './messages';

export default function RejectReasonModal({
  type,
  modalLabel,
  buttonLabel,
  disabledButton,
  enabledFetch,
  isLoading,
  onConfirm,
  open,
  onClose,
}: UCM.AcceptRejectModalType) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [reason, setReason] = useState('');

  const { data: rejectReasons } = useGetRejectReason(
    { reason_for: type },
    { enabled: enabledFetch },
  );

  const handleSubmit = useCallback(async () => {
    if (typeof onConfirm == 'function') {
      onConfirm(reason as string);
    }
  }, [onConfirm, reason]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReason((event.target as HTMLInputElement).value);
    },
    [],
  );

  if (!rejectReasons) {
    return null;
  }

  return (
    <ConfirmModal
      open={open}
      onClose={onClose}
      disabled={!reason}
      title={modalLabel ?? ''}
      submitTextButton={formatMessage(messages.confirmButtonLabel)}
      width={theme.spacing(69)}
      onConfirm={handleSubmit}
      triggerButton={(handleOpen) => {
        return (
          buttonLabel && (
            <LoadingButton
              onClick={handleOpen}
              loading={isLoading}
              disabled={disabledButton}
              variant="contained"
            >
              {buttonLabel}
            </LoadingButton>
          )
        );
      }}
    >
      <Grid p={1.5}>
        <RadioGroup
          defaultValue=""
          name="radio-buttons-group"
          onChange={handleChange}
        >
          {rejectReasons.map((item) => {
            return (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            );
          })}
        </RadioGroup>
      </Grid>
    </ConfirmModal>
  );
}
