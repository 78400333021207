import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { forceString } from 'src/utils/datetime';
import sortedUniqBy from 'lodash/sortedUniqBy';
import startCase from 'lodash/startCase';
import compact from 'lodash/compact';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import last from 'lodash/last';
import flow from 'lodash/flow';
import map from 'lodash/map';
import moment, { Moment } from 'moment';

type Locale = string;

interface NumberTypingOptions {
  locale: Locale;
  grouping?: boolean;
  decimals?: number;
}

export function buildApplicationFilters(
  selectedDate: string | null,
  quickSearch: { [key: string]: unknown },
) {
  const dateFilter = selectedDate ? { shift_date: selectedDate } : undefined;
  return { filters: { ...dateFilter, ...quickSearch } };
}

interface SelectFieldOption {
  value: string | number;
  label: string;
}

export function toSelectFieldOptions<
  T extends { id: string | number; name?: string; code?: string },
>(array: T[]): SelectFieldOption[] {
  return map(array, (item) => ({
    value: item.id,
    label: startCase(item.name || item.code),
  }));
}

interface NumberSymbols {
  group: string;
  decimal: string;
}

export function getNumberSymbols(locale: Locale) {
  const numberWithGroupAndDecimalSymbol = 1000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSymbol)
    .reduce((init, part) => {
      const accum = init;
      accum[part.type as keyof NumberSymbols] = part.value;
      return accum;
    }, {} as NumberSymbols);
}

export function checkNumberTyping(
  value: string,
  {
    locale,
    grouping,
    decimals = 0,
  }: NumberTypingOptions = {} as NumberTypingOptions,
) {
  const { group: groupingSymbol, decimal: decimalSymbol } =
    getNumberSymbols(locale);
  const decimalsRegExp = `(\\${decimalSymbol}{${+!!decimals}}\\d{0,${decimals}})`;
  const groupingRegExp = `|([1-9]\\d{0,2}((\\${groupingSymbol}\\d{3})*\\${groupingSymbol}\\d{0,3}|(\\${groupingSymbol}\\d{3})*\\${groupingSymbol}\\d{3}${decimalsRegExp}))`;
  const regExp = new RegExp(
    `^-?((0?${decimalsRegExp}?|[1-9]\\d*${decimalsRegExp}?)${
      (grouping && groupingRegExp) || ''
    })$`,
  );
  return regExp.test(value);
}

export function checkCurrencyTyping(
  value: string,
  { locale, grouping }: NumberTypingOptions = {} as NumberTypingOptions,
) {
  return checkNumberTyping(value, { locale, grouping, decimals: 2 });
}

export function resolveTitle(
  maleTitle: string | null,
  femaleTitle: string | null,
  gender: number | string,
) {
  if (gender == 1 || gender === 'male') return maleTitle || femaleTitle;
  if (gender == 2 || gender === 'female') return femaleTitle || maleTitle;
  const title =
    maleTitle && femaleTitle && femaleTitle !== maleTitle
      ? `${maleTitle} / ${femaleTitle}`
      : maleTitle;
  return title || femaleTitle;
}

export function formatPhoneNumber(countryCode: string, phoneNumber: string) {
  let phoneNumberParsed: PhoneNumber | undefined;
  if (countryCode && phoneNumber) {
    phoneNumberParsed = parsePhoneNumberFromString(
      `+${countryCode}${phoneNumber}`,
    );
  } else if (countryCode) {
    phoneNumberParsed = parsePhoneNumberFromString(countryCode.toString());
  }
  if (phoneNumberParsed) return phoneNumberParsed.formatInternational();
  return [countryCode, phoneNumber].join(' ').trim();
}

export function toParsableNumber(number: string, locale: Locale) {
  const { group, decimal } = getNumberSymbols(locale);
  return number
    .trim()
    .replace(new RegExp(`\\${group}`, 'g'), '')
    .replace(new RegExp(`\\${decimal}`, 'g'), '.');
}

export function formatNumber(
  number: string | number | null,
  locale: Locale,
  useGrouping: boolean = false,
  decimals = 2,
) {
  if (
    (typeof number === 'string' && number.trim() !== '') ||
    number === 0 ||
    number
  )
    return (+number).toLocaleString(locale, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      useGrouping,
    });
  return '';
}

export function formatCurrency(
  number: string | number | null | undefined,
  currency: string,
  locale: Locale,
) {
  if (
    (typeof number === 'string' && number.trim() !== '') ||
    number ||
    number === 0
  )
    return (+number).toLocaleString(locale, { style: 'currency', currency });
  return '';
}

/* eslint-disable */
export function formatDateRange(
  ...dates: Array<Moment | string | Date | null>
) {
  return flow([
    compact,
    (days: any) => map(days, (day: any) => moment(day).startOf('day')),
    sortBy,
    (days: any) => [head(days), last(days)],
    (days: any) => sortedUniqBy(days, (day: any) => +day),
    (days: any) => map(days, (day: any) => forceString(day)),
    (days: any) => days.join(' - '),
  ])(dates);
}

export function formatDates(
  dates: Array<Moment | string | Date | null>,
): string {
  return flow([
    compact,
    (days: Array<Moment | string | Date>) =>
      map(days, (day) => moment(day).startOf('day')),
    sortBy,
    (days: Array<Moment>) => sortedUniqBy(days, (day) => +day),
    (days: Array<Moment>) =>
      reduce(
        days,
        (
          accum: [string[][], Moment | null],
          day: Moment,
        ): [string[][], Moment | null] => {
          const [dayRanges, prevDay] = accum;
          const daysDiff = prevDay ? day.diff(prevDay, 'days') : null;
          if (daysDiff === 1 && last(dayRanges)) {
            last(dayRanges)![1] = forceString(day);
          } else {
            dayRanges.push([forceString(day)]);
          }
          return [dayRanges, day];
        },
        [[], null],
      ),
    head,
    (dayRanges: string[][]) =>
      map(dayRanges, (dayRange) => dayRange.join(' - ')),
    (days: string[]) => days.join(', '),
  ])(dates);
}

export function formatAddress(
  venue: string | null,
  streetName: string | null,
  houseNumber: string | null,
  postCode: string | null,
  city: string | null,
) {
  const items = [
    venue,
    compact([streetName, venue || streetName ? houseNumber : null]).join(' '),
    compact([venue || streetName || city ? postCode : null, city]).join(' '),
  ];
  return compact(items).join(', ');
}

export const getFlagIcon = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

export const flagIcons = {
  en: getFlagIcon('gb'),
  fr: getFlagIcon('fr'),
  pl: getFlagIcon('pl'),
  pt: getFlagIcon('pt'),
  es: getFlagIcon('es'),
  gr: getFlagIcon('gr'),
  de: getFlagIcon('de'),
  tr: getFlagIcon('tr'),
  it: getFlagIcon('it'),
  ru: getFlagIcon('ru'),
  ae: getFlagIcon('ae'),
  cn: getFlagIcon('cn'),
  al: getFlagIcon('al'),
  bg: getFlagIcon('bg'),
  nl: getFlagIcon('nl'),
  ar: getFlagIcon('ae'),
  no: getFlagIcon('no'),
  jp: getFlagIcon('jp'),
  hr: getFlagIcon('hr'),
  se: getFlagIcon('se'),
  th: getFlagIcon('th'),
};
