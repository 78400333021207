import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapVerificationMethod = (
  option: UCM.VerificationMethodOptionRawType,
): UCM.VerificationMethodOptionType => ({
  label: option.name,
  value: option.code,
});

const getVerificationMethodOptions = async () => {
  const {
    data: { data },
  } = await getRequest<RequestDataArray<UCM.VerificationMethodOptionRawType>>(
    API.GET.verificationMethods,
  );

  return map(data, (option) => mapVerificationMethod(option.attributes));
};

export const useGetVerificationMethodOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.VERIFICATION_METHODS],
    queryFn: () => getVerificationMethodOptions(),
    ...config,
  });
};
