import { styled } from '@mui/material/styles';

// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { colors } from 'src/utils/customColors';
import { Button } from '@mui/material';

const buttonSize = 3.5;

export const Styled = {
  MiceButton: styled(Button)(({ theme }) => ({
    padding: 0,
    width: theme.spacing(buttonSize),
    height: theme.spacing(buttonSize),
    minWidth: theme.spacing(buttonSize),
    minHeight: theme.spacing(buttonSize),
    maxWidth: theme.spacing(buttonSize),
    maxHeight: theme.spacing(buttonSize),
    // width: '23px',
    // height: '20px',
    // borderRadius: '6px',
    border: `thin solid ${theme.palette.grey[300]}`,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // cursor: 'pointer',
    // '&:hover': {
    //   backgroundColor: theme.palette.grey[200],
    // },
    '&.active': {
      backgroundColor: colors.lighterBlue,
    },
    '&.MuiButton-colorError': {
      borderColor: theme.palette.error.main,
    },
  })),
  Info: styled(Typography)(({ theme }) => ({
    // color: colors.darkGrey,
    // fontSize: 8,
    // fontWeight: '400',
    // opacity: 0.38,
    position: 'absolute',
    top: theme.spacing(buttonSize),
  })),
};
