import { useRef } from 'react';

import styled from '@mui/material/styles/styled';
import Tabs, { type TabsProps } from '@mui/material/Tabs';

import type { DefaultTheme } from '@mui/styles';

const noIndicator = { style: { display: 'none' } };

const StyledCards = styled((props: TabsProps) => {
  const tabSx = useRef((theme: DefaultTheme) => ({
    borderRadius: 0,
    width: 'fit-content',
    minHeight: 'fit-content',
    backgroundColor: theme.palette.grey[300],
  })).current;

  return (
    <Tabs
      {...props}
      sx={tabSx}
      variant="scrollable"
      scrollButtons={false}
      TabIndicatorProps={noIndicator}
    />
  );
})({
  '& .MuiTabs-scroller': {
    height: '100%',
  },
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
});

export default StyledCards;
