import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { getRequest, serialize } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';

const mapJobDetail = (
  job: UCM.JobDetailRawType,
  jobId: string,
): UCM.BockupJobDetailType => ({
  autoTransfer: job.auto_transfer,
  bookingDeadlineDate: job.booking_deadline_date,
  description: job.description,
  firstShiftAt: job.first_shift_at,
  ignoreDesiredCities: job.ignore_user_desired_cities,
  isFlex: job.is_flex,
  isStandby: job.is_standby,
  isVisible: job.is_visible,
  jobTypeCode: { label: job.job_type.name, value: job.job_type.code },
  languageCodes: map(job.languages, (language) => ({
    value: language.code,
    label: language.name,
  })),
  lastShiftAt: job.last_shift_at,
  venue: job.location.name ?? '',
  houseNumber: job.location.house_number ?? '',
  address: job.location.additional_address ?? '',
  zipCode: job.location.post_code ?? '',
  parentWageBonus: job.parent_wage_bonus,
  price: job.price,
  priceBonus: job.price_bonus,
  staffTypeUuid: job.staff_type_uuid ?? '',
  statusName: job.status_name,
  quantity: job.slots_number?.toString(),
  taskCodes: job.tasks.map((task) => ({ value: task.code, label: task.name })),
  title: job.title,
  uuid: job.uuid,
  tariffCode: job.tariff_code,
  salary: job.wage,
  salaryBonus: job.wage_bonus,
  backupJobs: map(job.backup_jobs, (backupJob) => ({
    booked: backupJob.booked,
    firstDayOfWork: backupJob.first_day_of_work,
    id: backupJob.id,
    isVisible: backupJob.is_visible,
    lastDayOfWork: backupJob.last_day_of_work,
    slotsNumber: backupJob.slots_number,
    statusName: backupJob.status_name,
    title: backupJob.title,
    uuid: backupJob.uuid,
  })),
  position: job.position,
  standbyJob: job.standby_job
    ? {
        id: job.standby_job.id,
        uuid: job.standby_job.uuid,
        title: job.standby_job.title,
        booked: job.standby_job.booked,
        projectUuid: job.standby_job.project_uuid,
      }
    : null,
  id: jobId,
  location: {
    name: job.location.name,
    streetName: job.location.street_name,
    houseNumber: job.location.house_number,
    additionalAddress: job.location.additional_address,
    city: { value: job.location.city.code, label: job.location.city.name },
    zipCode: job.location.post_code,
    country: job.location.country,
  },
});

const getBackupJobDetails = async (jobUuid?: string) => {
  const withoutAttributesParam = {
    without_attributes: ['summary', 'agile_attributes'],
  };
  const params = serialize(withoutAttributesParam);

  const {
    data: { data },
  } = await getRequest<RequestData<UCM.JobDetailRawType>>(
    API.GET.getJob(jobUuid, `?${params.toString()}`),
  );

  return mapJobDetail(data.attributes, data.id);
};

export const useGetBackupJobDetails = (
  jobUuid?: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.BACKUP_JOB, jobUuid],
    queryFn: () => getBackupJobDetails(jobUuid),
    staleTime: Infinity,
    ...config,
  });
};
