import { useIntl } from 'react-intl';

import moment, { type Moment } from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import { forceString } from 'src/utils/datetime';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import type { RequestSimplifiedData } from 'src/types/requestData';

import messages from '../../messages';

export type ContractParamsType = {
  userUuid: string;
  userName: string;
  userEmail: string;
  userId: string;
  taskTypeCode: string;
  certificateCode: string;
  jobLocationCode: string;
  wagePerHour: string;
  jobTypeCode: string;
  monthlyHours: string;
  tariffGroupCode: string;
  customerCode: string;
  plannedEndDate: Moment | null;
  startDate: Moment | null;
  endDate: Moment | null;
  jobRequirementCode: string;
  unpaidLeave: string;
  latestStartDate: Moment | null;
  plannedStartDate: Moment | null;
  employmentTypeCode: string;
  maxDuration: string;
  weeklyHours: string;
  status: UCM.ContractStatusType;
};

const createContract = async (payload: ContractParamsType) => {
  const params = {
    user_contract: {
      planned_start_date: forceString(
        payload.plannedStartDate,
        moment.HTML5_FMT.DATE,
      ),
      latest_start_date: forceString(
        payload.latestStartDate,
        moment.HTML5_FMT.DATE,
      ),
      planned_end_date: forceString(
        payload.plannedEndDate,
        moment.HTML5_FMT.DATE,
      ),
      user_uuid: payload.userUuid,
      company_uuid: payload.customerCode,
      employment_type: payload.employmentTypeCode,
      tariff_group: payload.tariffGroupCode,
      unpaid_leave: payload.unpaidLeave,
      task_type: payload.taskTypeCode,
      job_type: payload.jobTypeCode,
      location: payload.jobLocationCode,
      requirement: payload.jobRequirementCode,
      monthly_hours: payload.monthlyHours,
      max_duration: payload.maxDuration,
      wage: payload.wagePerHour,
    },
  };

  await postRequest<RequestSimplifiedData>(API.POST.createContract, params, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
};

export const useCreateContract = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createContract,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.contractCreated), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.CONTRACTS] });
    },
    onError: handleError,
  });
};
