/*
 DataTable Messages
 *
 * This contains all the text for the DataTable component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.components.DataTable';

export default defineMessages({
  noDataMessage: {
    id: `${scope}.noDataMessage`,
    defaultMessage: 'No data available',
  },
});
