import { useCallback, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import map from 'lodash/map';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { locale } from 'src/config';
import {
  useGetJob,
  useDeleteJob,
  useGetDynamicFieldOptions,
} from 'src/pages/ProjectDetailsPage/api';
import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import { forceString } from 'src/utils/datetime';
import { formatNumber } from 'src/utils/standards';
import { buildHelperText } from 'src/utils/component';
import ConfirmModal from 'src/components/ConfirmModal';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import JobForm, {
  tariffOptions,
} from 'src/pages/ProjectDetailsPage/components/JobForm';
import { buildJobProps } from 'src/utils/tools';

import messages from './messages';

interface JobDetailsFormProps {
  jobUuid: string;
  projectUuid: string;
}

export default function JobDetailsForm({
  jobUuid,
  projectUuid,
}: JobDetailsFormProps) {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  const { mutate: deleteJob } = useDeleteJob();
  const { data: dynamicFieldOptions } = useGetDynamicFieldOptions();
  const { formatDynamicFieldValue, formatDynamicFieldHelpText } =
    useDynamicFieldHelpers(dynamicFieldOptions);
  const { data: jobDetails } = useGetJob(jobUuid, {
    enabled: open,
  });

  const handleJobDelete = useCallback(() => {
    deleteJob(jobUuid);
  }, [jobUuid]);

  return (
    <ConfirmModal
      width="700px"
      onOpen={() => setOpen(true)}
      confirmationRequired={false}
      title={formatMessage(messages.title)}
      triggerButton={(handleOpen) => (
        <IconButton onClick={handleOpen}>
          <SettingsOutlinedIcon />
        </IconButton>
      )}
    >
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography fontWeight="600" fontSize={20}>
            {formatMessage(messages.detailSectionTitle)}
          </Typography>
        </Grid>

        <Grid container size={12} spacing={3}>
          <Grid size={{ xs: 12, md: 3 }}>
            <TextInput
              label={formatMessage(messages.jobIdLabel)}
              value={jobDetails?.id}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={{ xs: 12, md: 9 }}>
            <TextInput
              label={formatMessage(messages.titleLabel)}
              value={jobDetails?.title}
              fullWidth
              disabled
              multiline
            />
          </Grid>

          <Grid size={4}>
            <TextInput
              label={formatMessage(messages.jobTypeLabel)}
              value={jobDetails?.jobTypeCode.label}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={4}>
            <TextInput
              label={formatMessage(messages.ignoreDesiredCitiesLabel)}
              value={
                jobDetails?.ignoreDesiredCities == null
                  ? ''
                  : jobDetails.ignoreDesiredCities
                    ? 'Yes'
                    : 'No'
              }
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={4}>
            <TextInput
              label={formatMessage(messages.bookingDeadlineDateLabel)}
              value={forceString(jobDetails?.bookingDeadlineDate)}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={12}>
            <TextInput
              label={formatMessage(messages.languagesLabel)}
              value={map(jobDetails?.languageCodes, (lang) => lang.label).join(
                ', ',
              )}
              multiline
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={12}>
            <TextInput
              label={formatMessage(messages.tasksLabel)}
              value={map(jobDetails?.taskCodes, (task) => task.label).join(
                ', ',
              )}
              fullWidth
              disabled
              multiline
            />
          </Grid>

          <Grid size={12}>
            <TextInput
              label={formatMessage(messages.descriptionLabel)}
              value={jobDetails?.description}
              fullWidth
              disabled
              multiline
            />
          </Grid>

          {jobDetails?.additionalAttributes.length ? (
            <Grid size={12}>
              <Card variant="outlined">
                <Typography color="grey.500" padding={2}>
                  {formatMessage(messages.additionalAttributesLabel)}
                </Typography>

                {map(jobDetails.additionalAttributes, (field) => (
                  <CardContent key={field.dynamicFieldUuid}>
                    <TextInput
                      label={field.dynamicFieldName}
                      value={formatDynamicFieldValue(field)}
                      helperText={formatDynamicFieldHelpText(field)}
                      fullWidth
                      disabled
                      multiline
                    />
                  </CardContent>
                ))}
              </Card>
            </Grid>
          ) : null}

          <Grid size={12}>
            <Typography fontWeight="600" fontSize={20}>
              {formatMessage(messages.salarySectionTitle)}
            </Typography>
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.salaryLabel)}
              value={jobDetails?.salary}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.salaryBonusLabel)}
              value={jobDetails?.salaryBonus}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.priceLabel)}
              value={jobDetails?.price}
              fullWidth
              disabled
            />
          </Grid>

          {/* <Grid size={6}>
            <TextInput
              label={formatMessage(messages.priceBonusLabel)}
              value={jobDetails?.priceBonus}
              fullWidth
              disabled
            />
          </Grid> */}

          <Grid size={6}>
            <Dropdown
              options={tariffOptions}
              value={jobDetails?.tariffCode ?? ''}
              placeholder={formatMessage(messages.tariffCodeLabel)}
              disabled
            />
          </Grid>

          <Grid size={12}>
            <Typography fontWeight="600" fontSize={20}>
              {formatMessage(messages.workAddressSectionTitle)}
            </Typography>
          </Grid>

          <Grid size={12}>
            <TextInput
              label={formatMessage(messages.venueLabel)}
              value={jobDetails?.venue}
              fullWidth
              disabled
              multiline
            />
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.addressLabel)}
              value={jobDetails?.address}
              fullWidth
              disabled
              multiline
            />
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.houseNumberLabel)}
              value={jobDetails?.houseNumber}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.cityLabel)}
              value={jobDetails?.location.city.label}
              fullWidth
              disabled
            />
          </Grid>

          <Grid size={6}>
            <TextInput
              label={formatMessage(messages.zipCodeLabel)}
              value={jobDetails?.zipCode}
              fullWidth
              disabled
            />
          </Grid>

          <Grid
            size={12}
            container
            spacing={0.5}
            display="flex"
            justifyContent="flex-end"
          >
            <ConfirmationDialog
              maxWidth="sm"
              onConfirm={handleJobDelete}
              message={formatMessage(messages.deleteJobConfirmMessage)}
            >
              {(handleOpen) => (
                <IconButton onClick={handleOpen}>
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              )}
            </ConfirmationDialog>

            <JobForm
              projectUuid={projectUuid}
              triggerButton={(handleOpen) => (
                <IconButton onClick={handleOpen}>
                  <EditOutlinedIcon />
                </IconButton>
              )}
              jobProps={buildJobProps(jobDetails)}
            />
          </Grid>
        </Grid>
      </Grid>
    </ConfirmModal>
  );
}

const useDynamicFieldHelpers = (
  dynamicFieldOptions:
    | Array<{
        label: string;
        value: string;
        dynamicField: UCM.DynamicFieldType;
      }>
    | undefined,
) => {
  const { formatMessage } = useIntl();

  // Memoize the dynamic field options for efficient lookup
  // Building a Dictionary where every dynamic option is added across it's uuid
  const hashDynamicFieldOptions = useMemo(
    () => keyBy(dynamicFieldOptions, 'value'),
    [dynamicFieldOptions],
  );

  // Format boolean field values
  // Only check for null/undefined and false should be associated with noLabel
  const formatBooleanField = (value?: boolean | null) => {
    return value != null
      ? formatMessage(value ? messages.yesLabel : messages.noLabel)
      : '';
  };

  // Format number field values
  const formatNumberField = (
    minimum?: string | null,
    maximum?: string | null,
  ) => {
    if (minimum && maximum) {
      return `${formatNumber(minimum, locale, true)} - ${formatNumber(
        maximum,
        locale,
        true,
      )}`;
    }
    if (minimum) {
      return `${formatNumber(minimum, locale, true)} (min)`;
    }
    if (maximum) {
      return `${formatNumber(maximum, locale, true)} (max)`;
    }
    return '-';
  };

  // Format date field values
  const formatDateField = (
    minimum?: string | null,
    maximum?: string | null,
  ) => {
    if (minimum && maximum) {
      return `${forceString(minimum)} - ${forceString(maximum)}`;
    }
    if (minimum) {
      return `${forceString(minimum)} (min)`;
    }
    if (maximum) {
      return `${forceString(maximum)} (max)`;
    }
    return '-';
  };

  // Format selectable field values
  const formatSelectableField = (
    options: UCM.JobDynamicFieldType['targets']['options'],
    fieldOptions: UCM.DynamicFieldType['options'],
  ) => {
    if (isEmpty(options)) {
      return '-';
    }

    return map(options, (option) => {
      const dynFieldOption = find(
        fieldOptions,
        (fieldOption) => fieldOption.value === option,
      );
      return dynFieldOption?.label;
    }).join(', ');
  };

  // Format dynamic field values based on their type
  const formatDynamicFieldValue = useCallback(
    (dynamicField: UCM.JobDynamicFieldType) => {
      const { value, minimum, maximum, options } = dynamicField.targets;
      const dynField = hashDynamicFieldOptions[dynamicField.dynamicFieldUuid];
      const fieldOptions = dynField.dynamicField.options;
      const fieldType = dynField.dynamicField.type.code;

      switch (fieldType) {
        case 'boolean':
          return formatBooleanField(value);
        case 'number':
          return formatNumberField(minimum, maximum);
        case 'date':
          return formatDateField(minimum, maximum);
        case 'single-selectable':
        case 'multi-selectable':
          return formatSelectableField(options, fieldOptions);
        default:
          return '-';
      }
    },
    [hashDynamicFieldOptions],
  );

  // Format dynamic field help text
  const formatDynamicFieldHelpText = useCallback(
    (dynamicField: UCM.JobDynamicFieldType) => {
      const dynField = hashDynamicFieldOptions[dynamicField.dynamicFieldUuid];
      const description = dynField.dynamicField.description;
      const helperText = !dynField.dynamicField.isVisibleByUser
        ? formatMessage(messages.notVisibleByUserLabel)
        : '';
      return buildHelperText(description, helperText);
    },
    [hashDynamicFieldOptions],
  );

  return {
    formatDynamicFieldValue,
    formatDynamicFieldHelpText,
  };
};
