import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { common } from '@mui/material/colors';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import DataTable from 'src/components/DataTable';
import {
  useGetJobApplications,
  useUpdateJobFavourite,
  useUpdateAcceptAllShifts,
  useUpdateRejectAllShifts,
} from 'src/pages/ProjectDetailsPage/api';
import { REJECT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetRejectReason';
import { ACCEPT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetAcceptReason';

import FavoriteButton from '../FavoriteButton/FavoriteButton';
import { useStyles } from '../../JobDetails.styled';
import messages from '../../messages';
import CheckButton from '../CheckButton/CheckButton';
import AcceptReasonModal from '../AcceptReasonModal/AcceptReasonModal';
import RejectReasonModal from '../RejectReasonModal/RejectReasonModal';
import StudentDetailsButton from '../StudentDetailsButton/StudentDetailsButton';
import { JobApplicationTabProps } from '../JobApplicationsAll/JobApplicationsAll';
import { DefaultTheme } from 'node_modules/@mui/styles/defaultTheme';

export default function JobApplicationsApplied({
  jobUuid,
  filters,
  candidate,
  handleSelectCandidate,
}: JobApplicationTabProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const {
    data: jobApplications,
    isLoading,
    refetch,
  } = useGetJobApplications({
    jobUuid,
    type: 'applied',
    filters,
    params,
    enabled: Boolean(jobUuid),
  });

  useEffect(() => {
    if (jobApplications?.data && candidate) {
      const userNotExist =
        jobApplications.data.findIndex(
          (item) => item.applicant.uuid === candidate?.applicant?.uuid,
        ) === -1;
      if (userNotExist) {
        handleSelectCandidate?.(null);
      }
    }
  }, [jobApplications?.data]);

  const { mutateAsync: acceptShifts, isPending: isPendingAccept } =
    useUpdateAcceptAllShifts();

  const { mutateAsync: rejectShifts, isPending: isPendingReject } =
    useUpdateRejectAllShifts();

  const { mutate: updateJobFavorite, isPending: isFavoritePending } =
    useUpdateJobFavourite();

  const [selected, setSelected] = useState<string[]>([]);
  const [studentDetails, setStudentDetails] = useState(false);

  const handleFavorite = useCallback(
    (favorite: boolean, uuid: string) => {
      if (isFavoritePending) return;

      void updateJobFavorite({ jobUuid, applicationUuid: uuid, favorite });
    },
    [jobUuid, isFavoritePending],
  );

  const handleAccept = useCallback(
    async (reason: string) => {
      const promises = selected.map((userUuid: string) =>
        acceptShifts({ jobUuid, userUuid, reason }),
      );
      await Promise.all(promises).then(() => {
        refetch();
        setSelected([]);
      });
    },
    [selected],
  );

  const handleReject = useCallback(
    async (reason: string) => {
      const promises = selected.map((userUuid: string) =>
        rejectShifts({ jobUuid, userUuid, reason }),
      );
      await Promise.all(promises).then(() => {
        refetch();
        setSelected([]);
      });
    },
    [selected, refetch],
  );

  const checkIfExist = useCallback(
    (uuid: string) => {
      return selected.findIndex((item) => item === uuid) !== -1;
    },
    [selected],
  );

  const isSelectAll = useMemo(() => {
    return (
      jobApplications?.data?.length !== 0 &&
      selected.length === jobApplications?.data?.length
    );
  }, [selected.length, jobApplications?.data?.length]);

  const handleSelectAll = useCallback(() => {
    if (isSelectAll) {
      setSelected([]);
    } else if (jobApplications?.data) {
      const ids = jobApplications.data?.map((item) => item.applicant.uuid);
      setSelected(ids);
    }
  }, [jobApplications?.data, isSelectAll]);

  const handleSelect = useCallback(
    (uuid: string) => {
      const exist = checkIfExist(uuid);
      let local = [...selected];
      if (exist) {
        const filtered = local.filter((item) => item !== uuid);
        local = filtered;
      } else {
        local.push(uuid);
      }
      setSelected(local);
    },
    [selected],
  );

  const pressCandidate = useCallback(
    (item: UCM.JobApplicationType | null) => {
      let candidate = (item as UCM.JobApplicationType | string) ?? null;

      if (studentDetails && item?.applicant) {
        candidate = item?.applicant?.uuid;
      }

      handleSelectCandidate?.(candidate);
    },
    [studentDetails, handleSelectCandidate],
  );

  const columns: Array<{
    id: keyof UCM.JobApplicationType | string;
    label: string | React.ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'applicant',
        label: (
          <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
            <CheckButton active={isSelectAll} handleChange={handleSelectAll} />
            <Typography
              variant="body1"
              fontWeight={(theme) => theme.typography.fontWeightMedium}
            >
              {formatMessage(messages.applicantLabel)}
            </Typography>
            <StudentDetailsButton
              value={studentDetails}
              handleChange={setStudentDetails}
            />
          </Stack>
        ),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant) return '-';
          const { id, fullName, uuid } = item.applicant;
          const isSelected = checkIfExist(uuid);
          return (
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <CheckButton
                active={isSelected}
                handleChange={() => handleSelect(uuid)}
              />
              <FavoriteButton
                handleChange={(active) => handleFavorite(active, item.uuid)}
                active={item.favoriteManager}
              />
              <Box>
                <Typography
                  variant="body1"
                  fontWeight={(theme) => theme.typography.fontWeightMedium}
                >
                  {`${id} ${fullName}`}
                </Typography>
              </Box>
            </Stack>
          );
        },
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'languages',
        label: formatMessage(messages.languagesColumnLabel),
        size: 'small',
        align: 'center',
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant?.languages) return '-';
          return (
            <Typography variant="inherit">
              {item.applicant.languages?.join(', ') || '-'}
            </Typography>
          );
        },
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'initialScore',
        label: formatMessage(messages.initialScoreColumnLabel),
        size: 'small',
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.initialRatingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.initialRatingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      {
        id: 'appliedCount',
        label: formatMessage(messages.appliedToLabel),
        render: (appliedCount: number) => (
          <Typography variant="inherit">{appliedCount ?? '-'}</Typography>
        ),
        align: 'center',
      },
      // {
      //   id: 'workday',
      //   label: formatMessage(messages.workdayLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">DD/MM/YYYY</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'cancelRate',
      //   label: formatMessage(messages.cancelRateLabel),
      //   render: () => <Typography variant="inherit">%</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'timeToConfirm',
      //   label: formatMessage(messages.timeToConfirmLabel),
      //   render: () => <Typography variant="inherit">{'-'}</Typography>,
      //   align: 'center',
      // },
      {
        id: 'ratingScore',
        label: formatMessage(messages.ratingLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.ratingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.ratingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      // {
      //   id: 'preScreen',
      //   label: formatMessage(messages.preScreenLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'profile',
      //   label: formatMessage(messages.profileLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">%</Typography>,
      //   align: 'center',
      // },
      {
        id: 'lastWorkInDays',
        label: formatMessage(messages.lastJobLabel),
        render: (lastWorkInDays: number | null) => (
          <Typography variant="inherit">
            {lastWorkInDays ? `${lastWorkInDays}d` : '-'}
          </Typography>
        ),
        align: 'center',
      },
      {
        id: 'daysSinceApplied',
        label: formatMessage(messages.daysSinceAppliedLabel),
        render: (daysSinceApplied: string) => (
          <Typography variant="inherit">{daysSinceApplied ?? '-'}</Typography>
        ),
        align: 'center',
      },
    ],
    [
      studentDetails,
      checkIfExist,
      handleSelect,
      handleFavorite,
      handleSelectAll,
    ],
  );

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const tableFooterSx = useCallback(
    (theme: DefaultTheme) => ({
      borderTop: `thin solid ${theme.palette.grey[200]}`,
    }),
    [],
  );

  return (
    <Grid size={{ xs: 12 }}>
      <DataTable
        rows={jobApplications?.data}
        columns={columns}
        className={classes.tableContainer}
        loading={isLoading}
        pressRow={pressCandidate}
        activeRow={candidate}
        checkActiveKey="applicant.id"
        onPaginate={handlePagination}
        pagination={jobApplications?.meta}
      />
      <Stack
        p={2}
        gap={1}
        flexDirection="row"
        justifyContent="flex-end"
        sx={tableFooterSx}
        bgcolor={common.white}
      >
        <AcceptReasonModal
          type={ACCEPT_REASON.ACCEPTANCE_APPLICATION}
          modalLabel={formatMessage(messages.acceptModalLabel)}
          buttonLabel={formatMessage(messages.confirmAcceptButton)}
          onConfirm={handleAccept}
          enabledFetch={Boolean(jobUuid)}
          isLoading={isPendingAccept}
          disabledButton={!selected.length || isPendingAccept}
        />
        <RejectReasonModal
          type={REJECT_REASON.REJECT_APPLICATION}
          modalLabel={formatMessage(messages.rejectModalLabel)}
          buttonLabel={formatMessage(messages.confirmRejectButton)}
          onConfirm={handleReject}
          enabledFetch={Boolean(jobUuid)}
          isLoading={isPendingReject}
          disabledButton={!selected.length || isPendingReject}
        />
      </Stack>
    </Grid>
  );
}
