import Grid from '@mui/material/Grid2';
import { SvgIconOwnProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { monthYearLongFormat, toMoment } from 'src/utils/datetime';

interface Props {
  months: string[];
  monthIndex: number;
  onNextClick: () => void;
  onPreviousClick: () => void;
}

export default function JobCalendarMonth({
  months,
  monthIndex,
  onNextClick,
  onPreviousClick,
}: Props) {
  const month = months?.[monthIndex];
  if (!month) return null;

  return (
    <Grid container size={12} p={0.75} alignItems={'center'}>
      <StyledButton
        icon={ChevronLeftIcon}
        onClick={onPreviousClick}
        disabled={monthIndex === 0}
      />

      <Typography variant="h5" px={1}>
        {toMoment(month)?.format(monthYearLongFormat)}
      </Typography>

      <StyledButton
        onClick={onNextClick}
        icon={ChevronRightIcon}
        disabled={monthIndex === months.length - 1}
      />
    </Grid>
  );
}

const StyledButton = ({
  onClick,
  disabled,
  icon: Icon,
}: {
  disabled?: boolean;
  onClick: () => void;
  icon: React.FC<SvgIconOwnProps>;
}) => {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <Icon color="primary" />
    </IconButton>
  );
};
