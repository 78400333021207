import { useCallback } from 'react';
import Box from '@mui/material/Box';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import Typography from '@mui/material/Typography';

interface Props {
  value: boolean;
  handleChange: (value: boolean) => void;
}

export default function StudentDetailsButton({ value, handleChange }: Props) {
  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();

      handleChange(!value);
    },
    [value, handleChange],
  );

  return (
    <Box
      onClick={handleClick}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={0.5}
      px={1}
      py={0.5}
      borderRadius={'17px'}
      bgcolor={value ? '#001E2B' : '#fff'}
      border={'1px solid'}
      borderColor={value ? '#001E2B' : '#BFC8CB'}
      sx={{
        cursor: 'pointer',
      }}
    >
      <AssignmentIndOutlinedIcon
        sx={{
          width: '18px',
          height: '18px',
          color: value ? '#fff' : '#001E2B',
        }}
      />
      <Typography
        fontSize={12}
        fontWeight={700}
        color={value ? '#fff' : '#001E2B'}
        sx={{
          opacity: value ? 1 : 0.72,
        }}
      >
        {value ? 'On' : 'Off'}
      </Typography>
    </Box>
  );
}
