/*
 * Projects Dashboard  Messages
 *
 * This contains all the text for the Evaluation Modal component.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.ProjectsPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Projects',
  },
  searchInputPlaceholder: {
    id: `${scope}.searchInputPlaceholder`,
    defaultMessage: 'Project Search',
  },
  multipleCitiesLabel: {
    id: `${scope}.multipleCitiesLabel`,
    defaultMessage: 'Multiple',
  },
  projectColumnLabel: {
    id: `${scope}.projectColumnLabel`,
    defaultMessage: 'Project',
  },
  customerColumnLabel: {
    id: `${scope}.customerColumnLabel`,
    defaultMessage: 'Customer',
  },
  locationsColumnLabel: {
    id: `${scope}.locationsColumnLabel`,
    defaultMessage: 'Location',
  },
  periodColumnLabel: {
    id: `${scope}.periodColumnLabel`,
    defaultMessage: 'Start/End Date',
  },
  bookingColumnLabel: {
    id: `${scope}.bookingColumnLabel`,
    defaultMessage: 'Booking',
  },
  statusColumnLabel: {
    id: `${scope}.statusColumnLabel`,
    defaultMessage: 'Status',
  },
});
