import { useQuery } from '@tanstack/react-query';

import moment from 'moment';

import {
  getRequest,
  paginationMapper,
  resolveQueryString,
} from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { forceString } from 'src/utils/datetime';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

export function mapJobCoverage(
  job: UCM.JobCoverageRawType,
  jobId: string,
): UCM.JobCoverageType {
  return {
    id: jobId,
    uuid: job.uuid,
    title: job.title,
    project: {
      id: job.project.id,
      uuid: job.project.uuid,
      name: job.project.name,
    },
    slotsNumber: job.slots_number,
    bookedCount: job.booked_count,
    shiftDates: job.shift_dates.map((date) =>
      forceString(date, moment.HTML5_FMT.DATE),
    ),
    compensation: {
      wage: job.compensation.wage,
      nightWageBonus: job.compensation.night_wage_bonus,
      sundayWageBonus: job.compensation.sunday_wage_bonus,
      holidayWageBonus: job.compensation.holiday_wage_bonus,
    },
    firstShiftAt: forceString(job.first_shift_at, moment.HTML5_FMT.DATE),
    lastShiftAt: forceString(job.last_shift_at, moment.HTML5_FMT.DATE),
  };
}

const getJobCoverages = async (
  jobUuid: string,
  params: Record<string, string | number>,
  filters: Array<Array<{ value: string; name: string }>> = [],
) => {
  const query = resolveQueryString(filters, params);

  const {
    data: { data, meta },
  } = await getRequest<
    RequestDataArray<UCM.JobCoverageRawType, UCM.JobCoverageMetaRawType>
  >(`${API.GET.getJobCoverages(jobUuid)}${query}`);

  return {
    meta: {
      ...paginationMapper(meta),
      transferBonus: meta.transfer_bonus,
      selectedJobUuids: meta.selected_job_uuids,
    },
    data: data.map(({ attributes, id }) => mapJobCoverage(attributes, id)),
  };
};

export const useGetJobCoverages = (
  jobUuid: string,
  params: Record<string, string | number>,
  filters: Array<{ value: string; name: string }> = [],
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.JOB_COVERAGES, jobUuid, filters, params],
    queryFn: () => getJobCoverages(jobUuid, params, [filters]),
    staleTime: 1000 * 60 * 10,
    ...config,
  });
};
