import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { delay } from 'src/utils/component';
import { postRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

export interface JobFieldType {
  fieldType: string;
  fieldUuid: string;
  targets: Record<string, boolean | Array<string> | string> | undefined;
}

interface JobFieldParamsType {
  jobUuid: string;
  fields: Array<JobFieldType>;
}

const createJobFields = async ({ fields, jobUuid }: JobFieldParamsType) => {
  const results: RequestSimplifiedData[] = [];

  await fields.reduce(
    (prevPromise, { fieldUuid, targets }) =>
      prevPromise.then(async () => {
        const params = { dynamic_field: { uuid: fieldUuid, targets } };
        const { data } = await postRequest<RequestSimplifiedData>(
          API.POST.createJobFields(jobUuid),
          params,
        );
        results.push(data);
        await delay(500);
      }),
    Promise.resolve(),
  );

  return results;
};

export const useCreateJobFields = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createJobFields,
    onError: handleError,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.jobSaved), 1000);

      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOB] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
    },
  });
};
