import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, serialize } from 'src/utils/request';

// eslint-disable-next-line
const getFieldsCategory = async (query?: Record<any, any>) => {
  let params = '';
  if (query) {
    params = serialize(query, 'filters').toString();
  }

  const { data } = await getRequest(API.GET.getFieldsCategory(params));

  return mapFieldsCategory(data?.data);
};

export const useGetFieldsCategory = ({
  query,
  enabled = true,
}: {
  query?: Record<any, any>; // eslint-disable-line
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FIELDS_CATEGORY, query],
    queryFn: () => getFieldsCategory(query),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

const mapFieldsCategory = (
  data: {
    attributes: {
      name_de: string;
      name_en: string;
      position: number;
      uuid: string;
    };
    id: string;
  }[],
): { value: string; label: string }[] => {
  if (!data) return [];

  return data.map(({ attributes }) => ({
    value: attributes.uuid,
    label: attributes.name_en,
  }));
};
