/*
 * LongTermContractForm Messages
 *
 * This contains all the text for the LongTermContractForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ContractsPage.components.LongTermContractForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Long Term Contract',
  },
  openButton: {
    id: `${scope}.openButton`,
    defaultMessage: 'Create new contract',
  },
  createButton: {
    id: `${scope}.createButton`,
    defaultMessage: 'Create Contract',
  },
  saveButton: {
    id: `${scope}.saveButton`,
    defaultMessage: 'Save Contract',
  },
  userNotFound: {
    id: `${scope}.userNotFound`,
    defaultMessage: 'User not found',
  },
});
