import { useCallback } from 'react';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import {
  MobileTimePicker,
  MobileTimePickerSlotProps,
} from '@mui/x-date-pickers/MobileTimePicker';
import { TimeView } from '@mui/x-date-pickers';
import { type Moment } from 'moment';

import { timeFormat } from 'src/utils/datetime';

interface TimeInputProps {
  name: string;
  label?: string;
  helperText?: string;
  value: Moment | null;
  onChange: (newValue: Moment | null) => void;
  withPicker?: boolean;
  slotProps?: MobileTimePickerSlotProps<Moment, TimeView, boolean>;
}

const timeFieldSx = { input: { textAlign: 'center' } };

export default function TimeInput({
  name,
  label,
  value,
  onChange,
  helperText,
  withPicker,
  slotProps,
}: TimeInputProps) {
  const options = {
    name,
    value,
    format: timeFormat,
    onChange,
  };

  const preventEventBubbling = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  if (withPicker) {
    return (
      <div onClick={preventEventBubbling}>
        <MobileTimePicker
          ampm={false}
          label={label}
          slotProps={slotProps}
          {...options}
        />
      </div>
    );
  }

  return (
    <div onClick={preventEventBubbling}>
      <TimeField
        variant="standard"
        helperText={helperText}
        sx={timeFieldSx}
        {...options}
      />
    </div>
  );
}
