import React, { useMemo } from 'react';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';

interface Props {
  label: string | React.ReactNode;
  children?: React.ReactNode;
}

const StyledCard = styled((props: Props) => {
  const memoizedProps = useMemo(() => {
    const { children, ...tab } = props;

    return { children, tab };
  }, [props]);

  return (
    <Tab
      disableRipple
      {...memoizedProps.tab}
      label={
        <Stack
          width="100%"
          height="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>{props.label}</Box>
          {memoizedProps?.children}
        </Stack>
      }
    />
  );
})(({ theme }) => ({
  margin: 0,
  width: theme.spacing(20),
  padding: theme.spacing(1),
  color: theme.palette.grey[700],
  borderRight: `thin solid ${theme.palette.grey[400]}`,
  borderBottom: `thin solid ${theme.palette.grey[200]}`,
  '&.Mui-selected': {
    border: 'none',
    borderRadius: 0,
    color: theme.palette.primary,
    backgroundColor: theme.palette.common.white,
    borderRight: `thin solid ${theme.palette.grey[400]}`,
  },
}));

export default StyledCard;
