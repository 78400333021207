import { useCallback, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { common } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  useUpdateUserRating,
  useGetRatingScore,
  useUpdateJobFavourite,
  useGetUserRatings,
  useGetJobApplications,
} from 'src/pages/ProjectDetailsPage/api';
import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import DataTable from 'src/components/DataTable';
import { PencilAdornment } from 'src/utils/components';
import ContractTooltip from 'src/pages/ProjectDetailsPage/components/ContractTooltip';
import FavoriteButton from 'src/pages/ProjectDetailsPage/components/JobDetails/components/FavoriteButton/FavoriteButton';
import { JobApplicationTabProps } from 'src/pages/ProjectDetailsPage/components/JobDetails/components/JobApplicationsAll/JobApplicationsAll';

import messages from '../../messages';
import { useStyles } from '../../JobDetails.styled';
import { JobRatingType } from 'src/types/Job/Job';
import { DefaultTheme } from 'node_modules/@mui/styles/defaultTheme';

interface RatingRef {
  [id: string]: {
    score?: number | string | null;
    comment?: string | null;
    applicationUuid: string;
    userRatingUuid?: string;
  };
}

interface RatingCellProps {
  userRating: RatingRef;
  item: UCM.JobApplicationType;
  fetchedRating: UCM.JobUserRatingType | null;
  ratingScore: Array<JobRatingType> | undefined;
  onRatingChange: (
    event: SelectChangeEvent<unknown>,
    item: UCM.JobApplicationType,
    key: 'score' | 'comment',
    user?: UCM.JobUserRatingType | null,
  ) => void;
}
const RatingCell = ({
  item,
  userRating,
  ratingScore,
  fetchedRating,
  onRatingChange,
}: RatingCellProps) => {
  const value =
    userRating[item.applicant.uuid]?.score ??
    (fetchedRating?.userRating?.score ||
      Math.round(item.applicant.ratingScore));

  return (
    <Dropdown
      fullWidth={false}
      name="ratingScore"
      options={ratingScore}
      value={value.toString()}
      onChange={(event) => onRatingChange(event, item, 'score', fetchedRating)}
    />
  );
};

interface CommentCellProps {
  placeholder: string;
  userRating: RatingRef;
  item: UCM.JobApplicationType;
  fetchedRating: UCM.JobUserRatingType | null;
  onRatingChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: UCM.JobApplicationType,
    key: 'score' | 'comment',
    user?: UCM.JobUserRatingType | null,
  ) => void;
}

const CommentCell = ({
  item,
  userRating,
  placeholder,
  fetchedRating,
  onRatingChange,
}: CommentCellProps) => {
  const value =
    userRating[item.applicant.uuid]?.comment ??
    (fetchedRating?.userRating?.comment || '');

  return (
    <TextInput
      name="comment"
      value={value}
      placeholder={placeholder}
      onChange={(event) =>
        onRatingChange(event, item, 'comment', fetchedRating)
      }
      EndIcon={PencilAdornment}
      margin="none"
      size="small"
      sx={{ '& input::placeholder': { fontSize: '14px' } }}
    />
  );
};

export default function JobApplicationsDone({
  jobUuid,
  filters,
}: JobApplicationTabProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [userRating, setUserRating] = useState<RatingRef>({});
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: jobApplications, isLoading } = useGetJobApplications({
    jobUuid,
    type: 'done',
    filters,
    params,
    enabled: Boolean(jobUuid),
  });

  const { data: userRatingData, refetch: refetchUserRating } =
    useGetUserRatings({
      jobUuid,
      enabled: Boolean(jobUuid),
    });

  const { mutate: updateJobFavorite, isPending: isFavoritePending } =
    useUpdateJobFavourite();
  const { data: ratingScore } = useGetRatingScore({});

  // TODO: fix this
  // BE needs to be updated to support bulk rating upload
  // We need to do it like this so we wait for all ratings to be created
  const { mutateAsync: createRating, isPending: isRatingPending } =
    useUpdateUserRating();

  const handleFavorite = useCallback(
    (favorite: boolean, uuid: string) => {
      if (isFavoritePending) return;
      void updateJobFavorite({ jobUuid, applicationUuid: uuid, favorite });
    },
    [jobUuid, isFavoritePending],
  );
  const handleSave = useCallback(async () => {
    const applicationKeys = Object.keys(userRating);
    if (applicationKeys.length === 0 || isRatingPending) return;

    const promises = Object.entries(userRating).map(([_, value]) => {
      return createRating({
        jobUuid,
        applicationUuid: value.applicationUuid,
        comment: value?.comment ?? null,
        score: value?.score ? Number(value.score) : null,
        userRatingUuid: value.userRatingUuid,
      });
    });

    await Promise.all(promises).then(async () => {
      await refetchUserRating();
      setUserRating({});
    });
  }, [userRating, isRatingPending]);

  const findUserData = useCallback(
    (uuid: string) =>
      userRatingData?.find((item) => item.applicant.uuid === uuid) ?? null,
    [userRatingData],
  );

  const handleRating = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      item: UCM.JobApplicationType,
      key: 'score' | 'comment',
      user?: UCM.JobUserRatingType | null,
    ) => {
      const userUuid = item.applicant.uuid;
      const { value } = event.target as { value: string };
      const fetchedRatingUser = findUserData(item.applicant.uuid);
      const existRating = fetchedRatingUser?.userRating;
      const localData = userRating[userUuid] ?? {};

      const dataToSave = {
        ...localData,
        applicationUuid: item.uuid,
        score:
          key === 'score' ? value : (localData.score ?? existRating?.score),
        comment:
          key === 'comment'
            ? value
            : (localData.comment ?? existRating?.comment),
        ...(user && { userRatingUuid: user.userRating.uuid }),
      };

      setUserRating((prev) => ({ ...prev, [userUuid]: dataToSave }));
    },
    [userRating, findUserData],
  );

  const columns: Array<{
    id: keyof UCM.JobApplicationType | string;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 'applicant',
        label: formatMessage(messages.applicantLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant) return '-';
          const { id, fullName } = item.applicant;
          return (
            <FavoriteButton
              text={`${id} - ${fullName}`}
              active={item.favoriteManager}
              handleChange={(active) => handleFavorite(active, item.uuid)}
            />
          );
        },
      },
      // {
      //   id: 'preScreen',
      //   label: formatMessage(messages.preScreenLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'checkIn',
      //   label: formatMessage(messages.checkInLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'checkOut',
      //   label: formatMessage(messages.checkOutLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      {
        id: 'contractSummary',
        align: 'center',
        label: formatMessage(messages.contractSummaryLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.contractSummary.status) return '-';
          return <ContractTooltip contract={item?.contractSummary} />;
        },
      },
      {
        id: 'comment',
        label: formatMessage(messages.commentLabel),
        render: (_: Object, item: UCM.JobApplicationType) => (
          <CommentCell
            item={item}
            userRating={userRating}
            onRatingChange={handleRating}
            fetchedRating={findUserData(item.applicant.uuid)}
            placeholder={formatMessage(messages.commentField)}
          />
        ),
        align: 'center',
      },
      {
        id: 'ratingScore',
        label: formatMessage(messages.scoreLabel),
        render: (_: Object, item: UCM.JobApplicationType) => (
          <RatingCell
            item={item}
            userRating={userRating}
            ratingScore={ratingScore}
            onRatingChange={handleRating}
            fetchedRating={findUserData(item.applicant.uuid)}
          />
        ),
        align: 'center',
        width: '100px',
      },
    ],
    [ratingScore, handleRating, findUserData, handleFavorite, userRating],
  );

  const disabled = useMemo(
    () => isRatingPending || Object.keys(userRating).length === 0,
    [isRatingPending, userRating],
  );

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const tableFooterSx = useCallback(
    (theme: DefaultTheme) => ({
      borderTop: `thin solid ${theme.palette.grey[200]}`,
    }),
    [],
  );

  return (
    <Grid size={12}>
      <DataTable
        rows={jobApplications?.data}
        columns={columns}
        loading={isLoading}
        className={classes.tableContainer}
        onPaginate={handlePagination}
        pagination={jobApplications?.meta}
      />
      <Stack
        p={2}
        alignItems="flex-end"
        bgcolor={common.white}
        sx={tableFooterSx}
      >
        <LoadingButton
          onClick={handleSave}
          loading={isRatingPending}
          disabled={disabled}
          variant="contained"
        >
          {formatMessage(messages.saveRatingButton)}
        </LoadingButton>
      </Stack>
    </Grid>
  );
}
