import { defineMessages } from 'react-intl';

export const scope = 'src.components.DatePickerField';

export default defineMessages({
  invalidDate: {
    id: `${scope}.invalidDate`,
    defaultMessage: 'Invalid',
  },
  daysLabel: {
    id: `${scope}.daysLabel`,
    defaultMessage: 'days',
  },
  dayLabel: {
    id: `${scope}.dayLabel`,
    defaultMessage: 'day',
  },
});
