import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  MutableRefObject,
} from 'react';

import moment from 'moment';
import { FormikErrors, useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { array, boolean, date, number, object, string } from 'yup';

import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import flow from 'lodash/flow';
import map from 'lodash/map';
import hashValues from 'lodash/values';

import Button from '@mui/material/Button';

import { locale } from 'src/config';
import {
  useCreateJob,
  useGetStaffType,
  useGetTaskOptions,
  useCreateStaffType,
  useUpdateStaffType,
  useDeleteStaffType,
  useGetLangueOptions,
  useGetJobTypeOptions,
  useGetStaffTypeOptions,
  useGetDynamicFieldOptions,
  useUpdateJob,
  JobParamsType,
  DynamicOptionType,
} from 'src/pages/ProjectDetailsPage/api';
import { removeNullValues } from 'src/utils/component';
import globalMessages from 'src/messages';
import DropdownSplitButton, {
  type MenuOptionType,
} from 'src/components/DropdownSplitButton';
import { forceString, toMoment } from 'src/utils/datetime';
import ConfirmModal from 'src/components/ConfirmModal';
import { useGetCityOptions } from 'src/pages/GatekeeperPage/api';
import { formatNumber, toParsableNumber } from 'src/utils/standards';

import messages from './messages';
import JobEditPanel from './components/JobEditPanel';

import type { AutocompleteChangeReason } from '@mui/material';

export const tariffOptions = [
  { label: 'EG 1', value: 'eg_1', tariff: 14.53 },
  { label: 'EG 2a', value: 'eg_2a', tariff: 14.85 },
];

const DEFAULT_SALARY = 14.53;

const ValidationMode = { NONE: 0, JOB: 1, STAFF_TYPE: 2 };

export type FieldValueType = Record<
  string,
  Record<string, boolean | Array<string> | string | null>
>;

interface JobFormProps {
  isCreate?: boolean;
  isCopy?: boolean;
  projectUuid: string;
  jobProps?:
    | (JobParamsType & { isFlex: boolean; isStandby: boolean; uuid: string })
    | null;
  isOpen?: boolean;
  triggerButton?: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
  onClose?: () => void;
}

export default function JobForm({
  isCreate,
  isCopy,
  jobProps,
  projectUuid,
  isOpen,
  triggerButton,
  onClose,
}: JobFormProps) {
  const { formatMessage } = useIntl();

  const [selectedJobType, setSelectedJobType] = useState({
    isFlex: false,
    isStandby: false,
  });
  const [open, setOpen] = useState(isOpen);
  const [jobFieldValues, setJobFieldValues] = useState<FieldValueType>({});
  const [validateOnChange, setValidateOnChange] = useState(ValidationMode.NONE);
  const [jobFieldsToShow, setJobFieldsToShow] = useState<
    Record<string, Array<UCM.DynamicFieldType>>
  >({});

  const { data: taskOptions } = useGetTaskOptions();
  const { data: cityOptions } = useGetCityOptions();
  const { data: languageOptions } = useGetLangueOptions();
  const { data: jobTypeOptions } = useGetJobTypeOptions();
  const { mutate: createStaffType } = useCreateStaffType();
  const { mutate: updateStaffType } = useUpdateStaffType();
  const { mutate: deleteStaffType } = useDeleteStaffType();
  const { data: staffTypeOptions } = useGetStaffTypeOptions();
  const { data: dynamicFieldOptions } = useGetDynamicFieldOptions();
  const { mutate: createJob, isPending: isCreating } = useCreateJob();
  const { mutate: updateJob, isPending: isUpdating } = useUpdateJob();

  const staffTypeInputRef = useRef('');
  const jobFieldsToRemove = useRef<Record<string, Record<'fieldUuid', string>>>(
    {},
  );
  const jobFieldsToCreate = useRef<
    Record<string, { fieldType: UCM.Fields; fieldUuid: string }>
  >({});

  const initialValues: JobParamsType = useMemo(
    () => ({
      venue: '',
      address: '',
      zipCode: '',
      quantity: '',
      cityCode: '',
      tariffCode: '',
      houseNumber: '',
      bookingDeadlineDate: null,
      title: '',
      ignoreDesiredCities: false,
      taskCodes: [],
      jobTypeCode: '',
      description: '',
      priceBonus: formatNumber(0, locale),
      salaryBonus: formatNumber(0, locale),
      languageCodes: [],
      staffTypeUuid: '',
      salary: formatNumber(DEFAULT_SALARY, locale),
      price: formatNumber(0, locale),
      additionalAttributes: [],
    }),
    [],
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: validateOnChange !== ValidationMode.NONE,
    validate: ({
      price,
      salary,
      taskCodes,
      priceBonus,
      tariffCode,
      description,
      salaryBonus,
    }) => {
      const errors: {
        salary?: string;
        taskCodes?: string;
        description?: string;
      } = {};
      const minSalary = minimumSalary(tariffCode);

      if (description?.trim() && compact(description.split(' ')).length < 10) {
        errors.description = formatMessage(messages.minWordsCount, { min: 10 });
      }

      validateMinValue(errors, 'price', price, 0);
      validateMinValue(errors, 'priceBonus', priceBonus, 0);
      validateMinValue(errors, 'salaryBonus', salaryBonus, 0);

      if (parseInt(salary) < minSalary) {
        validateMinValue(errors, 'salary', salary, minSalary);
      }

      if (taskCodes.length === 0) {
        errors.taskCodes = formatMessage(globalMessages.requiredField);
      }

      return errors;
    },
    validationSchema: object().shape({
      ignoreDesiredCities: boolean(),
      priceBonus: string().required(),
      salaryBonus: string().required(),
      jobTypeCode: string().required(),
      taskCodes: array().of(string()).required(),
      ...(validateOnChange === ValidationMode.STAFF_TYPE
        ? staffTypeSchema(selectedJobType.isFlex, staffTypeInputRef)
        : jobSchema(selectedJobType.isFlex)),
    }),
    onSubmit: () => {},
  });

  const validateMinValue = useRef(
    (
      errors: FormikErrors<JobParamsType> = {},
      field: keyof JobParamsType,
      value: string,
      min: number,
      formattedMin?: string,
    ) => {
      if (value.trim()) {
        const parsedValue = parseFloat(toParsableNumber(value, locale));
        if (Number.isNaN(parsedValue)) {
          errors[field] = formatMessage(globalMessages.notTypeField, {
            type: 'number',
          });
        } else if (parsedValue < min) {
          errors[field] = formatMessage(globalMessages.minValueField, {
            min: formattedMin || min,
          });
        }
      }
      return errors;
    },
  ).current;

  const { values, errors, isValid } = formik;

  const { data: staffType } = useGetStaffType(values.staffTypeUuid, {
    enabled: !!values.staffTypeUuid,
  });
  const hashDynamicFieldOptions = useMemo(
    () => keyBy(dynamicFieldOptions, 'value'),
    [dynamicFieldOptions],
  );

  const findDynamicField = useCallback(
    (dynamicFieldUuid: string) => {
      const dynamicFieldOption = hashDynamicFieldOptions[dynamicFieldUuid];
      return dynamicFieldOption?.dynamicField;
    },
    [hashDynamicFieldOptions],
  );

  useEffect(() => {
    // Edit mode: Load jobProps initially whenever the form opens
    if (!isCreate && jobProps && open) {
      formik.setValues((prevValues) => ({ ...prevValues, ...jobProps }));

      setSelectedJobType({
        isFlex: jobProps.isFlex,
        isStandby: jobProps.isStandby,
      });
    }
  }, [open, isCreate, jobProps]);

  useEffect(() => {
    // If staffType has changed in create/edit mode we update formik accordingly
    if (staffType && jobProps?.staffTypeUuid !== values.staffTypeUuid) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...removeNullValues(staffType),
      }));
    }
  }, [staffType, values.staffTypeUuid, jobProps?.staffTypeUuid]);

  useEffect(() => {
    if (isCreate || isEmpty(dynamicFieldOptions)) return;

    const jobDynFieldsToShow = compact(
      map(values.additionalAttributes, (jobDynField) =>
        findDynamicField(jobDynField.dynamicFieldUuid),
      ),
    );
    setJobFieldsToShow(groupBy(jobDynFieldsToShow, 'category.name'));
  }, [isCreate, values.additionalAttributes, findDynamicField]);

  useEffect(() => {
    if (isCreate) return;

    setJobFieldValues(
      flow([
        (jobDynFields) => keyBy(jobDynFields, 'dynamicFieldUuid'),
        (jobDynFields) =>
          mapValues(jobDynFields, (jobDynField) => {
            const {
              targets,
              dynamicFieldUuid,
              targets: { minimum, maximum },
            } = jobDynField;
            const dynamicField = findDynamicField(dynamicFieldUuid);
            if (dynamicField && dynamicField.type.code === 'date')
              return {
                startDate: toMoment(minimum, moment.HTML5_FMT.DATE),
                endDate: toMoment(maximum, moment.HTML5_FMT.DATE),
              };
            if (dynamicField && dynamicField.type.code === 'number')
              return {
                minimum: formatNumber(minimum, locale, false),
                maximum: formatNumber(maximum, locale, false),
              };
            return targets;
          }),
      ])(values.additionalAttributes),
    );
  }, [isCreate, values.additionalAttributes, findDynamicField]);

  const handleClose = useCallback(() => {
    formik.resetForm();
    setValidateOnChange(ValidationMode.NONE);
    setSelectedJobType({ isFlex: false, isStandby: false });
    setJobFieldValues({});
    jobFieldsToCreate.current = {};
    jobFieldsToRemove.current = {};
    setJobFieldsToShow({});
    setOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  const handleStaffTypeDelete = useCallback(() => {
    if (!values.staffTypeUuid) return;

    deleteStaffType(values.staffTypeUuid, {
      onSettled: () => {
        formik.setFieldValue('staffTypeUuid', '', false);
        staffTypeInputRef.current = '';
      },
    });
  }, [values.staffTypeUuid]);

  const hashDynamicFields = useMemo(
    () => keyBy(values.additionalAttributes, 'dynamicFieldUuid'),
    [values.additionalAttributes],
  );

  const handleFieldSelect = useCallback(
    (fieldUuid: string, reason: AutocompleteChangeReason) => {
      setJobFieldsToShow((prevJobFields) => {
        const foundCategoryFields = findCategoryFields(
          hashDynamicFieldOptions,
          prevJobFields,
          fieldUuid,
        );

        const persisted = !!hashDynamicFields[fieldUuid];

        switch (reason) {
          case 'selectOption':
            if (!persisted) {
              const dynamicField = findDynamicField(fieldUuid);
              jobFieldsToCreate.current[fieldUuid] = {
                fieldType: dynamicField.type.code,
                fieldUuid,
              };
            }
            return addToCategoryFields(prevJobFields, foundCategoryFields);

          case 'removeOption':
            if (persisted) {
              jobFieldsToRemove.current[fieldUuid] = { fieldUuid };
            }
            setJobFieldValues((prevValues) => {
              const newValues = { ...prevValues };
              delete newValues[fieldUuid];
              return newValues;
            });
            delete jobFieldsToCreate.current[fieldUuid];
            return deleteFromCategoryFields(prevJobFields, foundCategoryFields);

          case 'clear':
            if (persisted) {
              jobFieldsToRemove.current[fieldUuid] = { fieldUuid };
            }
            jobFieldsToCreate.current = {};
            setJobFieldsToShow({});
            setJobFieldValues({});
            return {};

          default:
            return {};
        }
      });
    },
    [hashDynamicFieldOptions, findDynamicField, hashDynamicFields],
  );

  const handleFieldChange = useCallback(
    (
      field: UCM.DynamicFieldType,
      value: Record<string, boolean | Array<string> | string | null>,
    ) => {
      const persisted = !!hashDynamicFields[field.uuid];
      if (persisted) {
        jobFieldsToRemove.current[field.uuid] = { fieldUuid: field.uuid };
      }
      jobFieldsToCreate.current[field.uuid] = {
        fieldType: field.type.code,
        fieldUuid: field.uuid,
      };
      setJobFieldValues((prev) => ({
        ...prev,
        [field.uuid]: {
          ...prev[field.uuid], // Preserve existing properties if any
          ...value, // Update only the relevant field with new values
        },
      }));
    },
    [hashDynamicFields],
  );

  const handleStaffTypeSave = useCallback(() => {
    setValidateOnChange(ValidationMode.STAFF_TYPE);

    setTimeout(async () => {
      const errors = await formik.validateForm();
      const isValid = isEmpty(errors);

      if (!isValid) return;

      const params = {
        ...values,
        uuid: values.staffTypeUuid,
        name: staffTypeInputRef.current,
      };

      if (!values.staffTypeUuid) {
        createStaffType(params, {
          onSettled: (data) =>
            formik.setFieldValue('staffTypeUuid', data?.data.uuid),
        });
      } else {
        updateStaffType(params);
      }
      setValidateOnChange(ValidationMode.NONE);
    }, 0);
  }, [values]);

  const handleSubmit = useCallback(async () => {
    setValidateOnChange(ValidationMode.JOB);

    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) return false;

    const params = {
      isFlex: selectedJobType.isFlex,
      fields: mergeJobFieldsWithValues(
        jobFieldsToCreate.current,
        jobFieldValues,
      ),
      isStandby: selectedJobType.isStandby,
    };

    const fieldsToRemove = hashValues(jobFieldsToRemove.current);
    const formattedValues = {
      ...values,
      salaryBonus: toParsableNumber(values.salaryBonus, locale),
      priceBonus: toParsableNumber(values.priceBonus, locale),
      salary: toParsableNumber(values.salary, locale),
      price: toParsableNumber(values.price, locale),
    };

    return new Promise<boolean>((resolve) => {
      const mutationOptions = {
        onSuccess: () => {
          handleClose();
          resolve(true);
        },
        onError: () => resolve(false),
      };

      if (isCreate || isCopy) {
        return createJob(
          { ...formattedValues, ...params, projectUuid },
          mutationOptions,
        );
      }
      return updateJob(
        {
          ...formattedValues,
          ...params,
          fieldsToRemove,
          jobUuid: jobProps?.uuid ?? '',
        },
        mutationOptions,
      );
    });
  }, [
    values,
    isCreate,
    isCopy,
    projectUuid,
    jobProps?.uuid,
    jobFieldValues,
    selectedJobType,
  ]);

  return (
    <ConfirmModal
      width="700px"
      onClose={handleClose}
      open={isOpen}
      onOpen={() => setOpen(true)}
      loading={isCreating || isUpdating}
      onConfirm={handleSubmit}
      disabled={!isValid || isCreating || isUpdating}
      confirmationRequired={false}
      title={
        isCopy
          ? formatMessage(messages.copyTitle, { title: values.title })
          : formatMessage(messages.title)
      }
      triggerButton={
        triggerButton ||
        ((handleOpen) => {
          const menuOptions: Array<MenuOptionType> = useMemo(
            () => [
              {
                label: formatMessage(messages.standbyJobMenuLabel),
                onSelect: (e) => {
                  setSelectedJobType({ isFlex: true, isStandby: true });
                  handleOpen(e);
                },
              },
              {
                label: formatMessage(messages.flexDateJobMenuLabel),
                onSelect: (e) => {
                  setSelectedJobType({ isFlex: true, isStandby: false });
                  handleOpen(e);
                },
              },
            ],
            [],
          );

          return (
            <DropdownSplitButton
              options={menuOptions}
              button={() => (
                <Button onClick={handleOpen}>
                  {formatMessage(messages.createJobButton)}
                </Button>
              )}
            />
          );
        })
      }
    >
      <JobEditPanel
        errors={errors}
        values={values}
        isFlex={selectedJobType.isFlex}
        isStandby={selectedJobType.isStandby}
        staffTypeInputRef={staffTypeInputRef}
        jobFieldsToShow={jobFieldsToShow}
        tariffOptions={tariffOptions}
        cityOptions={cityOptions ?? []}
        taskOptions={taskOptions ?? []}
        jobTypeOptions={jobTypeOptions ?? []}
        languageOptions={languageOptions ?? []}
        staffTypeOptions={staffTypeOptions ?? []}
        dynamicFieldOptions={dynamicFieldOptions ?? []}
        jobFieldValues={jobFieldValues}
        onChange={formik.handleChange}
        onFieldSelect={handleFieldSelect}
        onValueChange={formik.setFieldValue}
        onStaffTypeSave={handleStaffTypeSave}
        onStaffTypeDelete={handleStaffTypeDelete}
        onFieldChange={handleFieldChange}
      />
    </ConfirmModal>
  );
}

function staffTypeSchema(
  isFlex: boolean,
  staffInputRef: MutableRefObject<string>,
) {
  const quantity = isFlex
    ? number().nullable().integer().min(1)
    : number().nullable();

  return {
    quantity,
    title: string().nullable(),
    price: string().required(),
    salary: string().required(),
    cityCode: string().nullable(),
    description: string().nullable(),
    staffTypeUuid: string().when('staffInputRef', (_, schema) => {
      return staffInputRef.current.trim()
        ? schema.nullable()
        : schema.required();
    }),
    bookingDeadlineDate: date().nullable(),
  };
}

function jobSchema(isFlex: boolean) {
  const quantity = isFlex
    ? number().nullable()
    : number().required().integer().min(1);

  return {
    quantity,
    title: string().required(),
    price: string().required(),
    salary: string().required(),
    cityCode: string().required(),
    tariffCode: string().required(),
    description: string().required(),
    bookingDeadlineDate: date().required(),
  };
}

function minimumSalary(tariffCode: string) {
  return (
    find(tariffOptions, (option) => option.value === tariffCode)?.tariff ||
    DEFAULT_SALARY
  );
}

function findCategoryFields(
  hashDynamicFieldOptions: Record<string, DynamicOptionType>,
  prevJobFields: Record<string, Array<UCM.DynamicFieldType>>,
  fieldUuid: string,
) {
  if (!hashDynamicFieldOptions) return null;
  let selectedDynamicField = hashDynamicFieldOptions[fieldUuid] as any; // eslint-disable-line
  if (!selectedDynamicField) return null;
  selectedDynamicField = selectedDynamicField.dynamicField;
  if (!selectedDynamicField) return null;
  const categoryFields = prevJobFields[selectedDynamicField.category.name];
  return { selectedDynamicField, categoryFields };
}

function addToCategoryFields(
  prevJobFields: Record<string, Array<UCM.DynamicFieldType>>,
  foundCategoryFields: any, // eslint-disable-line
) {
  if (!foundCategoryFields) return prevJobFields;
  const { selectedDynamicField, categoryFields } = foundCategoryFields;
  if (!categoryFields) {
    prevJobFields[selectedDynamicField.category.name] = [selectedDynamicField];
    return { ...prevJobFields };
  }
  const dynamicField = find(
    categoryFields,
    (field) => field.id === selectedDynamicField.id,
  );
  if (dynamicField) return prevJobFields;
  categoryFields.push(selectedDynamicField);
  return { ...prevJobFields };
}

function deleteFromCategoryFields(
  prevJobFields: Record<string, Array<UCM.DynamicFieldType>>,
  foundCategoryFields: any, // eslint-disable-line
) {
  if (!foundCategoryFields) return prevJobFields;
  const { selectedDynamicField, categoryFields } = foundCategoryFields;
  if (!categoryFields) return prevJobFields;
  const dynamicFieldIndex = categoryFields.findIndex(
    (field: any) => field.id === selectedDynamicField.id, // eslint-disable-line
  );
  if (dynamicFieldIndex === -1) return prevJobFields;
  categoryFields.splice(dynamicFieldIndex, 1);
  if (categoryFields.length === 0)
    delete prevJobFields[selectedDynamicField.category.name];
  return { ...prevJobFields };
}

function mergeJobFieldsWithValues(
  jobFields: Record<string, { fieldType: UCM.Fields; fieldUuid: string }>,
  jobFieldValues: FieldValueType,
) {
  return Object.entries(jobFields).map(([_, field]) => {
    const targets = jobFieldValues[field.fieldUuid] || {};
    let processedTargets = {};
    if ('startDate' in targets && 'endDate' in targets) {
      processedTargets = {
        minimum: forceString(
          targets.startDate as string,
          moment.HTML5_FMT.DATE,
        ),
        maximum: forceString(targets.endDate as string, moment.HTML5_FMT.DATE),
      };
    } else if ('minimum' in targets && 'maximum' in targets) {
      processedTargets = {
        minimum: toParsableNumber(targets.minimum as string, locale),
        maximum: toParsableNumber(targets.maximum as string, locale),
      };
    } else {
      processedTargets = targets;
    }
    return { ...field, targets: processedTargets };
  });
}
