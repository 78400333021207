import React from 'react';

export default function CoverageIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 20 20"
      fill="rgba(0, 0, 0, 0.54)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.33329 17.5C2.87496 17.5 2.4826 17.3368 2.15621 17.0104C1.82982 16.684 1.66663 16.2917 1.66663 15.8333V4.16667C1.66663 3.70833 1.82982 3.31597 2.15621 2.98958C2.4826 2.66319 2.87496 2.5 3.33329 2.5H16.6666C17.125 2.5 17.5173 2.66319 17.8437 2.98958C18.1701 3.31597 18.3333 3.70833 18.3333 4.16667V15.8333C18.3333 16.2917 18.1701 16.684 17.8437 17.0104C17.5173 17.3368 17.125 17.5 16.6666 17.5H3.33329ZM3.33329 15.8333H16.6666V4.16667H3.33329V15.8333ZM4.16663 14.1667H8.33329V12.5H4.16663V14.1667ZM12.125 12.5L16.25 8.375L15.0625 7.1875L12.125 10.1458L10.9375 8.95833L9.77079 10.1458L12.125 12.5ZM4.16663 10.8333H8.33329V9.16667H4.16663V10.8333ZM4.16663 7.5H8.33329V5.83333H4.16663V7.5Z" />
    </svg>
  );
}
