import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapEmploymentTypeOptions = (
  option: UCM.ContractEmploymentTypeOptionRawType,
): UCM.ContractEmploymentTypeOptionType => ({
  value: option.code,
  label: option.name,
});

const getEmploymentTypeOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.ContractEmploymentTypeOptionRawType>
  >(API.GET.contractEmploymentTypeOptions, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
  return data.data.map(({ attributes }) =>
    mapEmploymentTypeOptions(attributes),
  );
};

export const useGetEmploymentTypeOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACT_EMPLOYMENT_TYPE_OPTIONS],
    queryFn: getEmploymentTypeOptions,
    staleTime: 1000 * 60 * 10,
    ...config,
  });
};
