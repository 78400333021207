import Chip from '@mui/material/Chip';
import styled from '@mui/material/styles/styled';

const StyledChip = styled(Chip)<{ status: string }>(({ theme, status }) => {
  let color;

  if (status === 'draft') {
    color = theme.palette.warning.main;
  } else if (status === 'cancelled') {
    color = theme.palette.error.main;
  } else {
    color = theme.palette.success.main;
  }

  return {
    color: color,
    borderColor: color,
    borderRadius: theme.spacing(0.5),
  };
});

interface Props {
  status: UCM.ProjectType['statusName'];
}

export default function StatusChip({ status }: Props) {
  return (
    <StyledChip
      size="small"
      label={status}
      status={status}
      variant="outlined"
    />
  );
}
