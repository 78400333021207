import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { common } from '@mui/material/colors';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';

import { REJECT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetRejectReason';
import DataTable from 'src/components/DataTable';
import {
  useGetJobApplications,
  useUpdateRejectShift,
} from 'src/pages/ProjectDetailsPage/api';

import { useStyles } from '../../JobDetails.styled';
import messages from '../../messages';
import RejectReasonModal from '../RejectReasonModal/RejectReasonModal';
import { JobApplicationTabProps } from '../JobApplicationsAll/JobApplicationsAll';
import { DefaultTheme } from 'node_modules/@mui/system';

export default function JobApplicationsAccepted({
  job,
  jobUuid,
  filters,
  candidate,
  handleSelectCandidate,
}: JobApplicationTabProps) {
  const { formatMessage } = useIntl();

  const classes = useStyles();

  const [selected, setSelected] = useState<UCM.JobApplicationType | null>(null);
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: jobApplications, isLoading } = useGetJobApplications({
    jobUuid,
    type: 'accepted',
    filters,
    params,
    enabled: Boolean(jobUuid),
  });

  useEffect(() => {
    if (jobApplications?.data && candidate) {
      const userNotExist =
        jobApplications.data.findIndex(
          (item) => item.applicant.uuid === candidate?.applicant?.uuid,
        ) === -1;
      if (userNotExist) {
        handleSelectCandidate?.(null);
      }
    }
  }, [jobApplications?.data]);

  const { mutate: rejectShift, isPending: isPendingReject } =
    useUpdateRejectShift();

  const handleReject = useCallback(
    (reason: string) => {
      if (selected?.uuid) {
        rejectShift(
          {
            jobUuid,
            applicationUuid: selected?.uuid,
            reason,
            type: 'cancel',
          },
          {
            onSettled: () => {
              setSelected(null);
            },
          },
        );
      }
    },
    [selected?.uuid, jobUuid],
  );

  const pressCandidate = useCallback(
    (item: UCM.JobApplicationType | null) => {
      let candidate = (item as UCM.JobApplicationType | string) ?? null;

      if (!job?.isFlex) {
        return setSelected(item);
      }

      handleSelectCandidate?.(candidate);
    },
    [job?.isFlex, handleSelectCandidate],
  );

  const columns: Array<{
    id: keyof UCM.JobApplicationType | string;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 'applicant',
        label: formatMessage(messages.applicantLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant) return '-';
          const { id, fullName } = item.applicant;
          return (
            <Typography
              variant="body1"
              fontWeight={(theme) => theme.typography.fontWeightMedium}
            >
              {`${id} ${fullName}`}
            </Typography>
          );
        },
      },
      {
        id: 'appliedCount',
        align: 'center',
        label: formatMessage(messages.appliedToLabel),
        render: (appliedCount: number) => (
          <Typography variant="inherit">{appliedCount ?? '-'}</Typography>
        ),
      },
      // {
      //   id: 'workday',
      //   label: formatMessage(messages.workdayLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">DD/MM/YYYY</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'cancelRate',
      //   label: formatMessage(messages.cancelRateLabel),
      //   render: () => <Typography variant="inherit">%</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'timeToConfirm',
      //   align: 'center',
      //   label: formatMessage(messages.timeToConfirmLabel),
      //   render: () => <Typography variant="inherit">{'-'}</Typography>,
      // },
      {
        id: 'logIn',
        align: 'center',
        label: formatMessage(messages.logInLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.lastLoginAt)
            return (
              <CloseOutlinedIcon
                sx={{ color: '#904A44', width: 24, height: 24 }}
              />
            );
          return (
            <Typography variant="inherit">
              {item?.applicant.lastLoginAt}
            </Typography>
          );
        },
      },
      {
        id: 'ratingScore',
        align: 'center',
        label: formatMessage(messages.ratingLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.ratingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.ratingScore}
            </Typography>
          );
        },
      },
      // {
      //   id: 'preScreen',
      //   align: 'center',
      //   label: formatMessage(messages.preScreenLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      // },
      // {
      //   id: 'profile',
      //   align: 'center',
      //   label: formatMessage(messages.profileLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">%</Typography>,
      // },
      {
        id: 'lastWorkInDays',
        label: formatMessage(messages.lastJobLabel),
        render: (lastWorkInDays: number | null) => (
          <Typography variant="inherit">
            {lastWorkInDays ? `${lastWorkInDays}d` : '-'}
          </Typography>
        ),
        align: 'center',
      },
      // {
      //   id: 'daysSinceAccepted',
      //   align: 'center',
      //   label: formatMessage(messages.daysSinceAcceptedLabel),
      //   render: (daysSinceAccepted: string) => (
      //     <Typography variant="inherit">{'-'}</Typography>
      //   ),
      // },
    ],
    [],
  );

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const tableFooterSx = useCallback(
    (theme: DefaultTheme) => ({
      borderTop: `thin solid ${theme.palette.grey[200]}`,
    }),
    [],
  );

  return (
    <Grid size={{ xs: 12 }}>
      <DataTable
        columns={columns}
        rows={jobApplications?.data}
        className={classes.tableContainer}
        loading={isLoading}
        activeRow={job?.isFlex ? candidate : selected}
        pressRow={pressCandidate}
        checkActiveKey="applicant.id"
        onPaginate={handlePagination}
        pagination={jobApplications?.meta}
      />
      {!job?.isFlex && (
        <Stack
          p={2}
          gap={1}
          sx={tableFooterSx}
          flexDirection="row"
          bgcolor={common.white}
          justifyContent="flex-end"
        >
          <RejectReasonModal
            type={REJECT_REASON.CANCEL_APPLICATION}
            modalLabel={formatMessage(messages.rejectModalLabel)}
            buttonLabel={formatMessage(messages.rejectButton)}
            onConfirm={handleReject}
            enabledFetch={Boolean(jobUuid)}
            disabledButton={!selected || isPendingReject}
            isLoading={isPendingReject}
          />
        </Stack>
      )}
    </Grid>
  );
}
