/*
 * Contracts Dashboard  Messages
 *
 * This contains all the text for the Contracts dashboard.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.ContractsPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Contracts',
  },
  student: {
    id: `${scope}.student`,
    defaultMessage: 'Student',
  },
  jobId: {
    id: `${scope}.jobId`,
    defaultMessage: 'Job ID',
  },
  contractId: {
    id: `${scope}.contractId`,
    defaultMessage: 'Contract ID',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End Date',
  },
  daysLeft: {
    id: `${scope}.daysLeft`,
    defaultMessage: 'Days Left',
  },
  signingMethod: {
    id: `${scope}.signingMethod`,
    defaultMessage: 'Signing Method',
  },
  studentSigned: {
    id: `${scope}.studentSigned`,
    defaultMessage: 'Student Signed',
  },
  ucmSigned: {
    id: `${scope}.ucmSigned`,
    defaultMessage: 'UCM Signed',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  signatureStatus: {
    id: `${scope}.signatureStatus`,
    defaultMessage: 'Signature Status',
  },
  archiveId: {
    id: `${scope}.archiveId`,
    defaultMessage: 'Archive ID',
  },
  projectType: {
    id: `${scope}.projectType`,
    defaultMessage: 'Project Type',
  },
  contractSaved: {
    id: `${scope}.contractSaved`,
    defaultMessage: 'Contract saved successfully',
  },
  editTooltip: {
    id: `${scope}.editTooltip`,
    defaultMessage: 'Edit',
  },
  downloadTooltip: {
    id: `${scope}.downloadTooltip`,
    defaultMessage: 'Download',
  },
  deleteTooltip: {
    id: `${scope}.deleteTooltip`,
    defaultMessage: 'Delete',
  },
  contractDownloaded: {
    id: `${scope}.contractDownloaded`,
    defaultMessage: 'Contract downloaded successfully',
  },
  contractDeleted: {
    id: `${scope}.contractDeleted`,
    defaultMessage: 'Contract deleted successfully',
  },
  deleteContractConfirmMessage: {
    id: `${scope}.deleteContractConfirmMessage`,
    defaultMessage: 'Are you sure you want to delete this contract?',
  },
  searchUserIdPlaceholder: {
    id: `${scope}.searchUserIdPlaceholder`,
    defaultMessage: 'User ID',
  },
  searchJobIdPlaceholder: {
    id: `${scope}.searchJobIdPlaceholder`,
    defaultMessage: 'Job ID',
  },
  searchProjectIdPlaceholder: {
    id: `${scope}.searchProjectIdPlaceholder`,
    defaultMessage: 'Project ID',
  },
  longTerm: {
    id: `${scope}.longTerm`,
    defaultMessage: 'Long Term',
  },
  shortTerm: {
    id: `${scope}.shortTerm`,
    defaultMessage: 'Short Term',
  },
  contractCreated: {
    id: `${scope}.contractCreated`,
    defaultMessage: 'Contract created successfully',
  },
  contractActivated: {
    id: `${scope}.contractActivated`,
    defaultMessage: 'Contract activated successfully',
  },
});
