import styled from '@mui/material/styles/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Styled = {
  SmallInfoOutlinedIcon: styled(InfoOutlinedIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: theme.typography.h6.fontSize,
  })),
  JobTitle: styled('div')`
    cursor: pointer;
    padding: 16px 0;
  `,
};
