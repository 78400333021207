import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import DataTable from 'src/components/DataTable';
import { forceString, forceTimeString, toTimeString } from 'src/utils/datetime';

import {
  useGetDailyBriefing,
  DailyBriefingsFilters,
  TimeScopeType,
} from '../../api/queries/useGetDailyBriefing';
import { useUpdateBriefingAssignUsers } from '../../api';
import { useDownloadPositionJobDates } from '../../api/queries/useDownloadPositionJobDates';
import { useImportPositionJobDates } from '../../api/mutations/useImportPositionJobDates';
import DailyBriefingModal from './components/DailyBriefingModal/DailyBriefingModal';
import ErrorModal from 'src/components/ErrorModal/ErrorModal';
import { ErrorType } from 'src/utils/hooks/useMessageHandler';

import { Styled } from './index.styled';
import messages from './messages';

interface DailyBriefingsProps {
  projectUuid: string;
}

type Error = ErrorType | null;

const TABS = [
  { label: 'All', value: '' },
  { label: 'Draft', value: 'future' },
  { label: 'Active', value: 'active' },
  { label: 'Past', value: 'past' },
];

export default function DailyBriefings({ projectUuid }: DailyBriefingsProps) {
  const { formatMessage } = useIntl();

  const importField = useRef<HTMLInputElement | null>(null);

  const [filters, setFilters] = useState<DailyBriefingsFilters>({
    project_uuid: projectUuid,
    time_scope: '',
  });
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: briefings, isPending } = useGetDailyBriefing({
    filters,
    params,
    enabled: Boolean(projectUuid),
  });

  const { refetch: downloadFile, isFetching: isDownloading } =
    useDownloadPositionJobDates(filters);

  const onClearFileField = useCallback(() => {
    if (importField.current) importField.current.value = '';
  }, []);

  const {
    mutate: importFile,
    isPending: isUploading,
    error,
  } = useImportPositionJobDates({ onSuccess: onClearFileField });

  const { mutate: assigneUsers, isPending: isAssigningUsers } =
    useUpdateBriefingAssignUsers();

  const handleDownload = useCallback(() => {
    void downloadFile();
  }, []);

  const handleAssignUsers = useCallback(() => {
    void assigneUsers(filters);
  }, [filters]);

  const handleUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        importFile({ file });
      }
    },
    [projectUuid],
  );

  const columns: Array<{
    id: keyof UCM.DailyBriefingType | string;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 'id',
        label: formatMessage(messages.pjdidLabel),
        render: (id: string) => (
          <Typography variant="inherit">{id ?? '-'}</Typography>
        ),
      },
      {
        id: 'startsAt',
        label: formatMessage(messages.shiftStartLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.jobDate)
            return <Typography variant="inherit">-</Typography>;
          const { startsAt } = item.jobDate;
          return (
            <Typography variant="inherit">
              {forceTimeString(startsAt)}
            </Typography>
          );
        },
      },
      {
        id: 'endsAt',
        label: formatMessage(messages.shiftEndLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.jobDate)
            return <Typography variant="inherit">-</Typography>;
          const { endsAt } = item.jobDate;
          return (
            <Typography variant="inherit">{forceTimeString(endsAt)}</Typography>
          );
        },
      },
      {
        id: 'meetingTime',
        label: formatMessage(messages.meetingTimeLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.briefing?.meetingTime)
            return <Typography variant="inherit">-</Typography>;
          const { meetingTime } = item.briefing;
          return (
            <Typography variant="inherit">
              {toTimeString(moment.utc(meetingTime))}
            </Typography>
          );
        },
      },
      {
        id: 'jobId',
        label: formatMessage(messages.jobLabel),
        render: (jobId: number) => {
          return <Typography variant="inherit">{jobId ?? '-'}</Typography>;
        },
      },
      {
        id: 'date',
        label: formatMessage(messages.dateLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.jobDate)
            return <Typography variant="inherit">-</Typography>;
          const { startsAt } = item.jobDate;
          return (
            <Typography variant="inherit">{forceString(startsAt)}</Typography>
          );
        },
      },
      {
        id: 'status',
        label: formatMessage(messages.statusLabel),
        render: (status: string) => {
          return <Typography variant="inherit">{status ?? '-'}</Typography>;
        },
      },
      {
        id: 'userId',
        label: formatMessage(messages.uidLabel),
        render: (userId: number | null) => (
          <Typography variant="inherit">{userId ?? '-'}</Typography>
        ),
      },
      {
        id: 'contactPerson',
        label: formatMessage(messages.shiftContactPersonLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.briefing)
            return <Typography variant="inherit">-</Typography>;
          const { contactPerson } = item.briefing;
          return (
            <Typography variant="inherit">
              {contactPerson?.name ?? '-'}
            </Typography>
          );
        },
      },
      {
        id: 'phoneNumber',
        label: formatMessage(messages.shiftContactNumberLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.briefing)
            return <Typography variant="inherit">-</Typography>;
          const { contactPerson } = item.briefing;
          return (
            <Typography variant="inherit">
              {contactPerson?.phoneNumber ?? '-'}
            </Typography>
          );
        },
      },
      {
        id: 'meetingPoint',
        label: formatMessage(messages.meetingPointLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.briefing)
            return <Typography variant="inherit">-</Typography>;
          const { meetingPoint } = item.briefing;
          return (
            <Typography variant="inherit">{meetingPoint ?? '-'}</Typography>
          );
        },
      },
      {
        id: 'instruction',
        label: formatMessage(messages.specialInstructionLabel),
        render: (_: object, item: UCM.DailyBriefingType) => {
          if (!item.briefing)
            return <Typography variant="inherit">-</Typography>;
          const { instruction } = item.briefing;
          return (
            <Typography variant="inherit">{instruction ?? '-'}</Typography>
          );
        },
      },
      {
        id: 'actions',
        label: '',
        width: 0,
        render: (_: object, item: UCM.DailyBriefingType) => {
          return <DailyBriefingModal job={item} />;
        },
      },
    ],
    [],
  );

  const handleChangeTimeScope = useCallback(
    (item: TimeScopeType) => {
      if (item !== filters?.time_scope) {
        setFilters((prevState) => ({ ...prevState, time_scope: item }));
        setParams({ page: 1 });
      }
    },
    [filters],
  );

  const Toolbar = useMemo(() => {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flex={1}
      >
        <Grid container spacing={1} gap={4}>
          {TABS.map(({ value, label }) => {
            const isActive = value === filters.time_scope;
            return (
              <Box
                key={value}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleChangeTimeScope(value)}
              >
                <Typography
                  variant="h5"
                  color={isActive ? 'primary' : 'textDisabled'}
                  sx={{ textDecoration: isActive ? 'underline' : 'none' }}
                >
                  {label}
                </Typography>
              </Box>
            );
          })}
        </Grid>
        <Grid container spacing={1}>
          <Button
            color="secondary"
            onClick={handleAssignUsers}
            startIcon={<GroupOutlinedIcon color="inherit" />}
            sx={{ borderRadius: '100px', fontWeight: '500' }}
            disabled={
              briefings?.meta?.unassignedUsersCount === 0 || isAssigningUsers
            }
          >
            {formatMessage(messages.assignStudentButton, {
              amount: briefings?.meta?.unassignedUsersCount ?? 0,
            })}
          </Button>
        </Grid>
        <Grid container spacing={1}>
          <Button
            color="secondary"
            onClick={handleDownload}
            startIcon={<FileDownloadOutlinedIcon color="inherit" />}
            sx={{ borderRadius: '100px', fontWeight: '500' }}
            disabled={isDownloading}
          >
            {formatMessage(messages.downloadButton)}
          </Button>
          <Button
            color="secondary"
            component="label"
            startIcon={<FileUploadOutlinedIcon color="inherit" />}
            sx={{ borderRadius: '100px', fontWeight: '500' }}
            disabled={isUploading}
          >
            {formatMessage(messages.uploadButton)}
            <Styled.VisuallyHiddenInput
              ref={importField}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleUpload}
              multiple
            />
          </Button>
        </Grid>
      </Grid>
    );
  }, [filters, briefings?.meta, isDownloading, isUploading, isAssigningUsers]);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const onConfirm = useCallback(() => {
    if (importField.current?.files) {
      const file = importField.current?.files[0];
      importFile({ file, forced: true });
    }
  }, []);

  return (
    <>
      <DataTable
        columns={columns}
        rows={briefings?.data}
        pagination={briefings?.meta}
        onPaginate={handlePagination}
        toolbar={Toolbar}
        loading={isPending}
      />
      <ErrorModal
        errorResponse={error as Error}
        onCancel={onClearFileField}
        onConfirm={onConfirm}
      />
    </>
  );
}
