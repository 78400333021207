/*
 * JobFormEditPanel Messages
 *
 * This contains all the text for the JobFormEditPanel.
 */
import { defineMessages } from 'react-intl';

export const scope = 'src.pages.ProjectDetailsPage.components.JobForm';

export default defineMessages({
  titleLabel: {
    id: `${scope}.titleLabel`,
    defaultMessage: 'Title',
  },
  detailSectionTitle: {
    id: `${scope}.detailSectionTitle`,
    defaultMessage: 'Job Detail',
  },
  staffTypeLabel: {
    id: `${scope}.staffTypeLabel`,
    defaultMessage: 'Template',
  },
  jobTypeLabel: {
    id: `${scope}.jobTypeLabel`,
    defaultMessage: 'Job Type',
  },
  ignoreDesiredCitiesLabel: {
    id: `${scope}.ignoreDesiredCitiesLabel`,
    defaultMessage: 'Ignore cities preference',
  },
  tasksLabel: {
    id: `${scope}.tasksLabel`,
    defaultMessage: 'Tasks',
  },
  languagesLabel: {
    id: `${scope}.languagesLabel`,
    defaultMessage: 'Languages',
  },
  quantityLabel: {
    id: `${scope}.quantityLabel`,
    defaultMessage: 'Quantity',
  },
  bookingDeadlineDateLabel: {
    id: `${scope}.bookingDeadlineDateLabel`,
    defaultMessage: 'Booking deadline date',
  },
  salaryLabel: {
    id: `${scope}.salaryLabel`,
    defaultMessage: 'Salary',
  },
  salaryBonusLabel: {
    id: `${scope}.salaryBonusLabel`,
    defaultMessage: 'Bonus',
  },
  priceLabel: {
    id: `${scope}.priceLabel`,
    defaultMessage: 'Price',
  },
  priceBonusLabel: {
    id: `${scope}.priceBonusLabel`,
    defaultMessage: 'Additional Price',
  },
  venueLabel: {
    id: `${scope}.venueLabel`,
    defaultMessage: 'Venue',
  },
  addressLabel: {
    id: `${scope}.addressLabel`,
    defaultMessage: 'Address',
  },
  houseNumberLabel: {
    id: `${scope}.houseNumberLabel`,
    defaultMessage: 'Number',
  },
  cityLabel: {
    id: `${scope}.cityLabel`,
    defaultMessage: 'City',
  },
  zipCodeLabel: {
    id: `${scope}.zipCodeLabel`,
    defaultMessage: 'Zip Code',
  },
  descriptionLabel: {
    id: `${scope}.descriptionLabel`,
    defaultMessage: 'Description',
  },
  yesLabel: {
    id: `${scope}.yesLabel`,
    defaultMessage: 'Yes',
  },
  noLabel: {
    id: `${scope}.noLabel`,
    defaultMessage: 'No',
  },
  additionalAttributesLabel: {
    id: `${scope}.additionalAttributesLabel`,
    defaultMessage: 'Additional Attributes',
  },
  fieldsLabel: {
    id: `${scope}.fieldsLabel`,
    defaultMessage: 'Select a new field',
  },
  notVisibleByUserLabel: {
    id: `${scope}.notVisibleByUserLabel`,
    defaultMessage: '(only visible by ucm)',
  },
  tariffCodeLabel: {
    id: `${scope}.tariffCodeLabel`,
    defaultMessage: 'Pay Grade',
  },
  salarySectionTitle: {
    id: `${scope}.salarySectionTitle`,
    defaultMessage: 'Salary',
  },
  additionalAttributesPlaceholder: {
    id: `${scope}.additionalAttributesPlaceholder`,
    defaultMessage: 'Select a new field',
  },
  workAddressSectionTitle: {
    id: `${scope}.workAddressSectionTitle`,
    defaultMessage: 'Work Address',
  },
  deleteStaffTypeConfirmMessage: {
    id: `${scope}.deleteStaffTypeConfirmMessage`,
    defaultMessage: 'Do you really want to delete this template?',
  },
});
