import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';
import { forceString } from 'src/utils/datetime';

const mapProjectDetail = (
  projectId: string,
  project: UCM.ProjectDetailRawType,
): UCM.ProjectDetailType => ({
  additionalInfo: project.additional_info,
  certificates: map(project.certificates, (certificate) => ({
    value: certificate.code,
    label: certificate.name,
  })),
  customer: {
    ...project.customer,
    contact: {
      email: project.customer.contact.email,
      person: project.customer.contact.person,
      phoneNumber: project.customer.contact.phone_number,
      uuid: project.customer.contact.uuid,
    },
  },
  uuid: project.uuid,
  id: projectId,
  name: project.name,
  description: project.description,
  endDate: forceString(project.end_date),
  isPriority: project.is_priority,
  isVisible: project.is_visible,
  manager: project.manager,
  opportunityKey: project.opportunity_key,
  poNumber: project.po_number,
  report: {
    payrollWage: project.report.payroll_wage,
    plannedWage: project.report.planned_wage,
    plannedPrice: project.report.planned_price,
    invoicedPrice: project.report.invoiced_price,
    notInvoicedPrice: project.report.not_invoiced_price,
    expectedInvoicePrice: project.report.expected_invoice_price,
  },
  startDate: forceString(project.start_date),
  statusName: project.status_name,
  team: project.team,
  type: {
    uuid: project.type.uuid,
    value: project.type.code,
    label: project.type.name,
  },
  totalPublished: {
    bookedCount: project.total_published.booked_staff_count,
    slotsCount: project.total_published.slots_count,
  },
  cities: project.unique_cities,
  verificationCode: project.verification_code,
});

const getProject = async (uuid: string) => {
  const {
    data: { data },
  } = await getRequest<RequestData<UCM.ProjectDetailRawType>>(
    API.GET.getProject(uuid),
  );
  return mapProjectDetail(data.id, data.attributes);
};

export const useGetProject = (
  uuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROJECT, uuid],
    queryFn: () => getProject(uuid),
    staleTime: 1000 * 60,
    ...config,
  });
};
