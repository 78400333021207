import React from 'react';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarKey, SnackbarOrigin, SnackbarProvider } from 'notistack';

import Router from 'src/router';
import { translationMessages } from 'src/i18n';
import defaultTheme from 'src/utils/customTheme';
import { LanguageProvider } from 'src/providers/languageProvider';
import config, { isDev, isProd, locale, queryClient } from 'src/config';

import { setupAxios } from './utils/request';

const notistackRef = React.createRef<SnackbarProvider>();

const notistackAnchor: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

const renderNotistackAction = (key: SnackbarKey) => {
  return (
    <IconButton
      color="inherit"
      size="small"
      onClick={() => notistackRef.current?.closeSnackbar(key)}
    >
      <HighlightOffOutlinedIcon />
    </IconButton>
  );
};

setupAxios();

export default function App() {
  if (isProd) {
    Sentry.init({
      dsn: config.sentryDsn,
      tracesSampleRate: 0.25,
      environment: config.serverEnv,
      integrations: [Sentry.browserTracingIntegration()],
      tracePropagationTargets: [config.endpointHost],
    });
  }

  if (!isDev && !isProd) {
    Sentry.init({
      dsn: config.sentryDsn,
      environment: config.serverEnv,
    });
  }

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <LanguageProvider locale={locale} messages={translationMessages}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={defaultTheme}>
              <SnackbarProvider
                ref={notistackRef}
                action={renderNotistackAction}
                anchorOrigin={notistackAnchor}
                autoHideDuration={1000}
                maxSnack={10}
              >
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </SnackbarProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </LanguageProvider>
    </GoogleOAuthProvider>
  );
}
