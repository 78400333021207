import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

const getUserRatings = async (jobUuid: string) => {
  const { data } = await getRequest(API.GET.getUserRatingScores(jobUuid));

  return mapUserRatingScore(data?.data);
};

export const useGetUserRatings = ({
  jobUuid,
  enabled = true,
}: {
  jobUuid: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER_RATING_SCORE, jobUuid],
    queryFn: () => getUserRatings(jobUuid),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

const mapUserRatingScore = (
  data: {
    attributes: UCM.JobUserRatingTypeRaw;
    id: string;
  }[],
): UCM.JobUserRatingType[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    uuid: attributes.uuid,
    applicant: {
      id: attributes.applicant.id,
      uuid: attributes.applicant.uuid,
      fullName: attributes.applicant.full_name,
    },
    userRating: {
      id: attributes.user_rating.id,
      uuid: attributes.user_rating.uuid,
      score: attributes.user_rating.score,
      comment: attributes.user_rating.comment,
    },
  }));
};
