import { useCallback, useMemo } from 'react';
import { date, object, string } from 'yup';
import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import { useCreateBriefing } from 'src/pages/ProjectDetailsPage/api/mutations/useCreateBriefing';
import { useUpdateBriefing } from 'src/pages/ProjectDetailsPage/api/mutations/useUpdateBriefing';
import { PencilAdornment } from 'src/utils/components';

import messages from '../../messages';
import { forceTimeString, timeFormat } from 'src/utils/datetime';

interface DailyBriefingModalProps {
  job: UCM.DailyBriefingType;
}

const multilineOptions = {
  multiline: true,
  minRows: 1,
  maxRows: 3,
};

// const sxTimeField = { '& input': { fontSize: '16px' } };

export default function DailyBriefingModal({ job }: DailyBriefingModalProps) {
  const { formatMessage } = useIntl();

  const { mutate: createBriefing, isPending: isPendingCreate } =
    useCreateBriefing();
  const { mutate: updateBriefing, isPending: isPendingUpdate } =
    useUpdateBriefing();

  const initialValues: UCM.DailyBriefingPayloadState = useMemo(
    () => ({
      contactPerson: job.briefing?.contactPerson?.name ?? '',
      contactPhoneNumber: job.briefing?.contactPerson?.phoneNumber ?? '',
      // meetingTime: job.briefing?.meetingTime ?? undefined,
      meetingTime: job.briefing?.meetingTime
        ? moment.utc(job.briefing?.meetingTime)
        : undefined,
      meetingPoint: job.briefing?.meetingPoint ?? '',
      instruction: job.briefing?.instruction ?? '',
    }),
    [job.briefing],
  );

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: object().shape({
      contactPerson: string(),
      contactPhoneNumber: string(),
      meetingTime: date(),
      meetingPoint: string(),
      instruction: string(),
    }),
    onSubmit: () => {},
  });

  const { values, errors, isValid } = formik;

  const handleSubmit = useCallback(async () => {
    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) throw errors;

    if (job.briefing) {
      return updateBriefing(
        {
          jobUuid: job.jobUuid,
          uuid: job.briefing.uuid,
          briefing: { ...values, position_job_date_id: job.id },
        },
        { onSettled: handleClose },
      );
    }
    return createBriefing(
      {
        jobUuid: job.jobUuid,
        briefing: { ...values, position_job_date_id: job.id },
      },
      { onSettled: handleClose },
    );
  }, [values, job]);

  const handleClose = useCallback(() => {
    formik.resetForm();
  }, []);

  const changeDateValue = useCallback(
    (date: Moment | null, name: keyof UCM.BriefingPayloadState) => {
      // if (date?.isValid()) {
      //   const shiftDate = toTimezone(job.jobDate.startsAt);
      //   const combinedDate = moment({
      //     year: shiftDate.year(),
      //     month: shiftDate.month(),
      //     day: shiftDate.date(),
      //     hour: date.hour(),
      //     minute: date.minute(),
      //   });
      //   const value = forceString(
      //     combinedDate,
      //     moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
      //   );
      // }
      formik.setFieldValue(name, date);
    },
    [],
  );

  return (
    <ConfirmModal
      disabled={!isValid}
      onClose={handleClose}
      onConfirm={handleSubmit}
      confirmationDialogMaxWidth="xs"
      loading={isPendingCreate || isPendingUpdate}
      title={formatMessage(messages.dailyBriefingModalLabel)}
      submitTextButton={
        job.briefing
          ? formatMessage(messages.briefingUpdateButton)
          : formatMessage(messages.briefingCreateButton)
      }
      triggerButton={(handleOpen) => {
        return (
          <IconButton onClick={handleOpen}>
            <EditOutlinedIcon />
          </IconButton>
        );
      }}
    >
      <Grid container>
        <Grid container spacing={2} width={'100vw'} alignItems="end">
          <Grid size={6}>
            <TextInput fullWidth value={job.id} label={'PJD ID'} disabled />
          </Grid>
          <Grid size={6}>
            <TextInput fullWidth value={job.userId} label={'UID'} disabled />
          </Grid>
          <Grid size={6}>
            <TextInput
              fullWidth
              value={forceTimeString(job.jobDate.startsAt) ?? null}
              label="Shift Start Time"
              disabled
            />
          </Grid>
          <Grid size={6}>
            <TextInput
              fullWidth
              value={forceTimeString(job.jobDate.endsAt) ?? null}
              label="Shift End Time"
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <TextInput
        name="contactPerson"
        value={values.contactPerson}
        label={formatMessage(messages.contactPersonField)}
        onChange={formik.handleChange}
        error={!!errors.contactPerson}
        helperText={errors.contactPerson}
        EndIcon={PencilAdornment}
      />
      <Grid container>
        <Grid container spacing={2} width={'100vw'} alignItems="end">
          <Grid size={6}>
            <TextInput
              name="contactPhoneNumber"
              fullWidth
              value={values.contactPhoneNumber}
              label={formatMessage(messages.phoneNumberField)}
              onChange={formik.handleChange}
              error={!!errors.contactPhoneNumber}
              helperText={errors.contactPhoneNumber}
              EndIcon={PencilAdornment}
            />
          </Grid>
          <Grid size={6}>
            {/* <DemoContainer components={['TimeField']}>
              </DemoContainer> */}
            <TimeField
              name="meetingTime"
              variant="standard"
              fullWidth
              // value={toTimezone(values.meetingTime ?? null)}
              value={values.meetingTime}
              label={formatMessage(messages.meetingTimeField)}
              format={timeFormat}
              onChange={(value) => changeDateValue(value, 'meetingTime')}
              // sx={sxTimeField}
            />
          </Grid>
        </Grid>
      </Grid>
      <TextInput
        name="meetingPoint"
        value={values.meetingPoint}
        label={formatMessage(messages.meetingPointLabel)}
        onChange={formik.handleChange}
        error={!!errors.meetingPoint}
        helperText={errors.meetingPoint}
        EndIcon={PencilAdornment}
        {...multilineOptions}
        maxRows={2}
      />
      <TextInput
        name="instruction"
        value={values.instruction}
        label={formatMessage(messages.specialInstructionField)}
        onChange={formik.handleChange}
        error={!!errors.instruction}
        helperText={errors.instruction}
        EndIcon={PencilAdornment}
        {...multilineOptions}
      />
    </ConfirmModal>
  );
}
