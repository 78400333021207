import head from 'lodash/head';
import compact from 'lodash/compact';

// Build helper text from multiple sources
export const buildHelperText = (...helpText: Array<string | null>) => {
  const texts = compact(helpText);
  if (texts.length === 0) return '';
  if (texts.length === 1) return head(texts) ?? '';
  return texts.join(' ');
};

export const removeNullValues = <T extends object>(obj: T): Partial<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key as keyof T];
    if (value !== null) {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as Partial<T>);
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
