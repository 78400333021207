import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import uniq from 'lodash/uniq';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import {
  useGetJobs,
  useDownloadJobStaffPlan,
  useGetJob,
  useUpdateJobVisible,
} from 'src/pages/ProjectDetailsPage/api';
import { buildJobProps } from 'src/utils/tools';
import DataTable from 'src/components/DataTable';
import MoreActions from 'src/components/MoreActions';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import JobDetails from 'src/pages/ProjectDetailsPage/components/JobDetails';
import JobDetailsForm from 'src/pages/ProjectDetailsPage/components/JobDetailsForm';
import BriefingModal from 'src/pages/ProjectDetailsPage/components/DailyBriefings/components/BriefingModal/BriefingModal';

import JobForm from '../JobForm';
import messages from './messages';
import { Styled } from './index.styled';
import ShiftsForm from './components/ShiftsForm';

import type {
  FilterStateType,
  QuickFiltersType,
} from 'src/components/SearchFilterBar';
import type { DownloadStaffPlanFetchParamsType } from '../../api/queries/useDownloadJobStaffPlan';

interface JobListProps {
  projectUuid: string;
}

export default function JobList({ projectUuid }: JobListProps) {
  const { formatMessage } = useIntl();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [filters, setFilters] = useState<FilterStateType>({});
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });
  const [jobDetails, setJobDetails] = useState<UCM.JobType | null>(null);
  const [selectedJob, setSelectedJob] = useState<UCM.JobType | null>(null);
  const [downloadingJob, setDownloadingJob] =
    useState<DownloadStaffPlanFetchParamsType>({
      jobId: '',
      jobUuid: '',
    });
  const [shouldUpdateJob, setShouldUpdateJob] = useState(false);

  const {
    data: jobs,
    isFetching: isFetchingJobs,
    isRefetching,
  } = useGetJobs(projectUuid, params, filters);

  useDownloadJobStaffPlan({
    filters: downloadingJob,
    enabled: !!downloadingJob.jobId,
    onSettled: () => setDownloadingJob({ jobId: '', jobUuid: '' }),
  });

  const { data: details } = useGetJob(selectedJob?.uuid, {
    enabled: isDialogOpen && Boolean(selectedJob?.uuid),
  });

  const { mutate: changeVisibility } = useUpdateJobVisible({});

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: true,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchInputPlaceholder),
    },
  ]).current;

  useEffect(() => {
    if (!isRefetching && shouldUpdateJob && jobDetails) {
      const updatedJob = jobs?.data.find(
        (item) => item.uuid === jobDetails?.uuid,
      );
      if (updatedJob) {
        setJobDetails(updatedJob);
        setShouldUpdateJob(false);
      }
    }
  }, [shouldUpdateJob, isRefetching]);

  const handleCopyJob = useCallback((job: UCM.JobType) => {
    setSelectedJob(job);
    setDialogOpen(true);
  }, []);

  const handleDownloadJobStaffPlan = useCallback(
    (jobId: string, jobUuid: string) => setDownloadingJob({ jobId, jobUuid }),
    [],
  );

  const handlePressRow = useCallback(
    (job: UCM.JobType | null) => {
      const nextJob = job?.id === jobDetails?.id ? null : job;
      setJobDetails(nextJob);
    },
    [jobDetails?.id],
  );

  const handleToggleVisibility = useCallback(
    (jobUuid: string, isVisible: boolean) => {
      changeVisibility({ jobUuid, visibility: isVisible });
    },
    [],
  );

  const columns: Array<{ id: keyof UCM.JobType | string; label: string }> =
    useMemo(
      () => [
        {
          id: 'name',
          label: formatMessage(messages.jobColumnLabel),
          render: (_: Object, job: UCM.JobType) => (
            <Styled.JobTitle>
              {job.id} - {job.title}
            </Styled.JobTitle>
          ),
        },
        {
          id: 'dateRange',
          align: 'center',
          label: formatMessage(messages.periodColumnLabel),
          render: (_: Object, job: UCM.JobType) => {
            if (!job.firstShiftAt && !job.lastShiftAt)
              return <Typography variant="inherit">-</Typography>;

            return (
              <Typography variant="inherit">
                {uniq([job.firstShiftAt, job.lastShiftAt]).join(' - ')}
              </Typography>
            );
          },
        },
        {
          id: 'jobType',
          align: 'center',
          label: formatMessage(messages.jobTypeColumnLabel),
          render: (_: Object, job: UCM.JobType) => (
            <Typography variant="inherit">
              {job.isStandby ? 'Standby' : job.isFlex ? 'Flex-date' : 'Normal'}
            </Typography>
          ),
        },
        {
          id: 'location',
          align: 'center',
          label: formatMessage(messages.locationsColumnLabel),
          render: (location: UCM.JobType['location']) => {
            if (!location.city)
              return <Typography variant="inherit">-</Typography>;
            return <Typography variant="inherit">{location.city}</Typography>;
          },
        },
        {
          id: 'count',
          align: 'center',
          label: formatMessage(messages.bookingColumnLabel),
          render: (_: Object, job: UCM.JobType) => (
            <Typography variant="inherit">
              {job.summary.jobApplications.booked +
                job.summary.jobApplications.done}
              /{job.quantity}
            </Typography>
          ),
        },
        {
          id: 'statusName',
          align: 'center',
          label: formatMessage(messages.StatusColumnLabel),
          render: (status: string) => (
            <Typography variant="inherit">{status}</Typography>
          ),
        },
        {
          id: 'action',
          label: '',
          width: 0,
          align: 'center',
          whiteSpace: 'nowrap',
          render: (_: Object, job: UCM.JobType) => {
            const MenuOptions = [
              {
                label: formatMessage(messages.downloadStaffPlanMenuLabel),
                onSelect: () => handleDownloadJobStaffPlan(job.id, job.uuid),
              },
              {
                label: formatMessage(messages.copyJobMenuLabel),
                onSelect: () => handleCopyJob(job),
              },
            ];

            return (
              <Grid display="flex" alignItems="center">
                <ConfirmationDialog
                  maxWidth="sm"
                  onConfirm={() =>
                    handleToggleVisibility(job.uuid, !job.isVisible)
                  }
                  message={formatMessage(
                    messages.toggleVisibilityConfirmMessage,
                  )}
                >
                  {(handleOpen) => (
                    <IconButton onClick={handleOpen}>
                      {job.isVisible ? (
                        <VisibilityOutlinedIcon color="success" />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  )}
                </ConfirmationDialog>

                <JobDetailsForm jobUuid={job.uuid} projectUuid={projectUuid} />
                <ShiftsForm jobUuid={job.uuid} isFlex={job.isFlex} />
                <BriefingModal jobUuid={job.uuid} />
                <MoreActions
                  options={MenuOptions}
                  iconColor="darkerGrey"
                  loading={downloadingJob.jobId === job.id}
                />
              </Grid>
            );
          },
        },
      ],
      [
        selectedJob,
        downloadingJob,
        handleCopyJob,
        handleDownloadJobStaffPlan,
        handleToggleVisibility,
      ],
    );

  const handleCopyJobDialogClose = () => {
    setDialogOpen(false);
    setSelectedJob(null);
  };

  const handleSearch = useCallback((filters: FilterStateType) => {
    setFilters(filters);
  }, []);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const handleUpdateJobDetails = useCallback(() => {
    setShouldUpdateJob(true);
  }, []);

  return (
    <>
      {jobDetails && (
        <JobDetails
          job={jobDetails}
          projectUuid={projectUuid}
          handleClose={setJobDetails}
          updateJobDetails={handleUpdateJobDetails}
        />
      )}

      <DataTable
        columns={columns}
        rows={jobs?.data}
        pagination={jobs?.meta}
        onPaginate={handlePagination}
        loading={isFetchingJobs}
        onSearch={handleSearch}
        quickFilters={quickFilters}
        activeRow={jobDetails}
        checkActiveKey="uuid"
        pressRow={handlePressRow}
        scrollToPosition={150}
      />

      {selectedJob && (
        <JobForm
          isCopy
          projectUuid={projectUuid}
          isOpen={isDialogOpen}
          onClose={handleCopyJobDialogClose}
          jobProps={buildJobProps(details)}
        />
      )}
    </>
  );
}
