import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useIntl } from 'react-intl';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

interface UpdateRebookShiftParams {
  applicationUuid: string;
  reasonCode: string;
}

const updateRebookShift = async ({
  applicationUuid,
  reasonCode,
}: UpdateRebookShiftParams) => {
  await putRequest(API.PUT.updateRebookShift(applicationUuid), {
    candidate_job_shift: {
      reason_code: reasonCode,
    },
  });
};

export const useUpdateRebookShift = () => {
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: updateRebookShift,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.CALENDAR_JOBS],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS_COUNT],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS],
      });

      handleSuccess(formatMessage(messages.rebookShiftSuccess));
    },
    onError: handleError,
  });
};
