import styled from '@mui/material/styles/styled';

import Box from '@mui/material/Box';

export const Styled = {
  ModalContainer: styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: theme.spacing(57),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  })),
};
