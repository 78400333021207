import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

const mapJobTypeOptions = (
  option: UCM.ContractJobTypeOptionRawType,
): UCM.ContractJobTypeOptionType => ({
  value: option.code,
  label: option.name,
});

const getJobTypeOptions = async () => {
  const { data } = await getRequest<
    RequestDataArray<UCM.ContractJobTypeOptionRawType>
  >(API.GET.contractJobTypeOptions, {
    headers: { Accept: 'application/vnd.falcon.v2+json' },
  });
  return data.data.map(({ attributes }) => mapJobTypeOptions(attributes));
};

export const useGetJobTypeOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONTRACT_JOB_TYPE_OPTIONS],
    queryFn: getJobTypeOptions,
    staleTime: 1000 * 60 * 10,
    ...config,
  });
};
