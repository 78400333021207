import { useState, useCallback, useRef, useMemo } from 'react';

import { useIntl } from 'react-intl';
import moment, { type Moment } from 'moment';

import findIndex from 'lodash/findIndex';

import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { locale } from 'src/config';
import SearchFilterBar, {
  type FilterStateType,
  type QuickFiltersType,
} from 'src/components/SearchFilterBar';
import DataTable from 'src/components/DataTable';
import PageLayout from 'src/components/PageLayout';
import { forceString, toMoment } from 'src/utils/datetime';
import StyledTab from 'src/components/StyledTab/StyledTab';
import StyledTabs from 'src/components/StyledTabs/StyledTabs';
import { DatePickerField } from 'src/components/DatePickerField';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import LongTermContractForm from './components/LongTermContractForm';

import {
  useGetContracts,
  useUpdateContract,
  useDeleteContract,
  useDownloadContract,
  type ContractParamsType,
  type ContractFiltersType,
} from './api';
import messages from './messages';

import type { DefaultTheme } from '@mui/styles';
import { formatNumber } from 'src/utils/standards';

const navTabs: Array<{ value: UCM.ProjectContractType; label: string }> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'long_term',
    label: 'Long Term',
  },
  {
    value: 'short_term',
    label: 'Short Term',
  },
];

const tableTabs: Array<{ value: UCM.ContractStatusType; label: string }> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'to_be_signed',
    label: 'To Be Signed',
  },
  {
    value: 'signed_by_student',
    label: 'Signed by Student',
  },
  {
    value: 'completely_signed',
    label: 'Completely Signed',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'expired',
    label: 'Expired',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
  {
    value: 'deleted',
    label: 'Deleted',
  },
];

export default function ContractsPage() {
  const { formatMessage } = useIntl();

  const [selectedContract, setSelectedContract] =
    useState<UCM.ContractType | null>(null);
  const [downloadingContract, setDownloadingContract] = useState({
    contractId: '',
    contractUuid: '',
  });
  const [activeTab, setActiveTab] = useState<UCM.ProjectContractType>(
    navTabs[0].value,
  );
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });
  const [activeTableTab, setActiveTableTab] = useState<UCM.ContractStatusType>(
    tableTabs[0].value,
  );
  const [filters, setFilters] = useState<ContractFiltersType>({
    job_id: '',
    user_id: '',
    project_id: '',
    status: activeTableTab,
    project_type: activeTab,
  });

  const { mutate: updateContract } = useUpdateContract();
  const { mutate: deleteContract } = useDeleteContract();
  useDownloadContract(
    {
      ...downloadingContract,
      onSettled: () =>
        setDownloadingContract({ contractId: '', contractUuid: '' }),
    },
    { enabled: !!downloadingContract.contractId },
  );
  const { data: contracts, isFetching: isFetchingContracts } = useGetContracts(
    params,
    filters,
  );

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: true,
      id: 'user_id',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchUserIdPlaceholder),
    },
    {
      id: 'job_id',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchJobIdPlaceholder),
    },
    {
      id: 'project_id',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchProjectIdPlaceholder),
    },
  ]).current;

  const tabSx = useRef((theme: DefaultTheme) => ({
    width: '100%',
    borderRadius: theme.spacing(0),
  })).current;

  const iconSx = useRef((theme: DefaultTheme) => ({
    color: theme.palette.grey[600],
  })).current;

  const handleUpdateContract = useCallback((contract: UCM.ContractType) => {
    const { userSigningDate, companySigningDate, uuid } = contract;

    updateContract({
      contractUuid: uuid,
      signedByUserAt: userSigningDate,
      signedByUcmAt: companySigningDate,
    });
  }, []);

  const handleDownloadContract = useCallback((contract: UCM.ContractType) => {
    setDownloadingContract({
      contractId: contract.id,
      contractUuid: contract.uuid,
    });
  }, []);

  const handleDeleteContract = useCallback((contract: UCM.ContractType) => {
    deleteContract(contract.uuid);
  }, []);

  const handleEditClick = useCallback(
    (
      contract: UCM.ContractType,
      event: React.MouseEvent<HTMLElement>,
      handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
    ) => {
      handleOpen(event);
      setSelectedContract(contract);
    },
    [],
  );

  const columns: Array<{
    id: keyof UCM.ContractType | string;
    label: string | React.ReactNode;
  }> = useMemo(() => {
    const excludeColumns = [
      ...(activeTab !== 'all' ? ['projectType'] : []),
      ...(activeTableTab.match(
        /to_be_signed|signed_by_student|completely_signed|expired|cancelled/,
      )
        ? ['signatureStatus']
        : []),
    ];

    const allColumns = [
      {
        id: 'student',
        label: formatMessage(messages.student),
        render: (_: Object, { user }: UCM.ContractType) => {
          return `${user.id} - ${user.firstName} ${user.lastName}`;
        },
      },
      {
        id: 'jobId',
        align: 'center',
        label: formatMessage(messages.jobId),
        render: (_: Object, { jobIds }: UCM.ContractType) => {
          if (!jobIds.length) {
            return <Typography variant="inherit">-</Typography>;
          }
          return jobIds.map((jobId) => (
            <Typography key={jobId} variant="inherit">
              {jobId}
            </Typography>
          ));
        },
      },
      {
        id: 'contractId',
        align: 'center',
        label: formatMessage(messages.contractId),
        render: (_: Object, { contractNumber }: UCM.ContractType) => {
          return <Typography variant="inherit">{contractNumber}</Typography>;
        },
      },
      {
        id: 'archiveId',
        align: 'center',
        label: formatMessage(messages.archiveId),
        render: (_: Object, { archiveNumber }: UCM.ContractType) => (
          <Typography variant="inherit"> {archiveNumber ?? '-'}</Typography>
        ),
      },
      {
        id: 'customer',
        align: 'center',
        label: formatMessage(messages.customer),
        render: (_: Object, { company }: UCM.ContractType) => {
          return <Typography variant="inherit">{company.name}</Typography>;
        },
      },
      {
        id: 'projectType',
        align: 'center',
        label: formatMessage(messages.projectType),
        render: (_: Object, { projectTypeCode }: UCM.ContractType) => {
          const projectType =
            projectTypeCode === 'long_term'
              ? formatMessage(messages.longTerm)
              : formatMessage(messages.shortTerm);

          return <Typography variant="inherit">{projectType}</Typography>;
        },
      },
      {
        id: 'startDate',
        align: 'center',
        label: formatMessage(messages.startDate),
        render: (_: Object, { startDate }: UCM.ContractType) => (
          <Typography variant="inherit">
            {startDate ? forceString(startDate) : '-'}
          </Typography>
        ),
      },
      {
        id: 'endDate',
        align: 'center',
        label: formatMessage(messages.endDate),
        render: (_: Object, { endDate }: UCM.ContractType) => (
          <Typography variant="inherit">
            {endDate ? forceString(endDate) : '-'}
          </Typography>
        ),
      },
      {
        id: 'daysLeft',
        align: 'center',
        label: formatMessage(messages.daysLeft),
        render: (_: Object, { daysLeft }: UCM.ContractType) => {
          return <Typography variant="inherit">{daysLeft}</Typography>;
        },
      },
      // TODO: Add signing method, BE needs to be updated
      // {
      //   id: 'signingMethod',
      //   label: formatMessage(messages.signingMethod),
      //   render: (_: Object, { signatureStatus }: UCM.ContractType) => {
      //     return <Typography variant="inherit">{signatureStatus}</Typography>;
      //   },
      // },
      {
        id: 'userSigningDate',
        align: 'center',
        label: formatMessage(messages.studentSigned),
        render: (userSigningDate: string, contract: UCM.ContractType) => {
          return (
            <DatePickerField
              editable
              disabled={!contract.uuid}
              value={toMoment(userSigningDate, moment.HTML5_FMT.DATE)}
              onBlurChange={(date: Moment | null | undefined) => {
                const userSignedAt = forceString(date, moment.HTML5_FMT.DATE);

                handleUpdateContract({
                  ...contract,
                  userSigningDate: userSignedAt,
                });
              }}
            />
          );
        },
      },
      {
        id: 'companySigningDate',
        align: 'center',
        label: formatMessage(messages.ucmSigned),
        render: (companySigningDate: string, contract: UCM.ContractType) => {
          return (
            <DatePickerField
              editable
              disabled={!contract.uuid}
              value={toMoment(companySigningDate, moment.HTML5_FMT.DATE)}
              onBlurChange={(date: Moment | null | undefined) => {
                const ucmSignedAt = forceString(date, moment.HTML5_FMT.DATE);

                handleUpdateContract({
                  ...contract,
                  companySigningDate: ucmSignedAt,
                });
              }}
            />
          );
        },
      },
      {
        id: 'status',
        align: 'center',
        label: formatMessage(messages.status),
        render: (_: Object, { statusCode }: UCM.ContractType) => {
          const success = statusCode === 'active';

          return (
            <Typography
              color={success ? 'success' : 'inherit'}
              variant="inherit"
            >
              {statusCode}
            </Typography>
          );
        },
      },
      {
        id: 'signatureStatus',
        align: 'center',
        label: formatMessage(messages.signatureStatus),
        render: (signatureStatus: string) => {
          return <Typography variant="inherit">{signatureStatus}</Typography>;
        },
      },
      {
        id: 'actions',
        label: '',
        width: 0,
        align: 'center',
        whiteSpace: 'nowrap',
        render: (_: Object, contract: UCM.ContractType) => {
          const downloading = downloadingContract.contractId === contract.id;

          return (
            <Grid display="flex" alignItems="center">
              <LongTermContractForm
                create={false}
                contractUuid={contract.uuid}
                contract={buildContractProps(selectedContract)}
                triggerButton={(handleOpen) => (
                  <Tooltip title={formatMessage(messages.editTooltip)}>
                    <IconButton
                      onClick={(event) =>
                        handleEditClick(contract, event, handleOpen)
                      }
                    >
                      <ModeEditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              />

              <Tooltip title={formatMessage(messages.downloadTooltip)}>
                <IconButton
                  sx={{ pointerEvents: downloading ? 'none' : 'auto' }}
                  onClick={() => handleDownloadContract(contract)}
                >
                  {downloading ? (
                    <CircularProgress size={22} sx={iconSx} />
                  ) : (
                    <DownloadOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>

              <ConfirmationDialog
                maxWidth="sm"
                onConfirm={() => handleDeleteContract(contract)}
                message={formatMessage(messages.deleteContractConfirmMessage)}
              >
                {(handleOpen) => (
                  <Tooltip title={formatMessage(messages.deleteTooltip)}>
                    <IconButton onClick={handleOpen}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ConfirmationDialog>
            </Grid>
          );
        },
      },
    ];

    return [
      ...allColumns.filter((column) => !excludeColumns.includes(column.id)),
    ];
  }, [
    activeTab,
    activeTableTab,
    handleEditClick,
    selectedContract,
    handleDeleteContract,
    handleUpdateContract,
    handleDownloadContract,
    downloadingContract.contractId,
  ]);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const handleActiveTabChange = useCallback((tab: UCM.ProjectContractType) => {
    setActiveTab(tab);
    setParams({ page: 1 });
    setFilters((prevFilters) => ({
      ...prevFilters,
      project_type: tab,
    }));
  }, []);

  const handleActiveTableTabChange = useCallback(
    (_: React.SyntheticEvent, tabIndex: number) => {
      const newStatus = tableTabs[tabIndex].value;
      setActiveTableTab(newStatus);
      setParams({ page: 1 });
      setFilters((prevFilters) => ({
        ...prevFilters,
        status: newStatus,
      }));
    },
    [tableTabs],
  );

  const handleSearch = useCallback((filters: FilterStateType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  }, []);

  return (
    <PageLayout
      iconName="contracts"
      tabs={navTabs}
      activeTab={activeTab}
      title={formatMessage(messages.title)}
      onActiveTabChange={handleActiveTabChange}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 9 }}>
          <SearchFilterBar
            variant="noBorder"
            fields={quickFilters}
            onSearch={handleSearch}
          />
        </Grid>

        <Grid container size={{ xs: 'auto', md: 3 }} alignItems="center">
          <Grid size={12} display="flex" justifyContent="flex-end">
            <LongTermContractForm />
          </Grid>
        </Grid>

        <Grid size={12}>
          <DataTable
            columns={columns}
            rows={contracts?.data}
            pagination={contracts?.meta}
            onPaginate={handlePagination}
            loading={isFetchingContracts}
            headerTabs={
              <StyledTabs
                sx={tabSx}
                onChange={handleActiveTableTabChange}
                value={findIndex(
                  tableTabs,
                  (tab) => tab.value === activeTableTab,
                )}
              >
                {tableTabs.map((tab) => (
                  <StyledTab key={tab.value} label={tab.label} />
                ))}
              </StyledTabs>
            }
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export const buildContractProps = (
  contract: UCM.ContractType | null,
): ContractParamsType | null => {
  if (!contract) return null;

  return {
    userId: contract.user.id,
    status: contract.statusCode,
    userUuid: contract.user.uuid,
    jobTypeCode: contract.jobType,
    userEmail: contract.user.email,
    taskTypeCode: contract.taskType,
    jobLocationCode: contract.location,
    customerCode: contract.company.uuid,
    tariffGroupCode: contract.tariffCode,
    jobRequirementCode: contract.requirement,
    employmentTypeCode: contract.employmentType,
    wagePerHour: formatNumber(contract.wage, locale),
    certificateCode: contract.certificate?.code ?? '',
    maxDuration: contract.maxDuration?.toString() ?? '',
    unpaidLeave: contract.unpaidLeave?.toString() ?? '',
    monthlyHours: contract.monthlyHours?.toString() ?? '',
    weeklyHours: contract.avgWeeklyHours?.toString() ?? '',
    endDate: moment(contract.endDate, moment.HTML5_FMT.DATE),
    startDate: moment(contract.startDate, moment.HTML5_FMT.DATE),
    userName: `${contract.user.firstName} ${contract.user.lastName}`,
    plannedEndDate: moment(contract.plannedEndDate, moment.HTML5_FMT.DATE),
    latestStartDate: moment(contract.latestStartDate, moment.HTML5_FMT.DATE),
    plannedStartDate: moment(contract.plannedStartDate, moment.HTML5_FMT.DATE),
  };
};
