/*
 * Pre Register Form Modal Messages
 *
 * This contains all the text for the Pre Register Form Modal component.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.GatekeeperPage.components.PreRegisterFormModal';

export default defineMessages({
  createTitle: {
    id: `${scope}.createTitle`,
    defaultMessage: 'New Pre Register',
  },
  editTitle: {
    id: `${scope}.editTitle`,
    defaultMessage: 'Edit Pre Register',
  },
  addPreRegisterButton: {
    id: `${scope}.addPreRegisterButton`,
    defaultMessage: 'Add new pre-register',
  },
  emailField: {
    id: `${scope}.emailField`,
    defaultMessage: 'Student Email*',
  },
  phoneNumberField: {
    id: `${scope}.phoneNumberField`,
    defaultMessage: 'Phone Number',
  },
  phoneCodeField: {
    id: `${scope}.phoneCodeField`,
    defaultMessage: 'Country Code',
  },
  firstNameField: {
    id: `${scope}.firstNameField`,
    defaultMessage: 'First Name',
  },
  lastNameField: {
    id: `${scope}.lastNameField`,
    defaultMessage: 'Last Name',
  },
  germanField: {
    id: `${scope}.germanField`,
    defaultMessage: 'German Level',
  },
  englishField: {
    id: `${scope}.englishField`,
    defaultMessage: 'English Level',
  },
  dateOfBirthField: {
    id: `${scope}.dateOfBirthField`,
    defaultMessage: 'Date Of Birth',
  },
  occupationField: {
    id: `${scope}.occupationField`,
    defaultMessage: 'Occupation Type',
  },
  graduationField: {
    id: `${scope}.graduationField`,
    defaultMessage: 'Graduation Year',
  },
  mainCityField: {
    id: `${scope}.mainCityField`,
    defaultMessage: 'Main City',
  },
  nationalityField: {
    id: `${scope}.nationalityField`,
    defaultMessage: 'Nationality',
  },
  baseProfileText: {
    id: `${scope}.baseProfileText`,
    defaultMessage: 'Base Profile',
  },
  preRegistrationCreated: {
    id: `${scope}.preRegistrationCreated`,
    defaultMessage: 'Pre-registration created successfully',
  },
  workPermitLabel: {
    id: `${scope}.workPermitLabel`,
    defaultMessage: 'Work Permit',
  },
  healthCertificateLabel: {
    id: `${scope}.healthCertificateLabel`,
    defaultMessage: 'Health Certificate',
  },
  preRegisteredStatus: {
    id: `${scope}.preRegisteredStatus`,
    defaultMessage: 'This email address exists in our system (pre-registered)',
  },
  registeredStatus: {
    id: `${scope}.registeredStatus`,
    defaultMessage: 'This email address exists in our system (registered)',
  },
  availableStatus: {
    id: `${scope}.availableStatus`,
    defaultMessage:
      "This email address doesn't exist in our system (Please pre-register the user first)",
  },
  preRegistrationUpdated: {
    id: `${scope}.preRegistrationUpdated`,
    defaultMessage: 'Pre-registration updated successfully',
  },
  confirmationMessage: {
    id: `${scope}.confirmationMessage`,
    defaultMessage:
      'Do you really want to pre register the user with just the email? Additional Info can help us to identify the user. It will also speed up the registration process for them.',
  },
  futureYearRequired: {
    id: `${scope}.futureYearRequired`,
    defaultMessage: 'Graduation year must be in the future',
  },
});
