import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { number, object, string } from 'yup';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material';

import Dropdown from 'src/components/Dropdown';
import ConfirmModal from 'src/components/ConfirmModal';
import TextInput from 'src/components/TextInput';
import { PencilAdornment } from 'src/utils/components';
import { DatePickerField } from 'src/components/DatePickerField';
import { checkNumberTyping } from 'src/utils/standards';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { useCreateFines } from 'src/pages/FinesPage/api';
import {
  useGetAcceptReason,
  useGetCandidates,
} from 'src/pages/ProjectDetailsPage/api';

import { locale } from 'src/config';
import { ACCEPT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetAcceptReason';

import messages from './messages';

interface CreateFineState {
  userId: string;
  userUuid: string;
  userName: string;
  userEmail: string;
  cancelId: string;
  jobId: string;
  affectedDate: Moment | null;
  reasonCode: string;
  timeDeviation: string;
  explanation: string;
  amount: string;
  externalExplanation: string;
  adminComment: string;
}

export default function CreateFineFormModal() {
  const { formatMessage } = useIntl();
  const { handleSuccess } = useMessageHandler();

  const [isOpen, setOpen] = useState(false);
  const [debouncedUserId, setDebouncedUserId] = useState('');

  const { data: candidate, isFetching: isFetchingUser } = useGetCandidates(
    { id: debouncedUserId },
    { enabled: !!debouncedUserId },
  );

  const { data: acceptReason } = useGetAcceptReason(
    { reason_for: ACCEPT_REASON.FINES },
    { enabled: isOpen },
  );

  const { mutate: createFines, isPending: isPendingCreateFines } =
    useCreateFines();

  const [validateOnChange, setValidateOnChange] = useState(false);

  const initialValues: CreateFineState = useMemo(
    () => ({
      userId: '',
      userUuid: '',
      userName: '',
      userEmail: '',
      cancelId: '',
      jobId: '',
      affectedDate: null,
      reasonCode: '',
      timeDeviation: '',
      explanation: '',
      amount: '',
      externalExplanation: '',
      adminComment: '',
    }),
    [],
  );

  const formik = useFormik({
    initialValues,

    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validationSchema: object().shape({
      userId: number().required().integer().min(0),
      externalExplanation: string().required(),
      reasonCode: string().required(),
      amount: number().required().min(0),
    }),
    onSubmit: () => {},
  });

  const { values, errors, setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (!debouncedUserId) {
      resetForm();
      return;
    }

    if (isFetchingUser) return;

    const fields = {
      userName: candidate?.userName ?? formatMessage(messages.userNotFound),
      userUuid: candidate?.userUuid ?? '',
      userEmail: candidate?.userEmail ?? formatMessage(messages.userNotFound),
    };

    setFieldValue('userName', fields.userName);
    setFieldValue('userUuid', fields.userUuid);
    setFieldValue('userEmail', fields.userEmail);
  }, [candidate, debouncedUserId, isFetchingUser]);

  const handleClose = useCallback(() => {
    setValidateOnChange(false);
    resetForm();
    setOpen(false);
  }, []);

  const handleChangeDate = useCallback(
    (date: Moment | Moment[] | null) => {
      setFieldValue('affectedDate', date);
    },
    [formik],
  );

  const debouncedSetUserId = useCallback(
    debounce((value: string) => {
      setDebouncedUserId(value);
    }, 1000),
    [],
  );

  const onChangeEventValue = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      name: keyof CreateFineState,
    ) => {
      const { value } = event.target as { value: string };

      if (name === 'userId' && checkNumberTyping(value, { locale })) {
        debouncedSetUserId(value);
        setFieldValue(name, value);
        return;
      }

      setFieldValue(name, value);
    },
    [],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSubmit = useCallback(
    async (createAndApprove = false) => {
      setValidateOnChange(true);

      const errors = await formik.validateForm();
      const isValid = isEmpty(errors);

      if (!isValid || !candidate?.userUuid) return false;

      const preparePayload = () => {
        const payload: Record<string, string | number | boolean> = {};

        Object.entries({ ...values, createAndApprove }).forEach(
          ([key, value]) => {
            if (value && !key.match(/userEmail|userName|userUuid/)) {
              const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
              if (value && moment.isMoment(value)) {
                payload[snakeKey] = value.format(
                  moment.HTML5_FMT.DATE,
                ) as string;
              } else {
                payload[snakeKey] = value;
              }
            }
          },
        );

        return payload;
      };

      const payload = preparePayload();
      return new Promise<boolean>((resolve) => {
        const mutationOptions = {
          onSuccess: () => {
            handleSuccess(
              createAndApprove
                ? formatMessage(messages.fineSavedAndApproved)
                : formatMessage(messages.fineSaved),
            );
            resolve(true);
            handleClose();
          },
          onError: () => resolve(false),
        };

        return createFines(payload, mutationOptions);
      });
    },
    [values, candidate],
  );

  return (
    <ConfirmModal
      open={isOpen}
      width="700px"
      submitTextButton={formatMessage(messages.saveButton)}
      onConfirm={() => handleSubmit()}
      onClose={handleClose}
      title={formatMessage(messages.title)}
      loading={isPendingCreateFines}
      triggerButton={() => (
        <Button color="secondary" onClick={handleOpen} variant="outlined">
          {formatMessage(messages.buttonOpenLabel)}
        </Button>
      )}
      renderExtraButton={
        <Button
          onClick={() => handleSubmit(true)}
          disabled={isPendingCreateFines}
        >
          {formatMessage(messages.saveAndApproveButton)}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="userId"
            value={values.userId}
            label={formatMessage(messages.userIdLabel)}
            onChange={(event) => onChangeEventValue(event, 'userId')}
            error={!!errors.userId}
            helperText={errors.userId}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="userEmail"
            value={values.userEmail}
            label={formatMessage(messages.userEmailLabel)}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="userName"
            value={values.userName}
            label={formatMessage(messages.userNameLabel)}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="cancelId"
            value={values.cancelId}
            label={formatMessage(messages.cancelIdLabel)}
            onChange={formik.handleChange}
            error={!!errors.cancelId}
            helperText={errors.cancelId}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="jobId"
            value={values.jobId}
            label={formatMessage(messages.jobIdLabel)}
            onChange={formik.handleChange}
            error={!!errors.jobId}
            helperText={errors.jobId}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <DatePickerField
            name="affectedDate"
            error={!!errors.affectedDate}
            helperText={errors.affectedDate}
            onChange={handleChangeDate}
            label={formatMessage(messages.affectedDateLabel)}
            value={values.affectedDate}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Dropdown
            name="reasonCode"
            label={formatMessage(messages.reasonLabel)}
            value={values.reasonCode}
            error={!!errors.reasonCode}
            helperText={errors.reasonCode}
            options={acceptReason}
            onChange={(event) => onChangeEventValue(event, 'reasonCode')}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="timeDeviation"
            value={values.timeDeviation}
            label={formatMessage(messages.timeDeviationLabel)}
            onChange={formik.handleChange}
            error={!!errors.timeDeviation}
            helperText={errors.timeDeviation}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="explanation"
            value={values.explanation}
            label={formatMessage(messages.explanationLabel)}
            onChange={formik.handleChange}
            error={!!errors.explanation}
            helperText={errors.explanation}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="amount"
            value={values.amount}
            label={formatMessage(messages.amountLabel)}
            onChange={formik.handleChange}
            error={!!errors.amount}
            helperText={errors.amount}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="externalExplanation"
            value={values.externalExplanation}
            label={formatMessage(messages.externalExplanationLabel)}
            onChange={formik.handleChange}
            error={!!errors.externalExplanation}
            helperText={errors.externalExplanation}
            EndIcon={PencilAdornment}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="adminComment"
            value={values.adminComment}
            label={formatMessage(messages.adminCommentLabel)}
            onChange={formik.handleChange}
            error={!!errors.adminComment}
            helperText={errors.adminComment}
            EndIcon={PencilAdornment}
          />
        </Grid>
      </Grid>
    </ConfirmModal>
  );
}
