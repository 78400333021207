import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'src/components/DataTable';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import {
  useGetJobApplications,
  useUpdateJobFavourite,
  useUpdateReapplyShift,
} from 'src/pages/ProjectDetailsPage/api';
import { forceString } from 'src/utils/datetime';
import ConfirmationDialog from 'src/components/ConfirmationDialog';

import messages from '../../messages';
import { useStyles } from '../../JobDetails.styled';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import { JobApplicationTabProps } from '../JobApplicationsAll/JobApplicationsAll';
import ContractTooltip from '../../../ContractTooltip';

export default function JobApplicationsDenied({
  job,
  jobUuid,
  filters,
  candidate,
  handleSelectCandidate,
}: JobApplicationTabProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: jobApplications, isLoading } = useGetJobApplications({
    jobUuid,
    type: 'denied',
    filters,
    params,
    enabled: Boolean(jobUuid),
  });

  const { mutate: updateJobFavorite, isPending: isFavoritePending } =
    useUpdateJobFavourite();
  const { mutate: reApplyShift } = useUpdateReapplyShift();

  useEffect(() => {
    if (jobApplications?.data && candidate) {
      const userNotExist =
        jobApplications.data.findIndex(
          (item) => item.applicant.uuid === candidate?.applicant?.uuid,
        ) === -1;
      if (userNotExist) {
        handleSelectCandidate?.(null);
      }
    }
  }, [jobApplications?.data]);

  const handleFavorite = useCallback(
    (favorite: boolean, uuid: string) => {
      if (isFavoritePending) return;

      void updateJobFavorite({ jobUuid, applicationUuid: uuid, favorite });
    },
    [jobUuid, isFavoritePending],
  );

  const handleReapply = useCallback(
    (applicationUuid: string) => {
      reApplyShift({ jobUuid, applicationUuid });
    },
    [jobUuid],
  );

  const columns: Array<{
    id: keyof UCM.JobApplicationType | string;
    label: string | React.ReactNode;
  }> = useMemo(() => {
    const excludeColumns = job?.isFlex ? ['actions'] : [];

    const allColumns = [
      {
        id: 'applicant',
        label: (
          <Typography variant="inherit">
            {formatMessage(messages.applicantLabel)}
          </Typography>
        ),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant) return '-';
          const { id, fullName } = item.applicant;
          return (
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <FavoriteButton
                handleChange={(active) => handleFavorite(active, item.uuid)}
                active={item.favoriteManager}
              />
              <Box>
                <Typography variant="inherit">{`${id} ${fullName}`}</Typography>
              </Box>
            </Stack>
          );
        },
      },
      {
        id: 'appliedCount',
        label: formatMessage(messages.appliedToLabel),
        render: (appliedCount: number) => (
          <Typography variant="inherit">{appliedCount ?? '-'}</Typography>
        ),
        align: 'center',
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'languages',
        label: formatMessage(messages.languagesColumnLabel),
        size: 'small',
        align: 'center',
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant?.languages) return '-';
          return (
            <Typography variant="inherit">
              {item.applicant.languages?.join(', ') || '-'}
            </Typography>
          );
        },
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'initialScore',
        label: formatMessage(messages.initialScoreColumnLabel),
        size: 'small',
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.initialRatingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.initialRatingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      // {
      //   id: 'workday',
      //   label: formatMessage(messages.workdayLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">DD/MM/YYYY</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'cancelRate',
      //   label: formatMessage(messages.cancelRateLabel),
      //   render: () => <Typography variant="inherit">%</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'timeToConfirm',
      //   label: formatMessage(messages.timeToConfirmLabel),
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      {
        id: 'ratingScore',
        label: formatMessage(messages.ratingLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant.ratingScore) return '-';
          return (
            <Typography variant="inherit">
              {item?.applicant.ratingScore}
            </Typography>
          );
        },
        align: 'center',
      },
      // {
      //   id: 'preScreen',
      //   label: formatMessage(messages.preScreenLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">-</Typography>,
      //   align: 'center',
      // },
      // {
      //   id: 'profile',
      //   label: formatMessage(messages.profileLabel),
      //   // TODO - waiting for backend adjustements
      //   render: () => <Typography variant="inherit">%</Typography>,
      //   align: 'center',
      // },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'profile',
        label: formatMessage(messages.profileLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.applicant?.status) return '-';
          let label = formatMessage(messages.completeLabel);
          if (item?.applicant?.status?.code === 'required')
            label = formatMessage(messages.incompleteLabel);
          return <Typography variant="inherit">{label}</Typography>;
        },
        align: 'center',
      },
      {
        id: 'statusCode',
        label: formatMessage(messages.statusLabel),
        render: (statusCode: string) => (
          <Typography variant="inherit">{statusCode ?? '-'}</Typography>
        ),
        align: 'center',
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'briefingReadAt',
        label: formatMessage(messages.briefingLabel),
        render: (briefingReadAt: string | null) => {
          if (!briefingReadAt)
            return (
              <CloseOutlinedIcon
                sx={{ color: '#904A44', width: 24, height: 24 }}
              />
            );
          return (
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="center"
              gap={1}
            >
              <CheckOutlinedIcon
                sx={{ color: '#1E6A4F', width: 24, height: 24 }}
              />
              {forceString(briefingReadAt)}
            </Stack>
          );
        },
        align: 'center',
      },
      {
        id: 'contractSummary',
        label: formatMessage(messages.contractSummaryLabel),
        render: (_: Object, item: UCM.JobApplicationType) => {
          if (!item?.contractSummary.status) return '-';
          return <ContractTooltip contract={item?.contractSummary} />;
        },
        align: 'center',
      },
      // TODO - temporary solution - waiting for BE adjustment
      {
        id: 'daysSinceApplied',
        label: formatMessage(messages.daysSinceAppliedLabel),
        render: (daysSinceApplied: string) => (
          <Typography variant="inherit">{daysSinceApplied ?? '-'}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: (_: Object, item: UCM.JobApplicationType) => (
          <ConfirmationDialog
            maxWidth="sm"
            onConfirm={() => handleReapply(item.uuid)}
            message={formatMessage(messages.reapplyApplicationConfirmMessage)}
          >
            {(handleOpen) => (
              <Tooltip title={formatMessage(messages.reapplyTooltip)}>
                <span>
                  <IconButton
                    disabled={item.statusCode === 'unbooked'}
                    onClick={handleOpen}
                  >
                    <SettingsBackupRestoreIcon color="primary" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </ConfirmationDialog>
        ),
      },
    ];

    return allColumns.filter((column) => !excludeColumns.includes(column.id));
  }, [job?.isFlex, handleFavorite]);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  return (
    <Grid size={{ xs: 12 }}>
      <DataTable
        rows={jobApplications?.data}
        columns={columns}
        className={classes.tableContainer}
        loading={isLoading}
        activeRow={candidate}
        pressRow={handleSelectCandidate}
        checkActiveKey="applicant.id"
        onPaginate={handlePagination}
        pagination={jobApplications?.meta}
      />
    </Grid>
  );
}
