import { Styled } from './index.styled';

interface ToggleProps<T> {
  selected: T;
  options: Array<string>;
  onChange: (value: T) => void;
}

export default function ToggleSwitch<T>({
  options,
  selected,
  onChange,
}: ToggleProps<T>) {
  return (
    <Styled.StyledToggleGroup
      exclusive
      value={selected}
      onChange={(_, newValue) => newValue !== null && onChange(newValue)}
    >
      {options.map((option) => (
        <Styled.StyledToggleButton key={option} value={option}>
          {option}
        </Styled.StyledToggleButton>
      ))}
    </Styled.StyledToggleGroup>
  );
}
