import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

const updateCancellationBackup = async ({
  uuid,
  isBackupRequired,
}: {
  uuid: string;
  isBackupRequired: boolean;
}) => {
  const backupStatus = isBackupRequired ? 'require_backup' : 'decline_backup';

  const { data } = await putRequest(
    `${API.PUT.updateCancellationNotices(uuid)}/${backupStatus}`,
  );
  return data;
};

export const useUpdateCancellationBackup = () => {
  const client = useQueryClient();
  const { handleError } = useMessageHandler();

  return useMutation({
    mutationFn: updateCancellationBackup,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.CANCELLATIONS] });
    },
    onError: handleError,
  });
};
