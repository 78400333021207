/*
 * LongTermContractEditPanel Messages
 *
 * This contains all the text for the LongTermContractEditPanel component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ContractsPage.components.LongTermContractEditPanel';

export default defineMessages({
  userIdLabel: {
    id: `${scope}.userIdLabel`,
    defaultMessage: 'User ID',
  },
  userEmailLabel: {
    id: `${scope}.userEmailLabel`,
    defaultMessage: 'Email',
  },
  userNameLabel: {
    id: `${scope}.userNameLabel`,
    defaultMessage: 'Student Name',
  },
  customerLabel: {
    id: `${scope}.customerLabel`,
    defaultMessage: 'Customer',
  },
  certificateLabel: {
    id: `${scope}.certificateLabel`,
    defaultMessage: 'Certificate',
  },
  employmentTypeLabel: {
    id: `${scope}.employmentTypeLabel`,
    defaultMessage: 'Employment',
  },
  jobRequirementLabel: {
    id: `${scope}.jobRequirementLabel`,
    defaultMessage: 'Requirement',
  },
  jobLocationLabel: {
    id: `${scope}.jobLocationLabel`,
    defaultMessage: 'Place of Work',
  },
  tariffGroupLabel: {
    id: `${scope}.tariffGroupLabel`,
    defaultMessage: 'Pay Grade',
  },
  unpaidLeaveLabel: {
    id: `${scope}.unpaidLeaveLabel`,
    defaultMessage: 'Unpaid Leave (Mo)',
  },
  taskTypeLabel: {
    id: `${scope}.taskTypeLabel`,
    defaultMessage: 'Task',
  },
  jobTypeLabel: {
    id: `${scope}.jobTypeLabel`,
    defaultMessage: 'Job Type',
  },
  plannedStartDateLabel: {
    id: `${scope}.plannedStartDateLabel`,
    defaultMessage: 'Planned Start Date',
  },
  plannedEndDateLabel: {
    id: `${scope}.plannedEndDateLabel`,
    defaultMessage: 'Planned End Date',
  },
  maxDurationLabel: {
    id: `${scope}.maxDurationLabel`,
    defaultMessage: 'Max. Duration (Mo)',
  },
  latestStartDateLabel: {
    id: `${scope}.latestStartDateLabel`,
    defaultMessage: 'Latest Start Date',
  },
  startDateLabel: {
    id: `${scope}.startDateLabel`,
    defaultMessage: 'Start Date',
  },
  endDateLabel: {
    id: `${scope}.endDateLabel`,
    defaultMessage: 'End Date',
  },
  wagePerHourLabel: {
    id: `${scope}.wagePerHourLabel`,
    defaultMessage: 'Wage / Hour',
  },
  weeklyHoursLabel: {
    id: `${scope}.weeklyHoursLabel`,
    defaultMessage: 'Weekly Hours',
  },
  monthlyHoursLabel: {
    id: `${scope}.monthlyHoursLabel`,
    defaultMessage: 'Monthly Hours',
  },
});
