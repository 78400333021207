const GET = {
  loginGoogle: '/auth/google_oauth2/callback',
  languageLevelOptions: '/dictionaries/language_levels',
  occupationOptions: '/dictionaries/occupations',
  cityOptions: '/dictionaries/cities',
  countryOptions: '/dictionaries/countries',
  checkEmail: '/user_accounts/email_available',
  getUserAccount: (uuid: string) => `/candidates/${uuid}/user_accounts`,
  getCandidate: (uuid?: string) => `/candidates/${uuid}`,
  getAllUserAccounts: `/candidates/user_accounts`,
  getUserAccounts: (candidateStatus: UCM.CandidateStatusType) =>
    `/candidates/user_accounts/${candidateStatus}`,
  interviewMethodOptions: '/dictionaries/prescreening_methods',
  getPrescreening: (candidateUuid: string, uuid: string) =>
    `/candidates/${candidateUuid}/prescreenings/${uuid}`,
  getAllProjects: '/projects',
  getProject: (uuid: string) => `/projects/${uuid}`,
  getProjectSettings: (uuid: string) => `/projects/${uuid}/settings`,
  getProjectStaffPlan: (projectUuid: string) =>
    `/projects/${projectUuid}/staff_plan`,
  getJobs: (projectUuid: string) => `projects/${projectUuid}/jobs`,
  getJobStaffPlan: (jobUuid: string) => `/jobs/${jobUuid}/staff_plan`,
  getCandidates: `/candidates`,
  getLanguageOptions: `/languages`,
  getJobTypeOptions: `/job_types`,
  getTaskOptions: `/tasks`,
  getDynamicFields: `/dynamic_fields`,
  getStaffTypeOptions: `/staff_types`,
  getStaffType: (staffTypeUuid: string) => `/staff_types/${staffTypeUuid}`,
  getJob: (jobUuid?: string, params?: string) =>
    `jobs/${jobUuid}/details${params ?? ''}`,
  getPositionJobDates: (projectUuid: string) =>
    `/position_job_dates/${projectUuid}`,
  getDownloadPositionJobDates: (params: string) =>
    `/position_job_dates/export_csv?${params}`,
  getDailyBriefing: (params: string) => `/position_job_dates?${params}`,
  getBriefingDetails: (jobUuid: string) => `/jobs/${jobUuid}/briefings`,
  getDailyBriefingDetails: (jobUuid: string, uuid: string) =>
    `/jobs/${jobUuid}/briefings/${uuid}`,
  getJobApplications: (jobUuid: string, type: string, params: string) =>
    `/jobs/${jobUuid}/job_applications/${type}${params}`,
  getBookedToTransfer: (standbyUuid: string, params: string) =>
    `/jobs/${standbyUuid}/job_applications/booked_to_transfer?${params}`,
  getBookedFromTransfer: (jobUuid: string, params: string) =>
    `/jobs/${jobUuid}/job_applications/booked_from_transfer?${params}`,
  getRatingScores: `/user_rating_scores`,
  getUserRatingScores: (jobUuid: string) =>
    `/jobs/${jobUuid}/job_applications/user_job_performance_ratings`,
  getAcceptReason: (params: string) => `/acceptance_reasons?${params}`,
  getRejectReason: (params: string) => `/rejection_reasons?${params}`,
  getFieldsCategory: (params: string) => `/field_categories?${params}`,
  getInternalAttributes: (userUuid?: string, params?: string) =>
    `/candidates/${userUuid}/dynamic_fields?${params}`,
  getCalendarJobs: (jobUuid: string, params: string) =>
    `/jobs/${jobUuid}/job_applications/flex_date?${params}`,
  getCancellationShifts: (params: string) =>
    `/cancellation_notices/shift_options?${params}`,
  getCancellationNotices: (uuid?: string) => `/cancellation_notices/${uuid}`,
  getJobCoverages: (jobUuid: string) => `standby_jobs/${jobUuid}/job_coverages`,
  getShiftsList: (uuid: string) => `/jobs/${uuid}/shifts`,
  backupShiftOptions: (jobUuid: string, params?: string) =>
    `jobs/${jobUuid}/backup_shift_options?${params}`,
  verificationMethods: `/projects/verification_methods`,
  getAssetsPictures: (params: string) => `/assets/pictures?${params}`,
  getAcceptedCount: (jobUuid: string) =>
    `/jobs/${jobUuid}/job_applications/accepted_count`,
  getJobStatuses: (params?: string) => `job_statuses?${params}`,
  getContracts: `/user_contracts`,
  getDownloadContract: (contractUuid: string) =>
    `user_contracts/${contractUuid}/download`,
  contractEmploymentTypeOptions: `/user_contracts/employment_types`,
  contractRequirementOptions: `/user_contracts/requirements`,
  customerOptions: `/customers`,
  contractTaskOptions: `/user_contracts/task_types`,
  contractJobTypeOptions: `/user_contracts/job_types`,
  contractTariffGroupOptions: `/user_contracts/tariff_groups`,
  contractLocationOptions: `/user_contracts/locations`,
  contractCertificateOptions: `/certificates/proof_required`,
  getCancellations: (params?: string) => `/cancellation_notices${params}`,
  getFines: (params?: string) => `/fines${params}`,
  getPreviousFineReports: (params?: string) => `/fines_reports${params}`,
  downloadFineReport: (uuid: string) => `/fines_reports/${uuid}/download`,
};

const POST = {
  loginUser: '/login/admin',
  createPreRegistration: '/candidates/user_accounts',
  createPrescreening: (candidateUuid: string) =>
    `/candidates/${candidateUuid}/prescreenings/`,
  createApplyUser: '/quick_apply',
  createJob: (projectUuid: string) => `/projects/${projectUuid}/jobs`,
  createStaffType: `/staff_types`,
  createJobFields: (jobUuid: string) => `jobs/${jobUuid}/dynamic_fields`,
  createBriefing: (jobUuid: string) => `jobs/${jobUuid}/briefings`,
  createUserRating: (jobUuid: string, applicationUuid: string) =>
    `/jobs/${jobUuid}/job_applications/${applicationUuid}/user_job_performance_ratings`,
  cancellationNotices: `cancellation_notices`,
  createShift: (uuid: string) => `/jobs/${uuid}/shifts`,
  createBackupJob: (jobUuid: string) => `/jobs/${jobUuid}/backup_jobs`,
  createContract: '/user_contracts',
  finesReports: `/fines_reports`,
  createFines: `/fines`,
};

const PATCH = {};

const PUT = {
  updateUserAccount: (uuid: string) => `/candidates/${uuid}/user_accounts`,
  updatePrescreening: (candidateUuid: string, uuid: string) =>
    `/candidates/${candidateUuid}/prescreenings/${uuid}`,
  grantStudentAccess: (candidateUuid: string) =>
    `/candidates/${candidateUuid}/user_roles/grant_student_access`,
  setProjectPriority: (projectUuid: string) =>
    `/projects/${projectUuid}/set_priority`,
  removeProjectPriority: (projectUuid: string) =>
    `/projects/${projectUuid}/remove_priority`,
  updateStaffType: (staffUuid: string) => `/staff_types/${staffUuid}`,
  updateJob: (jobUuid: string) => `jobs/${jobUuid}`,
  importPositionJobDates: (params?: string) =>
    `/position_job_dates/import_csv?${params}`,
  briefingAssignUsers: (params: string) =>
    `/position_job_dates/assign_users?${params}`,
  updateBriefingDetails: (jobUuid: string, uuid: string) =>
    `/jobs/${jobUuid}/briefings/${uuid}`,
  updateJobVisibility: (jobUuid: string, visible: boolean) =>
    `/jobs/${jobUuid}/${visible ? 'publish' : 'hide'}`,
  updateJobStatus: (jobUuid: string, status: string) =>
    `/jobs/${jobUuid}/${status}`,
  updateJobFavorite: (
    jobUuid: string,
    applicationUuid: string,
    favorite: boolean,
  ) =>
    `jobs/${jobUuid}/job_applications/${applicationUuid}/${favorite ? 'favorite' : 'unfavorite'}`,
  updateUserRating: (
    jobUuid: string,
    applicationUuid: string,
    ratingUuid: string,
  ) =>
    `/jobs/${jobUuid}/job_applications/${applicationUuid}/user_job_performance_ratings/${ratingUuid}`,
  acceptAllShifts: (jobUuid: string, userUuid: string) =>
    `/candidates/${userUuid}/jobs/${jobUuid}/job_applications/accept`,
  rejectAllShifts: (jobUuid: string, userUuid: string) =>
    `/candidates/${userUuid}/jobs/${jobUuid}/job_applications/reject`,
  acceptShift: (jobUuid: string, shiftUuid: string) =>
    `/jobs/${jobUuid}/job_applications/${shiftUuid}/accept`,
  rejectShift: (
    jobUuid: string,
    shiftUuid: string,
    type: 'reject' | 'cancel',
  ) => `/jobs/${jobUuid}/job_applications/${shiftUuid}/${type}`,
  reapplyShift: (jobUuid: string, shiftUuid: string) =>
    `/jobs/${jobUuid}/job_applications/${shiftUuid}/reapply`,
  updateCancellationNotices: (uuid?: string) => `/cancellation_notices/${uuid}`,
  withdrawCancellationNotices: (uuid?: string) =>
    `/cancellation_notices/${uuid}/withdraw`,
  updateJobCoverages: (jobUuid: string) =>
    `standby_jobs/${jobUuid}/job_coverages`,
  updateShift: (jobUuid: string, shiftUuid: string) =>
    `/jobs/${jobUuid}/shifts/${shiftUuid}`,
  updateBackupJob: (uuid: string) => `/backup_jobs/${uuid}`,
  updateFine: (uuid: string) => `/fines/${uuid}`,
  updatePayFine: (uuid: string) => `/fines/${uuid}/pay`,
  updateApproveFines: '/fines/approve',
  updateRevertFines: '/fines/revert',
  updateExemptFines: '/fines/exempt',
  updateRebookShift: (applicationUuid: string) =>
    `/candidate_job_shifts/${applicationUuid}/reset_status`,
  updateContract: (contractUuid: string) => `/user_contracts/${contractUuid}`,
  activateContract: (contractUuid: string) =>
    `/user_contracts/${contractUuid}/activate`,
};

const DELETE = {
  deleteStaffType: (staffUuid: string) => `/staff_types/${staffUuid}`,
  deleteJob: (jobUuid: string) => `/jobs/${jobUuid}`,
  deleteJobFields: (jobUuid: string) => `jobs/${jobUuid}/dynamic_fields`,
  deleteShift: (jobUuid: string, shiftUuid: string) =>
    `/jobs/${jobUuid}/shifts/${shiftUuid}`,
  deleteContract: (contractUuid: string) => `/user_contracts/${contractUuid}`,
  fines: '/fines',
};

export const API = { GET, PUT, POST, PATCH, DELETE };
