import { useState, useMemo, useCallback, type FocusEvent, useRef } from 'react';

import { useIntl } from 'react-intl';

import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import {
  DateField,
  PickersDay,
  MobileDatePicker,
  type PickersDayProps,
} from '@mui/x-date-pickers';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

import type { Moment } from 'moment';

import messages from './messages';

const inputProps = { endAdornment: <TodayOutlinedIcon color="primary" /> };

type DatePickerFieldProps = {
  name?: string;
  label?: string;
  error?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  editable?: boolean;
  helperText?: string;
  value?: Moment | null;
  variant?: 'standard' | 'filled' | 'outlined';
  onChange?: (date: Moment | Moment[] | null) => void;
  onBlurChange?: (date: Moment | null | undefined) => void;
};

export const DatePickerField = ({
  name,
  label,
  value,
  error,
  onChange,
  helperText,
  onBlurChange,
  readOnly = false,
  multiple = false,
  disabled = false,
  editable = false,
  variant = 'standard',
}: DatePickerFieldProps) => {
  const { formatMessage } = useIntl();

  const originalValue = useRef(value);
  const [date, setDate] = useState(value);
  const [selectedDates, setSelectedDates] = useState<Moment[]>([]);

  // Handle the label for multiple-select
  const handleLabel = useMemo(() => {
    const selectedDatesLength = size(selectedDates);
    if (selectedDatesLength === 0) return label;
    return selectedDatesLength === 1
      ? `${selectedDatesLength} ${formatMessage(messages.dayLabel)}`
      : `${selectedDatesLength} ${formatMessage(messages.daysLabel)}`;
  }, [label, selectedDates]);

  // Handle day click for multiple mode
  const handleDayClick = useCallback(
    (day: Moment) => {
      setSelectedDates((prevSelectedDates) => {
        const isSelected = prevSelectedDates.some((selected) =>
          day.isSame(selected, 'day'),
        );
        const newSelectedDates = isSelected
          ? prevSelectedDates.filter((selected) => !day.isSame(selected, 'day')) // Remove if already selected
          : [...prevSelectedDates, day]; // Add if not selected

        const sortedDates = sortBy(newSelectedDates, (date) => +date);
        onChange?.(sortedDates);
        return sortedDates;
      });
    },
    [onChange],
  );

  // Render the individual day in the calendar for multiple mode
  const handleRenderDay = useCallback(
    (props: PickersDayProps<Moment>) => {
      const isSelectedDay = selectedDates.some((selectedDate) =>
        selectedDate.isSame(props.day, 'day'),
      );
      return (
        <PickersDay
          {...props}
          selected={isSelectedDay}
          onDaySelect={() => handleDayClick(props.day)}
        />
      );
    },
    [selectedDates, handleDayClick],
  );

  const handleChange = useCallback(
    (date: Moment | null) => {
      if (multiple) return;
      onChange?.(date);
    },
    [onChange, multiple],
  );

  const slotProps = useMemo(
    () => ({
      textField: {
        error,
        variant,
        helperText,
        fullWidth: true,
        label: handleLabel,
        InputProps: inputProps,
      },
    }),
    [helperText, error, variant, handleLabel],
  );

  if (editable) {
    const handleBlur = useCallback(
      (_: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const originalDate = originalValue.current;

        // Early return if no change handler
        if (!onBlurChange) return;

        // Case 1: No original date, but we have a new valid date
        const hasNewValidDate = date && date.isValid();
        if (!originalDate && hasNewValidDate) {
          onBlurChange(date);
          originalValue.current = date;
          return;
        }

        // Case 2: Had original date, but now it's cleared
        const hasDateCleared = originalDate && !date;
        if (hasDateCleared) {
          onBlurChange(null);
          originalValue.current = null;
          return;
        }

        // Case 3: Both dates exist, check if they're different
        if (originalDate && date && date.isValid()) {
          const datesAreDifferent = !originalDate
            .startOf('day')
            .isSame(date.startOf('day'), 'day');
          if (datesAreDifferent) {
            onBlurChange(date);
            originalValue.current = date;
          }
        }
      },
      [onBlurChange, date],
    );

    return (
      <DateField
        name={name}
        value={date}
        fullWidth={true}
        variant={variant}
        onChange={setDate}
        disabled={disabled}
        readOnly={readOnly}
        label={handleLabel}
        onBlur={handleBlur}
        helperText={helperText}
      />
    );
  }

  return (
    <MobileDatePicker
      name={name}
      value={value}
      reduceAnimations
      disabled={disabled}
      readOnly={readOnly}
      slotProps={slotProps}
      onChange={handleChange}
      slots={multiple ? { day: handleRenderDay } : undefined}
    />
  );
};
