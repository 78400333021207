import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import { colors } from 'src/utils/customColors';
import { makeStyles } from '@mui/styles';

// max-height: 53vh;
// @media (max-width: 1200px) {
//   max-height: 59vh;
// }

export const Styled = {
  Container: styled(Box)`
    display: flex;
    flex: 1;
    overflow: hidden;
    max-width: calc(100vw - 118px);
    border-radius: 16px;
    flex-direction: column;
    border: 1px solid ${colors.mediumGrey};
  `,
  CloseContainer: styled(IconButton)({
    width: '50px',
    height: '50px',
  }),
  Row: styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Toolbar: styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    & svg {
      color: #787878;
      width: 24px;
      height: 24px;
    }
  `,
  Title: styled('p')`
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    margin: 0;
  `,
  Content: styled(Box)``,
};

//@ts-ignore
export const useStyles = makeStyles(() => ({
  tableContainer: {
    borderLeft: '0 !important',
    borderRight: '0 !important',
    borderRadius: '0 !important',
    borderBottom: '0 !important',
    maxHeight: '40vh',
    // minHeight: 'auto !important',
    // height: '43vh',
    // overflowY: 'scroll !important',

    '& .MuiTableCell-root:not(last-child)': {
      borderRight: 0,
    },
  },
}));
