import moment from 'moment';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import { forceString } from 'src/utils/datetime';
import useMessenger from 'src/utils/hooks/useMessenger';
import { RequestSimplifiedData } from 'src/types/requestData';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { useDeleteJobFields } from './useDeleteJobFields';
import { useCreateJobFields, type JobFieldType } from './useCreateJobFields';

import type { JobParamsType } from './useCreateJob';

interface UpdateJobParamsType extends JobParamsType {
  isFlex: boolean;
  jobUuid: string;
  isStandby: boolean;
  fields: Array<JobFieldType>;
  fieldsToRemove: Array<Record<'fieldUuid', string>>;
}

const updateJob = async (props: UpdateJobParamsType) => {
  const params = {
    job: {
      title: props.title,
      type: props.jobTypeCode,
      total_vacancies: props.quantity,
      booking_deadline_date: forceString(
        props.bookingDeadlineDate,
        moment.HTML5_FMT.DATE,
      ),
      description: props.description,
      address: {
        name: props.venue,
        city: props.cityCode,
        post_code: props.zipCode,
        street_name: props.address,
        house_number: props.houseNumber,
      },
      wage: props.salary,
      price: props.price,
      is_flex: props.isFlex,
      tasks: props.taskCodes,
      is_standby: props.isStandby,
      wage_bonus: props.salaryBonus,
      price_bonus: props.priceBonus,
      tariff_code: props.tariffCode,
      languages: props.languageCodes,
      staff_type_uuid: props.staffTypeUuid,
      ignore_user_desired_cities: props.ignoreDesiredCities,
    },
  };

  const { data } = await putRequest<RequestSimplifiedData>(
    API.PUT.updateJob(props.jobUuid),
    params,
  );

  return data;
};

export const useUpdateJob = () => {
  const messenger = useMessenger();
  const { formatMessage } = useIntl();
  const { handleError } = useMessageHandler();

  const { mutate: createJobFields } = useCreateJobFields();
  const { mutate: deleteJobFields } = useDeleteJobFields();

  return useMutation({
    mutationFn: updateJob,
    onSuccess: ({ data: { uuid } }, { fieldsToRemove, fields }) => {
      return deleteJobFields(
        { jobUuid: uuid, fieldsToRemove },
        {
          onSuccess: () => {
            return createJobFields(
              { jobUuid: uuid, fields },
              {
                onError: () =>
                  messenger.warning(
                    formatMessage(messages.jobPartiallySaved),
                    1000,
                  ),
              },
            );
          },
        },
      );
    },
    onError: handleError,
  });
};
