import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import messages from '../../messages';

const updateFine = async ({
  uuid,
  externalExplanation,
  adminComment,
  amount,
}: {
  uuid: string;
  externalExplanation?: string | null;
  adminComment?: string | null;
  amount?: number | null;
}) => {
  const payload = {
    external_explanation: externalExplanation,
    admin_comment: adminComment,
    amount: Number(amount),
  };

  const { data } = await putRequest(API.PUT.updateFine(uuid), payload);
  return data;
};

export const useUpdateFine = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateFine,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.FINES] });
      handleSuccess(formatMessage(messages.fineSaved), 1000);
    },
    onError: handleError,
  });
};
