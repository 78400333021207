/*
 * CancellationModal Messages
 *
 * This contains all the text for the CancellationModal component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.CancellationModal';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Cancellation Notice',
  },
  dateLabel: {
    id: `${scope}.dateLabel`,
    defaultMessage: 'Date',
  },
  jobIdLabel: {
    id: `${scope}.jobIdLabel`,
    defaultMessage: 'Job ID',
  },
  shiftTimeframeLabel: {
    id: `${scope}.shiftTimeframeLabel`,
    defaultMessage: 'Shift Timeframe',
  },
  confirmButtonLabel: {
    id: `${scope}.confirmButtonLabel`,
    defaultMessage: 'Confirm',
  },
  confirmCancelTitle: {
    id: `${scope}.confirmCancelTitle`,
    defaultMessage:
      'Are you sure you want to confirm this cancellation with a student-related reason?',
  },
  confirmCancelMessage: {
    id: `${scope}.confirmCancelMessage`,
    defaultMessage:
      "These should only be used in exceptional cases where the student can't cancel themselves. If not, please choose a different reason or check Confluence for guidance on using cancellation reasons!",
  },
  userInputLabel: {
    id: `${scope}.userInputLabel`,
    defaultMessage: 'User ID',
  },
  emailInputLabel: {
    id: `${scope}.emailInputLabel`,
    defaultMessage: 'E-mail',
  },
  nameInputLabel: {
    id: `${scope}.nameInputLabel`,
    defaultMessage: 'Name',
  },
  cancelIdInputLabel: {
    id: `${scope}.cancelIdInputLabel`,
    defaultMessage: 'Cancel ID',
  },
  cancelDropDownLabel: {
    id: `${scope}.cancelDropDownLabel`,
    defaultMessage: 'Reason for uncancelling',
  },
  reasonDropDownLabel: {
    id: `${scope}.reasonDropDownLabel`,
    defaultMessage: 'Reason',
  },
  userCommentInputLabel: {
    id: `${scope}.userCommentInputLabel`,
    defaultMessage: 'User comment*',
  },
  adminCommentInputLabel: {
    id: `${scope}.adminCommentInputLabel`,
    defaultMessage: 'Admin comment',
  },
});
