/*
 * ProjectSettings Form Messages
 *
 * This contains all the text for the Summary Panel component.
 */

import { defineMessages } from 'react-intl';
export const scope =
  'src.pages.ProjectDetailsPage.components.ProjectSettingsForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Project Info',
  },
  detailSectionTitle: {
    id: `${scope}.detailSectionTitle`,
    defaultMessage: 'Details',
  },
  projectTypeFieldLabel: {
    id: `${scope}.projectTypeFieldLabel`,
    defaultMessage: 'Project Type',
  },
  statusFieldLabel: {
    id: `${scope}.statusFieldLabel`,
    defaultMessage: 'Status',
  },
  visibilityFieldLabel: {
    id: `${scope}.visibilityFieldLabel`,
    defaultMessage: 'Visibility',
  },
  verificationCodeFieldLabel: {
    id: `${scope}.verificationCodeFieldLabel`,
    defaultMessage: 'Verification Code',
  },
  certificateFieldLabel: {
    id: `${scope}.certificateFieldLabel`,
    defaultMessage: 'Certificate',
  },
  descriptionFieldLabel: {
    id: `${scope}.descriptionFieldLabel`,
    defaultMessage: 'Description',
  },
  contactPersonFieldLabel: {
    id: `${scope}.contactPersonFieldLabel`,
    defaultMessage: 'Contact Person',
  },
  projectManagerFieldLabel: {
    id: `${scope}.projectManagerFieldLabel`,
    defaultMessage: 'Project Manager',
  },
  settingSectionTitle: {
    id: `${scope}.settingSectionTitle`,
    defaultMessage: 'Settings',
  },
  minWorkHoursFieldLabel: {
    id: `${scope}.minWorkHoursFieldLabel`,
    defaultMessage: 'Minimum Working Hours',
  },
  wageBonusNightFieldLabel: {
    id: `${scope}.wageBonusNightFieldLabel`,
    defaultMessage: 'Wage Bonus - Night',
  },
  wageBonusSundayFieldLabel: {
    id: `${scope}.wageBonusSundayFieldLabel`,
    defaultMessage: 'Wage Bonus - Sunday',
  },
  wageBonusHolidayFieldLabel: {
    id: `${scope}.wageBonusHolidayFieldLabel`,
    defaultMessage: 'Wage Bonus - Holiday',
  },
  verificationMethodFieldLabel: {
    id: `${scope}.verificationMethodFieldLabel`,
    defaultMessage: 'TT Verification Method',
  },
  isBreakFieldLabel: {
    id: `${scope}.isBreakFieldLabel`,
    defaultMessage: 'Break Included?',
  },
  yesLabel: {
    id: `${scope}.yesLabel`,
    defaultMessage: 'Yes',
  },
  noLabel: {
    id: `${scope}.noLabel`,
    defaultMessage: 'No',
  },
});
