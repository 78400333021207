import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import messages from '../../messages';

const updateApproveFines = async ({ uuids }: { uuids: Array<string> }) => {
  const payload = {
    fine_uuids: uuids,
  };

  const { data } = await putRequest(API.PUT.updateApproveFines, payload);
  return data;
};

export const useUpdateApproveFines = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateApproveFines,
    onSuccess: (res) => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.FINES] });
      handleSuccess(
        res?.data?.uuids?.length > 1
          ? formatMessage(messages.finesApproved)
          : formatMessage(messages.fineApproved),
      );
    },
    onError: handleError,
  });
};
