import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';

const mapProjectSetting = (
  setting: UCM.ProjectSettingRawType,
): UCM.ProjectSettingType => ({
  allowedDeviationHours: setting.allowed_deviation_hours,
  allowedOvertimeHours: setting.allowed_overtime_hours,
  isAutoBackup: setting.is_auto_backup,
  isBreak: setting.is_break,
  isCommutePaid: setting.is_commute_paid,
  isHelpNeeded: setting.is_help_needed,
  isTest: setting.is_test,
  minWorkingHours: setting.min_working_hours,
  priceBonusHoliday: setting.price_bonus_holiday,
  priceBonusNight: setting.price_bonus_night,
  priceBonusSunday: setting.price_bonus_sunday,
  standbyPriority: setting.standby_priority,
  verificationMethod: setting.verification_method,
  wageBonusHoliday: setting.wage_bonus_holiday,
  wageBonusNight: setting.wage_bonus_night,
  wageBonusSunday: setting.wage_bonus_sunday,
});

const getProjectSettings = async (uuid: string) => {
  const {
    data: {
      data: { attributes },
    },
  } = await getRequest<RequestData<UCM.ProjectSettingRawType>>(
    API.GET.getProjectSettings(uuid),
  );
  return mapProjectSetting(attributes);
};

export const useGetProjectSettings = (
  uuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROJECT_SETTINGS, uuid],
    queryFn: () => getProjectSettings(uuid),
    staleTime: 1000 * 60,
    ...config,
  });
};
