import { useIntl } from 'react-intl';

import TextInput from 'src/components/TextInput';
import { buildHelperText } from 'src/utils/components';

import messages from './messages';

interface TextFieldProps {
  helperText?: string;
  field: UCM.DynamicFieldType;
}

export default function TextField({ field, helperText = '' }: TextFieldProps) {
  const { formatMessage } = useIntl();

  return (
    <TextInput
      disabled
      fullWidth
      placeholder={formatMessage(messages.textFieldPlaceholder)}
      helperText={buildHelperText(field.description, helperText, field.label)}
    />
  );
}
