import { styled, css } from '@mui/material/styles';

interface WrapperProps {
  fullScreen?: boolean;
}

const Wrapper = styled('div')<WrapperProps>`
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          height: 100vh;
          position: fixed; /* Use fixed to cover the whole viewport */
          top: 0;
          left: 0;
          right: 0;
          display: flex;
        `
      : css`
          height: 100%; /* Take height of the container */
          position: absolute; /* Position relative to its parent */
          display: flex;
        `}
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

export default Wrapper;
