import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';

const mapJobType = (task: UCM.TaskOptionRawType): UCM.TaskOptionType => ({
  value: task.code,
  label: task.title,
});

const getTaskOptions = async () => {
  const {
    data: { data },
  } = await getRequest<RequestDataArray<UCM.TaskOptionRawType>>(
    API.GET.getTaskOptions,
    { headers: { Accept: 'application/vnd.falcon.v1+json' } },
  );

  return map(data, ({ attributes }) => mapJobType(attributes));
};

export const useGetTaskOptions = (
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TASK_OPTIONS],
    queryFn: getTaskOptions,
    ...config,
  });
};
