import { useIntl } from 'react-intl';

import Chip from '@mui/material/Chip';
import styled from '@mui/material/styles/styled';

import { colors } from 'src/utils/customColors';

import messages from './messages';

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: colors.turquoise,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(0.5),
  height: theme.spacing(2.5),
  '& .MuiChip-icon': {
    color: theme.palette.common.white,
  },
}));

export default function FlexDateChip() {
  const { formatMessage } = useIntl();

  return <StyledChip label={formatMessage(messages.flexDate)} size="small" />;
}
