import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

type UpdateJobCoverageParamsType = {
  jobUuid: string;
  transferBonus: number | string;
  jobCoverageUuids: Array<string>;
};

const updateJobCoverage = async ({
  jobUuid,
  transferBonus,
  jobCoverageUuids,
}: UpdateJobCoverageParamsType) => {
  const params = {
    job: {
      job_uuids: jobCoverageUuids,
      transfer_bonus: transferBonus,
    },
  };

  const { data } = await putRequest<RequestSimplifiedData>(
    API.PUT.updateJobCoverages(jobUuid),
    params,
  );

  return data;
};

export const useUpdateJobCoverage = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateJobCoverage,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.jobCoverageSaved), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOB_COVERAGES] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
    },
    onError: handleError,
  });
};
