import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { colors } from 'src/utils/customColors';

export const Styled = {
  ToggleButton: styled(Stack)(({ theme }) => ({
    color: colors.darkerGrey,
    cursor: 'pointer',
    width: 'fit-content',
    minHeight: theme.spacing(7),
    alignItems: 'center',
  })),
};
