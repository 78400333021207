import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { queryClient } from 'src/config';
import { putRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import messages from '../../messages';

interface UpdateContractParamsType {
  contractUuid: string;
  signedByUcmAt: string | null;
  signedByUserAt: string | null;
}

const updateContract = async ({
  contractUuid,
  signedByUcmAt,
  signedByUserAt,
}: UpdateContractParamsType) => {
  const params = {
    user_contract: {
      user_signing_date: signedByUserAt,
      company_signing_date: signedByUcmAt,
    },
  };

  return await putRequest<RequestSimplifiedData>(
    API.PUT.updateContract(contractUuid),
    params,
    { headers: { Accept: 'application/vnd.falcon.v2+json' } },
  );
};

export const useUpdateContract = () => {
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateContract,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.contractSaved));
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CONTRACTS],
      });
    },
    onError: handleError,
  });
};
