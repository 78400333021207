import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestDataArray } from 'src/types/requestData';
import { getRequest, serialize } from 'src/utils/request';

export type TimeScopeType = string | 'active' | 'past' | 'future';

export interface DailyBriefingsFilters {
  project_uuid: string;
  time_scope: TimeScopeType;
}

const getDailyBriefings = async (
  filters: DailyBriefingsFilters,
  params: Record<string, string | number>,
) => {
  const { time_scope, ...others } = filters;

  const updatedFilter = {
    ...others,
    ...(!time_scope ? {} : { time_scope }),
  };

  const queryParams = serialize(params);
  const queryFilters = serialize(updatedFilter, 'filters');
  const queryString = [queryParams, queryFilters].filter(Boolean).join('&');

  const {
    data: { data, meta },
  } = await getRequest<
    RequestDataArray<UCM.DailyBriefingRawType, MetaBriefingRaw>
  >(API.GET.getDailyBriefing(queryString));

  return {
    data: mapBriefings(data),
    meta: paginationMapper(meta),
  };
};

export const useGetDailyBriefing = ({
  filters,
  params,
  enabled = true,
}: {
  filters: DailyBriefingsFilters;
  params: Record<string, string | number>;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DAILY_BRIEFINGS, filters, params],
    queryFn: () => getDailyBriefings(filters, params),
    staleTime: 1000 * 60 * 10,
    enabled,
  });
};

const mapBriefings = (
  data: {
    attributes: UCM.DailyBriefingRawType;
    id: string;
  }[],
): UCM.DailyBriefingType[] | null => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    userId: attributes?.user_id ?? null,
    jobId: attributes.job_id,
    jobDateId: attributes.job_date_id,
    jobUuid: attributes.job_uuid,
    status: attributes.status,
    jobDate: {
      startsAt: attributes.job_date.starts_at,
      endsAt: attributes.job_date.ends_at,
    },
    briefing: attributes?.briefing
      ? {
          meetingPoint: attributes.briefing?.meeting_point ?? null,
          meetingTime: attributes.briefing?.meeting_time ?? null,
          contactPerson: attributes.briefing?.contact_person
            ? {
                name: attributes.briefing.contact_person?.name ?? null,
                phoneNumber:
                  attributes.briefing.contact_person.phone_number ?? null,
              }
            : null,
          instruction: attributes.briefing?.instruction ?? null,
          uuid: attributes.briefing?.uuid,
        }
      : null,
  }));
};

interface MetaBriefingRaw extends UCM.MetaRawType {
  unassigned_users_count: number;
}

interface MetaBriefing extends UCM.MetaType {
  unassignedUsersCount: number;
}

const paginationMapper = (meta: MetaBriefingRaw): MetaBriefing => ({
  totalData: meta.total_data,
  totalPages: meta.total_pages,
  currentPage: meta.current_page,
  unassignedUsersCount: meta.unassigned_users_count,
});
