import { useIntl } from 'react-intl';
import moment, { type Moment } from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { forceString } from 'src/utils/datetime';
import { postRequest } from 'src/utils/request';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { useCreateJobFields, type JobFieldType } from './useCreateJobFields';

import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import messages from 'src/pages/ProjectDetailsPage/messages';

export interface JobParamsType {
  venue: string;
  title: string;
  price: string;
  salary: string;
  address: string;
  zipCode: string;
  cityCode: string;
  quantity?: string;
  priceBonus: string;
  tariffCode: string;
  salaryBonus: string;
  description: string;
  jobTypeCode: string;
  houseNumber: string;
  staffTypeUuid: string;
  taskCodes: Array<string>;
  languageCodes: Array<string>;
  ignoreDesiredCities: boolean;
  bookingDeadlineDate: Moment | null;
  additionalAttributes: Array<UCM.JobDynamicFieldType>;
}

interface CreateJobParamsType extends JobParamsType {
  isFlex: boolean;
  isStandby: boolean;
  projectUuid: string;
  fields: Array<JobFieldType>;
}

const createJob = async (props: CreateJobParamsType) => {
  const params = {
    job: {
      title: props.title,
      type: props.jobTypeCode,
      total_vacancies: props.quantity,
      booking_deadline_date: forceString(
        props.bookingDeadlineDate,
        moment.HTML5_FMT.DATE,
      ),
      description: props.description,
      address: {
        name: props.venue,
        city: props.cityCode,
        post_code: props.zipCode,
        street_name: props.address,
        house_number: props.houseNumber,
      },
      wage: props.salary,
      price: props.price,
      is_flex: props.isFlex,
      tasks: props.taskCodes,
      is_standby: props.isStandby,
      wage_bonus: props.salaryBonus,
      price_bonus: props.priceBonus,
      tariff_code: props.tariffCode,
      languages: props.languageCodes,
      staff_type_uuid: props.staffTypeUuid,
      ignore_user_desired_cities: props.ignoreDesiredCities,
    },
  };

  const { data } = await postRequest<RequestSimplifiedData>(
    API.POST.createJob(props.projectUuid),
    params,
  );

  return data;
};

export const useCreateJob = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { mutate: createJobFields } = useCreateJobFields();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createJob,
    onSuccess: ({ data: { uuid } }, { fields }) => {
      if (fields.length > 0) createJobFields({ jobUuid: uuid, fields });

      handleSuccess(formatMessage(messages.jobSaved), 1000);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOB] });
    },
    onError: handleError,
  });
};
