/*
 * Project Details Page Messages
 *
 * This contains all the text for the Project Details Page component.
 */

import { defineMessages } from 'react-intl';
export const scope = 'src.pages.ProjectDetailsPage';

export default defineMessages({
  markedProjectPiority: {
    id: `${scope}.markProjectPiority`,
    defaultMessage: 'Marked as Priority',
  },
  unmarkedProjectPiority: {
    id: `${scope}.unmarkProjectPiority`,
    defaultMessage: 'Unmarked as Priority',
  },
  jobListSwitchLabel: {
    id: `${scope}.jobListSwitchLabel`,
    defaultMessage: 'Job List',
  },
  dailyBriefingSwitchLabel: {
    id: `${scope}.dailyBriefingSwitchLabel`,
    defaultMessage: 'Daily Briefing',
  },
  staffPlanDownloaded: {
    id: `${scope}.staffPlanDownloaded`,
    defaultMessage: 'Staff Plan downloaded successfully',
  },
  positionJobDetailsDownloaded: {
    id: `${scope}.positionJobDetailsDownloaded`,
    defaultMessage: 'Position jobs downloaded successfully',
  },
  userApplied: {
    id: `${scope}.userApplied`,
    defaultMessage: 'User applied successfully',
  },
  jobSaved: {
    id: `${scope}.jobSaved`,
    defaultMessage: 'Job saved successfully',
  },
  staffTypeSaved: {
    id: `${scope}.staffTypeSaved`,
    defaultMessage: 'Staff Type saved successfully',
  },
  staffTypeDeleted: {
    id: `${scope}.staffTypeDeleted`,
    defaultMessage: 'Staff Type deleted successfully',
  },
  jobDeleted: {
    id: `${scope}.jobDeleted`,
    defaultMessage: 'Job deleted successfully',
  },
  jobPartiallySaved: {
    id: `${scope}.jobPartiallySaved`,
    defaultMessage: 'Job saved partially',
  },
  importSuccess: {
    id: `${scope}.importSuccess`,
    defaultMessage: 'Import file went successfully',
  },
  jobCoverageSaved: {
    id: `${scope}.jobCoverageSaved`,
    defaultMessage: 'Job coverage saved successfully',
  },
  backupJobSaved: {
    id: `${scope}.backupJobSaved`,
    defaultMessage: 'Backup Job saved successfully',
  },
  BriefingCreated: {
    id: `${scope}.BriefingCreated`,
    defaultMessage: 'Briefing created successfully',
  },
  BriefingSaved: {
    id: `${scope}.BriefingSaved`,
    defaultMessage: 'Briefing saved successfully',
  },
  shiftSaved: {
    id: `${scope}.shiftSaved`,
    defaultMessage: 'Shift saved successfully',
  },
  shiftAccepted: {
    id: `${scope}.shiftAccepted`,
    defaultMessage: 'Application accepted successfully',
  },
  applicationBulkAccepted: {
    id: `${scope}.applicationBulkAccepted`,
    defaultMessage: 'Applications accepted successfully',
  },
  applicationReapplied: {
    id: `${scope}.applicationReapplied`,
    defaultMessage: 'Application re-applied successfully',
  },
  applicationRejected: {
    id: `${scope}.applicationRejected`,
    defaultMessage: 'Application rejected successfully',
  },
  applicationBulkRejected: {
    id: `${scope}.applicationBulkRejected`,
    defaultMessage: 'Applications rejected successfully',
  },
  applicationCancelled: {
    id: `${scope}.applicationCancelled`,
    defaultMessage: 'Application cancelled successfully',
  },
  cancellationSaved: {
    id: `${scope}.cancellationSaved`,
    defaultMessage: 'Cancellation saved successfully',
  },
  cancellationWithdrawn: {
    id: `${scope}.cancellationWithdrawn`,
    defaultMessage: 'Cancellation successfully withdrawn',
  },
  applicationFavorited: {
    id: `${scope}.applicationFavorited`,
    defaultMessage: 'Application favorited successfully',
  },
  applicationUnfavorited: {
    id: `${scope}.applicationUnfavorited`,
    defaultMessage: 'Application unfavorited successfully',
  },
  ratingSuccess: {
    id: `${scope}.ratingSuccess`,
    defaultMessage: 'Rating updated successfully',
  },
  rebookShiftSuccess: {
    id: `${scope}.rebookShiftSuccess`,
    defaultMessage: 'Application reset successfully',
  },
});
