import { useMutation } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { delay } from 'src/utils/component';
import { deleteRequest } from 'src/utils/request';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

interface JobFieldParamsType {
  jobUuid: string;
  fieldsToRemove: Array<Record<'fieldUuid', string>>;
}

const deleteJobFields = async ({
  fieldsToRemove,
  jobUuid,
}: JobFieldParamsType) => {
  const results: RequestSimplifiedData[] = [];

  await fieldsToRemove.reduce(
    (prevPromise, { fieldUuid }) =>
      prevPromise.then(async () => {
        const { data } = await deleteRequest<RequestSimplifiedData>(
          `${API.DELETE.deleteJobFields(jobUuid)}/${fieldUuid}`,
        );
        results.push(data);
        await delay(500);
      }),
    Promise.resolve(),
  );

  return results;
};

export const useDeleteJobFields = () => {
  const { handleError } = useMessageHandler();

  return useMutation({
    mutationFn: deleteJobFields,
    onError: handleError,
  });
};
