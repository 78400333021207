import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { JobParamsType } from './useCreateJob';

export interface saveStaffParamsType extends JobParamsType {
  name: string;
  uuid: string;
}

export function buildSaveStaffTypeParams(staffType: saveStaffParamsType) {
  return {
    staff_type: {
      name: staffType.name,
      price: staffType.price,
      wage: staffType.salary,
      job_name: staffType.title,
      tasks: staffType.taskCodes,
      job_type: staffType.jobTypeCode,
      languages: staffType.languageCodes,
      job_description: staffType.description,
    },
  };
}

const createStaffType = async (params: saveStaffParamsType) => {
  const { data } = await postRequest<RequestSimplifiedData>(
    API.POST.createStaffType,
    buildSaveStaffTypeParams(params),
  );

  return data;
};

export const useCreateStaffType = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createStaffType,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.staffTypeSaved), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.STAFF_TYPE_OPTIONS],
      });
    },
    onError: handleError,
  });
};
