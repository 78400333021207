import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';

export enum JobStatusEnum {
  DRAFT = 'draft',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  DONE = 'done',
}

const putUpdateJobStatus = async ({
  jobUuid,
  statusName,
}: {
  jobUuid: string;
  statusName: string;
}) => {
  let status = '';

  if (statusName == JobStatusEnum.DRAFT) {
    status = 'reset';
  } else if (statusName == JobStatusEnum.ACTIVE) {
    status = 'activate';
  } else if (statusName == JobStatusEnum.CANCELLED) {
    status = 'cancel';
  } else if (statusName == JobStatusEnum.DONE) {
    status = 'done';
  }

  const res = await putRequest(API.PUT.updateJobStatus(jobUuid, status));
  return res.data;
};

export const useUpdateJobStatus = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const client = useQueryClient();
  const { handleError } = useMessageHandler();

  return useMutation({
    mutationFn: putUpdateJobStatus,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] });
      if (onSuccess) onSuccess();
    },
    onError: handleError,
  });
};
