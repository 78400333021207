import { useIntl } from 'react-intl';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/GatekeeperPage/messages';

import type { RequestSimplifiedData } from 'src/types/requestData';

const grantStudentAccess = async (candidateUuid: string) => {
  const params = {
    candidate: { access_status: 'forced' },
  };
  await putRequest<RequestSimplifiedData>(
    API.PUT.grantStudentAccess(candidateUuid),
    params,
  );
};

export const useUpdateGrantStudentAccess = () => {
  const { formatMessage } = useIntl();
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: grantStudentAccess,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.accessGrantedSuccess), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.USER_ACCOUNTS],
      });
    },
    onError: handleError,
  });
};
