/*
 * PageLayout Messages
 *
 * This contains all the text for the PageLayout component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.components.PageLayout';

export default defineMessages({
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
});
