import { useState, useCallback, useRef, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';

import map from 'lodash/map';
import uniq from 'lodash/uniq';

import SearchFilterBar, {
  resolveQuickSearch,
  type QuickFiltersType,
} from 'src/components/SearchFilterBar';
import { forceString } from 'src/utils/datetime';
import DataTable from 'src/components/DataTable';
import PageLayout from 'src/components/PageLayout';

import messages from './messages';
import { useGetProjects } from './api';
import { Styled } from './index.styled';
import Typography from '@mui/material/Typography';

// TODO
// Need to put the exact type according to what BE returns
// BE needs to refactor this to keep it similar to the ways we do for Gatekeeper
const navTabs: Array<{ value: UCM.ProjectStatusType; label: string }> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'open',
    label: 'Open',
  },
];

export default function ProjectsPage() {
  const { formatMessage } = useIntl();

  const [activeTab, setActiveTab] = useState<UCM.ProjectStatusType>(
    navTabs[0].value,
  );

  const [filters, setFilters] = useState<Record<string, string>>({});
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: projects, isFetching: isFetchingProjects } = useGetProjects(
    activeTab,
    params,
    resolveQuickSearch(filters),
  );

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: true,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchInputPlaceholder),
    },
  ]).current;

  const fixedColumns = useMemo(() => {
    return [
      {
        id: 'name',
        label: formatMessage(messages.projectColumnLabel),
        render: (_: Object, project: UCM.ProjectType) => (
          <Link to={`/project_details/${project.uuid}`}>
            {project.id} - {project.name}
          </Link>
        ),
      },
      {
        id: 'customerName',
        label: formatMessage(messages.customerColumnLabel),
        render: (customerName: string) => (
          <Typography variant="inherit">{customerName}</Typography>
        ),
      },
      {
        id: 'dateRange',
        align: 'center',
        label: formatMessage(messages.periodColumnLabel),
        render: (_: Object, project: UCM.ProjectType) => (
          <Typography variant="inherit">
            {uniq([
              forceString(project.startDate),
              forceString(project.endDate),
            ]).join(' - ')}
          </Typography>
        ),
      },
      {
        id: 'cities',
        align: 'center',
        label: formatMessage(messages.locationsColumnLabel),
        render: (cities: Array<string> | null) => {
          if (!cities || !cities.length)
            return <Typography variant="inherit">-</Typography>;

          if (cities.length > 1) {
            return (
              <Grid container alignItems="center" justifyContent="center">
                <Tooltip
                  arrow
                  title={map(cities, (city) => (
                    <div key={city}>{city}</div>
                  ))}
                >
                  <Grid container spacing={0.5}>
                    {formatMessage(messages.multipleCitiesLabel)}
                    <Styled.SmallInfoOutlinedIcon />
                  </Grid>
                </Tooltip>
              </Grid>
            );
          }
          return <Typography variant="inherit">{cities[0]}</Typography>;
        },
      },
      {
        id: 'statusName',
        align: 'center',
        label: formatMessage(messages.statusColumnLabel),
        render: (status: string) => (
          <Typography variant="inherit">{status}</Typography>
        ),
      },
      {
        id: 'count',
        align: 'center',
        label: formatMessage(messages.bookingColumnLabel),
        render: (_: Object, project: UCM.ProjectType) => (
          <Typography variant="inherit">
            {project.bookedStaffCount}/{project.slotsCount}
          </Typography>
        ),
      },
    ];
  }, []);

  const columns: Record<
    UCM.ProjectStatusType,
    Array<{ id: keyof UCM.ProjectType | string; label: string }>
  > = useMemo(() => ({ all: fixedColumns, open: fixedColumns }), []);

  // eslint-disable-next-line
  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  const handleActiveTabChange = useCallback((tab: UCM.ProjectStatusType) => {
    setActiveTab(tab);
    setParams({ page: 1 });
  }, []);

  return (
    <PageLayout
      iconName="projects"
      title={formatMessage(messages.title)}
      tabs={navTabs}
      activeTab={activeTab}
      onActiveTabChange={handleActiveTabChange}
    >
      <Grid container spacing={2}>
        <Grid size={4}>
          <SearchFilterBar
            fields={quickFilters}
            onSearch={handleSearch}
            variant="noBorder"
          />
        </Grid>

        <Grid size={12}>
          <DataTable
            rows={projects?.data}
            pagination={projects?.meta}
            columns={columns[activeTab]}
            onPaginate={handlePagination}
            loading={isFetchingProjects}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
