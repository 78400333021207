// candidates/dab421e6-2387-4ee8-8fe8-2758d0a8b8d

import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest } from 'src/utils/request';

import { daysUntilToday } from 'src/utils/datetime';
import { keyBy } from 'lodash';
import { buildAttributes, buildChild, buildChildren } from 'src/utils/tools';

const getCandidateDetails = async (uuid?: string) => {
  const { data } = await getRequest(API.GET.getCandidate(uuid));

  return {
    data: mapCandidate(data?.data, data.included) as UCM.Candidate,
    included: data.included,
  };
};

export const useGetCandidateDetails = ({
  uuid,
  enabled = true,
}: {
  uuid?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER_RATING_SCORE, uuid],
    queryFn: () => getCandidateDetails(uuid),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};
// eslint-disable-next-line
const mapCandidate = (data: any, included: any) => {
  if (!data) return [];

  const paresedIncluded = keyBy(included, (item) => `${item.id}-${item.type}`);

  return buildCandidates(data, paresedIncluded);
};
// eslint-disable-next-line
export function findAttribute(parent: any, attributeName: string) {
  if (!parent.attributes) return null;
  if (!parent.attributes[attributeName]) return null;
  return parent.attributes[attributeName];
}
// eslint-disable-next-line
export function buildCandidates(data: any, included: any) {
  return buildAttributes(data, {
    email: 'email',
    lastName: 'last_name',
    firstName: 'first_name',
    rating: 'ratings_score',
    lastJobShift: () =>
      buildChild(data, included, 'last_working_day', null, {
        jobDate: (jobShift) =>
          buildChild(jobShift, included, 'job_date', null, {
            job: (jobDate) =>
              buildChild(jobDate, included, 'job', null, {
                title: 'title',
                jobType: (innerJob) =>
                  buildChild(innerJob, included, 'job_type'),
                project: (innerJob) =>
                  buildChild(innerJob, included, 'project'),
              }),
            startsAt: 'starts_at',
            endsAt: 'ends_at',
          }),
        age: (jobShift) => {
          const lastShift = findAttribute(jobShift, 'last_shift');
          return daysUntilToday(lastShift);
        },
      }),
    pastJobs: () => data?.meta?.completed_works_count ?? null,
    userLanguages: () =>
      buildChildren(data, included, 'user_preferred_languages', null, {
        language: (userLanguage) =>
          buildChild(userLanguage, included, 'language'),
        status: 'status',
      }),
    certificates: () =>
      buildChildren(data, included, 'certificates', null, {
        code: 'code',
        title: 'title',
      }),
    gender: () =>
      buildChild(data, included, 'gender', null, {
        code: 'code',
        name: 'name',
      }),
    status: () =>
      buildChild(data, included, 'status', null, {
        code: 'code',
        name: 'name',
      }),
  });
}
