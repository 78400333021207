import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import PageLayout from 'src/components/PageLayout';
import DataTable from 'src/components/DataTable';
import TextInput from 'src/components/TextInput';
import ConfirmationDialog from 'src/components/ConfirmationDialog';

import {
  useGetFines,
  FinesStatusType,
  useUpdateFine,
  useUpdatePayFine,
  useUpdateApproveFines,
  useDeleteFines,
  useUpdateExemptFines,
  useUpdateRevertFines,
  useCreateFinesReports,
} from 'src/pages/FinesPage/api';

import { formatNumber } from 'src/utils/standards';
import { locale } from 'src/config';

import messages from './messages';
import SearchFilterBar, {
  QuickFiltersType,
} from 'src/components/SearchFilterBar';
import CheckButton from '../ProjectDetailsPage/components/JobDetails/components/CheckButton/CheckButton';
import ExemptFineFormModal from './components/ExemptFineFormModal';
import PreviousExportFinesFormModal from './components/PreviousExportFinesFormModal';
import CreateFineFormModal from './components/CreateFineFormModal';

const navTabs: Array<{ value: FinesStatusType; label: string }> = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'exported',
    label: 'Exported',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'exempted',
    label: 'Exempted',
  },
];

interface ExplanationRef {
  [id: string]: {
    externalExplanation?: string | null;
    adminComment?: string | null;
    amount?: number | null;
  };
}

export default function FinesPage() {
  const { formatMessage } = useIntl();

  const [activeTab, setActiveTab] = useState<FinesStatusType>(navTabs[0].value);

  const [usersFines, setUsersFines] = useState<ExplanationRef>({});
  const [selected, setSelected] = useState<UCM.FinesType[]>([]);

  const [filters, setFilters] = useState<Record<string, string>>({});
  const [params, setParams] = useState<Record<string, string | number>>({
    page: 1,
  });

  const { data: fines, isFetching: isFetchingFines } = useGetFines({
    filters: {
      ...filters,
      status: activeTab,
    },
    params,
  });

  const { mutate: updateFine, isPending: isUpdatingFine } = useUpdateFine();
  const { mutate: updatePayFine, isPending: isUpdatingPayFine } =
    useUpdatePayFine();

  const { mutate: updateApproveFines, isPending: isUpdatingApproveFines } =
    useUpdateApproveFines();

  const { mutate: deleteFines, isPending: isDeletingFines } = useDeleteFines();

  const { mutate: updateExemptFines, isPending: isUpdatingExemptFines } =
    useUpdateExemptFines();

  const { mutate: updateRevertFines, isPending: isUpdatingRevertFines } =
    useUpdateRevertFines();

  const { mutate: createFinesReports, isPending: isCreatingFinesReports } =
    useCreateFinesReports();

  const clearFine = useCallback(
    (uuid: string) => {
      const local = { ...usersFines };
      delete local[uuid];

      setUsersFines(local);
    },
    [usersFines],
  );

  const handleSaveFine = useCallback(
    (uuid: string) => {
      const fine = usersFines[uuid];

      if (!fine) return;

      updateFine(
        {
          ...fine,
          uuid,
        },
        {
          onSuccess: () => clearFine(uuid),
        },
      );
    },
    [usersFines],
  );

  const handleChangeFine = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      item: UCM.FinesType,
      key: string,
    ) => {
      const { value } = event.target;
      const localData = usersFines[item.uuid] ?? {};
      const existFine = fines?.data.find((fine) => fine.id === item.id);

      let dataToSave = {
        ...localData,
        amount: existFine?.amount,
        adminComment: existFine?.adminComment,
        externalExplanation: existFine?.externalExplanation,
        [key]: value,
      };

      setUsersFines((prevState) => ({
        ...prevState,
        [item.uuid]: dataToSave,
      }));
    },
    [usersFines, fines?.data],
  );

  const handlePaidFine = useCallback((uuid: string) => {
    if (!uuid) return;

    updatePayFine({ uuid });
  }, []);

  const clearSelected = useCallback(() => {
    setSelected([]);
  }, []);

  const checkIfExist = useCallback(
    (uuid: string) => {
      return selected.findIndex((item) => item.uuid === uuid) !== -1;
    },
    [selected],
  );

  const isSelectAll = useMemo(() => {
    return fines?.data?.length !== 0 && selected.length === fines?.data?.length;
  }, [selected.length, fines?.data?.length]);

  const handleSelectAll = useCallback(() => {
    if (isSelectAll) {
      setSelected([]);
    } else if (fines?.data) {
      const allFines = fines?.data?.map((item) => item);
      setSelected(allFines);
    }
  }, [fines?.data, isSelectAll]);

  const handleSelect = useCallback(
    (fine: UCM.FinesType) => {
      const exist = checkIfExist(fine.uuid);
      let local = [...selected];
      if (exist) {
        const filtered = local.filter((item) => item.uuid !== fine.uuid);
        local = filtered;
      } else {
        local.push(fine);
      }
      setSelected(local);
    },
    [selected],
  );

  const fixedColumns = useMemo(() => {
    return [
      {
        id: 'selection',
        label: (
          <CheckButton active={isSelectAll} handleChange={handleSelectAll}>
            <Typography variant="inherit">
              {formatMessage(messages.fineIdColumnLabel)}
            </Typography>
          </CheckButton>
        ),
        render: (_: unknown, item: UCM.FinesType) => {
          if (!item?.uuid) return '-';
          const isSelected = checkIfExist(item?.uuid);
          return (
            <CheckButton
              active={isSelected}
              handleChange={() => handleSelect(item)}
            >
              <Typography variant="inherit">{item.id}</Typography>
            </CheckButton>
          );
        },
      },
      {
        id: 'created',
        label: formatMessage(messages.createdByColumnLabel),
        render: (_: unknown, item: UCM.FinesType) => {
          return (
            <>
              <Typography variant="inherit">{item.createdBy}</Typography>
              <Typography variant="inherit">{item.createdAt}</Typography>
            </>
          );
        },
        align: 'center',
      },
      {
        id: 'studentName',
        label: formatMessage(messages.studentNameColumnLabel),
        render: (_: object, item: UCM.FinesType) => (
          <Typography variant="inherit">
            {`${item.user.id} ${item.user.firstName} ${item.user.lastName}`}
          </Typography>
        ),
      },
      {
        id: 'cancellationNoticeId',
        label: formatMessage(messages.cancellationIdColumnLabel),
        render: (cancellationNoticeId: number) => (
          <Typography variant="inherit">{cancellationNoticeId}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'jobId',
        label: formatMessage(messages.jobIdColumnLabel),
        render: (jobId: string) => (
          <Typography variant="inherit">{jobId}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'reason',
        label: formatMessage(messages.reasonColumnLabel),
        render: (_: unknown, item: UCM.FinesType) => (
          <Typography variant="inherit">{item.reason.title}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'timeDeviation',
        label: formatMessage(messages.timeDeviationColumnLabel),
        render: (timeDeviation: string) => (
          <Typography variant="inherit">{timeDeviation ?? '-'}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'explanation',
        label: formatMessage(messages.explanationColumnLabel),
        render: (explanation: string) => (
          <Typography variant="inherit">{explanation}</Typography>
        ),
        align: 'center',
      },
      {
        id: 'amount',
        label: formatMessage(messages.fineAmountColumnLabel),
        render: (amount: string, item: UCM.FinesType) => {
          const value = usersFines[item.uuid]?.amount ?? (amount || '');
          return (
            <>
              <Typography color="textSecondary">
                {formatNumber(item.defaultAmount, locale) || '-'}
              </Typography>
              <TextInput
                name="amount"
                value={value}
                onChange={(event) => handleChangeFine(event, item, 'amount')}
                margin="none"
                size="small"
                sx={{ '& input::placeholder': { fontSize: '14px' } }}
                type="number"
                disabled={activeTab === 'paid' || activeTab === 'exempted'}
              />
            </>
          );
        },
      },
      {
        id: 'externalExplanation',
        label: formatMessage(messages.externalExplanationColumnLabel),
        render: (externalExplanation: string, item: UCM.FinesType) => {
          const value =
            usersFines[item.uuid]?.externalExplanation ??
            (externalExplanation || '');

          return (
            <TextInput
              name="externalExplanation"
              value={value}
              onChange={(event) =>
                handleChangeFine(event, item, 'externalExplanation')
              }
              margin="none"
              size="small"
              sx={{ '& input::placeholder': { fontSize: '14px' } }}
              disabled={activeTab === 'paid' || activeTab === 'exempted'}
            />
          );
        },
      },
      {
        id: 'adminComment',
        label: formatMessage(messages.adminCommentColumnLabel),
        render: (adminComment: string, item: UCM.FinesType) => {
          const value =
            usersFines[item.uuid]?.adminComment ?? (adminComment || '');

          return (
            <TextInput
              name="adminComment"
              value={value}
              onChange={(event) =>
                handleChangeFine(event, item, 'adminComment')
              }
              margin="none"
              size="small"
              sx={{ '& input::placeholder': { fontSize: '14px' } }}
              disabled={activeTab === 'paid' || activeTab === 'exempted'}
            />
          );
        },
      },
    ];
  }, [
    usersFines,
    activeTab,
    handleChangeFine,
    isSelectAll,
    handleSelectAll,
    checkIfExist,
    handleSelect,
  ]);

  const pendingColumns = useMemo(() => {
    return [
      ...fixedColumns,
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: (_: unknown, item: UCM.FinesType) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => handleSaveFine(item.uuid)}
            disabled={isUpdatingFine}
          >
            <SaveOutlinedIcon />
          </IconButton>
        ),
      },
    ];
  }, [fixedColumns, handleSaveFine, isUpdatingFine]);

  const approvedColumns = useMemo(() => {
    return [
      ...fixedColumns,
      {
        id: 'verified',
        label: formatMessage(messages.approvedByColumnLabel),
        render: (_: unknown, item: UCM.FinesType) => {
          return (
            <>
              <Typography variant="inherit">{item.verifiedBy}</Typography>
              <Typography variant="inherit">{item.verifiedAt}</Typography>
            </>
          );
        },
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: (_: unknown, item: UCM.FinesType) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => handleSaveFine(item.uuid)}
            disabled={isUpdatingFine}
          >
            <SaveOutlinedIcon />
          </IconButton>
        ),
      },
    ];
  }, [fixedColumns, handleSaveFine, isUpdatingFine]);

  const exportedColumns = useMemo(() => {
    return [
      ...fixedColumns,
      {
        id: 'verified',
        label: formatMessage(messages.approvedByColumnLabel),
        render: (_: unknown, item: UCM.FinesType) => {
          return (
            <>
              <Typography variant="inherit">{item.verifiedBy}</Typography>
              <Typography variant="inherit">{item.verifiedAt}</Typography>
            </>
          );
        },
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: (_: unknown, item: UCM.FinesType) => (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => handleSaveFine(item.uuid)}
              disabled={isUpdatingFine}
            >
              <SaveOutlinedIcon />
            </IconButton>
            <ConfirmationDialog
              onConfirm={() => handlePaidFine(item.uuid)}
              message={formatMessage(messages.paidConfirmMessage)}
            >
              {(handleDialogOpen) => (
                <Button
                  color="secondary"
                  name="confirmButton"
                  onClick={handleDialogOpen}
                  disabled={isUpdatingPayFine}
                >
                  {formatMessage(messages.paidButtonLabel)}
                </Button>
              )}
            </ConfirmationDialog>
          </Stack>
        ),
      },
    ];
  }, [
    fixedColumns,
    handleSaveFine,
    handlePaidFine,
    isUpdatingFine,
    isUpdatingPayFine,
  ]);

  const paidColumns = useMemo(() => {
    return [
      ...fixedColumns,
      {
        id: 'verified',
        label: formatMessage(messages.approvedByColumnLabel),
        render: (_: unknown, item: UCM.FinesType) => {
          return (
            <>
              <Typography variant="inherit">{item.verifiedBy}</Typography>
              <Typography variant="inherit">{item.verifiedAt}</Typography>
            </>
          );
        },
      },
    ];
  }, [fixedColumns]);

  const columns: Record<
    FinesStatusType,
    Array<{
      id: string;
      label: string | React.ReactNode;
    }>
  > = useMemo(
    () => ({
      pending: pendingColumns,
      approved: approvedColumns,
      exported: exportedColumns,
      paid: paidColumns,
      exempted: paidColumns,
    }),
    [pendingColumns, approvedColumns, exportedColumns, paidColumns],
  );

  const searchFilters = useMemo(() => {
    return [
      {
        id: 'user_id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchUidPlaceholder),
      },
      {
        id: 'id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchFineIdPlaceholder),
      },
      {
        id: 'job_id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchJobIdPlaceholder),
      },
      {
        id: 'cancellation_notice_id',
        type: 'text' as QuickFiltersType['type'],
        placeholder: formatMessage(messages.searchCancellationIdPlaceholder),
      },
    ];
  }, []);

  const handleApproveFines = useCallback(() => {
    const uuids = selected.map((item) => item.uuid);
    updateApproveFines(
      { uuids },
      {
        onSuccess: clearSelected,
      },
    );
  }, [selected, clearSelected]);

  const handleDeleteFines = useCallback(() => {
    const uuids = selected.map((item) => item.uuid);
    deleteFines(
      { uuids },
      {
        onSuccess: clearSelected,
      },
    );
  }, [selected, clearSelected]);

  const handleExempt = useCallback(() => {
    const uuids = selected.map((item) => item.uuid);
    updateExemptFines(
      { uuids },
      {
        onSuccess: clearSelected,
      },
    );
  }, [selected, clearSelected]);

  const handleExportFines = useCallback(() => {
    const uuids = selected.map((item) => item.uuid);
    createFinesReports(
      { uuids },
      {
        onSuccess: clearSelected,
      },
    );
  }, [selected, clearSelected]);

  const handleRevertFines = useCallback(() => {
    const uuids = selected.map((item) => item.uuid);
    updateRevertFines(
      { uuids },
      {
        onSuccess: clearSelected,
      },
    );
  }, [selected, clearSelected]);

  const actionsButtons = useMemo(() => {
    const isMultiple = selected.length > 1;
    if (activeTab === 'pending') {
      return [
        {
          id: 'approve',
          label: formatMessage(messages.approveSelectedButtonLabel),
          onConfirm: handleApproveFines,
          confirmationMessage: isMultiple
            ? formatMessage(messages.approveFinesConfirmMessage)
            : formatMessage(messages.approveFineConfirmMessage),
        },
        {
          id: 'delete',
          label: formatMessage(messages.deleteSelectedButtonLabel),
          onConfirm: handleDeleteFines,
          confirmationMessage: isMultiple
            ? formatMessage(messages.deleteFinesConfirmMessage)
            : formatMessage(messages.deleteFineConfirmMessage),
        },
      ];
    } else if (activeTab.match(/approved|exported/)) {
      return [
        activeTab === 'approved'
          ? {
              id: 'export',
              label: formatMessage(messages.exportSelectedButtonLabel),
              onConfirm: handleExportFines,
              confirmationMessage: isMultiple
                ? formatMessage(messages.exportFinesConfirmMessage)
                : formatMessage(messages.exportFineConfirmMessage),
            }
          : undefined,
        {
          id: 'revert',
          label: formatMessage(messages.revertSelectedButtonLabel),
          onConfirm: handleRevertFines,
          confirmationMessage: isMultiple
            ? formatMessage(messages.revertFinesConfirmMessage)
            : formatMessage(messages.revertFineConfirmMessage),
        },
      ];
    }

    return [];
  }, [selected, handleApproveFines, handleExportFines, handleRevertFines]);

  const handleActiveTabChange = useCallback((tab: FinesStatusType) => {
    setActiveTab(tab);
    setParams({ page: 1 });
    setSelected([]);
  }, []);

  const handlePagination = useCallback((_: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  // eslint-disable-next-line
  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const disableActions = useMemo(() => {
    return (
      selected.length === 0 ||
      isUpdatingFine ||
      isUpdatingPayFine ||
      isUpdatingApproveFines ||
      isDeletingFines ||
      isUpdatingRevertFines ||
      isUpdatingExemptFines ||
      isCreatingFinesReports
    );
  }, [
    selected,
    isUpdatingFine,
    isUpdatingPayFine,
    isUpdatingApproveFines,
    isDeletingFines,
    isUpdatingRevertFines,
    isUpdatingExemptFines,
    isCreatingFinesReports,
  ]);

  return (
    <PageLayout
      iconName="fines"
      title={formatMessage(messages.title)}
      tabs={navTabs}
      activeTab={activeTab}
      onActiveTabChange={handleActiveTabChange}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid size={6}>
          <SearchFilterBar
            fields={searchFilters}
            onSearch={handleSearch}
            variant="noBorder"
          />
        </Grid>
        <Grid size={6} container>
          <Grid size={9} container spacing={2}>
            {activeTab === 'exported' && <PreviousExportFinesFormModal />}
            {actionsButtons?.map(
              (button) =>
                button && (
                  <ConfirmationDialog
                    onConfirm={button.onConfirm}
                    message={button.confirmationMessage}
                    key={button.id}
                  >
                    {(handleDialogOpen) => (
                      <Button
                        color="secondary"
                        name="confirmButton"
                        onClick={handleDialogOpen}
                        disabled={disableActions}
                      >
                        {button.label}
                      </Button>
                    )}
                  </ConfirmationDialog>
                ),
            )}
            {activeTab.match(/pending|approved|exported/) && (
              <ExemptFineFormModal
                selected={selected}
                onConfirm={handleExempt}
                disabled={disableActions}
              />
            )}
          </Grid>
          <Grid
            size={3}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <CreateFineFormModal />
          </Grid>
        </Grid>
        <Grid size={12}>
          <DataTable
            rows={fines?.data}
            pagination={fines?.meta}
            columns={columns[activeTab]}
            onPaginate={handlePagination}
            loading={isFetchingFines}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
