import { useMemo, useRef } from 'react';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { shortWeekFormat, toMoment } from 'src/utils/datetime';

import type { DefaultTheme } from '@mui/styles';

interface Props {
  monthIndex: number;
  months: Array<string>;
}
export default function JobCalendarWeekdays({ monthIndex, months }: Props) {
  const calendarWeekSx = useRef((theme: DefaultTheme) => ({
    backgroundColor: theme.palette.grey[100],
  })).current;

  const weekdaysContent = useMemo(() => {
    const month = months?.[monthIndex];
    if (!month) return null;

    const currDay = toMoment(month)?.startOf('month').startOf('week');
    const firstWeek = currDay?.week();
    const weekdays = [];

    if (!currDay) return null;

    while (firstWeek === currDay.week()) {
      weekdays.push(currDay.format(shortWeekFormat));
      currDay?.add(1, 'day');
    }

    return weekdays;
  }, [months, monthIndex]);

  if (!weekdaysContent) return null;

  return (
    <Grid container size={12}>
      {weekdaysContent.map((days) => (
        <Grid
          key={days}
          flex={1}
          height={'44px'}
          sx={calendarWeekSx}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Typography variant="body2" textAlign={'center'}>
            {days}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}
