import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PencilAdornment } from 'src/utils/components';
import ConfirmModal from 'src/components/ConfirmModal';
import TextInput from 'src/components/TextInput';
import Dropdown from 'src/components/Dropdown';
import {
  REJECT_REASON,
  useGetRejectReason,
} from 'src/pages/ProjectDetailsPage/api/queries/useGetRejectReason';
import { forceString, forceTimeString } from 'src/utils/datetime';
import { useGetCancellationShifts } from 'src/pages/ProjectDetailsPage/api';
import { useGetCancellation } from 'src/pages/ProjectDetailsPage/api/queries/useGetCancellation';
import DataTable from 'src/components/DataTable';

import CheckButton from '../CheckButton/CheckButton';
import messages from './messages';

type Field = 'reason_code' | 'admin_comment' | 'explanation';

export type CancellationType = 'save' | 'update' | 'withdraw';

const INITIAL_STATE = { reason_code: '', admin_comment: '', explanation: '' };

interface Props extends UCM.AcceptRejectModalType {
  cancellation?: Partial<UCM.CancellationType> | null;
}

export default function CancellationModal({
  type,
  disabledButton,
  open,
  onClose,
  enabledFetch,
  isLoading,
  onConfirm,
  buttonLabel,
  candidate,
  job,
  cancellation,
}: Props) {
  const { formatMessage } = useIntl();

  const classes = customStyles();

  const [state, setState] = useState(INITIAL_STATE);
  const [selected, setSelected] = useState<string[]>([]);

  const { data: rejectReason } = useGetRejectReason(
    { reason_for: type },
    { enabled: enabledFetch },
  );

  const { data: cancellationReason } = useGetRejectReason(
    { reason_for: REJECT_REASON.CANCELLATION_NOTICE },
    { enabled: Boolean(cancellation) },
  );

  const {
    data: cancellationData,
    isPending: isPendingCancellationData,
    isSuccess,
  } = useGetCancellation({
    uuid: cancellation?.uuid,
    enabled: Boolean(cancellation),
  });

  const params = useMemo(
    () => ({ user_id: candidate?.id, job_id: job?.id, timespan: 'all' }),
    [candidate?.id],
  );

  const { data: shifts, isPending } = useGetCancellationShifts({
    params,
    enabled: Boolean(candidate?.id) && Boolean(job?.id) && !cancellation,
  });

  useEffect(() => {
    if (isSuccess && cancellationData) {
      setState({
        reason_code: '',
        admin_comment: cancellationData?.adminComment ?? '',
        explanation: cancellationData?.explanation ?? '',
      });
      const uuids: Array<string> =
        cancellationData?.jobShifts?.map((item) => item.uuid) ?? [];
      setSelected(uuids);
    }
  }, [isSuccess, open]);

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
    setState(INITIAL_STATE);
    setSelected([]);
  }, [onClose]);

  const handleChange = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      key: Field,
    ) => {
      const value = e.target.value as string;

      setState((prevState) => ({ ...prevState, [key]: value }));
    },
    [],
  );

  const checkIfExist = useCallback(
    (uuid: string) => {
      return selected.findIndex((item) => item === uuid) !== -1;
    },
    [selected],
  );

  const dataToSelect = useMemo(() => {
    return cancellation ? cancellationData?.jobShifts : shifts;
  }, [shifts?.length, cancellation, cancellationData?.jobShifts]);

  const isSelectAll = useMemo(() => {
    return (
      dataToSelect?.length !== 0 && selected.length === dataToSelect?.length
    );
  }, [selected, dataToSelect]);

  useEffect(() => {
    if (isSelectAll && cancellation && state?.reason_code !== '') {
      setState((prevState) => ({ ...prevState, reason_code: '' }));
    }
  }, [isSelectAll]);

  const handleSelectAll = useCallback(() => {
    if (isSelectAll) {
      setSelected([]);
    } else if (dataToSelect) {
      const ids = dataToSelect?.map((item) => item.uuid);
      setSelected(ids);
    }
  }, [dataToSelect, isSelectAll]);

  const handleSelect = useCallback(
    (uuid: string) => {
      const exist = checkIfExist(uuid);
      let local = [...selected];
      if (exist) {
        const filtered = local.filter((item) => item !== uuid);
        local = filtered;
      } else {
        local.push(uuid);
      }
      setSelected(local);
    },
    [selected, cancellation],
  );

  const handleSubmit = useCallback(async () => {
    if (typeof onConfirm == 'function') {
      let type: CancellationType = 'save';
      if (cancellation && selected?.length !== 0) {
        type = 'update';
      } else if (cancellation && selected?.length === 0) {
        type = 'withdraw';
      }
      onConfirm({
        ...state,
        job_shift_uuids: selected,
        user_uuid: candidate?.uuid,
        cancellationUuid: cancellationData?.uuid ?? undefined,
        type,
      } as object);
    }
  }, [
    state,
    selected,
    candidate,
    onConfirm,
    cancellation,
    cancellationData,
    isSelectAll,
  ]);

  const columns: Array<{
    id: keyof UCM.ShiftType | string;
    label: string | React.ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'actions',
        label: (
          <CheckButton active={isSelectAll} handleChange={handleSelectAll} />
        ),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '-';
          const { uuid } = item;
          const isSelected = checkIfExist(uuid);
          return (
            <CheckButton
              active={isSelected}
              handleChange={() => handleSelect(item.uuid)}
            />
          );
        },
        align: 'center',
      },
      {
        id: 'startsAt',
        label: formatMessage(messages?.dateLabel),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '';
          return (
            <Typography variant="inherit">
              {forceString(item?.shift.startsAt)}
            </Typography>
          );
        },
        align: 'center',
      },
      {
        id: 'jobId',
        label: formatMessage(messages?.jobIdLabel),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '';
          return <Typography variant="inherit">{item?.shift.jobId}</Typography>;
        },
        align: 'center',
      },
      {
        id: 'timeframe',
        label: formatMessage(messages?.shiftTimeframeLabel),
        render: (_: Object, item: UCM.ShiftType) => {
          if (!item?.shift) return '';
          return (
            <Typography variant="inherit">
              {`${forceTimeString(item?.shift.startsAt)} - ${forceTimeString(item?.shift.endsAt)}`}
            </Typography>
          );
        },
        align: 'center',
      },
    ],
    [selected, handleSelect, handleSelectAll],
  );

  const disabledForm = useMemo(() => {
    if (cancellation) {
      return !isSelectAll && state.reason_code === '';
    }

    return (
      state.reason_code === '' || state?.explanation === '' || !selected?.length
    );
  }, [state, selected, cancellationData, cancellation]);

  const userName = useMemo(() => {
    if (!candidate) return '-';

    if ('fullName' in candidate) {
      return candidate.fullName.trim() || '-';
    }

    const { firstName, lastName } = candidate;
    return (
      [firstName, lastName]
        .map((name) => name.trim())
        .filter(Boolean)
        .join(' ') || '-'
    );
  }, [candidate]);

  const userEmail = useMemo(() => {
    if (!candidate) return '-';
    if ('email' in candidate) return candidate?.email;
    return '-';
  }, [candidate]);

  return (
    <ConfirmModal
      onConfirm={handleSubmit}
      maxWidth="550px"
      open={open}
      disabled={disabledForm}
      onClose={handleClose}
      submitTextButton={formatMessage(messages.confirmButtonLabel)}
      title={formatMessage(messages.header)}
      confirmationRequired={!cancellation}
      confirmationTitle={formatMessage(messages.confirmCancelTitle)}
      confirmationMessage={formatMessage(messages.confirmCancelMessage)}
      triggerButton={(handleOpen) => {
        return (
          buttonLabel && (
            <LoadingButton
              onClick={handleOpen}
              loading={isLoading}
              disabled={disabledButton}
              variant="contained"
            >
              {buttonLabel}
            </LoadingButton>
          )
        );
      }}
    >
      <Stack mb={2} spacing={1}>
        <Grid container spacing={2}>
          <Grid size={4}>
            <TextInput
              value={candidate?.id}
              label={formatMessage(messages.userInputLabel)}
              disabled
              margin="none"
            />
          </Grid>
          <Grid size={8} flex={1}>
            <TextInput
              value={userEmail}
              label={formatMessage(messages.emailInputLabel)}
              disabled
              margin="none"
              fullWidth
            />
          </Grid>
        </Grid>
        <TextInput
          value={userName}
          label={formatMessage(messages.nameInputLabel)}
          disabled
          margin="none"
          fullWidth
          className="mb-2"
        />
        {cancellation && (
          <TextInput
            value={cancellation?.id}
            label={formatMessage(messages.cancelIdInputLabel)}
            disabled
            margin="none"
            fullWidth
          />
        )}
      </Stack>

      <DataTable
        columns={columns}
        rows={cancellation ? cancellationData?.jobShifts : shifts}
        loading={cancellation ? isPendingCancellationData : isPending}
        className={classes.tableContainer}
        enabledFooter={false}
      />

      {cancellation && !isSelectAll && (
        <Dropdown
          label={formatMessage(messages.cancelDropDownLabel)}
          value={state.reason_code}
          options={rejectReason}
          onChange={(e) => handleChange(e, 'reason_code')}
          fullWidth
        />
      )}
      <Dropdown
        label={formatMessage(messages.reasonDropDownLabel)}
        value={
          cancellationData ? cancellationData?.reason.code : state.reason_code
        }
        options={cancellationData ? cancellationReason : rejectReason}
        onChange={(e) => handleChange(e, 'reason_code')}
        fullWidth
        disabled={Boolean(cancellationData)}
      />
      <TextInput
        value={state.explanation}
        label={formatMessage(messages.userCommentInputLabel)}
        onChange={(e) => handleChange(e, 'explanation')}
        EndIcon={PencilAdornment}
        margin="none"
        size="small"
        sx={{ '& input::placeholder': { fontSize: '14px' } }}
      />
      {((cancellation && selected?.length !== 0) || !cancellation) && (
        <TextInput
          value={state.admin_comment}
          label={formatMessage(messages.adminCommentInputLabel)}
          onChange={(e) => handleChange(e, 'admin_comment')}
          EndIcon={PencilAdornment}
          margin="none"
          size="small"
          sx={{ '& input::placeholder': { fontSize: '14px' } }}
        />
      )}
    </ConfirmModal>
  );
}

// @ts-ignore
export const customStyles = makeStyles(() => ({
  tableContainer: {
    minHeight: 'auto!important',
    maxHeight: '200px',
    overflowY: 'scroll !important',
    overflowX: 'hidden',
    width: 'calc(100% - 2px)!important',

    '& td': { padding: '16px' },
  },
}));
