/*
 * CancellationBackupButton Messages
 *
 * This contains all the text for the CancellationBackupButton component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.CancellationsPage.components.CancellationBackupButton';

export default defineMessages({
  cancellationBackupSuccess: {
    id: `${scope}.cancellationBackupSuccess`,
    defaultMessage: 'Backup required for this cancellation',
  },
  cancellationNoBackupSuccess: {
    id: `${scope}.cancellationNoBackupSuccess`,
    defaultMessage: 'No backup required for this cancellation',
  },
  noBackupConfirmMessage: {
    id: `${scope}.noBackupConfirmMessage`,
    defaultMessage: 'Do you really want no backup for this cancellation?',
  },
  requireBackupConfirmMessage: {
    id: `${scope}.requiredBackupConfirmMessage`,
    defaultMessage:
      'Do you really want to require backup for this cancellation?',
  },
});
