/*
 * ShiftEditPanel Messages
 *
 * This contains all the text for the ShiftEditPanel component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobList.components.ShiftsForm.components.ShiftEditPanel';

export default defineMessages({
  dateLabel: {
    id: `${scope}.dateLabel`,
    defaultMessage: 'Date',
  },
  quantityLabel: {
    id: `${scope}.quantityLabel`,
    defaultMessage: 'Quantity',
  },
  startTimeLabel: {
    id: `${scope}.startTimeLabel`,
    defaultMessage: 'Start Time',
  },
  endTimeLabel: {
    id: `${scope}.endTimeLabel`,
    defaultMessage: 'End Time',
  },
  saveShiftButton: {
    id: `${scope}.saveShiftButton`,
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: `${scope}.cancelButton`,
    defaultMessage: 'Cancel',
  },
  addButton: {
    id: `${scope}.addButton`,
    defaultMessage: 'Add Shift',
  },
});
