/*
 * StandbyCoverageForm Messages
 *
 * This contains all the text for the StandbyCoverageForm.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobList.components.StandbyCoverageForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Job Coverage',
  },
  coverageButtonTooltip: {
    id: `${scope}.coverageButtonTooltip`,
    defaultMessage: 'Job Coverage',
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search by Job ID, Title, Project ID',
  },
  jobColumnLabel: {
    id: `${scope}.jobColumnLabel`,
    defaultMessage: 'Job',
  },
  projectColumnLabel: {
    id: `${scope}.projectColumnLabel`,
    defaultMessage: 'Project',
  },
  dateColumnLabel: {
    id: `${scope}.dateColumnLabel`,
    defaultMessage: 'Date Range',
  },
  bookingColumnLabel: {
    id: `${scope}.bookingColumnLabel`,
    defaultMessage: 'Booked',
  },
  compensationColumnLabel: {
    id: `${scope}.compensationColumnLabel`,
    defaultMessage: 'Compensation',
  },
  searchFieldLabel: {
    id: `${scope}.searchFieldLabel`,
    defaultMessage:
      'Available jobs (according to project timeframe and location)',
  },
  transferBonusLabel: {
    id: `${scope}.transferBonusLabel`,
    defaultMessage: 'Transfer Bonus',
  },
  confirmButton: {
    id: `${scope}.confirmButton`,
    defaultMessage: 'Confirm',
  },
  clearButton: {
    id: `${scope}.clearButton`,
    defaultMessage: 'Clear',
  },
});
