import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import startsWith from 'lodash/startsWith';

import {
  buildChild,
  buildChildren,
  buildAttributes,
} from 'src/utils/selectors';
import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

export interface DynamicOptionType {
  label: string;
  value: string;
  dynamicField: UCM.DynamicFieldType;
}

type IncludedDataResponse<
  TDataAttributes = unknown,
  TIncluded = unknown,
  TMeta = unknown,
> = {
  data: Array<{
    id: string;
    type: string;
    attributes: TDataAttributes;
    relationships: {
      type: { data: { id: string; type: string } };
      category: { data: { id: string; type: string } };
      options: { data: Array<Record<string, any>> }; // eslint-disable-line
    };
  }>;
  included: Array<{
    id: string;
    type: string;
    attributes: TIncluded;
  }>;
  links: Record<string, string | null>;
  meta: TMeta;
};

export const mapDynamicField = (
  fields: IncludedDataResponse<UCM.DynamicFieldRawType>['data'],
  included: Record<
    string,
    { id: string; type: string; attributes: UCM.DynamicFieldIncludedRawType }
  >,
): Array<DynamicOptionType> => {
  return map(fields, (field) => {
    const dynamicField =
      (buildAttributes(field, {
        description: () =>
          startsWith(field.attributes.description, '*')
            ? field.attributes.description.substring(1)
            : null,
        isVisibleByUser: 'is_visible_by_user',
        label: 'name_en',
      }) as UCM.DynamicFieldType) ?? {};
    dynamicField.options =
      buildChildren(field, included, 'options', null, {
        label: 'input_value_en',
        value: 'uuid',
      }) ?? [];

    dynamicField.category = buildChild(field, included, 'category', null, {
      name: 'name_en',
    });
    dynamicField.type = buildChild(field, included, 'type');

    return {
      dynamicField,
      label: dynamicField.label,
      value: dynamicField.uuid,
    };
  });
};

const getDynamicFieldOptions = async (page: number) => {
  const { data } = await getRequest<
    IncludedDataResponse<
      UCM.DynamicFieldRawType,
      UCM.DynamicFieldIncludedRawType,
      UCM.MetaRawType
    >
  >(`${API.GET.getDynamicFields}?page=${page}`, {
    headers: { Accept: 'application/vnd.falcon.v1+json' },
  });

  const included = keyBy(data.included, (item) => `${item.id}-${item.type}`);

  return mapDynamicField(data.data, included);
};

export const useGetDynamicFieldOptions = (
  page: number = 1,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DYNAMIC_FIELD_OPTIONS, page],
    queryFn: () => getDynamicFieldOptions(page),
    ...config,
  });
};
