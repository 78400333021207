import React, { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
  active?: boolean;
  handleChange?: (active: boolean) => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export default function CheckButton({
  active,
  handleChange,
  children,
  disabled,
}: Props) {
  const handlePress = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();

      if (handleChange && !disabled) handleChange(!active);
    },
    [active, disabled, handleChange],
  );

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={1}
      onClick={handlePress}
      sx={{
        cursor:
          typeof handleChange === 'function' && !disabled
            ? 'pointer'
            : 'default',
      }}
    >
      <Box sx={{ opacity: disabled ? 0.38 : 1 }} display="flex">
        {active ? (
          <CheckBoxIcon sx={{ width: 16, height: 16 }} />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon sx={{ width: 16, height: 16 }} />
        )}
      </Box>
      {children}
    </Stack>
  );
}
