import { useState } from 'react';

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid2';

import PageLayout from 'src/components/PageLayout';
import ToggleSwitch from 'src/components/ToggleSwitch';

import messages from './messages';
import { useGetProject } from './api';
import JobList from './components/JobList';
import SummaryPanel from './components/SummaryPanel';
import JobForm from './components/JobForm';
import DailyBriefings from './components/DailyBriefings';

type ToggleSwitchType = 'Job List' | 'Daily Briefings';

export default function ProjectDetailsPage() {
  const { formatMessage } = useIntl();
  const { uuid } = useParams<{ uuid: string }>();

  const [selected, setSelected] = useState<ToggleSwitchType>('Job List');

  const { data: project } = useGetProject(uuid ?? '', {
    enabled: !!uuid,
  });

  if (!project) {
    return null;
  }

  return (
    <PageLayout backHref="/projects">
      <Grid container spacing={3} width="100%">
        <Grid size={12}>
          <SummaryPanel
            title={project.name}
            projectId={project.id}
            cities={project.cities}
            endDate={project.endDate}
            projectUuid={project.uuid}
            status={project.statusName}
            startDate={project.startDate}
            company={project.customer.name}
            slotsCount={project.totalPublished.slotsCount}
            bookedStaffCount={project.totalPublished.bookedCount}
            isPriority={project.isPriority && project.statusName === 'active'}
          />
        </Grid>

        <Grid size={6}>
          <ToggleSwitch
            selected={selected}
            onChange={setSelected}
            options={[
              formatMessage(messages.jobListSwitchLabel),
              formatMessage(messages.dailyBriefingSwitchLabel),
            ]}
          />
        </Grid>

        <Grid size={6} display="flex" justifyContent="flex-end">
          <JobForm projectUuid={project.uuid} isCreate />
        </Grid>

        <Grid size={12}>
          {selected === 'Job List' ? (
            <JobList projectUuid={project.uuid} />
          ) : (
            <DailyBriefings projectUuid={project.uuid} />
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
}
