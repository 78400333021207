import React, { useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export interface PageLayoutTabProps<T> {
  activeTab?: T;
  onActiveTabChange?: (tab: T) => void;
}

export interface DashboardTabProps<T> {
  tabs?: Array<{ label: string; value: T }>;
}

export type NavTabProps<T> = PageLayoutTabProps<T> & DashboardTabProps<T>;

const noIndicator = { style: { display: 'none' } };

export default function NavTabs<T extends string>({
  tabs,
  activeTab,
  onActiveTabChange,
}: NavTabProps<T>) {
  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: T) => {
      if (onActiveTabChange) onActiveTabChange(newValue);
    },
    [onActiveTabChange],
  );

  if (tabs == null) return null;

  return (
    <Tabs
      value={activeTab ?? false}
      onChange={handleChange}
      TabIndicatorProps={noIndicator}
    >
      {tabs.map(({ label, value }) => (
        <Tab key={String(value)} value={value} label={label} />
      ))}
    </Tabs>
  );
}
