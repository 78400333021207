import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, serialize } from 'src/utils/request';

export enum REJECT_REASON {
  CANCELLATION_NOTICE = 'cancellation_notice',
  REJECT_APPLICATION = 'job_application_rejection',
  CANCEL_APPLICATION = 'job_application_cancellation',
  CANCEL_TIME_TRACKING = 'user_job_dates_cancellation',
  RESET_TIME_TRACKING = 'user_job_dates_reset',
}

const getRejectReason = async (filters: Record<string, string>) => {
  const queryFilters = serialize(filters, 'filters');

  const { data } = await getRequest(
    API.GET.getRejectReason(queryFilters.toString()),
  );

  return mapRejectReason(data?.data);
};

export const useGetRejectReason = (
  filters: Record<string, string>,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REJECT_REASON, filters],
    queryFn: () => getRejectReason(filters),
    staleTime: 1000 * 60 * 5,
    ...config,
  });
};

const mapRejectReason = (
  data: {
    attributes: UCM.RejectReasonRawType;
    id: string;
  }[],
): UCM.RejectReasonType[] => {
  if (!data) return [];

  return data.map(({ attributes, id }) => ({
    id,
    value: attributes.code,
    reasonFor: attributes.reason_for,
    label: attributes.title,
  }));
};
