/*
 * BackupJobEditPanel Messages
 *
 * This contains all the text for the BackupJobEditPanel component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.BackupJobForm.components.BackupJobEditPanel';

export default defineMessages({
  userAmountLabel: {
    id: `${scope}.userAmountLabel`,
    defaultMessage: 'User Amount',
  },
  oneTimeBonusLabel: {
    id: `${scope}.oneTimeBonusLabel`,
    defaultMessage: 'One-time Bonus',
  },
  statusLabel: {
    id: `${scope}.statusLabel`,
    defaultMessage: 'Status',
  },
  jobSettingsLabel: {
    id: `${scope}.jobSettingsLabel`,
    defaultMessage: 'Job Settings',
  },
});
