import styled from '@mui/material/styles/styled';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { colors } from 'src/utils/customColors';

export const Styled = {
  StyledToggleGroup: styled(ToggleButtonGroup)(({ theme }) => ({
    border: `thin solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(3.125),
  })),
  StyledToggleButton: styled(ToggleButton)(({ theme }) => ({
    border: 'none',
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.55, 2),
    borderRadius: theme.spacing(3.125),
    '&.Mui-selected': {
      background: colors.lightestBlue,
      color: colors.darkerTurquoise,
      border: `thin solid ${colors.lightBlue}`,
      borderRadius: theme.spacing(3.125),
    },
  })),
};
