/*
 * MainJob Messages
 *
 * This contains all the text for the MainJob component.
 */

import { defineMessages } from 'react-intl';

export const scope =
  'src.pages.ProjectDetailsPage.components.JobDetails.components.MainJob';

export default defineMessages({
  jobSerchLabel: {
    id: `${scope}.jobSerchLabel`,
    defaultMessage: 'In Job Search',
  },
  allTabLabel: {
    id: `${scope}.allTabLabel`,
    defaultMessage: 'All',
  },
  appliedTabLabel: {
    id: `${scope}.appliedTabLabel`,
    defaultMessage: 'Applied',
  },
  acceptedTabLabel: {
    id: `${scope}.accepted`,
    defaultMessage: 'Accepted',
  },
  bookedTabLabel: {
    id: `${scope}.bookedTabLabel`,
    defaultMessage: 'Booked',
  },
  deniedTabLabel: {
    id: `${scope}.deniedTabLabel`,
    defaultMessage: 'Denied',
  },
  doneTabLabel: {
    id: `${scope}.doneTabLabel`,
    defaultMessage: 'Done',
  },
});
