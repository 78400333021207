import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { putRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';

const cancellationNotices = async ({
  cancellationUuid,
  data,
}: {
  cancellationUuid?: string;
  data: {
    explanation?: string;
    reason_code?: string;
    user_uuid?: string;
    admin_comment?: string;
    job_shift_uuids?: string[];
  };
}) => {
  const payload = { cancellation_notice: { ...data } };

  const res = await putRequest(
    API.PUT.updateCancellationNotices(cancellationUuid),
    payload,
  );
  return res.data;
};

export const useUpdateCancellationNotices = ({
  inCancellationsPage = false,
}: {
  inCancellationsPage?: boolean;
}) => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: cancellationNotices,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.CANCELLATION_NOTICES],
      });
      if (inCancellationsPage) {
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.CANCELLATIONS],
        });
      } else {
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.JOB_APPLICATIONS_COUNT],
        });
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.CALENDAR_JOBS],
        });
      }
      handleSuccess(formatMessage(messages.cancellationSaved));
    },
    onError: handleError,
  });
};
